import { styled } from '@mui/system';
import * as React from 'react';
import colors from '../../config/theme/colors';
import { Container, Item } from 'Components/Grid';

const NextIcon = ({ skip, flip }: { disabled?: boolean; skip?: true; flip?: true }): JSX.Element => (
  <svg
    transform={flip ? 'rotate(180)' : undefined}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.29492 16.59L12.8749 12L8.29492 7.41L9.70492 6L15.7049 12L9.70492 18L8.29492 16.59Z"
      fill="currentColor"
    />
    {skip && <rect x="16" y="6" width="2" height="12" fill="currentColor" />}
  </svg>
);

const StyledControls = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})(({ disabled }: { disabled?: boolean }) => ({
  position: 'relative',
  top: 3,
  userSelect: 'none',
  color: `${disabled ? colors.gray : colors.primary} !important`,
  '&:hover': {
    cursor: disabled ? undefined : 'pointer',
  },
}));

const StyledControlsContainer = styled(Container)({
  width: '100%',
  height: '5rem',
  color: colors.primary,
  fontWeight: 600,
  fontSize: '1.6rem',
});

const StyledRange = styled('div')({
  width: '10rem',
  padding: '0 0.5rem 0 0.5rem',
  userSelect: 'none',
  textAlign: 'center',
});

export const PageControls = ({
  length,
  limitOfItemsInPage,
  pageNumber,
  setPageNumber,
  stepSize,
  controlsJustify,
}: IPageControls): JSX.Element => {
  const range = `${pageNumber * (stepSize || limitOfItemsInPage) + 1} - ${
    pageNumber * (stepSize || limitOfItemsInPage) + limitOfItemsInPage > length - 1
      ? length
      : pageNumber * (stepSize || limitOfItemsInPage) + limitOfItemsInPage
  } / ${length}`;

  const pages = Math.ceil(stepSize ? length - (limitOfItemsInPage - 1) : length / limitOfItemsInPage);

  return (
    <StyledControlsContainer alignItems="center" justifyContent={controlsJustify}>
      {length > 0 && (
        <Item xs={6} style={{ display: 'flex', justifyContent: controlsJustify, alignItems: 'center' }}>
          <StyledControls disabled={pageNumber === 0} onClick={() => (pageNumber === 0 ? '' : setPageNumber(0))}>
            <NextIcon skip flip />
          </StyledControls>
          <StyledControls
            disabled={pageNumber === 0}
            onClick={() => (pageNumber === 0 ? '' : setPageNumber(pageNumber - 1))}
          >
            <NextIcon flip />
          </StyledControls>
          <StyledRange>{range}</StyledRange>
          <StyledControls
            disabled={pageNumber === pages - 1}
            onClick={() => (pageNumber === pages - 1 ? '' : setPageNumber(pageNumber + 1))}
          >
            <NextIcon />
          </StyledControls>
          <StyledControls
            disabled={pageNumber === pages - 1}
            onClick={() => (pageNumber === pages - 1 ? '' : setPageNumber(pages - 1))}
          >
            <NextIcon skip />
          </StyledControls>
        </Item>
      )}
    </StyledControlsContainer>
  );
};

interface IPageControls {
  /** How many items in the list total */
  length: number;
  /** Which page is currently selected */
  pageNumber: number;
  /** Set page */
  setPageNumber: (p: number) => void;
  /** How many items are shown in the page */
  limitOfItemsInPage: IPagingWrapper['limitOfItemsInPage'];
  /** If set, changes paging to work as steps where stepsize controls how many items are stepped through */
  stepSize?: number;
  /** Where in the container are the controls shown */
  controlsJustify: IPagingWrapper['controlsJustify'];
}

const PagingWrapper = ({
  list = [],
  listItemComponent,
  limitOfItemsInPage = 25,
  stepSize,
  headerComponent,
  topControls = true,
  bottomControls = true,
  controlsJustify = 'center',
}: IPagingWrapper) => {
  const [currentPage, setCurrentPage] = React.useState<number>(0);

  const shownListItems = list.slice(
    currentPage * (stepSize || limitOfItemsInPage),
    currentPage * (stepSize || limitOfItemsInPage) + limitOfItemsInPage,
  );

  // Only show paging controls if there are more than one page
  const showPagingControls = list.length > limitOfItemsInPage;

  return (
    <div>
      {topControls && showPagingControls && (
        <PageControls
          length={list.length}
          limitOfItemsInPage={limitOfItemsInPage}
          pageNumber={currentPage}
          setPageNumber={setCurrentPage}
          stepSize={stepSize}
          controlsJustify={controlsJustify}
        />
      )}
      {headerComponent && headerComponent}
      {shownListItems.map((li, i) => listItemComponent(li, i))}
      {bottomControls && showPagingControls && (
        <PageControls
          length={list.length}
          limitOfItemsInPage={limitOfItemsInPage}
          pageNumber={currentPage}
          setPageNumber={setCurrentPage}
          stepSize={stepSize}
          controlsJustify={controlsJustify}
        />
      )}
    </div>
  );
};

interface IPagingWrapper {
  /** List of paged items */
  list: any[];
  /** Component which renders the items in the list */
  listItemComponent: (listItem: any, index: number) => JSX.Element;
  /** Limit on how many items are shown */
  limitOfItemsInPage: number;
  /** If set, changes paging to work as steps where stepsize controls how many items are stepped through */
  stepSize?: number;
  /** Optional headers component rendered under top page controls */
  headerComponent?: JSX.Element;
  /** Whether to render top page controls */
  topControls?: boolean;
  /** Whether to render bottom page controls */
  bottomControls?: boolean;
  /** Align page changing controls */
  controlsJustify?: 'start' | 'center' | 'end';
}

export default PagingWrapper;
