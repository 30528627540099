import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import HistorySection from '../../../../components/HistorySection';
import { IHistoryContext, withHistoryContext } from '../../../../containers/FormContextHandler';
import { DocumentCreationButtonWithContext } from '../../utils';
import { TreatmentHistorySection } from './components';
import HistoryRow from 'Components/HistoryRow';
import { PAPTherapyContext } from '..';

const PAPTherapy = ({ documents = [], startEdit, view }: IHistoryContext<IPAPTherapy>): JSX.Element => {
  const papTherapyContext = React.useContext(PAPTherapyContext);
  const { resMedIntegrationEnabled } = papTherapyContext;

  return (
    <HistorySection
      headerText={<FormattedMessage id="treatment.papTherapy.title" />}
      newButton={<DocumentCreationButtonWithContext name="papTherapy" text="treatment.papTherapy.newPapTherapy" />}
      hasHistoryRows
    >
      <HistoryRow
        headerText={
          <span>
            <FormattedMessage id="treatment.papTherapy.resMedIntegration" />
            <span style={{ textTransform: 'lowercase' }}>
              {' '}
              <FormattedMessage id={`treatment.papTherapy.resMed${resMedIntegrationEnabled ? 'InUse' : 'NotInUse'}`} />
            </span>
          </span>
        }
        key={'resMedIntegration'}
        rowButton={<></>}
        controlsMargin={false}
      >
        <TreatmentHistorySection documents={documents} startEdit={startEdit} type="papTherapy" view={view} />
      </HistoryRow>
    </HistorySection>
  );
};

export default withHistoryContext(PAPTherapy);
