import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import { Container } from '../../../../../components/Grid';
import InfoPopover from '../../../../../components/InfoPopover';
import InputHandler from '../../../../../components/InputHandler';
import QuestionStepper from '../../../../../components/QuestionStepper';
import colors from '../../../../../config/theme/colors';
import { countFunctionalAssessmentScore, isFunctionalAssessmentComplete } from '../../../utils';
import {
  steps as functionalAssessmentSteps,
  getStepContent as getFunctionalAssessmentSteps,
} from '../functionalAssessmentSettings';

const UHDRSFunctionalAssessmentForm = ({ formData, viewing }: IOwnProps): JSX.Element => (
  <React.Fragment>
    <div style={{ fontStyle: 'italic', color: colors.darkGray, margin: '0rem 0 3rem 0' }}>
      The Unified Huntington Disease Rating Scale and revised UHDR® &apos;99 and accompanying manual (collectively, the
      “UHDRS™”) are the copyrighted intellectual property of the Huntington Study Group, Ltd. (“HSG”). Organizations
      seeking to use these tools and/or materials must obtain prior written permission from the HSG. The request form
      can be found in https://huntingtonstudygroup.org/.
    </div>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={!viewing}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="functionalPerformance.labels.uhdrsFunctionalAssessment.informationalSources">
      <InputHandler
        type="Radio"
        editing={true}
        name="informationalSources"
        formData={formData}
        options={['subjectOnly', 'subjectAndFamilyOrCompanion']}
        optionFormatter={(s: string | number): JSX.Element => (
          <FormattedMessage id={`functionalPerformance.opts.uhdrsFunctionalAssessment.${s}`} />
        )}
      />
    </FormRow>
    <FormSection>
      <QuestionStepper
        viewing={viewing}
        formData={formData}
        pageType="functionalPerformance"
        docType={'uhdrsFunctionalAssessment'}
        steps={functionalAssessmentSteps}
        getStepContent={getFunctionalAssessmentSteps}
      />
    </FormSection>
    <FormSection>
      <React.Fragment>
        <FormRow title="functionalPerformance.labels.uhdrsFunctionalAssessment.score" bottomMargin={true}>
          <Container style={{ fontSize: '1.8rem', fontWeight: 600, color: colors.primary }}>
            {isFunctionalAssessmentComplete(formData.document) ? (
              countFunctionalAssessmentScore(formData.document)
            ) : (
              <InfoPopover text="general.notFilled" color="primary" />
            )}
          </Container>
        </FormRow>
        <span style={{ fontStyle: 'italic', color: colors.darkGray }}>
          The unified Huntington&apos;s disease rating scale &apos;99: Motor examination. Huntington study group 2005.
          Examination guidelines for the modified unified Huntington&apos;s disease rating scale &apos;99 (UHDRS
          &apos;99). Huntington study group 2002.
        </span>
      </React.Fragment>
    </FormSection>
  </React.Fragment>
);

interface IOwnProps {
  formData: IFormData<IUHDRSFunctionalAssessment>;
  viewing?: boolean;
}

export default UHDRSFunctionalAssessmentForm;
