import { uniq } from 'ramda';
import { sortPartialDate } from 'neuro-utils';
import { sortAlphaObject } from '../../../utility/string';
import { isString } from '../../../utility/typeGuards';

/**
 * Map field names so their values can be used as headers
 * @param {IExamination[]} docs - All documents
 * @param {'date'|'name'} field - Field name to make headers by
 * @returns {[]} Field value array
 */
export const mapFieldNamesToArray = (docs: IExamination[], field: 'date' | 'codeName'): any[] =>
  docs && docs.length > 0 ? uniq(docs.map((d) => d[field])) : [];

/**
 * Sort documents under the headers based on header sorting (types alphabetical ascending, dates descending)
 * @param {IExamination[]} docs - Documents to sort
 * @param {'date'|'codeName'} field - Field name to sort by
 * @returns {IExamination[]} Sorted documents
 */
export const sortDocuments = (
  docs: IExamination[],
  field: TSortableFields,
  direction: TSortDirection,
): IExamination[] => {
  docs.sort((n1, n2) => (field === 'codeName' ? sortAlphaObject(field)(n1, n2) : sortPartialDate(n1.date, n2.date)));
  return direction === 'descending' ? docs.reverse() : docs;
};

export type TSortDirection = 'ascending' | 'descending';

/**
 * Filter documents by text
 * @param  {string} text - Text to filter by
 * @param  {string[]} fields - By which fields the documents are filtered
 * @param  {IExamination[]} documents - Documents
 * @returns {IExamination[]} Filtered documents
 */
export const filterByText = (text: string, fields: string[], documents: IExamination[]): IExamination[] => {
  const match = RegExp(`${text.replace(/\s/g, '')}`, 'i');

  const matchSome = (d: { [key: string]: any }): boolean =>
    fields.some((s: string) => d[s] && match.test(d[s].replace(/\s/g, '')));

  return documents.filter((d) => (text !== '' ? matchSome(d) : true));
};

/**
 * Cumulate autocomplete search options from searched documents.
 * @param documents Documents to parse the options from.
 * @param fields Fields (properties) of the doc that are included.
 * @returns An array containing options for an autocomplete input field.
 */
export const cumulateOptions = (documents: IExamination[], fields: Array<keyof IExamination>): string[] => {
  let opts: string[] = [];
  fields.forEach((f) => {
    documents.forEach((d) => {
      const value = d[f];
      // allow only string values
      if (!isString(value)) return;
      else if (!opts.includes(value.toLowerCase()) && !opts.includes(value)) opts = opts.concat(value);
    });
  });
  return opts;
};

export type TSortableFields = keyof IFieldNames;

interface IFieldNames {
  date: string;
  codeName: string;
}

export const examinationsList: IExaminationCode[] = [
  { code: '1731', codeName: 'P-TT' },
  { code: '2783', codeName: 'P-APTT' },
  { code: '4594', codeName: 'P-CRP' },
  { code: '4586', codeName: 'P-Alb' },
  { code: '2494', codeName: 'S-Prealb' },
  { code: '2519', codeName: 'U-Prot' },
  { code: '2145', codeName: 'U-Krea' },
  { code: '1887', codeName: 'S-KysC' },
  { code: '1171', codeName: 'S-B2Miglo' },
  { code: '1542', codeName: 'cB-HeTase' },
  { code: '591', codeName: 'P-K+Na' },
  { code: '1541', codeName: 'aB-HE-Tase' },
  { code: '1527', codeName: 'aB-pH' },
  { code: '1621', codeName: 'aB-pCO2' },
  { code: '1535', codeName: 'aB-pO2' },
  { code: '4400', codeName: 'S-GadAb' },
  { code: '150', codeName: 'aB-BE' },
  { code: '153', codeName: 'aB-HCO3-st' },
  { code: '1533', codeName: 'aB-HbO2Sat' },
  { code: '1528', codeName: 'cB-pH' },
  { code: '1622', codeName: 'cB-pCO2' },
  { code: '151', codeName: 'cB-BE' },
  { code: '154', codeName: 'cB-HCO3-st' },
  { code: '2041', codeName: 'S-Kerulo' },
  { code: '3450', codeName: 'Li-IgG-Ind' },
  { code: '6168', codeName: 'Li-IgG-Oc' },
  { code: '2655', codeName: 'Li-Solut' },
  { code: '2514', codeName: 'Li-Prot' },
  { code: '4591', codeName: 'P-ASAT' },
  { code: '1024', codeName: 'P-ALAT' },
  { code: '4587', codeName: 'P-AFOS' },
  { code: '4597', codeName: 'P-GT' },
  { code: '4592', codeName: 'P-Bil' },
  { code: '1341', codeName: 'B-Eryt' },
  { code: '1552', codeName: 'B-Hb' },
  { code: '1358', codeName: 'B-HKR' },
  { code: '2791', codeName: 'B-Trom' },
  { code: '1558', codeName: 'E-MCH' },
  { code: '1557', codeName: 'E-MCHC' },
  { code: '2474', codeName: 'B-PVK+T' },
  { code: '2218', codeName: 'B-Leuk' },
  { code: '3157', codeName: 'B-Basof' },
  { code: '1330', codeName: 'B-Eos' },
  { code: '3230', codeName: 'B-Monos' },
  { code: '3238', codeName: 'B-Neutr' },
  { code: '6099', codeName: 'S-Aqp4Ab' },
  { code: '4980', codeName: 'B-MxA' },
  { code: '6028', codeName: 'S-NatsuAb' },
  { code: '6329', codeName: 'S-JCVAb' },
  { code: '4467', codeName: 'S-VZVAbG' },
  { code: '1806', codeName: 'S-VZVAbM' },
  { code: '4095', codeName: 'S-BorrAbG' },
  { code: '4096', codeName: 'S-BorrAbM' },
  { code: '4200', codeName: 'Li-BorrAbG' },
  { code: '4201', codeName: 'Li-BorrAbM' },
  { code: '1092', codeName: 'S-ACE' },
  { code: '3426', codeName: 'Li-ACE' },
  { code: '1606', codeName: 'S-HBcAb' },
  { code: '1608', codeName: 'S-HBsAb' },
  { code: '3815', codeName: 'S-HCVAb' },
  { code: '2819', codeName: 'S-ANA' },
  { code: '4814', codeName: 'S-HIVAgAb' },
  { code: '3612', codeName: 'S-ANCA' },
  { code: '4148', codeName: 'S-MPOAb' },
  { code: '4160', codeName: 'S-Pr3Ab' },
  { code: '1345', codeName: 'U-Eryt-O' },
  { code: '2223', codeName: 'U-Leuk-O' },
  { code: '3094', codeName: 'U-Nitr-O' },
  { code: '1030', codeName: 'U-Alb-O' },
  { code: '1479', codeName: 'U-Gluk-O' },
  { code: '1122', codeName: 'U-Keto-O' },
  { code: '1530', codeName: 'U-pH' },
  { code: '2715', codeName: 'U-Suhti' },
  { code: '1155', codeName: 'U-BaktVi' },
  { code: '1941', codeName: 'U-Diffi' },
  { code: '2142', codeName: 'P-Krea' },
  { code: '4999', codeName: 'Pt-GFRe-CG' },
  { code: '1456', codeName: 'S-GbmAb' },
  { code: '4599', codeName: 'fP-Kol-LDL' },
  { code: '4516', codeName: 'fP-Kol-HDL' },
  { code: '4515', codeName: 'fP-Kol' },
  { code: '4568', codeName: 'fP-Trigly' },
  { code: '4831', codeName: 'P-TSH' },
  { code: '4832', codeName: 'P-T4-V' },
  { code: '6362', codeName: 'P-T3-V' },
  { code: '4028', codeName: 'S-TPOAb' },
  { code: '4965', codeName: 'S-TSHRAb' },
  { code: '1220', codeName: 'P-D-25' },
  { code: '2124', codeName: 'U-HCG-O' },
  { code: '4490', codeName: 'TbNhO' },
  { code: '4044', codeName: 'Pt-Papa1' },
  { code: '1468', codeName: 'fP-Gluk' },
  { code: '1999', codeName: 'P-K' },
  { code: '2203', codeName: 'B-La' },
  { code: '3622', codeName: 'P-Na' },
  { code: '13002', codeName: 'B-JCV-Ab' },
  { code: '12654', codeName: 'S-IgG-Oc' },
  { code: '10721', codeName: 'S-C-ANCA' },
  { code: '11313', codeName: 'S-P-ANCA' },
  { code: '505', codeName: 'HDL/Kol' },
  { code: '1357', codeName: 'E-MCV' },
  { code: '1881', codeName: 'U-KemSeul' },
  { code: '2225', codeName: 'B-Diffi' },
  { code: '600', codeName: 'L-Lymf' },
  { code: '612', codeName: 'L-Neutr' },
  { code: '602', codeName: 'L-Eos' },
  { code: '4517', codeName: 'P-CK' },
  { code: '1220', codeName: 'P-D25' },
  { code: '6128', codeName: 'B-HbA1c' },
  { code: '6598', codeName: 'P-Cu' },
  { code: '14442', codeName: 'P-NFL' },
  { code: '3223', codeName: 'B-Lymf' },
  { code: '2219', codeName: 'Li-Leuk' },
  { code: '1342', codeName: 'Li-Eryt' },
  { code: '3367', codeName: 'Li-Lymf %' },
  { code: '1470', codeName: 'Li-Gluk' },
  { code: '1185', codeName: 'S-Bil' },
  { code: '20408', codeName: 'E-RDW' },
  { code: '2218', codeName: 'fB-Leuk' },
  { code: '1168', codeName: 'L-Baso' },
  { code: '197', codeName: 'L-Monos' },
  { code: '1092', codeName: 'fS-ACE' },
  { code: '2132', codeName: 'S-Krtiin' },
  { code: '1220', codeName: 'S-D-25' },
  { code: '6384', codeName: 'P-Ca-albk' },
  { code: '2019', codeName: 'S-Ca-Ion' },
  { code: '13470', codeName: 'Pt-GFReEPI' },
  { code: '50491', codeName: 'P-Teriflu' },
  { code: '46150', codeName: 'S-MOGAb' },
  { code: '3427', codeName: 'S-AChR-Ab' },
  { code: '6101', codeName: 'S-MuSKAb' },
  { code: '6710', codeName: 'S-TitiAb' },
  { code: '1536', codeName: 'cB-pO2' },
  { code: '6635', codeName: 'S-CaKPQAb' },
  { code: 'LRP4_antibody', codeName: 'LRP4_antibody' },
  { code: 'ryanodine_antibody', codeName: 'ryanodine_antibody' },
  { code: '4828', codeName: 'P-IgG' },
  { code: '4829', codeName: 'P-IgM' },
];

export const localizeCodenames = (option: string, fm: (opt: string) => string) => {
  if (option === 'LRP4_antibody' || option === 'ryanodine_antibody') {
    // Mgravis localizations
    return fm('examination.opts.' + option);
  } else return `${option}`;
};

export const smaLabResultTypes = [
  'P-CK ',
  'P-D25',
  'P-TT',
  'P-APTT',
  'P-ASAT',
  'P-Bil',
  'P-CRP',
  'P-Alb',
  'P-Krea',
  'P-ALAT',
  'P-GT',
  'B-PVK+T',
  'S-Prealb',
  'U-Prot',
  'U-Krea',
  'S-KysC',
  'S-B2Miglo',
  'Li-Solut',
  'cB-HeTase',
  'P-Na',
];
