import { path, includes } from 'ramda';
import * as React from 'react';
import { styled } from '@mui/system';
import HistoryRowSubHeader from '../../../../../components/HistoryRowSubHeader';
import HistoryRowSubHeaderBar from '../../../../../components/HistoryRowSubHeaderBar';
import HistoryRowVerticalItem from '../../../../../components/HistoryRowVerticalItem';
import { formatPartialDate, isPartialDate } from 'neuro-utils';
import { DataContext } from './context';
import { neurologicalComplications, otherComplications, surgicalComplications } from '../../Form/Complications';

const HistoryArrayItem = styled('div')({
  '* > &:not(:last-of-type)': {
    marginBottom: '2rem',
  },
});

const OperationWithLocation = ({ d, fm }: { d: any; fm: (id: string) => string }): JSX.Element => {
  const locations = d.locations ?? [];
  const locPath = `surgicalTreatment.${d.typeOfOperation}`;
  const keys: Array<keyof ILocalResectionLocation | keyof ILesionectomyLocation> = ['sideOfRemoval', 'areaOfRemoval'];
  return (
    <React.Fragment>
      {Array.isArray(locations) &&
        locations.map(
          (location: ILocalResectionLocation): JSX.Element => (
            <HistoryArrayItem key={location.id}>
              {location.location && (
                <HistoryRowSubHeaderBar header={fm(`${locPath}.locations.location.opts.${location.location}`)} />
              )}

              {keys.map(
                (p: keyof ILocalResectionLocation | keyof ILesionectomyLocation): JSX.Element => (
                  <React.Fragment key={p}>
                    <HistoryRowVerticalItem
                      header={fm(`${locPath}.locations.${p}.title`)}
                      value={location[p] && fm(`${locPath}.locations.${p}.opts.${location[p]}`)}
                    />
                  </React.Fragment>
                ),
              )}
              {location.otherInfo && (
                <HistoryRowVerticalItem
                  header={fm(`${locPath}.locations.otherInfo.title`)}
                  value={location.otherInfo}
                />
              )}
            </HistoryArrayItem>
          ),
        )}
    </React.Fragment>
  );
};

const Disconnection = ({ d, fm }: { d: IDisconnection; fm: (id: string) => string }): JSX.Element => {
  const locPath = 'surgicalTreatment.disconnection.disconnections';
  const disconnections: Array<TDisconnection> | undefined = d.disconnections;

  const settings: { [key: string]: Array<string> } = {
    blockDisconnection: ['block', 'side', 'otherInfo'],
    skullsotomy: ['scope'],
    hemispherectomy: ['side'],
    functionalPartialHemispherectomy: ['side', 'location', 'otherInfo'],
    multipleSubpialTransection: ['side', 'location'],
    hypothalamicHamartomaDisconnection: ['disconnection'],
    other: ['otherInfo'],
  };

  return (
    <React.Fragment>
      {Array.isArray(disconnections) &&
        disconnections.map((disconnection: TDisconnection) => (
          <HistoryArrayItem key={disconnection.id}>
            <HistoryRowSubHeaderBar header={fm(`${locPath}.${disconnection.type}.title`)} />

            {((path([disconnection.type ?? ''], settings) as Array<string>) ?? []).map((k: string) => (
              <React.Fragment key={k}>
                {k in disconnection && path([k], disconnection) ? (
                  <HistoryRowVerticalItem
                    header={fm(`${locPath}.${disconnection.type}.${k}.title`)}
                    value={
                      <div>
                        {k !== 'otherInfo'
                          ? fm(`${locPath}.${disconnection.type}.${k}.opts.${path([k], disconnection)}`)
                          : k === 'otherInfo'
                            ? path([k], disconnection)
                            : undefined}
                      </div>
                    }
                  />
                ) : (
                  ''
                )}
              </React.Fragment>
            ))}
          </HistoryArrayItem>
        ))}
    </React.Fragment>
  );
};

const CyberKnife = ({ d, fm }: { d: ICyberKnife; fm: (id: string) => string }): JSX.Element => {
  const locPath = 'surgicalTreatment.cyberKnife.areas';
  const areas: Array<ICyberKnifeArea> | undefined = d.areas;

  return (
    <React.Fragment>
      {Array.isArray(areas) &&
        areas.map((area: ICyberKnifeArea) => (
          <HistoryArrayItem key={area.id}>
            <HistoryRowSubHeaderBar header={fm(`${locPath}.area.opts.${area.area}`)} />
            {area.side && (
              <HistoryRowVerticalItem
                header={fm(`${locPath}.side.title`)}
                value={fm(`${locPath}.side.opts.${area.side}`)}
              />
            )}
            {area.other && <HistoryRowVerticalItem header={fm(`${locPath}.other.title`)} value={area.other} />}
          </HistoryArrayItem>
        ))}
    </React.Fragment>
  );
};

const ThermoCoagulation = ({ d, fm }: { d: IThermocoagulation; fm: (id: string) => string }): JSX.Element => {
  const locPath = 'surgicalTreatment.thermoCoagulation.coagulations';
  const coagulations = d.coagulations;
  return (
    <React.Fragment>
      {Array.isArray(coagulations) &&
        coagulations.map(
          (coagulation: ICoagulation): JSX.Element => (
            <HistoryArrayItem key={coagulation.id}>
              <HistoryRowSubHeaderBar header={fm(`${locPath}.coagulationArea.opts.${coagulation.coagulationArea}`)} />

              {(['side', 'seegLocation', 'stimulationAssist'] as Array<keyof ICoagulation>).map(
                (k: keyof ICoagulation): JSX.Element | null => (
                  <React.Fragment key={k}>
                    {k in coagulation && coagulation[k] ? (
                      <HistoryRowVerticalItem
                        header={fm(`${locPath}.${k}.title`)}
                        value={fm(`${locPath}.${k}.opts.${coagulation[k]}`)}
                      />
                    ) : null}
                  </React.Fragment>
                ),
              )}
            </HistoryArrayItem>
          ),
        )}
    </React.Fragment>
  );
};

const IntracranialImaging = ({ d, fm }: { d: IIntracranialImaging; fm: (id: string) => string }): JSX.Element => {
  return (
    <>
      <HistoryRowVerticalItem
        header={fm('surgicalTreatment.intracranialImaging.title')}
        value={d.imagingType && fm('surgicalTreatment.intracranialImaging.opts.' + d.imagingType)}
      />
      <HistoryRowVerticalItem
        header={fm('surgicalTreatment.stereoEEG.electrodeNumber.title')}
        value={d.electrodeNumber}
      />
      <HistoryRowVerticalItem
        header={fm('surgicalTreatment.stereoEEG.electrodeLocation.title')}
        value={d.electrodeLocation && fm('surgicalTreatment.stereoEEG.electrodeLocation.opts.' + d.electrodeLocation)}
      />
    </>
  );
};

export const Operations = (): JSX.Element => {
  const locPath = 'surgicalTreatment';
  return (
    <DataContext.Consumer>
      {({ d, fm }): JSX.Element | null => {
        const formatM = fm || ((s: string): string => s);
        return d ? (
          <React.Fragment>
            <HistoryRowSubHeader
              header={'typeOfOperation' in d ? formatM(`${locPath}.${d.typeOfOperation}.header`) : ''}
            />

            {/* <HistoryRowSubHeaderBar
                    header={'typeOfOperation' in d ? formatM(`${locPath}.${d.typeOfOperation}.${entry[0]}.header`) : ''}
                  /> */}
            {'typeOfOperation' in d &&
            (d.typeOfOperation === 'localResection' || d.typeOfOperation === 'lesionectomy') ? (
              <OperationWithLocation d={d as ILocalResection | ILesionectomy} fm={formatM} />
            ) : (
              ''
            )}
            {'typeOfOperation' in d && d.typeOfOperation === 'disconnection' ? (
              <Disconnection d={d} fm={formatM} />
            ) : (
              ''
            )}
            {'typeOfOperation' in d && d.typeOfOperation === 'cyberKnife' ? <CyberKnife d={d} fm={formatM} /> : ''}
            {'typeOfOperation' in d && d.typeOfOperation === 'thermoCoagulation' ? (
              <ThermoCoagulation d={d} fm={formatM} />
            ) : (
              ''
            )}
            {'typeOfOperation' in d && d.typeOfOperation === 'intracranialImaging' ? (
              <IntracranialImaging d={d} fm={formatM} />
            ) : (
              ''
            )}
          </React.Fragment>
        ) : null;
      }}
    </DataContext.Consumer>
  );
};

export const Pads = (): JSX.Element => {
  const locPath = 'surgicalTreatment.pad';
  return (
    <DataContext.Consumer>
      {({ d, fm }): JSX.Element => (
        <React.Fragment>
          {d ? (
            <React.Fragment>
              <HistoryRowSubHeaderBar
                header={fm && 'findings' in d && d.findings ? fm(`${locPath}.findings.opts.${d.findings}`) : ''}
              />
              {'subType' in d && d.subType ? (
                <HistoryRowVerticalItem
                  header={fm ? fm(`${locPath}.subType.header`) : ''}
                  value={fm ? fm(`${locPath}.subType.opts.${d.subType}`) : ''}
                />
              ) : (
                ''
              )}
              {'otherInfo' in d && d.otherInfo ? (
                <HistoryRowVerticalItem header={fm ? fm(`${locPath}.otherInfo.header`) : ''} value={d.otherInfo} />
              ) : (
                ''
              )}
            </React.Fragment>
          ) : (
            ''
          )}
        </React.Fragment>
      )}
    </DataContext.Consumer>
  );
};

export const Complications = (): JSX.Element => {
  const locPath = 'surgicalTreatment.complications';
  return (
    <DataContext.Consumer>
      {({ d, fm }): JSX.Element => (
        <React.Fragment>
          {d ? (
            <React.Fragment>
              <HistoryRowSubHeaderBar header={fm && 'date' in d && d.date ? formatPartialDate(d.date) : ''} />
              {(
                [
                  'complication',
                  'surgicalOther',
                  'cerebralNerveParesisInfo',
                  'neurologicalOtherInfo',
                  'severeMetabolicEndocrineDisorderInfo',
                  'psychiatricDisorderInfo',
                  'otherInfo',
                  'complicationStart',
                  'complicationCededDate',
                ] as Array<keyof IComplications>
              ).map((k: keyof IComplications) => {
                const doc: IComplications = d;
                const value = k in doc ? doc[k] : false;
                return (
                  <React.Fragment key={k}>
                    {k === 'complication' && fm && typeof value === 'string' ? (
                      <HistoryRowVerticalItem
                        header={fm(`${locPath}.${k}.title`)}
                        value={
                          surgicalComplications.includes(value)
                            ? fm(`${locPath}.surgical.opts.${value}`)
                            : neurologicalComplications.includes(value)
                              ? fm(`${locPath}.neurological.opts.${value}`)
                              : otherComplications.includes(value)
                                ? fm(`${locPath}.other.opts.${value}`)
                                : undefined
                        }
                      />
                    ) : (k === 'complicationCededDate' || k === 'complicationStart') && fm && isPartialDate(value) ? (
                      <HistoryRowVerticalItem header={fm(`${locPath}.${k}`)} value={formatPartialDate(value)} />
                    ) : (
                      <>
                        {fm && value && typeof value === 'string' ? (
                          <HistoryRowVerticalItem header={fm(`${locPath}.${k}.title`)} value={value} />
                        ) : (
                          ''
                        )}
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          ) : (
            ''
          )}
        </React.Fragment>
      )}
    </DataContext.Consumer>
  );
};

export const EngelClassification = (): JSX.Element => {
  const locPath = 'surgicalTreatment.engelClassification';

  interface IOpts {
    timeFromOperation: Array<string>;
    classI: Array<string>;
    classII: Array<string>;
    classIII: Array<string>;
    classIV: Array<string>;
  }
  const opts: IOpts = {
    timeFromOperation: ['1', '2', '3', '4', '5'],
    classI: ['aI', 'bI', 'cI', 'dI'],
    classII: ['aII', 'bII', 'cII', 'dII'],
    classIII: ['aIII', 'bIII'],
    classIV: ['aIV', 'bIV', 'cIV'],
  };

  return (
    <DataContext.Consumer>
      {({ d, fm }): JSX.Element => (
        <React.Fragment>
          {d ? (
            <React.Fragment>
              <HistoryRowSubHeaderBar header={fm && 'date' in d && d.date ? formatPartialDate(d.date) : ''} />
              {(['timeFromOperation', 'classI', 'classII', 'classIII', 'classIV'] as Array<keyof IOpts>).map(
                (k: keyof IOpts) => {
                  const doc: IEngelClassification = d;
                  const value = doc.classification ?? false;
                  return (
                    <React.Fragment key={k}>
                      {fm && value && includes(value, opts[k] ?? []) && typeof value === 'string' ? (
                        <HistoryRowVerticalItem
                          header={fm(`${locPath}.${k}.title`)}
                          value={fm(`${locPath}.${k}.opts.${value}`)}
                        />
                      ) : (
                        ''
                      )}
                    </React.Fragment>
                  );
                },
              )}
            </React.Fragment>
          ) : (
            ''
          )}
        </React.Fragment>
      )}
    </DataContext.Consumer>
  );
};
