import { styled } from '@mui/material';
import * as React from 'react';

const StyledRoundBox = styled('div')(({ theme }) => ({
  padding: '1.5rem',
  border: `0.1rem solid ${theme.palette.grey[400]}`,
  borderRadius: '1rem',
  boxSizing: 'border-box',
  width: '100%',
}));

const RoundBox = ({ style, children }: IRoundBox) => {
  return <StyledRoundBox style={style}>{children}</StyledRoundBox>;
};

interface IRoundBox {
  style?: React.CSSProperties;
  children?: JSX.Element | JSX.Element[] | string | null;
}

export default RoundBox;
