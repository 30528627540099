import { path } from 'ramda';
import * as React from 'react';
import { IRegimenContext } from '..';
import FormRow from '../../../../../../../components/FormRow';
import InputHandler from '../../../../../../../components/InputHandler';
import { StrengthSelect } from '../components/StrengthSelect';
import { otherOnChange, strengthsArray, strengthsOnChange } from '../utils';
import { isParkinsonInfusionType } from '../utils/isParkinsonInfusionType';
import ParkinsonInfusion from './ParkinsonInfusion';

const Custom = ({ formData, editIndex, packages }: IRegimenContext): JSX.Element => {
  const regimen = formData?.document?.regimen?.[editIndex] as IRegimenBasics & IRegimenCustom;
  const allRegimen = formData?.document?.regimen as Array<Regimen>;
  const availableStrengths = packages ? strengthsArray(packages) : [];
  const strengths = regimen.strengths || [];

  const parkinsonCustomFieldType = isParkinsonInfusionType(formData.document);
  return (
    <React.Fragment>
      <StrengthSelect
        formData={formData}
        availableStrengths={availableStrengths}
        strengthsOnChange={strengthsOnChange(formData.onChange, availableStrengths, editIndex, regimen, allRegimen)}
        strengths={strengths}
        editIndex={editIndex}
      />
      {/* Render different custom fields for parkinson infusions/gels */}
      {parkinsonCustomFieldType ? (
        <ParkinsonInfusion type={parkinsonCustomFieldType} index={editIndex} formData={formData} />
      ) : (
        <>
          <FormRow title="medication.regimenDetails" headerWidth={3}>
            <InputHandler
              type="TextArea"
              name="regimenDetails"
              editing={true}
              width={40}
              placeholder={'medication.regimenDetails'}
              formData={{
                onChange: otherOnChange(formData.onChange, editIndex, regimen, allRegimen),
                document: {
                  regimenDetails: path(['document', 'regimen', editIndex, 'regimenDetails'], formData) || '',
                },
              }}
            />
          </FormRow>
        </>
      )}
    </React.Fragment>
  );
};

export default Custom;
