import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import QuestionStepper from '../../../../../components/QuestionStepper';
import FormSection from '../../../../../components/FormSection';

import { styled } from '@mui/system';

import { steps as chopINTENDSteps, getStepContent as getCHOPINTENDSteps } from '../chopINTENDSettings';
import { getPatientAgeInMonthsWithOneDecimal } from '../../../../../utility/patientInfo';
import { chopINTENDScore, showPatientAgeInMonthsOnForm } from '../../../utils';
import InfoPopover from '../../../../../components/InfoPopover';
import colors from '../../../../../config/theme/colors';
import { FormattedMessage } from 'react-intl';
import { Container, Item } from 'Components/Grid';

const Text = styled('div')({
  color: colors.tertiaryText,
});

const testingEnvironmentFields = ['ideally', 'paddedMat', 'diaper', 'redBall', 'pacifier', 'cnt'];

const CHOPINTENDForm = ({ formData, viewing }: IOwnProps): JSX.Element => {
  return (
    <React.Fragment>
      <FormRow title="motor.labels.chopINTEND.testingEnvironment.title">
        <Container>
          <Item>
            <Text>
              <React.Fragment>
                {testingEnvironmentFields.map(
                  (field: string) => (
                    <React.Fragment key={field}>
                      <FormattedMessage id={`motor.labels.chopINTEND.testingEnvironment.${field}`} />
                      <br />
                    </React.Fragment>
                  ),
                  testingEnvironmentFields,
                )}
              </React.Fragment>
            </Text>
          </Item>
        </Container>
      </FormRow>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={!viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow title="general.timeOfDay" id={'time'}>
        <InputHandler type="TimePicker" editing={!viewing} name="time" formData={formData} timeDefault="now" />
      </FormRow>
      <FormRow title="motor.hoursSinceLastFeeding">
        <InputHandler
          type="NumberField"
          editing={!viewing}
          name="hoursSinceLastFeeding"
          formData={formData}
          placeholder={'motor.hoursSinceLastFeedingShort'}
          min={0}
        />
      </FormRow>
      <FormRow title="motor.ageMonth" condition={showPatientAgeInMonthsOnForm(formData.document.date)}>
        <InputHandler
          type="TextField"
          editing={false}
          name="age"
          formData={{
            onChange: formData.onChange,
            document: {
              age: formData.document.date ? getPatientAgeInMonthsWithOneDecimal(formData.document.date) : '',
            },
          }}
          disabled={true}
        />
      </FormRow>
      <FormSection>
        <QuestionStepper
          viewing={viewing}
          formData={formData}
          pageType={'motor'}
          docType={'chopINTEND'}
          steps={chopINTENDSteps}
          getStepContent={getCHOPINTENDSteps}
        />
      </FormSection>
      <FormSection>
        <FormRow
          title={
            chopINTENDScore(formData.document) === formData.document.manualScore &&
            formData.document.manualScore != null
              ? 'motor.manuallyGivenScore'
              : 'motor.automaticScore'
          }
        >
          <Container style={{ fontSize: '1.8rem', fontWeight: 600, color: colors.primary }}>
            {chopINTENDScore(formData.document) === 'notCounted' ? (
              <InfoPopover text="general.notFilled" color="primary" />
            ) : (
              chopINTENDScore(formData.document)
            )}
          </Container>
        </FormRow>
        <FormRow title="motor.manualScore">
          <Container style={{ fontSize: '1.8rem', fontWeight: 600, color: colors.primary }}>
            <InputHandler
              type="NumberField"
              editing={!viewing}
              name="manualScore"
              formData={formData}
              width={4}
              height={3}
              maxLength={2}
            />
          </Container>
        </FormRow>
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<ICHOPINTEND>;
  viewing: boolean;
}

export default CHOPINTENDForm;
