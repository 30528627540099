import * as React from 'react';
import { styled } from '@mui/system';

import FormRow from '../../../../../../../../components/FormRow';
import FormSectionHistoryAcceptor from '../../../../../../../../components/FormSectionHistoryAcceptor';
import InputHandler from '../../../../../../../../components/InputHandler';

import colors from '../../../../../../../../config/theme/colors';

import { fm } from 'Components/FormatMessage';
import { diagnosticCriteriaForPP } from '../../../../../../utils/MS';
import { InputlessFormRow } from '../../../../../components';

const SubHeader = styled('div')({
  margin: '1rem 0',
  color: colors.tertiaryText,
});

const formFields = [
  'progressionOfDisease',
  'periventicularArea',
  'corticalArea',
  'infratentorialArea',
  'changesInSpinalCord',
  'oligoclonalFractionsInLiquid',
];

const setAllUnknown = (formData: IOwnProps['formData'], viewing: boolean): void => {
  formFields.forEach((f) => {
    if (!viewing && !(formData.document as { [key: string]: any })[f]) {
      formData?.onChange?.({
        [f]: 'unknown',
      });
    }
  });
};

const generalOptionFormatter = (s?: React.ReactText | boolean): JSX.Element => fm(`general.${s || 'empty'}`);

const MakeFormRow = ({
  title,
  description,
  name,
  formData,
  viewing,
  leftMargin,
  bottomMargin = true,
}: {
  title: string;
  description?: JSX.Element;
  name: string;
  formData: IOwnProps['formData'];
  viewing: boolean;
  leftMargin?: number;
  bottomMargin?: boolean;
}): JSX.Element => (
  <FormRow
    title={title}
    description={description}
    headerWidth={6}
    bottomMargin={bottomMargin}
    headerStyle={{ marginLeft: `${leftMargin ?? 0}rem` }}
  >
    <InputHandler
      editing={!viewing}
      formData={formData}
      name={name}
      type="RadioButtonRow"
      preset="yesNoUnknown"
      height={3.5}
      width={9}
      optionFormatter={generalOptionFormatter}
    />
  </FormRow>
);

const PPMS = ({ documents, formData, view, editingID }: IOwnProps): JSX.Element => {
  const isViewing = !editingID && !!view?.viewing;
  const docID = editingID ?? view?.viewing;

  React.useEffect(() => {
    setAllUnknown(formData, isViewing);
  }, [formData, isViewing]);

  return (
    <React.Fragment>
      <FormSectionHistoryAcceptor
        formData={formData}
        documentID={docID}
        documents={documents?.filter((d) => d.revision === '2017')}
        optionFormatter={generalOptionFormatter}
        header="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaPPMS.title"
        hideCopyButton={isViewing}
      >
        {{
          progressionOfDisease: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaPPMS.progressionOfDisease"
                name="progressionOfDisease"
                formData={formData}
                viewing={isViewing}
              />
            ),
          },
          atLeastTwoFields: {
            header: (
              <SubHeader style={{ color: colors.primaryText }}>
                {fm('diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaPPMS.atLeastTwoFields')}
              </SubHeader>
            ),
          },
          oneOrMoreInMK: {
            element: (
              <SubHeader>
                {fm('diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaPPMS.oneOrMoreInMK')}
              </SubHeader>
            ),
          },

          periventicularArea: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaPPMS.periventicularArea"
                name="periventicularArea"
                formData={formData}
                viewing={isViewing}
              />
            ),
          },
          corticalArea: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaPPMS.corticalArea"
                name="corticalArea"
                formData={formData}
                viewing={isViewing}
              />
            ),
          },
          infratentorialArea: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaPPMS.infratentorialArea"
                name="infratentorialArea"
                formData={formData}
                viewing={isViewing}
              />
            ),
          },

          changesInSpinalCord: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaPPMS.changesInSpinalCord"
                name="changesInSpinalCord"
                formData={formData}
                viewing={isViewing}
              />
            ),
          },
          oligoclonalFractionsInLiquid: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaPPMS.oligoclonalFractionsInLiquid"
                name="oligoclonalFractionsInLiquid"
                formData={formData}
                viewing={isViewing}
              />
            ),
          },

          diagnosticCriteriaForRRMS: {
            header: (
              <InputlessFormRow
                title={'diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaPPMS.result'}
                element={fm(`general.${diagnosticCriteriaForPP(formData.document)}`)}
                boldValue={true}
                bottomMargin={false}
              />
            ),
          },
        }}
      </FormSectionHistoryAcceptor>
    </React.Fragment>
  );
};

interface IOwnProps {
  documents?: Array<IMSCriteria>;
  formData: IFormData<IMSCriteria>;
  view?: IView;
  editingID?: string;
}

export default PPMS;
