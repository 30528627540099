import * as React from 'react';
import { MyServiceContext } from '..';
import { Bdi } from './SurveyForms/Bdi';
import { Bai } from './SurveyForms/Bai';
import NINMTPreInquiry from './SurveyForms/NINMTPreInquiry';
import { ContraIndicationsInquiry } from './SurveyForms/ContraIndicationsInquiry';
import TreatmentMonitoringInquiry from './SurveyForms/TreatmentMonitoringInquiry';
import TDCSReport from './SurveyForms/TDCSReport';
import { ICompactVaultUser } from 'neuro-data-structures';
import { Copd } from './SurveyForms/COPD';
import { Dss } from './SurveyForms/DSS';
import { Deps } from './SurveyForms/DEPS';
import { Ess } from './SurveyForms/ESS';
import { Des } from './SurveyForms/DES';
import { Isi } from './SurveyForms/ISI';
import { SleepApneaFirstVisitInquiry } from './SurveyForms/SleepApneaFirstVisitInquiry';
import { DeviceTherapyControlVisitInquiry } from './SurveyForms/DeviceTherapyControlVisitInquiry';
import { SatisfactionWithCare } from './SurveyForms/SatisfactionWithCare';
import { EQ5D } from './SurveyForms/EQ5D';
import { PEDSQL } from './SurveyForms/PEDSQL';
import SupplyShortagesInquiry from './SurveyForms/SupplyShortagesInquiry';
import { NMSQ } from './SurveyForms/NMSQ';
import { ImpulseControlSurvey } from './SurveyForms/ImpulseControlSurvey';
import { MdsUpdrsIV } from './SurveyForms/MdsUpdrsIV';
import { PDQ8Form } from './SurveyForms/PDQ8';
import { useSelector } from 'react-redux';
import { surveyCreator, surveyModifyer } from '../HistoryRowData/SurveysHistory';
import { TSurveyType } from 'Routes/MyService/util';
import QuestionAnswerPair from 'Components/_NewElements/QuestionAnswerPair';

const SurveyForm = ({ documents, users }: IOwnProps): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const patientDelegates = useSelector((s: IState) => s.patient.delegates) || [];
  const { editing, viewing, mysqUserId, fm } = myServContext;

  const { type } = editing || viewing || { type: null };

  const survCreator = surveyCreator(
    (editing?.data ?? viewing?.data ?? {}) as TSurveyType | Partial<IBackground>,
    mysqUserId,
    users,
    patientDelegates,
    fm,
  );
  const survEditor = surveyModifyer(
    (editing?.data ?? viewing?.data ?? {}) as TSurveyType | Partial<IBackground>,
    mysqUserId,
    users,
    patientDelegates,
    fm,
  );

  let survForm: undefined | JSX.Element;

  switch (type) {
    case 'bdi': {
      survForm = <Bdi />;
      break;
    }
    case 'bai': {
      survForm = <Bai />;
      break;
    }
    case 'ninmtPreInquiry': {
      survForm = <NINMTPreInquiry />;
      break;
    }
    case 'contraIndicationsInquiry': {
      survForm = <ContraIndicationsInquiry />;
      break;
    }
    case 'treatmentMonitoringInquiry': {
      const interestingDocs = documents.filter(
        (d) => d._type === 'symptomsAndLocations' || d._type === 'ninmtTreatmentPeriod',
      );
      survForm = <TreatmentMonitoringInquiry documents={interestingDocs} />;
      break;
    }
    case 'tdcsReport': {
      survForm = <TDCSReport documents={documents} />;
      break;
    }
    case 'supplyShortagesInquiry': {
      survForm = <SupplyShortagesInquiry />;
      break;
    }
    case 'copd': {
      survForm = <Copd />;
      break;
    }
    case 'deps': {
      survForm = <Deps />;
      break;
    }
    case 'des': {
      survForm = <Des />;
      break;
    }
    case 'dss': {
      survForm = <Dss />;
      break;
    }
    case 'ess': {
      survForm = <Ess />;
      break;
    }
    case 'isi': {
      survForm = <Isi />;
      break;
    }
    case 'sleepApneaFirstVisitInquiry': {
      survForm = <SleepApneaFirstVisitInquiry />;
      break;
    }
    case 'deviceTherapyControlVisitInquiry': {
      survForm = <DeviceTherapyControlVisitInquiry />;
      break;
    }
    case 'satisfactionWithCare': {
      survForm = <SatisfactionWithCare />;
      break;
    }
    case 'eq5d': {
      survForm = <EQ5D />;
      break;
    }
    case 'pedsql': {
      survForm = <PEDSQL />;
      break;
    }
    case 'nmsq': {
      survForm = <NMSQ />;
      break;
    }
    case 'impulseControlSurvey': {
      survForm = <ImpulseControlSurvey />;
      break;
    }
    case 'mdsUpdrsIV': {
      survForm = <MdsUpdrsIV />;
      break;
    }
    case 'pdq8': {
      survForm = <PDQ8Form />;
      break;
    }
    default:
      break;
  }

  return (
    <React.Fragment>
      {viewing && (
        <div style={{ marginBottom: '4rem' }}>
          <QuestionAnswerPair question={fm('myService.reporter')} answer={survCreator} />
          {survEditor && <QuestionAnswerPair question={fm('myService.edited')} answer={survEditor} />}
        </div>
      )}
      {survForm}
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: Array<IRTMS | ITDCS | ISymptomsAndLocations | IDoctorsOrder>;
  users: ICompactVaultUser[];
  mysqUserId: string;
}

export default SurveyForm;
