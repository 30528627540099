import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import { isLocaleKey, impulseControlSurvey, IImpulseControlSurvey } from 'neuro-schemas';
import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { MyServiceContext } from '../..';
import colors from '../../../../../config/theme/colors';
import { Theme, useMediaQuery } from '@mui/material';
import Divider from 'Components/Divider';

export const ImpulseControlSurvey = (): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, locale, viewing, setViewingObj, setEditingObj } = myServContext;
  const impulseControlData = (editing?.data || viewing?.data) as IImpulseControlSurvey & IControlProps;
  const impulseControlLocales = impulseControlSurvey.localizations;
  const useLocale = isLocaleKey(locale) ? locale : 'fi';
  const onChangeSurveyForm = (values: TOnChangeValues): void => {
    const field = Object.keys(values)[0];
    const value = Object.values(values)[0];
    setEditingData?.({
      ...impulseControlData,
      [field]: value,
    });
  };

  // Track page width and breakpoints
  const mdOrSmaller = useMediaQuery((theme: Theme) => theme.breakpoints.down(1401));

  return (
    <>
      {!viewing ? (
        <div style={{ width: mdOrSmaller ? '100%' : '80%', color: colors.tertiaryText, marginBottom: '3rem' }}>
          <p>{impulseControlLocales[useLocale]['surveyInfo1']}</p>
          <p>{impulseControlLocales[useLocale]['surveyInfo2']}</p>
        </div>
      ) : null}
      <BlockWrapper
        title={viewing ? formatPartialDate(impulseControlData.date) : undefined}
        buttons={
          viewing
            ? [
                {
                  title: 'general.edit',
                  onClick: () => {
                    setViewingObj(null);
                    setEditingObj({ type: 'impulseControlSurvey', data: impulseControlData });
                  },
                },
              ]
            : undefined
        }
      >
        {!viewing && (
          <FormRow title={'general.date'}>
            <InputHandler
              name="date"
              type="PartialDate"
              dateDefault="now"
              formData={{
                document: { date: impulseControlData?.date },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
        )}
        <FormRow
          title={impulseControlLocales[useLocale]['gambling']}
          formatTitle={false}
          description={impulseControlLocales[useLocale]['gamblingDesc']}
        >
          <InputHandler
            name="gambling"
            type="Radio"
            options={['yes', 'no']}
            editing={!!editing}
            formData={{
              document: { gambling: impulseControlData?.gambling },
              onChange: onChangeSurveyForm,
            }}
            optionFormatter={(o) => impulseControlLocales[useLocale][`opts.${o}`]}
          />
        </FormRow>
        <FormRow
          title={impulseControlLocales[useLocale]['details']}
          condition={impulseControlData?.gambling === 'yes'}
          formatTitle={false}
        >
          <InputHandler
            name="gamblingDetails"
            type="TextArea"
            editing={!!editing}
            formData={{
              document: { gamblingDetails: impulseControlData?.gamblingDetails },
              onChange: onChangeSurveyForm,
            }}
            placeholder={impulseControlLocales[useLocale]['details']}
            disablePlaceholderFormatting
          />
        </FormRow>
        <Divider />
        <FormRow
          title={impulseControlLocales[useLocale]['sexualBehavior']}
          description={impulseControlLocales[useLocale]['sexualBehaviorDesc']}
          formatTitle={false}
        >
          <InputHandler
            name="sexualBehavior"
            type="Radio"
            options={['yes', 'no']}
            editing={!!editing}
            formData={{
              document: { sexualBehavior: impulseControlData?.sexualBehavior },
              onChange: onChangeSurveyForm,
            }}
            optionFormatter={(o) => impulseControlLocales[useLocale][`opts.${o}`]}
          />
        </FormRow>
        <FormRow
          title={impulseControlLocales[useLocale]['details']}
          condition={impulseControlData?.sexualBehavior === 'yes'}
          formatTitle={false}
        >
          <InputHandler
            name="sexualBehaviorDetails"
            type="TextArea"
            editing={!!editing}
            formData={{
              document: { sexualBehaviorDetails: impulseControlData?.sexualBehaviorDetails },
              onChange: onChangeSurveyForm,
            }}
            placeholder={impulseControlLocales[useLocale]['details']}
            disablePlaceholderFormatting
          />
        </FormRow>
        <Divider />
        <FormRow
          title={impulseControlLocales[useLocale]['shopping']}
          description={impulseControlLocales[useLocale]['shoppingDesc']}
          formatTitle={false}
        >
          <InputHandler
            name="shopping"
            type="Radio"
            options={['yes', 'no']}
            editing={!!editing}
            formData={{
              document: { shopping: impulseControlData?.shopping },
              onChange: onChangeSurveyForm,
            }}
            optionFormatter={(o) => impulseControlLocales[useLocale][`opts.${o}`]}
          />
        </FormRow>
        <FormRow
          title={impulseControlLocales[useLocale]['details']}
          condition={impulseControlData?.shopping === 'yes'}
          formatTitle={false}
        >
          <InputHandler
            name="shoppingDetails"
            type="TextArea"
            editing={!!editing}
            formData={{
              document: { shoppingDetails: impulseControlData?.shoppingDetails },
              onChange: onChangeSurveyForm,
            }}
            placeholder={impulseControlLocales[useLocale]['details']}
            disablePlaceholderFormatting
          />
        </FormRow>
        <Divider />
        <FormRow
          title={impulseControlLocales[useLocale]['eatingBehavior']}
          description={impulseControlLocales[useLocale]['eatingBehaviorDesc']}
          formatTitle={false}
        >
          <InputHandler
            name="eatingBehavior"
            type="Radio"
            options={['yes', 'no']}
            editing={!!editing}
            formData={{
              document: { eatingBehavior: impulseControlData?.eatingBehavior },
              onChange: onChangeSurveyForm,
            }}
            optionFormatter={(o) => impulseControlLocales[useLocale][`opts.${o}`]}
          />
        </FormRow>
        <FormRow
          title={impulseControlLocales[useLocale]['details']}
          condition={impulseControlData?.eatingBehavior === 'yes'}
          formatTitle={false}
        >
          <InputHandler
            name="eatingBehaviorDetails"
            type="TextArea"
            editing={!!editing}
            formData={{
              document: { eatingBehaviorDetails: impulseControlData?.eatingBehaviorDetails },
              onChange: onChangeSurveyForm,
            }}
            placeholder={impulseControlLocales[useLocale]['details']}
            disablePlaceholderFormatting
          />
        </FormRow>
      </BlockWrapper>
    </>
  );
};
