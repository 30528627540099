/**
 * Wrapper which simply adds the blue (or whatever color) bar to the left side of the element
 */

import { styled } from '@mui/system';
import * as React from 'react';

import { Container, Item } from 'Components/Grid';
import colors from '../../../config/theme/colors';

const StyledDividerArea = styled(Item, { shouldForwardProp: (prop) => prop !== 'pad' })(
  ({ pad }: { pad: boolean }) => ({
    paddingRight: pad ? '3.5rem' : 0,
  }),
);

const StyledDivider = styled('div')(({ color }) => ({
  width: '0.7rem',
  minHeight: '100%',
  borderRadius: '0.2rem',
  backgroundColor: `${color ?? colors.appBlue.lightest}`,
  display: 'flex',
}));

const LeftBarWrapper = ({ leftPadding = true, color, children }: ILeftBarWrapper) => {
  return (
    <Container>
      <StyledDividerArea pad={leftPadding}>
        <StyledDivider color={color} />
      </StyledDividerArea>
      <Item xs={true}>{children}</Item>
    </Container>
  );
};

interface ILeftBarWrapper {
  leftPadding?: boolean;
  color?: string;
  children?: React.ReactNode | React.ReactNode[] | null;
}

export default LeftBarWrapper;
