/**
 * Component that prints InputHandler with previous field value and accept / clear buttons:
 * - Get value for field from earlier document (based on date of current document)
 * - This value is printed next to the input field with an accept button
 * - If accept button is pressed, this value is copied to formData using the onChange method
 * - When the field has a value, accept button is not shown, instead show a clear button which can be used to remove the value from formData
 */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import { intersperse, path } from 'ramda';

import { exists, formatPartialDate, partialDateToValue, sortPartialDate, isPartialDate } from 'neuro-utils';
import colors from '../../config/theme/colors';

import InputHandler from '../InputHandler';
import ActionButton from '../ActionButton';
import { Container, Item } from 'Components/Grid';

const PreviousValueText = styled('div')({
  fontSize: '1.4rem',
  color: colors.secondaryText,
});

const confirmValue = (name: string, onChange?: IFormData['onChange'], value?: any) => (): void => {
  if (onChange && exists(value)) {
    onChange({ [name]: value });
  }
};

const clearValue = (name: string, type: string, onChange?: IFormData['onChange']) => (): void => {
  if (onChange) {
    onChange({ [name]: null });
  }
};

const checkIfPartialDateNotNull = (value: PartialDate): boolean => (value && value[0] ? true : false);

const InputHistoryAcceptor = ({
  name,
  type,
  documents,
  formData,
  sizing,
  onlyShowValue = false,
  noPrevValueFiller = '',
  customConfirmText,
  ...props
}: IOwnProps & TInputProperties): JSX.Element => {
  // Previous document based on date
  const prevValue = React.useRef<{ date?: PartialDate; value: any } | undefined>(undefined);

  const getPreviousDocs = React.useCallback(() => {
    return (
      documents?.filter(
        (d) => partialDateToValue(d?.date) < partialDateToValue(formData?.document.date) && d?._editing === false,
      ) || []
    );
  }, [documents, formData?.document.date]);

  React.useEffect(() => {
    if (formData?.document.date) {
      // All documents previous to editing documents date
      const previousDocs = getPreviousDocs();

      if (previousDocs.length === 0) {
        prevValue.current = undefined;
        return;
      }
      // Sort by date
      previousDocs.sort((n1, n2) => sortPartialDate(n1.date, n2.date)).reverse();
      // Get first occurance of the value from previous docs
      previousDocs.some((d) => {
        const value = name.indexOf('.') > 0 ? path(name.split('.'), d) : d[name];
        if (exists(value)) {
          prevValue.current = { date: d['date'], value: value };
          return true;
        } else return false;
      });
    } else {
      prevValue.current = undefined;
    }
  }, [formData?.document.date, getPreviousDocs, name]);

  const optionFormatter = 'optionFormatter' in props && props.optionFormatter;

  return (
    <Container>
      <Item xs={sizing?.left ?? 5}>
        <InputHandler name={name} type={type as any} formData={formData as IFormData} {...props} />
      </Item>
      {/* Render previous value and accept/clear buttons */}
      <Item xs={sizing?.right ?? 7}>
        <Container>
          <Item xs={8} style={{ paddingLeft: '2rem' }}>
            {(!exists(formData?.document[name]) ||
              (Array.isArray(formData?.document[name]) &&
                (!formData?.document[name][0] || formData?.document[name][0] === null))) &&
            prevValue.current ? (
              <PreviousValueText>
                {!onlyShowValue && (
                  <>
                    <FormattedMessage id="general.previousData" />:
                  </>
                )}
                {prevValue && (
                  <React.Fragment>
                    <div style={{ fontWeight: 600 }}>
                      {prevValue.current?.value && Array.isArray(prevValue.current.value)
                        ? intersperse(
                            ', ',
                            prevValue.current.value.map((s) => (optionFormatter ? optionFormatter(s) : s)),
                          ).map((o, i) => <React.Fragment key={i}>{o}</React.Fragment>)
                        : prevValue.current?.value && isPartialDate(prevValue.current.value)
                          ? formatPartialDate(prevValue.current.value)
                          : optionFormatter && prevValue.current?.value
                            ? optionFormatter(prevValue.current.value)
                            : prevValue.current?.value
                              ? prevValue.current.value
                              : ''}
                    </div>
                    {!onlyShowValue && prevValue.current?.date ? (
                      <div>{`(${formatPartialDate(prevValue.current.date)})`}</div>
                    ) : (
                      ''
                    )}
                  </React.Fragment>
                )}
              </PreviousValueText>
            ) : (
              <PreviousValueText>{noPrevValueFiller}</PreviousValueText>
            )}
          </Item>
          {!onlyShowValue && (
            <Item xs={4}>
              <Container justifyContent="flex-end">
                <Item>
                  {((type != 'PartialDate' && !exists(formData?.document[name])) ||
                    (type === 'PartialDate' && !checkIfPartialDateNotNull(formData?.document[name])) ||
                    (Array.isArray(formData?.document[name]) && formData?.document[name].length === 0)) &&
                  exists(prevValue.current?.value) ? (
                    <ActionButton
                      text={customConfirmText ? customConfirmText : 'general.confirm'}
                      width={8}
                      height={3}
                      fontSize={14}
                      onClick={confirmValue(name, formData?.onChange, prevValue.current?.value)}
                    />
                  ) : (type != 'PartialDate' && exists(formData?.document[name])) ||
                    (type === 'PartialDate' && checkIfPartialDateNotNull(formData?.document[name])) ? (
                    <ActionButton
                      text="general.clear"
                      width={8}
                      height={3}
                      fontSize={14}
                      onClick={clearValue(name, type, formData?.onChange)}
                    />
                  ) : (
                    ''
                  )}
                </Item>
              </Container>
            </Item>
          )}
        </Container>
      </Item>
    </Container>
  );
};

type TDocument = IControlProps & { [key: string]: any; date?: PartialDate };

interface IOwnProps {
  documents: TDocument[];
  type: TInputTypes;
  formData?: IFormData<{ [key: string]: any; date?: PartialDate }>;
  sizing?: { left: number; right: number };
  /** Only show previous value (if exists). Removes the acceptor and previous value date. */
  onlyShowValue?: boolean;
  /** Which string to show when previous value does not exist */
  noPrevValueFiller?: string;
  /** Used to change the text on the "Confirm" ActionButton */
  customConfirmText?: string;
}

export default InputHistoryAcceptor;
