import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';

const StyledDescription = styled('div')({
  marginBottom: '2rem',
});

const ChestCircumferenceForm = ({ formData }: IOwnProps): JSX.Element => (
  <FormSection>
    <StyledDescription>
      <FormattedMessage id="pulmonary.forInfants" />
    </StyledDescription>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={true}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="pulmonary.chestCircumferenceAtFullExpiration">
      <InputHandler
        type="NumberField"
        editing={true}
        name="fullExpiration"
        formData={formData}
        placeholder="pulmonary.measurementCM"
      />
    </FormRow>
    <FormRow title="pulmonary.chestCircumferenceAtFullInspiration">
      <InputHandler
        type="NumberField"
        editing={true}
        name="fullInspiration"
        formData={formData}
        placeholder="pulmonary.measurementCM"
      />
    </FormRow>
  </FormSection>
);

interface IOwnProps {
  formData: IFormData<IChestCircumference>;
}

export default ChestCircumferenceForm;
