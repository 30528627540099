import { StandAloneContent, StandAloneHeader } from 'Components/EventStepper/components';
import FormRow from 'Components/FormRow';
import * as React from 'react';
import InputHandler from 'Components/InputHandler';
import { isPartialDate, nowPartialDate, sortPartialDate } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import { StyledControls } from './components';

const EventViewer = ({
  name,
  formData,
  dateFieldName,
  content,
  header,
  dateDefault,
  dateHook,
  noEventsTextHeader,
}: IOwnProps): JSX.Element => {
  const [dateFilter, setDateFilter] = React.useState<PartialDate | undefined>(undefined);
  const dateField = typeof dateFieldName === 'string' ? dateFieldName : 'date';

  const events: Array<any> =
    formData?.document &&
    typeof formData.document === 'object' &&
    name in formData.document &&
    Array.isArray(formData.document[name as keyof typeof formData.document])
      ? formData.document[name as keyof typeof formData.document]
      : [];

  React.useEffect(() => {
    const latestEvent = events.sort((a, b) => sortPartialDate(b[dateField], a[dateField]))[0];
    const latestDate = latestEvent?.[dateField];
    if (!dateFilter) setDateFilter(latestDate ?? nowPartialDate());
  }, []);

  const eventsForDate = events.filter((e) => sortPartialDate(e[dateField], dateFilter) === 0);

  const [eventIndex, setEventIndex] = React.useState<number>(0);

  return (
    <FormRow title={header}>
      {events.length === 0 ? (
        fm(noEventsTextHeader ? noEventsTextHeader : 'general.noEvents')
      ) : (
        <React.Fragment>
          <StandAloneHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
              <InputHandler
                type="PartialDate"
                name="dateFilter"
                editing={true}
                formData={{
                  document: { dateFilter: dateFilter },
                  onChange: (values) => {
                    const name = Object.keys(values)[0];
                    const value = values[name];
                    if (isPartialDate(value)) {
                      setEventIndex(0);
                      setDateFilter(value);
                    }
                  },
                }}
                isNotCancellable
                dateDefault={dateDefault ? dateDefault : 'now'}
                dateHook={dateHook}
              />
            </div>
            <div>
              {eventsForDate.length > 1 && (
                <StyledControls
                  eventIndex={eventIndex}
                  events={eventsForDate}
                  changeEventIndex={(change) => () => setEventIndex(change)}
                />
              )}
            </div>
          </StandAloneHeader>
          <StandAloneContent editing={false}>
            {eventsForDate.length > 0 ? (
              content(eventsForDate[eventIndex])
            ) : (
              <span>{fm('general.noEventsForSelectedDate')}</span>
            )}
          </StandAloneContent>
        </React.Fragment>
      )}
    </FormRow>
  );
};

interface IOwnProps {
  /** Name of the event array in formData */
  name: string;
  /** Form data */
  formData: IFormData<any>;
  /** Name for the date field events are sorted by */
  dateFieldName?: string;
  /** Content shown - use StepperHeaderValuePairs */
  content: (d: any) => JSX.Element; // StepperHeaderValuePairs
  /** Locale id for FormRow header */
  header?: string;
  dateDefault?: IPartialDate['dateDefault'];
  dateHook?: IPartialDate['dateHook'];
  noEventsTextHeader?: string;
}

export default EventViewer;
