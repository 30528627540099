import * as React from 'react';
import EchocardiographyForm from 'Routes/Imaging/Document/Form/Echocardiography';
import MriForm from 'Routes/Imaging/Document/Form/Mri';
import PelvisForm from 'Routes/Imaging/Document/Form/Pelvis';
import ScintigraphyForm from 'Routes/Imaging/Document/Form/Scintigraphy';
import ScoliosisForm from 'Routes/Imaging/Document/Form/Scoliosis';
import TTForm from 'Routes/Imaging/Document/Form/TT';

type TEDFields = {
  /** Fields are inverse, meaning that those aren't to be edited */
  inverse?: boolean;
  /** What kinda form is to be rendered */
  formType: 'dialog' | 'normal';
  /** Which fields to allow editing to */
  fields: string[];
  component: (
    onChange: (values: TOnChangeValues) => void,
    document: IControlProps,
    formSettings: TEDFields,
  ) => JSX.Element;
};

type TEditableFields = Record<
  Platform,
  {
    [documentType: string]: TEDFields | undefined;
  }
>;

export const possibleEditableFields: TEditableFields = {
  ms: {
    mri: {
      inverse: true,
      formType: 'normal',
      fields: ['date', 'procedureCode', 'statement'],
      component: (onChange, document, formSettings) => (
        <MriForm formData={{ onChange, document }} disabledFields={formSettings.fields} />
      ),
    },
  },
  sma: {
    scoliosis: {
      inverse: true,
      formType: 'normal',
      fields: ['date', 'procedureCode', 'statement'],
      component: (onChange, document, formSettings) => (
        <ScoliosisForm formData={{ onChange, document }} disabledFields={formSettings.fields} />
      ),
    },
    pelvis: {
      inverse: true,
      formType: 'normal',
      fields: ['date', 'procedureCode', 'statement'],
      component: (onChange, document, formSettings) => (
        <PelvisForm formData={{ onChange, document }} disabledFields={formSettings.fields} />
      ),
    },
  },
  dmd: {
    echocardiography: {
      inverse: true,
      formType: 'normal',
      fields: ['date', 'procedureCode', 'statement'],
      component: (onChange, document, formSettings) => (
        <EchocardiographyForm formData={{ onChange, document }} disabledFields={formSettings.fields} />
      ),
    },
    scoliosis: {
      inverse: true,
      formType: 'normal',
      fields: ['date', 'procedureCode', 'statement'],
      component: (onChange, document, formSettings) => (
        <ScoliosisForm formData={{ onChange, document }} disabledFields={formSettings.fields} />
      ),
    },
    pelvis: {
      inverse: true,
      formType: 'normal',
      fields: ['date', 'procedureCode', 'statement'],
      component: (onChange, document, formSettings) => (
        <PelvisForm formData={{ onChange, document }} disabledFields={formSettings.fields} />
      ),
    },
  },
  huntington: {},
  epilepsy: {},
  sleepApnea: {},
  parkinson: {
    mri: {
      inverse: true,
      formType: 'normal',
      fields: ['date', 'procedureCode', 'statement'],
      component: (onChange, document, formSettings) => (
        <MriForm formData={{ onChange, document }} disabledFields={formSettings.fields} />
      ),
    },
    tt: {
      inverse: true,
      formType: 'normal',
      fields: ['date', 'procedureCode', 'statement'],
      component: (onChange, document, formSettings) => (
        <TTForm formData={{ onChange, document }} disabledFields={formSettings.fields} />
      ),
    },
    scintigraphy: {
      inverse: true,
      formType: 'normal',
      fields: ['date', 'procedureCode', 'statement'],
      component: (onChange, document, formSettings) => (
        <ScintigraphyForm formData={{ onChange, document }} disabledFields={formSettings.fields} />
      ),
    },
  },
  mgravis: {},
  ninmt: {},
};
