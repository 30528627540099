import ActionButtonRounded from 'Components/ActionButtonRounded';
import DataTable from 'Components/DataTable';
import { Container, Item } from 'Components/Grid';
import HistoryRowSubHeader from 'Components/HistoryRowSubHeader';
import InputHandler from 'Components/InputHandler';
import { formatPartialDate, nowPartialDate, partialDateFromDate, partialDateToValue } from 'neuro-utils';
import * as React from 'react';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'Store/index';
import { MyServiceContext } from '..';
import NewTreatmentDecisionCell from './components/treatmentDecisionCell';
import { getSurveyStatus, isTreatmentPeriod, TSurveyType } from 'Routes/MyService/util';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import colors from '../../../../config/theme/colors';
import {
  bai,
  bdi,
  copd,
  deps,
  des,
  deviceTherapyControlVisitInquiry,
  dss,
  ess,
  isi,
  isLocaleKey,
  ISatisfactionWithCare,
  contraIndicationsInquiry,
  ninmtPreInquiry,
  sleepApneaFirstVisitInquiry,
  satisfactionWithCare,
  treatmentMonitoringInquiry,
  eq5d,
  pedsql,
  Task_Progress,
} from 'neuro-schemas';
import { ICompactVaultUser } from 'neuro-data-structures';
import { IconButton, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { MenuItem } from '@mui/material';
import ConfirmationDialog from 'Components/ConfirmationDialog';
import { actions } from 'Store/myapp/actions';
import { ICapabilityContextProps, withCapabilities } from 'Containers/CapabilityHandler';
import { assertCapabilities } from 'Store/index';
import PlatformCapabilities from '../../../../config/capabilities';
import { surveysByPlatform } from '../config';
import RecurringTasksList from './components/RecurringTasksList';
import { omitControlProps } from 'Utility/documentHandling';
import { capitalize } from 'Utility/string';

const StyledMenuIcon = ({ ...other }) => (
  <IconButton
    disableRipple
    disableFocusRipple
    {...other}
    sx={{ padding: '0 1rem', color: '#6c96ae', cursor: 'pointer' }}
  >
    <MoreVertIcon />
  </IconButton>
);

const satisfactionWithCareIconStyle = {
  color: colors.black,
  background: 'radial-gradient(circle, #F2C900 50%, #00000000 50%)',
};

const SatisfactionWithCareIcon = ({ value }: { value: ISatisfactionWithCare['satisfactionWithCare'] }) =>
  ({
    satisfied: <SentimentSatisfiedAltIcon style={satisfactionWithCareIconStyle} />,
    somewhatSatisfied: <SentimentSatisfiedIcon style={satisfactionWithCareIconStyle} />,
    dissatisfied: <SentimentVeryDissatisfiedIcon style={satisfactionWithCareIconStyle} />,
    unknown: <></>,
  })[value ?? 'unknown'];

// TODO: Move to a more general location since these are used in other sections too
export const surveyCreator = (
  document: TSurveyType | Partial<IBackground>,
  patientId: string,
  users: ICompactVaultUser[],
  delegates: Array<IDelegate>,
  fm: (s: string) => string,
): string => {
  if (document._creator === patientId) return fm('myService.patient');

  const delegate = delegates.find((del) => del.id === document._creator);
  if (delegate) {
    return `${delegate.first_names} ${delegate.last_names}`;
  }

  const hcpCreator = users.find((u) => u.userId === document._creator);
  if (hcpCreator) return `${hcpCreator.firstNames} ${hcpCreator.lastNames}`;

  return fm('myService.hcp');
};
// TODO: Move to a more general location since these are used in other sections too
export const surveyModifyer = (
  document: TSurveyType | Partial<IBackground>,
  patientId: string,
  users: ICompactVaultUser[],
  delegates: Array<IDelegate>,
  fm: (s: string) => string,
): string => {
  if (!document._commitsLength || document._commitsLength === 1) return '';

  const commitPartialDate = formatPartialDate(partialDateFromDate(new Date(document._cdate ?? 0)));

  if (document._commitCreator === patientId) return `${fm('myService.patient')} (${commitPartialDate})`;

  const delegate = delegates.find((del) => del.id === document._commitCreator);
  if (delegate) {
    return `${delegate.first_names} ${delegate.last_names} (${commitPartialDate})`;
  }

  const hcpCreator = users.find((u) => u.userId === document._commitCreator);
  if (hcpCreator) return `${hcpCreator.firstNames} ${hcpCreator.lastNames} (${commitPartialDate})`;

  return `${fm('myService.hcp')} (${commitPartialDate})`;
};

const SurveysHistory = ({ treatmentDecisionDocs, surveyDocs, capabilityGroups = {}, users, taskLists }: IOwnProps) => {
  const myServContext = React.useContext(MyServiceContext);
  const { fm, setEditingObj, locale, setViewingObj, platform } = myServContext;
  const [surveyTypeInput, setSurveyTypeInput] = React.useState<undefined | string>(undefined);
  const [tableFilterInput, setTableFilterInput] = React.useState<string>('all');

  const allHistoryData: Array<ININMTTreatmentPeriod | TSurveyType> = [...treatmentDecisionDocs, ...surveyDocs];

  const mysqUserId = useSelector((s: IState) => s.myapp.mysqUserId) || '';
  const patientDelegates = useSelector((s: IState) => s.patient.delegates) || [];

  const onChangeSurveyType = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    if (typeof value === 'string') {
      setSurveyTypeInput(value);
    }
  };

  const onChangeFilter = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    if (typeof value === 'string') {
      setTableFilterInput(value);
    }
  };

  const [menuData, setMenuData] = React.useState<{
    anchor: null | HTMLElement;
    data: Record<string, any>;
    type: string;
  } | null>(null);

  const toggleMenu =
    (data: Record<string, any>, type: string) =>
    (e: React.MouseEvent<HTMLElement>): void => {
      setMenuData(menuData ? null : { anchor: e.currentTarget, data, type });
    };

  const allSurveySchemas = {
    bdi,
    bai,
    ninmtPreInquiry,
    contraIndicationsInquiry,
    treatmentMonitoringInquiry,
    copd,
    deps,
    des,
    dss,
    ess,
    isi,
    sleepApneaFirstVisitInquiry,
    deviceTherapyControlVisitInquiry,
    satisfactionWithCare,
    eq5d,
    pedsql,
  };
  const useLocale = isLocaleKey(locale) ? locale : 'fi';

  const canEdit = (doctype?: string) => doctype && doctype !== 'pedsql' && doctype !== 'pdq8';
  const deletingCapability = assertCapabilities([PlatformCapabilities.MYSERVICE_SURVEYS_DELETION], capabilityGroups);
  const [deleteId, setDeleteId] = React.useState<string | null>(null);

  const historyDataToTable = allHistoryData
    .sort((a, b) => partialDateToValue(b.date) - partialDateToValue(a.date))
    // TODO: Hacky...
    .filter(
      (d) =>
        tableFilterInput === 'all' ||
        d._type === tableFilterInput ||
        (d._type === 'pedsql' &&
          'module' in d &&
          typeof d.module === 'string' &&
          tableFilterInput === `pedsql${capitalize(d.module)}`),
    )
    .map((data, i) => {
      let title;
      if (Object.keys(allSurveySchemas).includes(data._type)) {
        if (data._type === 'pedsql' && 'module' in data && typeof data.module === 'string') {
          title = fm(`myService.${platform}.opts.pedsql${capitalize(data.module)}`);
        } else {
          title = allSurveySchemas[data._type as keyof typeof allSurveySchemas].localizations[useLocale].title;
        }
      } else {
        title = fm(`myService.${platform}.${data._type}`) ?? '-';
      }

      const { yielded, ceiling } = Task_Progress.calculateProgress(
        data._type as keyof Task_Progress.TTaskDocType,
        omitControlProps(data),
      );

      if (isTreatmentPeriod(data) && data.date) {
        return <NewTreatmentDecisionCell fm={fm} date={data.date} key={i} />;
      } else {
        return [
          data._type === 'eq5d'
            ? formatPartialDate(data.date ?? partialDateFromDate(new Date(data._docCreateDate)))
            : formatPartialDate(data.date),
          title,
          getSurveyStatus(data) ? (
            <div data-surveystatus="filled" style={{ display: 'flex' }}>
              {data._type !== 'satisfactionWithCare' && (
                <CheckCircleIcon style={{ marginRight: '0.5rem', color: colors.success }} />
              )}
              {ceiling || ceiling === 0 ? (
                data._type === 'pdq8' ? (
                  `${Math.round(((typeof yielded === 'number' ? yielded : 1) / ceiling) * 100)} %`
                ) : (
                  `${yielded} / ${ceiling}`
                )
              ) : data._type === 'satisfactionWithCare' ? (
                <SatisfactionWithCareIcon value={(data as ISatisfactionWithCare).satisfactionWithCare} />
              ) : (
                fm('myService.filled')
              )}
            </div>
          ) : getSurveyStatus(data) === false ? (
            <div data-surveystatus="inProgress" style={{ display: 'flex' }}>
              <WarningIcon style={{ marginRight: '0.5rem', color: colors.warning.dark }} />
              {fm('myService.inProgress')}
            </div>
          ) : (
            '-'
          ),
          surveyCreator(data, mysqUserId, users, patientDelegates, fm),
          surveyModifyer(data, mysqUserId, users, patientDelegates, fm),
          <div key={`${i}${data._id ?? ''}`} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ActionButtonRounded
              text="general.view"
              filled={true}
              width={9.6}
              height={2.5}
              fontSize={16}
              uppercase={false}
              onClick={() => {
                data._type && setViewingObj({ type: data._type, data: data as TSurveyType });
              }}
            />

            <StyledMenuIcon onClick={toggleMenu(data, data._type)} />
          </div>,
        ];
      }
    });

  const rowStyleArr = new Array(historyDataToTable.length || 0);
  rowStyleArr.fill(undefined);

  historyDataToTable.forEach((row, i) => {
    if (Array.isArray(row) && row[2]) {
      if (typeof row[2] === 'string') return;
      if (row[2].props['data-surveystatus'] === 'inProgress') rowStyleArr[i] = { rowColor: colors.warning.lightest };
    }
  });

  const dispatch = useDispatch();

  const recurringTaskLists = taskLists?.filter(
    (t) =>
      t?.recurring &&
      partialDateToValue(t.deadline) > partialDateToValue(nowPartialDate()) &&
      platform &&
      t.tasks?.every((task) => surveysByPlatform[platform]?.includes(task)),
  );

  return (
    <>
      <Container style={{ justifyContent: 'space-between', marginBottom: '2rem' }}>
        <Item
          xs={6}
          style={{
            paddingRight: '2rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <HistoryRowSubHeader header={fm('myService.surveys')} />
          {platform && recurringTaskLists && recurringTaskLists.length > 0 && (
            <RecurringTasksList platform={platform} recurringTasks={recurringTaskLists} />
          )}
          <div
            style={{
              width: '100%',
              height: '5.6rem',
              backgroundColor: colors.appBlue.lightest,
              borderRadius: '0.5rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span style={{ color: colors.appBlue.default, paddingLeft: '2rem', marginRight: '1rem' }}>
              {fm('myService.show')}
            </span>
            <div>
              <InputHandler
                type="Select"
                name="tableFilter"
                editing={true}
                options={[
                  'all',
                  ...(platform && surveysByPlatform[platform] && Array.isArray(surveysByPlatform[platform])
                    ? (surveysByPlatform[platform] ?? [])
                        .flatMap((s) => (s === 'pedsql' ? ['pedsqlNeuromuscular', 'pedsqlFatigue'] : s))
                        .filter((st) =>
                          platform === 'ninmt'
                            ? st !== 'supplyShortagesInquiry'
                            : platform === 'sleepApnea'
                              ? st !== 'background'
                              : platform === 'parkinson'
                                ? st !== 'parkinsonMobileBackground' && st !== 'lifestyleMobile'
                                : st,
                        )
                    : []),
                ]}
                formData={{ onChange: onChangeFilter, document: { tableFilter: tableFilterInput } }}
                optionFormatter={(o) => fm(`myService.${platform}.opts.${o}`)}
              />
            </div>
          </div>
        </Item>
        <Item xs={6} style={{ paddingLeft: '2rem' }}>
          <div style={{ border: 'solid 1px', borderColor: colors.gray, borderRadius: '0.3rem', padding: '2rem' }}>
            <span style={{ color: colors.tertiaryText }}>{fm('myService.surveyFillingText')}</span>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.5rem' }}>
              <InputHandler
                type="Select"
                name="surveyTypeInput"
                editing={true}
                options={
                  platform
                    ? surveysByPlatform[platform]
                        ?.flatMap((s) => (s === 'pedsql' ? ['pedsqlNeuromuscular', 'pedsqlFatigue'] : s))
                        ?.filter((st) =>
                          platform === 'ninmt'
                            ? st !== 'contraIndicationsInquiry' && st !== 'supplyShortagesInquiry'
                            : platform === 'sleepApnea'
                              ? st !== 'background'
                              : platform === 'sma' || platform === 'dmd'
                                ? st !== 'pedsqlNeuromuscular' && st !== 'pedsqlFatigue'
                                : platform === 'parkinson'
                                  ? st !== 'parkinsonMobileBackground' && st !== 'pdq8' && st !== 'lifestyleMobile'
                                  : st,
                        )
                    : []
                }
                optionFormatter={(o) => fm(`myService.${platform}.opts.${o}`)}
                formData={{ onChange: onChangeSurveyType, document: { surveyTypeInput: surveyTypeInput } }}
                placeholder={'myService.chooseSurvey'}
              />
              <ActionButtonRounded
                text="myService.fillSurvey"
                filled={true}
                uppercase={false}
                width={9.6}
                height={3}
                fontSize={16}
                disabled={surveyTypeInput === undefined}
                onClick={() => {
                  surveyTypeInput &&
                    setEditingObj({
                      type: surveyTypeInput,
                      data: null,
                    });
                }}
              />
            </div>
          </div>
        </Item>
      </Container>
      <DataTable
        headers={['date', 'name', 'statusAndScore', 'reporter', 'edited', 'hidden']}
        headersFormatter={(h) =>
          h === 'hidden'
            ? ''
            : h === 'statusAndScore'
              ? `${fm('myService.status')} / ${fm('myService.score')}`
              : fm(`myService.${h}`)
        }
        data={{
          rowData: historyDataToTable,
          rowStyle: rowStyleArr,
        }}
      />

      <Menu id="surveyhistorymenu" anchorEl={menuData?.anchor} open={Boolean(menuData)} onClose={toggleMenu({}, '')}>
        {
          <MenuItem
            onClick={() =>
              menuData?.type && setEditingObj({ type: menuData?.type, data: menuData?.data as TSurveyType })
            }
            disabled={!canEdit(menuData?.type)}
          >
            {fm('general.edit')}
          </MenuItem>
        }
        {deletingCapability && (
          <MenuItem
            onClick={(e) => {
              setDeleteId(menuData?.data._id);
              toggleMenu({}, '')(e);
            }}
          >
            {fm('general.delete')}
          </MenuItem>
        )}
      </Menu>
      <ConfirmationDialog
        open={!!deleteId}
        text={fm('general.reallyWantToDelete')}
        cancel={{ callback: () => setDeleteId(null) }}
        confirm={{
          callback: () => {
            const deleteSurvey = allHistoryData.find((d) => d._id === deleteId);
            deleteSurvey && actions.deleteDocument(deleteSurvey._type, deleteSurvey._id, dispatch);
            setDeleteId(null);
          },
        }}
      />
    </>
  );
};

interface IOwnProps extends ICapabilityContextProps {
  treatmentDecisionDocs: Array<ININMTTreatmentPeriod>;
  surveyDocs: Array<TSurveyType>;
  onGoingTasks: Array<ITaskList>;
  users: ICompactVaultUser[];
  taskLists: ITaskList[];
}

export default withCapabilities(SurveysHistory);
