import { find, isEmpty, omit } from 'ramda';
import * as React from 'react';
import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import { FormContextProvider, HistoryContextProvider } from '../../../containers/FormContextHandler';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import {
  docType,
  getHeaderId,
  mapPatientDoesNotWantRespiratorySupportTherapyEndDates,
  patientHasRespiratoryFailureDiagnosis,
  patientHasSleepApneaDiagnosis,
  TDocument,
} from '../utils';
import Form from './Form';

import PatientDoesNotWantRespiratorySupportTherapyHistory from './HistoryRowData/PatientDoesNotWantRespiratorySupportTherapy';
import OxygenTherapyHistory from './HistoryRowData/OxygenTherapy';
import HFNCTherapyHistory from './HistoryRowData/HFNCTherapy';
import PAPTherapyHistory from './HistoryRowData/PAPTherapy';
import MADTherapyHistory from './HistoryRowData/MADTherapy';
import SurgicalTreatmentHistory from './HistoryRowData/SurgicalTreatment';
import OtherTreatmentHistory from './HistoryRowData/OtherTreatment';
import { actions, TCreationPromiseType } from '../../../store/documents/actions';
import { controlProps } from '../../../utility/documentHandling';
import { useAppDispatch, useAppSelector } from 'Store/index';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import { Container, Item } from 'Components/Grid';
import Icon from 'Components/_NewElements/Icon';
import { fetchAvxPatientLink } from './Form/PAPTherapy/functions';
import { fm } from 'Components/FormatMessage';

export const PAPTherapyContext = React.createContext<IPAPTherapyContext>({
  resMedIntegrationEnabled: undefined,
  setResMedIntegrationEnabled: () => '',
  resMedDialogOpen: false,
  setResMedDialogOpen: () => '',
  ecn: undefined,
});

interface IPAPTherapyContext {
  resMedIntegrationEnabled: boolean | undefined;
  setResMedIntegrationEnabled: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  resMedDialogOpen: boolean;
  setResMedDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ecn: string | undefined;
}

const TreatmentHistory = ({ documents }: { documents: Array<IDiagnosis> }): JSX.Element => {
  return (
    <React.Fragment>
      <PatientDoesNotWantRespiratorySupportTherapyHistory />
      {(patientHasRespiratoryFailureDiagnosis(documents) ||
        (!patientHasRespiratoryFailureDiagnosis(documents) && !patientHasSleepApneaDiagnosis(documents))) && (
        <OxygenTherapyHistory />
      )}
      {(patientHasRespiratoryFailureDiagnosis(documents) ||
        (!patientHasRespiratoryFailureDiagnosis(documents) && !patientHasSleepApneaDiagnosis(documents))) && (
        <HFNCTherapyHistory />
      )}
      <PAPTherapyHistory />
      {(patientHasSleepApneaDiagnosis(documents) ||
        (!patientHasRespiratoryFailureDiagnosis(documents) && !patientHasSleepApneaDiagnosis(documents))) && (
        <MADTherapyHistory />
      )}
      {(patientHasSleepApneaDiagnosis(documents) ||
        (!patientHasRespiratoryFailureDiagnosis(documents) && !patientHasSleepApneaDiagnosis(documents))) && (
        <SurgicalTreatmentHistory />
      )}
      <OtherTreatmentHistory />
    </React.Fragment>
  );
};

const Treatment = ({ documents }: IOwnProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const createCommit = async (id: string): Promise<TCreationPromiseType> =>
    actions.createCommit({ id, name: 'patientDoesNotWantRespiratorySupportTherapy' })(dispatch);

  const closeCommitWithData = (id: string, data: Record<string, unknown>) =>
    actions.closeCommit({ id, name: 'patientDoesNotWantRespiratorySupportTherapy' }, data)(dispatch);

  const isEditing = documents.some((d) => d._editing === true);

  React.useEffect(() => {
    const docsWithUpdatedEndDates = mapPatientDoesNotWantRespiratorySupportTherapyEndDates(documents);
    // PDNWRST endDates are only updated if they don't exist, not if there isn't a matching treatment start/decision date
    if (!isEditing) {
      docsWithUpdatedEndDates?.forEach((d) => {
        createCommit(d._id)
          .then(() => {
            closeCommitWithData(d._id, omit(controlProps, d));
          })
          .catch(() => {
            return;
          });
      });
    }
  }, [isEditing]);

  const visitReasonRetro = useAppSelector((s: IState) => s.session.data?.visitreason === 'retrospectiveDataTransfer');

  const [resMedIntegrationEnabled, setResMedIntegrationEnabled] = React.useState<boolean | undefined>(undefined);
  const [ecn, setECN] = React.useState<string | undefined>(undefined);
  const [resMedDialogOpen, setResMedDialogOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    fetchAvxPatientLink()
      .then((res) => {
        if (Array.isArray(res) && res.length > 0) {
          setResMedIntegrationEnabled(!!(res && typeof res === 'object' && !isEmpty(res)));
          setECN(res[0].ecn);
        } else {
          setResMedIntegrationEnabled(false);
          if (ecn) setECN(undefined);
        }
      })
      .catch(() => {
        return;
      });
  }, [resMedIntegrationEnabled]);

  const resMedContext = {
    resMedIntegrationEnabled,
    setResMedIntegrationEnabled,
    resMedDialogOpen,
    setResMedDialogOpen,
    ecn,
  };

  return (
    <FormEditingHandler name="treatment" documents={documents}>
      {(editing, startEdit, formData, view): JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name="treatment"
            headerId={getHeaderId(documents, editing, view?.viewing)}
            editing={editing}
            editButtons={<div />}
            infoText={
              docType(documents, editing ?? view?.viewing) === 'papTherapy' ? (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ fontWeight: '600' }}>
                    {fm('treatment.papTherapy.resMedIntegration')}{' '}
                    <span style={{ textTransform: 'lowercase' }}>
                      {fm(`treatment.papTherapy.resMed${resMedIntegrationEnabled ? 'InUse' : 'NotInUse'}`)}
                    </span>
                  </div>
                  <ActionButtonRounded
                    text={
                      <Container alignItems="center" justifyContent="space-between">
                        <Item>
                          {resMedIntegrationEnabled ? <Icon icon="mergeType" /> : <Icon icon="sqicon" size={3.1} />}
                        </Item>
                        <Item xs={true}>
                          {fm(`treatment.papTherapy.${resMedIntegrationEnabled ? 'editResMed' : 'connectResMed'}`)}
                        </Item>
                      </Container>
                    }
                    width={26}
                    height={4}
                    fontSize={16}
                    onClick={() => setResMedDialogOpen(true)}
                    disabled={typeof resMedIntegrationEnabled !== 'boolean' || !!view?.viewing}
                  />
                </div>
              ) : undefined
            }
            headerWidth={12}
          />
          <PAPTherapyContext.Provider value={resMedContext}>
            {/** Editing */}
            {editing ? (
              <FormContextProvider context={{ formData, documents, editing }}>
                <Form document={documents && (find((d: TDocument) => d._id === editing, documents) as TDocument)} />
              </FormContextProvider>
            ) : null}
            {/** Viewing */}
            {view?.viewing ? (
              <FormContextProvider context={{ formData, documents, view }}>
                <Form
                  document={documents && (find((d: TDocument) => d._id === view?.viewing, documents) as TDocument)}
                />
              </FormContextProvider>
            ) : null}
            {/** History */}
            {!editing && !view?.viewing ? (
              <HistoryContextProvider
                context={{
                  documents: documents,
                  startEdit: startEdit,
                  view: view,
                  additionalData: { visitReasonRetro },
                }}
              >
                <TreatmentHistory documents={documents.filter((d) => d._type === 'diagnosis') as Array<IDiagnosis>} />
              </HistoryContextProvider>
            ) : null}
          </PAPTherapyContext.Provider>
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: (
    | TDocument
    | IDiagnosis
    | ISleepApneaFirstVisit
    | IRespiratoryFirstVisit
    | ISleepPolygraphy
    | ISleepStudy
  )[];
}

export default Treatment;
