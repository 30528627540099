import { Container, Item } from 'Components/Grid';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';

import FormRow from '../../../../../components/FormRow';
import FormSectionBar from '../../../../../components/FormSectionBar';
import FormSectionHistoryAcceptor from '../../../../../components/FormSectionHistoryAcceptor';
import InputHandler from '../../../../../components/InputHandler';
import colors from '../../../../../config/theme/colors';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import {
  FBSorNAV,
  FBSorNAVandOneCBS,
  FBSRequirementsAreMet,
  NAVRequirementsAreMet,
  PossibleCBDCanBeDiagnosed,
  possibleCBSRequirementsMet,
  ProbableCBDCanBeDiagnosed,
  probableCBSRequirementsMet,
  PSPSandOneCBS,
  PSPSRequirementsAreMet,
} from '../../../utils/CBD';
import { fm } from 'Components/FormatMessage';

import { ReferenceText } from '../../../utils/styled';

const SubHeader = styled('div')({
  margin: '1rem 0',
  fontWeight: 600,
});

const SubHeader2 = styled('div')({
  margin: '1rem 0',
});

const SubHeader3 = styled('div')({
  margin: '0 0 4.5rem 0',
});

const Text = styled('div')({
  margin: '0 0 4rem 0',
});

const formFields = [
  'limbRigidityOrAkinesia',
  'limbDystonia',
  'limbMyclonus',
  'orobuccalOrLimbApraxia',
  'corticalSensoryDeficit',
  'alienLimbPhenomena',
  'FBSExecutiveDysfunction',
  'FBSBehavioralOrPersonalityChanges',
  'FBSVisuospatialDeficits',
  'NAVEffortfulAgrammaticSpeech',
  'NAVImpairedGrammarSentenceComprehension',
  'NAVGropingDistortedSpeechProduction',
  'PSPSAxialOrSymmetricLimbRigidityOrAkinesia',
  'PSPSPosturalInstabilityOrFails',
  'PSPSUrinaryIncontinence',
  'PSPSBehavioralChanges',
  'PSPSSupranuclearVerticalGazePalsyOrDecreasedVelocityOrVerticalSaccades',
  'presentationInsidiousOnsetAndGradualProgression',
  'minimumDurationOfSymptoms1Year',
  'probableAgeAtOnset50Year',
  'probableFamilyHistory',
  'probableGeneticMutationAffectingTau',
];

const setAllUnknown = (formData: IFormContext['formData'], viewing: boolean): void => {
  formFields.forEach((f) => {
    if (!viewing && !(formData?.document as { [key: string]: any })[f]) {
      formData?.onChange?.({
        [f]: 'unknown',
      });
    }
  });
};

const MakeFormRowCounter = ({
  title,
  description,
  content,
}: {
  title: string;
  description?: JSX.Element;
  content: JSX.Element;
}): JSX.Element => {
  return (
    <FormRow title={title} description={description} headerStyle={{ fontWeight: 400, color: colors.primaryText }}>
      {content}
    </FormRow>
  );
};

const MakeFormRow = ({
  title,
  description,
  name,
  formData,
  viewing,
  bottomMargin = true,
}: {
  title: string;
  description?: JSX.Element;
  name: string;
  formData: IFormContext['formData'];
  viewing: boolean;
  bottomMargin?: boolean;
}): JSX.Element => (
  <FormRow title={title} description={description} headerWidth={6} bottomMargin={bottomMargin}>
    <InputHandler
      editing={!viewing}
      formData={formData}
      name={name}
      type="RadioButtonRow"
      preset="yesNoUnknown"
      height={3.5}
      width={9}
    />
  </FormRow>
);

const CBDForm = ({ documents, formData, editing, view }: IFormContext<ICBD>): JSX.Element => {
  const isViewing = !editing && !!view?.viewing;
  const docID = editing ?? view?.viewing;

  React.useEffect(() => {
    setAllUnknown(formData, isViewing);
  }, [formData, isViewing]);

  return (
    <React.Fragment>
      {formData ? (
        <React.Fragment>
          <FormRow title="general.date">
            <InputHandler
              type="PartialDate"
              editing={!isViewing}
              name="date"
              formData={formData}
              dateDefault="now"
              isNotCancellable={true}
            />
          </FormRow>
          <FormSectionBar header={'diagnosis.cbd.clinicalPhenotypes.title'} />

          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={(s: string): JSX.Element => <FormattedMessage id={'general.' + s} />}
            header="diagnosis.cbd.clinicalPhenotypes.probablePossibleCorticobasalSyndrome"
            hideCopyButton={isViewing}
          >
            {{
              probableCorticobasalSyndrome: {
                header: <SubHeader>{fm('diagnosis.cbd.clinicalPhenotypes.probableCorticobasalSyndrome')}</SubHeader>,
              },
              asymmetricPre2: { header: fm('diagnosis.cbd.clinicalPhenotypes.asymmetricPre2') },
              limbRigidityOrAkinesia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.probableLimbRigidityOrAkinesia"
                    name="limbRigidityOrAkinesia"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              limbDystonia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.probableLimbDystonia"
                    name="limbDystonia"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              limbMyclonus: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.probableLimbMyclonus"
                    name="limbMyclonus"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              plus2Of: {
                header: <SubHeader2>{fm('diagnosis.cbd.clinicalPhenotypes.plus2Of')}</SubHeader2>,
              },
              orobuccalOrLimbApraxia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.probableOrobuccalOrLimbApraxia"
                    name="orobuccalOrLimbApraxia"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              corticalSensoryDeficit: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.probableCorticalSensoryDeficit"
                    name="corticalSensoryDeficit"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              alienLimbPhenomena: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.probableAlienLimbPhenomena"
                    name="alienLimbPhenomena"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              probableCBSRequirementsMet: {
                header: (
                  <MakeFormRowCounter
                    title={'diagnosis.cbd.clinicalPhenotypes.probableCBSRequirementsMet'}
                    content={
                      <div style={{ fontWeight: 600 }}>
                        {fm('general.' + probableCBSRequirementsMet(formData.document))}
                      </div>
                    }
                  />
                ),
              },
              possibleCorticobasalSyndrome: {
                header: <SubHeader>{fm('diagnosis.cbd.clinicalPhenotypes.possibleCorticobasalSyndrome')}</SubHeader>,
              },
              mayBeSym1: { header: fm('diagnosis.cbd.clinicalPhenotypes.mayBeSym1') },
              possibleLimbRigidityOrAkinesia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.possibleLimbRigidityOrAkinesia"
                    name="limbRigidityOrAkinesia"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              possibleLimbDystonia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.possibleLimbDystonia"
                    name="limbDystonia"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              possibleLimbMyclonus: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.possibleLimbMyclonus"
                    name="limbMyclonus"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              plus1Of: {
                header: <SubHeader2>{fm('diagnosis.cbd.clinicalPhenotypes.plus1Of')}</SubHeader2>,
              },
              possibleOrobuccalOrLimbApraxia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.possibleOrobuccalOrLimbApraxia"
                    name="orobuccalOrLimbApraxia"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              possibleCorticalSensoryDeficit: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.possibleCorticalSensoryDeficit"
                    name="corticalSensoryDeficit"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              possibleAlienLimbPhenomena: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.possibleAlienLimbPhenomena"
                    name="alienLimbPhenomena"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              possibleCBSRequirementsMet: {
                header: (
                  <MakeFormRowCounter
                    title={'diagnosis.cbd.clinicalPhenotypes.possibleCBSRequirementsMet'}
                    content={
                      <div style={{ fontWeight: 600 }}>
                        {fm('general.' + possibleCBSRequirementsMet(formData.document))}
                      </div>
                    }
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>

          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={(s: string): JSX.Element => <FormattedMessage id={'general.' + s} />}
            header="diagnosis.cbd.clinicalPhenotypes.FBS"
            hideCopyButton={isViewing}
          >
            {{
              twoOf: {
                header: <SubHeader2>{fm('diagnosis.cbd.clinicalPhenotypes.twoOf')}</SubHeader2>,
              },
              FBSExecutiveDysfunction: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.FBSExecutiveDysfunction"
                    name="FBSExecutiveDysfunction"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              FBSBehavioralOrPersonalityChanges: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.FBSBehavioralOrPersonalityChanges"
                    name="FBSBehavioralOrPersonalityChanges"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              FBSVisuospatialDeficits: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.FBSVisuospatialDeficits"
                    name="FBSVisuospatialDeficits"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              FBSRequirementsAreMet: {
                header: (
                  <MakeFormRowCounter
                    title={'diagnosis.cbd.clinicalPhenotypes.FBSRequirementsAreMet'}
                    content={
                      <div style={{ fontWeight: 600 }}>{fm('general.' + FBSRequirementsAreMet(formData.document))}</div>
                    }
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>

          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={(s: string): JSX.Element => <FormattedMessage id={'general.' + s} />}
            header="diagnosis.cbd.clinicalPhenotypes.NAV"
            hideCopyButton={isViewing}
          >
            {{
              NAVEffortfulAgrammaticSpeech: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.NAVEffortfulAgrammaticSpeech"
                    name="NAVEffortfulAgrammaticSpeech"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              plus1Of: {
                header: <SubHeader2>{fm('diagnosis.cbd.clinicalPhenotypes.plus1Of')}</SubHeader2>,
              },
              NAVImpairedGrammarSentenceComprehension: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.NAVImpairedGrammarSentenceComprehension"
                    name="NAVImpairedGrammarSentenceComprehension"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              NAVGropingDistortedSpeechProduction: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.NAVGropingDistortedSpeechProduction"
                    name="NAVGropingDistortedSpeechProduction"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              NAVRequirementsAreMet: {
                header: (
                  <MakeFormRowCounter
                    title={'diagnosis.cbd.clinicalPhenotypes.NAVRequirementsAreMet'}
                    content={
                      <div style={{ fontWeight: 600 }}>{fm('general.' + NAVRequirementsAreMet(formData.document))}</div>
                    }
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>

          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={(s: string): JSX.Element => <FormattedMessage id={'general.' + s} />}
            header="diagnosis.cbd.clinicalPhenotypes.PSPS"
            hideCopyButton={isViewing}
          >
            {{
              threeOf: {
                header: <SubHeader2>{fm('diagnosis.cbd.clinicalPhenotypes.threeOf')}</SubHeader2>,
              },
              PSPSAxialOrSymmetricLimbRigidityOrAkinesia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.PSPSAxialOrSymmetricLimbRigidityOrAkinesia"
                    name="PSPSAxialOrSymmetricLimbRigidityOrAkinesia"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              PSPSPosturalInstabilityOrFails: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.PSPSPosturalInstabilityOrFails"
                    name="PSPSPosturalInstabilityOrFails"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              PSPSUrinaryIncontinence: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.PSPSUrinaryIncontinence"
                    name="PSPSUrinaryIncontinence"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              PSPSBehavioralChanges: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.PSPSBehavioralChanges"
                    name="PSPSBehavioralChanges"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              PSPSSupranuclearVerticalGazePalsyOrDecreasedVelocityOrVerticalSaccades: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.clinicalPhenotypes.PSPSSupranuclearVerticalGazePalsyOrDecreasedVelocityOrVerticalSaccades"
                    name="PSPSSupranuclearVerticalGazePalsyOrDecreasedVelocityOrVerticalSaccades"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              PSPSRequirementsAreMet: {
                header: (
                  <MakeFormRowCounter
                    title={'diagnosis.cbd.clinicalPhenotypes.PSPSRequirementsAreMet'}
                    content={
                      <div style={{ fontWeight: 600 }}>
                        {fm('general.' + PSPSRequirementsAreMet(formData.document))}
                      </div>
                    }
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>

          <FormSectionBar header={'diagnosis.cbd.diagnosticCriteria.title'} />

          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={(s: string): JSX.Element => <FormattedMessage id={'general.' + s} />}
            header="diagnosis.cbd.diagnosticCriteria.probableCBDCriteria"
            hideCopyButton={isViewing}
          >
            {{
              presentationInsidiousOnsetAndGradualProgression: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.diagnosticCriteria.probablePresentationInsidiousOnsetAndGradualProgression"
                    name="presentationInsidiousOnsetAndGradualProgression"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              minimumDurationOfSymptoms1Year: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.diagnosticCriteria.probableMinimumDurationOfSymptoms1Year"
                    name="minimumDurationOfSymptoms1Year"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              probableAgeAtOnset50Year: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.diagnosticCriteria.probableAgeAtOnset50Year"
                    name="probableAgeAtOnset50Year"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              probableFamilyHistory: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.diagnosticCriteria.probableFamilyHistory"
                    name="probableFamilyHistory"
                    formData={formData}
                    viewing={isViewing}
                    description={fm('diagnosis.cbd.diagnosticCriteria.probableCBDConclusionInfo')}
                  />
                ),
              },
              permittedPhenotypes: {
                header: <SubHeader2>{fm('diagnosis.cbd.diagnosticCriteria.permittedPhenotypes')}</SubHeader2>,
              },
              probableProbableCBS: {
                header: (
                  <MakeFormRowCounter
                    title={'diagnosis.cbd.diagnosticCriteria.probableProbableCBS'}
                    content={
                      <div style={{ fontWeight: 600 }}>
                        {fm('general.' + probableCBSRequirementsMet(formData.document))}
                      </div>
                    }
                  />
                ),
              },
              or: {
                header: <Text>{'or'}</Text>,
              },
              probableFBSOrNAVPlusAtLeastOneCBSFeature: {
                header: (
                  <MakeFormRowCounter
                    title={'diagnosis.cbd.diagnosticCriteria.probableFBSOrNAVPlusAtLeastOneCBSFeature'}
                    content={
                      <div style={{ fontWeight: 600 }}>{fm('general.' + FBSorNAVandOneCBS(formData.document))}</div>
                    }
                  />
                ),
              },
              probableGeneticMutationAffectingTau: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.diagnosticCriteria.probableGeneticMutationAffectingTau"
                    name="probableGeneticMutationAffectingTau"
                    formData={formData}
                    viewing={isViewing}
                    description={fm('diagnosis.cbd.diagnosticCriteria.probableCBDConclusionInfo')}
                  />
                ),
              },
              probableCBDCanBeDiagnosed: {
                header: (
                  <MakeFormRowCounter
                    title={'diagnosis.cbd.diagnosticCriteria.probableCBDCanBeDiagnosed'}
                    content={
                      <div style={{ fontWeight: 600 }}>
                        {fm('general.' + ProbableCBDCanBeDiagnosed(formData.document))}
                      </div>
                    }
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>

          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={(s: string): JSX.Element => <FormattedMessage id={'general.' + s} />}
            header="diagnosis.cbd.diagnosticCriteria.possibleBDCriteria"
            hideCopyButton={isViewing}
          >
            {{
              presentationInsidiousOnsetAndGradualProgression: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.diagnosticCriteria.possiblePresentationInsidiousOnsetAndGradualProgression"
                    name="presentationInsidiousOnsetAndGradualProgression"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              minimumDurationOfSymptoms1Year: {
                element: (
                  <MakeFormRow
                    title="diagnosis.cbd.diagnosticCriteria.possibleMinimumDurationOfSymptoms1Year"
                    name="minimumDurationOfSymptoms1Year"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              possibleAgeAtOnset: {
                header: <SubHeader3>{fm('diagnosis.cbd.diagnosticCriteria.possibleAgeAtOnset')}</SubHeader3>,
              },
              possibleFamilyHistory: {
                header: <SubHeader3>{fm('diagnosis.cbd.diagnosticCriteria.possibleFamilyHistory')}</SubHeader3>,
              },
              permittedPhenotypes: {
                header: (
                  <SubHeader2 style={{ marginTop: 0 }}>
                    {fm('diagnosis.cbd.diagnosticCriteria.permittedPhenotypes')}
                  </SubHeader2>
                ),
              },
              possiblePossibleCBS: {
                header: (
                  <MakeFormRowCounter
                    title={'diagnosis.cbd.diagnosticCriteria.possiblePossibleCBS'}
                    content={
                      <div style={{ fontWeight: 600 }}>
                        {fm('general.' + possibleCBSRequirementsMet(formData.document))}
                      </div>
                    }
                  />
                ),
              },
              or1: {
                header: <Text>{'or'}</Text>,
              },
              possibleFBSOrNAV: {
                header: (
                  <MakeFormRowCounter
                    title={'diagnosis.cbd.diagnosticCriteria.possibleFBSOrNAV'}
                    content={<div style={{ fontWeight: 600 }}>{fm('general.' + FBSorNAV(formData.document))}</div>}
                  />
                ),
              },
              or2: {
                header: <Text>{'or'}</Text>,
              },
              possiblePSPSPlusAtLeastOneCBSFeature: {
                header: (
                  <MakeFormRowCounter
                    title={'diagnosis.cbd.diagnosticCriteria.possiblePSPSPlusAtLeastOneCBSFeature'}
                    content={<div style={{ fontWeight: 600 }}>{fm('general.' + PSPSandOneCBS(formData.document))}</div>}
                  />
                ),
              },
              possibleGeneticMutationAffectingTau: {
                header: (
                  <SubHeader3 style={{ marginTop: '1rem' }}>
                    {fm('diagnosis.cbd.diagnosticCriteria.possibleGeneticMutationAffectingTau')}
                  </SubHeader3>
                ),
              },
              possibleCBDCanBeDiagnosed: {
                header: (
                  <MakeFormRowCounter
                    title={'diagnosis.cbd.diagnosticCriteria.possibleCBDCanBeDiagnosed'}
                    content={
                      <div style={{ fontWeight: 600 }}>
                        {fm('general.' + PossibleCBDCanBeDiagnosed(formData.document))}
                      </div>
                    }
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>

          <Container>
            <Item style={{ padding: '2rem 0' }}>
              <ReferenceText>
                Armstrong MJ, Litvan I, Lang AE, Bak TH, Bhatia KP, Borroni B, Boxer AL, Dickson DW, Grossman M, Hallett
                M, Josephs KA, Kertesz A, Lee SE, Miller BL, Reich SG, Riley DE, Tolosa E, Tröster AI, Vidailhet M,
                Weiner WJ. Criteria for the diagnosis of corticobasal degeneration. Neurology. 2013 Jan
                29;80(5):496-503.
              </ReferenceText>
            </Item>
          </Container>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default withFormContext(CBDForm);
