/*
 * Situational bar component for forms when additional separation between sections is required.
 */

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import { ArrowDropDown, ArrowRight } from '@mui/icons-material';

import colors from '../../config/theme/colors';
import { Container, Item } from '../Grid';

const Bar = styled('div')({
  fontWeight: 600,
  width: 'auto !important',
  height: '5rem',
  margin: '0 -4rem',
  padding: '0 4rem',
  backgroundColor: colors.primary,
  color: colors.white,
  fontSize: '2.2rem',
});

const IconArea = styled('div')({
  width: '4rem',
  marginLeft: '-4rem',
});

const OpenClosedIcon = ({ open }: { open: boolean }): JSX.Element | null => (
  <IconArea>
    <Container justifyContent="center">
      <Item>
        {open ? (
          <ArrowDropDown color="inherit" fontSize="large" style={{ display: 'block' }} />
        ) : (
          <ArrowRight color="inherit" fontSize="large" style={{ display: 'block' }} />
        )}
      </Item>
    </Container>
  </IconArea>
);

const FormSectionBar = ({ header, open }: IOwnProps): JSX.Element | null => (
  <Bar>
    {header && (
      <Container alignItems="center" style={{ height: '100%' }}>
        {open !== undefined && (
          <Item>
            <OpenClosedIcon open={open} />
          </Item>
        )}
        <Item xs={true}>
          <FormattedMessage id={header} />
        </Item>
      </Container>
    )}
  </Bar>
);

interface IOwnProps {
  header?: string;
  open?: boolean;
}

export default FormSectionBar;
