import * as React from 'react';

import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import PlatformConditional from 'Components/PlatformConditional';
import { FormattedMessage } from 'react-intl';
import FormSection from '../../../../../components/FormSection';
import { styled } from '@mui/system';
import colors from '../../../../../config/theme/colors';
import ToolTip from 'Components/ToolTip';
import { useAppSelector } from 'Store/index';
import { formatPartialDate } from 'neuro-utils';
import { Container, Item } from 'Components/Grid';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import { path } from 'ramda';

const optionFormatter =
  (opt: number | string) =>
  (name: number | string): JSX.Element => {
    return <FormattedMessage id={`imaging.${opt}.${name}`} />;
  };

const fm = (id: string, values?: { [key: string]: string | number | null }): JSX.Element => (
  <FormattedMessage id={id} values={values || undefined} />
);

const StyledSubHeader = styled('div')({
  color: colors.tertiaryText,
  fontSize: '2rem',
  marginBottom: '0.6rem',
});

const StyledSubHeaderCentered = styled(StyledSubHeader)({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'flex-start',
});

const StyledDiv = styled('div')({
  marginTop: '4rem',
});

const StyledDivCentered = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'flex-start',
});

const StyledRowTitle = styled('span')({
  color: colors.tertiaryText,
});

const StyledTable = styled('table')({
  marginLeft: '-0.4rem',
});
const StyledTableBody = styled('tbody')({});
const StyledTableRow = styled('tr')({
  display: 'table-row',
  verticalAlign: 'top',
  '&:first-of-type': {
    verticalAlign: 'top',
  },
});
const StyledTableColumn = styled('td')({
  display: 'table-cell',
  '&:first-of-type': {
    width: '32%',
  },
  '&:nth-of-type(3)': {
    padding: '0 0 0 1rem',
  },
  '&:nth-of-type(4)': {
    padding: '0 1rem 0 1rem',
  },
});

const StyledPreviousNumerical = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'flex-start',
  color: theme.palette.primary.light,
  fontWeight: 600,
}));

const lesionOptions = ['X', 'N/A', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, '10-20', '21-40', '>40'];

const LesionsFormTitleRow = ({
  title,
  lesionsTitle,
  deltaSymbol,
  diameterSymbol,
  trendTitle,
}: ILesionsFormTitleRow): JSX.Element => (
  <StyledTableRow>
    <StyledTableColumn>
      <StyledSubHeader>{fm(title)}</StyledSubHeader>
    </StyledTableColumn>
    <StyledTableColumn>
      <StyledSubHeaderCentered>{lesionsTitle && fm(lesionsTitle)}</StyledSubHeaderCentered>
    </StyledTableColumn>
    <StyledTableColumn>
      <ToolTip
        description={fm('imaging.lesionsDelta')}
        content={<StyledSubHeaderCentered>{deltaSymbol}</StyledSubHeaderCentered>}
        hover={true}
      />
    </StyledTableColumn>
    <StyledTableColumn>
      <ToolTip
        description={fm('imaging.lesionsMaxDiameter')}
        content={<StyledSubHeaderCentered>{diameterSymbol}</StyledSubHeaderCentered>}
        hover={true}
      />
    </StyledTableColumn>
    <StyledTableColumn>
      <StyledSubHeaderCentered>{trendTitle && fm(trendTitle)}</StyledSubHeaderCentered>
    </StyledTableColumn>
  </StyledTableRow>
);

const LesionsFormDataRow = ({
  formData,
  rowTitle,
  lesions,
  titlePadding,
  prevDoc,
}: ILesionsFormDataRow): JSX.Element => {
  const fieldArray = (field: string) => `${lesions}.${field}`.split('.');
  const pathToPrevValue = (field: string) => path(fieldArray(field), prevDoc) as string | number;
  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableColumn style={{ paddingRight: titlePadding }}>
          <StyledRowTitle>{fm(rowTitle)}</StyledRowTitle>
        </StyledTableColumn>
        <StyledTableColumn>
          <StyledDivCentered>
            <InputHandler
              type="RadioButtonRow"
              editing={true}
              name={`${lesions}.lesions`}
              formData={formData}
              options={lesionOptions}
              tooltips={[
                { index: 0, message: fm('imaging.opts.notDoneUnknown.notDone') },
                { index: 1, message: fm('imaging.opts.notDoneUnknown.unknown') },
              ]}
              previousValue={pathToPrevValue('lesions')}
            />
          </StyledDivCentered>
        </StyledTableColumn>
        <StyledTableColumn>
          <InputHandler
            type="NumberField"
            editing={true}
            name={`${lesions}.lesionsDelta`}
            formData={formData}
            width={3}
          />
          <StyledPreviousNumerical>{pathToPrevValue('lesionsDelta')}</StyledPreviousNumerical>
        </StyledTableColumn>
        <StyledTableColumn>
          <InputHandler
            type="NumberField"
            editing={true}
            name={`${lesions}.lesionsMaxDiameter`}
            formData={formData}
            width={3}
          />
          <StyledPreviousNumerical>{pathToPrevValue('lesionsMaxDiameter')}</StyledPreviousNumerical>
        </StyledTableColumn>
        <StyledTableColumn>
          <StyledDivCentered>
            <InputHandler
              type="RadioTrendRow"
              editing={true}
              name={`${lesions}.lesionsTrend`}
              formData={formData}
              options={['decreased', 'same', 'increased']}
              previousValue={pathToPrevValue('lesionsTrend')}
            />
          </StyledDivCentered>
        </StyledTableColumn>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableColumn colSpan={5}>
          <div style={{ marginBottom: '1.5rem' }}></div>
        </StyledTableColumn>
      </StyledTableRow>
    </React.Fragment>
  );
};

const MriForm = ({ formData, disabledFields = [] }: IOwnProps): JSX.Element => {
  const platform = useAppSelector((s: { session: ISessionStore }) => s.session.platforms?.selected);
  const previousMRIDoc = useAppSelector((s) => {
    const docs = s.documents.sortedAndMergedDocuments;
    const filteredDocument = [...(docs || [])].filter((d) => d._type === 'mri')?.[1];
    return filteredDocument;
  });
  const [previousDocument, setPreviousDocument] = React.useState<IMRI | null>(null);

  const togglePrevious = () => setPreviousDocument(previousDocument ? null : previousMRIDoc || null);

  return (
    <React.Fragment>
      <FormRow title="general.date" headerWidth={platform === 'ms' ? 4 : 5}>
        <InputHandler
          type="PartialDate"
          editing={!disabledFields.includes('date')}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>

      {platform === 'ms' ? (
        <FormRow title="imaging.procedureCode" headerWidth={4}>
          <InputHandler
            type="TextField"
            editing={!disabledFields.includes('procedureCode')}
            name="procedureCode"
            formData={formData}
            placeholder={'imaging.procedureCodePlaceholder'}
          />
        </FormRow>
      ) : formData.document.procedureCode ? (
        <FormRow title="imaging.procedureCode">
          <InputHandler type="TextField" editing={false} name="procedureCode" formData={formData} />
        </FormRow>
      ) : null}

      {formData.document.statement && (
        <FormRow title="imaging.statement" headerWidth={platform === 'ms' ? 4 : 5}>
          <InputHandler type="TextArea" editing={false} name="statement" formData={formData} />
        </FormRow>
      )}

      <PlatformConditional platform="ms">
        <React.Fragment>
          <FormRow title="imaging.tesla" headerWidth={4}>
            <InputHandler
              type="RadioRow"
              editing={true}
              name="tesla"
              formData={formData}
              options={['0.5', '1.0', '1.5', '3.0']}
            />
          </FormRow>
          <FormRow title="imaging.wasGadoliniumGiven" headerWidth={4}>
            <InputHandler
              type="Radio"
              editing={true}
              name="wasGadoliniumGiven"
              formData={formData}
              options={['yes', 'no', 'unknown']}
              optionFormatter={optionFormatter('opts.yesNoUnknown')}
            />
          </FormRow>

          {previousMRIDoc && (
            <FormSection>
              <FormRow title="imaging.previousMRI" headerWidth={4}>
                <Container>
                  <Item>{previousMRIDoc.date ? formatPartialDate(previousMRIDoc.date) : '-'}</Item>
                  <Item xs={9} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ActionButtonRounded
                      text={previousDocument ? 'imaging.hidePrevData' : 'imaging.showPrevData'}
                      onClick={togglePrevious}
                      width={16}
                      height={3}
                      fontSize={16}
                      uppercase={false}
                    />
                  </Item>
                </Container>
              </FormRow>
            </FormSection>
          )}

          <FormSection header="imaging.brain">
            <StyledTable>
              <StyledTableBody>
                <LesionsFormTitleRow
                  title="imaging.brainT2Flair"
                  lesionsTitle="imaging.lesions"
                  deltaSymbol={<>&Delta;</>}
                  diameterSymbol="Ø"
                  trendTitle="imaging.trend"
                />
                <LesionsFormDataRow
                  formData={formData}
                  rowTitle="imaging.totalLesions"
                  lesions="brainT2Flair.total"
                  prevDoc={previousDocument}
                />
                <LesionsFormDataRow
                  formData={formData}
                  rowTitle="imaging.infratentorialLesions"
                  lesions="brainT2Flair.infratentorial"
                  prevDoc={previousDocument}
                />
                <LesionsFormDataRow
                  formData={formData}
                  rowTitle="imaging.supratentorialLesions"
                  lesions="brainT2Flair.supratentorial"
                  prevDoc={previousDocument}
                />
                <LesionsFormDataRow
                  formData={formData}
                  rowTitle="imaging.intraAndJuxtacorticalLesions"
                  lesions="brainT2Flair.intraAndJuxtacortical"
                  prevDoc={previousDocument}
                />
                <LesionsFormTitleRow title="imaging.brainT1" />
                <LesionsFormDataRow
                  formData={formData}
                  rowTitle="imaging.totalLesions"
                  lesions="brainT1.total"
                  prevDoc={previousDocument}
                />
                <LesionsFormDataRow
                  formData={formData}
                  rowTitle="imaging.gadoliniumEnhancedLesions"
                  lesions="brainT1.gadoliniumEnhanced"
                  prevDoc={previousDocument}
                />
                <StyledTableRow>
                  <StyledTableColumn style={{ verticalAlign: 'top' }}>
                    <StyledDiv>
                      <StyledRowTitle>{fm('imaging.brainVolume')}</StyledRowTitle>
                    </StyledDiv>
                  </StyledTableColumn>
                  <StyledTableColumn colSpan={4}>
                    <Container style={{ marginTop: '4rem' }}>
                      <Item>
                        <InputHandler
                          type="NumberField"
                          editing={true}
                          name="brainVolume"
                          formData={formData}
                          width={12}
                        />
                        {previousDocument && (
                          <StyledPreviousNumerical>{path(['brainVolume'], previousDocument)}</StyledPreviousNumerical>
                        )}
                      </Item>

                      <Item style={{ marginLeft: '3rem' }}>
                        <InputHandler
                          type="RadioTrendRow"
                          editing={true}
                          name="brainVolumeTrend"
                          formData={formData}
                          options={['decreased', 'same', 'increased']}
                          invert
                          previousValue={path(['brainVolumeTrend'], previousDocument)}
                        />
                      </Item>
                    </Container>
                  </StyledTableColumn>
                </StyledTableRow>
              </StyledTableBody>
            </StyledTable>
          </FormSection>
          <FormSection header="imaging.spinalCord">
            <StyledTable>
              <StyledTableBody>
                <LesionsFormTitleRow
                  title="imaging.spinalCordT2"
                  lesionsTitle="imaging.lesions"
                  deltaSymbol={<>&Delta;</>}
                  diameterSymbol="Ø"
                  trendTitle="imaging.trend"
                />
                <LesionsFormDataRow
                  formData={formData}
                  rowTitle="imaging.totalLesions"
                  lesions="spinalCordT2.total"
                  prevDoc={previousDocument}
                />
                <LesionsFormTitleRow title="imaging.spinalCordT1" />
                <LesionsFormDataRow
                  formData={formData}
                  rowTitle="imaging.gadoliniumEnhancedLesions"
                  lesions="spinalCordT1.gadoliniumEnhanced"
                  prevDoc={previousDocument}
                />
              </StyledTableBody>
            </StyledTable>
          </FormSection>
        </React.Fragment>
      </PlatformConditional>
      <PlatformConditional platform="parkinson">
        <React.Fragment>
          <FormRow title="imaging.wereMicroangiopathicChangesInWhiteMatterAbnormallyNumerous" id={'microAngiopathic'}>
            <InputHandler
              type="Radio"
              editing={true}
              name="wereMicroangiopathicChangesInWhiteMatterAbnormallyNumerous"
              formData={formData}
              options={['yes', 'fazekas0']}
              optionFormatter={optionFormatter('opts.yesFazekas0')}
            />
          </FormRow>
          {formData.document.wereMicroangiopathicChangesInWhiteMatterAbnormallyNumerous === 'yes' && (
            <React.Fragment>
              <FormRow title="imaging.wasFazekasClassificationDone" id={'fazekas'}>
                <InputHandler
                  type="Radio"
                  editing={true}
                  name="wasFazekasClassificationDone"
                  formData={formData}
                  options={['yes', 'no']}
                  optionFormatter={optionFormatter('opts.yesNo')}
                />
              </FormRow>
              {formData.document.wasFazekasClassificationDone === 'yes' && (
                <FormRow title="imaging.fazekasGrade" id={'fazekasGrade'}>
                  <InputHandler
                    type="Radio"
                    editing={true}
                    name="fazekasGrade"
                    formData={formData}
                    options={['fazekas1', 'fazekas2', 'fazekas3']}
                    optionFormatter={optionFormatter('opts.fazekas')}
                  />
                </FormRow>
              )}
            </React.Fragment>
          )}
          <FormRow title="imaging.atrophy">
            <InputHandler
              type="Radio"
              editing={true}
              name="atrophy"
              formData={formData}
              options={['no', 'appropriateForAge', 'pronouncedCommon', 'focal']}
              optionFormatter={optionFormatter('opts.atrophy')}
            />
          </FormRow>
          {formData.document.atrophy === 'focal' && (
            <FormRow title="imaging.atrophyLocalization">
              <InputHandler type="TextArea" editing={true} name="atrophyLocalization" formData={formData} />
            </FormRow>
          )}
          <FormRow title="imaging.otherClinicallyRelevantFindingFound" id={'otherRelevant'}>
            <InputHandler
              type="Radio"
              editing={true}
              name="otherClinicallyRelevantFindingFound"
              formData={formData}
              options={['yes', 'no']}
              optionFormatter={optionFormatter('opts.yesNo')}
            />
          </FormRow>
          {formData.document.otherClinicallyRelevantFindingFound === 'yes' && (
            <FormRow title="imaging.whatFinding">
              <InputHandler type="TextArea" editing={true} name="otherClinicallyRelevantFinding" formData={formData} />
            </FormRow>
          )}
        </React.Fragment>
      </PlatformConditional>
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<IMRI>;
  disabledFields?: string[];
}

interface ILesionsFormTitleRow {
  title: string;
  lesionsTitle?: string;
  deltaSymbol?: JSX.Element;
  diameterSymbol?: string;
  trendTitle?: string;
}

interface ILesionsFormDataRow extends IOwnProps {
  rowTitle: string;
  lesions: string | number;
  titlePadding?: string;
  prevDoc: IMRI | null;
}

export default MriForm;
