import DataTable from 'Components/DataTable';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import { ess, IESS, isLocaleKey, Task_Progress } from 'neuro-schemas';
import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { omitControlProps } from 'Utility/documentHandling';
import { MyServiceContext } from '../..';
import colors from '../../../../../config/theme/colors';

const essFields: Array<keyof IESS> = [
  'sittingReading',
  'watchingTV',
  'sittingInactiveInPublic',
  'passengerInCarForHour',
  'restingInAfternoon',
  'sittingTalking',
  'sittingAfterLunchWithoutAlcohol',
  'inCarInTrafficStopped',
];

export const Ess = (): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, fm, locale, viewing, setViewingObj, setEditingObj } = myServContext;
  const essData = (editing?.data || viewing?.data) as IESS & IControlProps;
  const essLocales = ess.localizations;
  const useLocale = isLocaleKey(locale) ? locale : 'fi';
  const onChangeSurveyForm = (values: TOnChangeValues): void => {
    const field = Object.keys(values)[0];
    const value = Object.values(values)[0];
    setEditingData?.({
      ...essData,
      [field]: value,
    });
  };
  return (
    <>
      {!viewing ? (
        <div style={{ width: '80%', color: colors.tertiaryText, marginBottom: '2rem' }}>
          <p>{essLocales[useLocale]['surveyInfo']}</p>
          <p>{essLocales[useLocale]['surveyFillingInfo']}</p>
        </div>
      ) : null}
      <BlockWrapper
        title={viewing ? formatPartialDate(essData.date) : undefined}
        buttons={
          viewing
            ? [
                {
                  title: 'general.edit',
                  onClick: () => {
                    setViewingObj(null);
                    setEditingObj({ type: 'ess', data: essData });
                  },
                },
              ]
            : undefined
        }
      >
        {!viewing && (
          <FormRow title={'general.date'} headerWidth={5.3}>
            <InputHandler
              name="date"
              type="PartialDate"
              dateDefault="now"
              formData={{
                document: { date: essData?.date },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
        )}
        <div style={{ width: '60%', marginBottom: '2rem', marginTop: viewing ? '6rem' : undefined }}>
          <DataTable
            headers={viewing ? ['', '', ''] : ['', '']}
            data={{
              rowData: essFields.map((f) => {
                const rowSet = [
                  essLocales[useLocale][f],
                  <InputHandler
                    key={f}
                    name={f}
                    type="Radio"
                    editing={!!editing}
                    formData={{
                      document: { [f]: essData?.[f] },
                      onChange: onChangeSurveyForm,
                    }}
                    options={['none', 'slight', 'moderate', 'high']}
                    optionFormatter={(o) => essLocales[useLocale][`opts.${o}`]}
                  />,
                ];
                if (viewing && f !== 'date') {
                  rowSet.push(
                    <span style={{ fontWeight: 600 }}>
                      {essData[f] ? ess.calculators.getNumberValueOfField(f, essData[f]) : ''}
                    </span>,
                  );
                }
                return rowSet;
              }),
              rowStyle: viewing ? undefined : essFields.map(() => ({ rowAlign: 'top' })),
            }}
          />
        </div>
        <FormRow
          title="myService.sleepApnea.ess.score"
          headerStyle={{ fontWeight: 600 }}
          headerWidth={viewing ? 6.75 : 5.3}
        >
          <div style={{ color: colors.tertiaryText, fontWeight: 600 }}>
            <span>
              {typeof Task_Progress.calculateProgress('ess', omitControlProps(essData)).yielded === 'number'
                ? Task_Progress.calculateProgress('ess', omitControlProps(essData)).yielded
                : '-'}
            </span>
            <span>{` / ${Task_Progress.calculateProgress('ess', omitControlProps(essData)).ceiling}`}</span>
          </div>
        </FormRow>
        <div style={{ color: colors.tertiaryText, marginTop: '-4rem' }}>{fm('myService.sleepApnea.ess.scoreInfo')}</div>
      </BlockWrapper>
      <div style={{ color: colors.tertiaryText, fontStyle: 'italic' }}>
        <p>{fm('myService.sleepApnea.ess.reference')}</p>
      </div>
    </>
  );
};
