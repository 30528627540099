import * as React from 'react';
import { styled } from '@mui/system';
import FormRow from '../../../../../../../../components/FormRow';
import FormSectionHistoryAcceptor from '../../../../../../../../components/FormSectionHistoryAcceptor';
import { Container, Item } from '../../../../../../../../components/Grid';
import InputHandler from '../../../../../../../../components/InputHandler';

import colors from '../../../../../../../../config/theme/colors';

import { fm } from 'Components/FormatMessage';
import {
  atLeastOneCoreClinicalCharacteristicWithoutAQP4,
  atLeastTwoCoreClinicalCharacteristicsOccuring,
  diagnosisCriteriaAreFullfilledWithoutAQP4,
  fullfillmentOfAdditionalMRI,
  negativeTestsForAQP4,
  numberOfGivenAnswers,
  redFlags,
} from '../../../../../../utils/NMOSD';
import { InputlessFormRow } from '../../../../../components';

const Header = styled('div')({
  fontWeight: 'bold',
  margin: '2rem 0',
});

const SubHeader = styled(Header)({
  margin: '0 0 1rem 0',
});

const GroupStyle = styled('div')({
  backgroundColor: colors.lightestGray,
  padding: '1rem 0 1rem 1rem',
});

const GroupElement = (elem?: JSX.Element): JSX.Element => <GroupStyle>{elem}</GroupStyle>;

const generalOptionFormatter = (s?: React.ReactText | boolean): JSX.Element => fm(`general.${s || 'empty'}`);

const formFields = [
  'disseminationInSpace',
  'acuteOpticNeuritisRequires',
  'acuteMyelitisRequires',
  'areaPostremaSyndromeRequires',
  'acuteBrainstemSyndromeRequires',
  'exclusionOfAlternativeDiagnosesWithoutAQP4IgG',
];

const setAllUnknown = (formData: IOwnProps['formData'], viewing: boolean): void => {
  formFields.forEach((f) => {
    if (!viewing && !(formData.document as { [key: string]: any })[f]) {
      formData?.onChange?.({
        [f]: 'unknown',
      });
    }
  });
};

const MakeFormRow = ({
  title,
  description,
  name,
  formData,
  viewing,
  leftMargin,
  bottomMargin = true,
}: {
  title: string;
  description?: JSX.Element;
  name: string;
  formData: IOwnProps['formData'];
  viewing: boolean;
  leftMargin?: number;
  bottomMargin?: boolean;
}): JSX.Element => (
  <FormRow
    title={title}
    description={description}
    headerWidth={6}
    bottomMargin={bottomMargin}
    headerStyle={{ marginLeft: `${leftMargin ?? 0}rem` }}
  >
    <InputHandler
      editing={!viewing}
      formData={formData}
      name={name}
      type="RadioButtonRow"
      preset="yesNoUnknown"
      height={3.5}
      width={9}
      optionFormatter={generalOptionFormatter}
    />
  </FormRow>
);

const NMOSDWithoutAQP4 = ({ documents, formData, view, editingID }: IOwnProps): JSX.Element => {
  const isViewing = !editingID && !!view?.viewing;
  const docID = editingID ?? view?.viewing;

  const [showAcuteOpticNeuritis, setAcuteOpticNeuritis] = React.useState<boolean>(true);
  React.useEffect(() => {
    setAcuteOpticNeuritis(formData.document.opticNeuritis !== 'no');
  }, [formData.document.opticNeuritis]);

  const [showAcuteMyelitis, setAcuteMyelitis] = React.useState<boolean>(true);
  React.useEffect(() => {
    setAcuteMyelitis(formData.document.acuteMyelitis !== 'no');
  }, [formData.document.acuteMyelitis]);

  const [showAreaPostremaSyndrome, setAreaPostremaSyndrome] = React.useState<boolean>(true);
  React.useEffect(() => {
    setAreaPostremaSyndrome(formData.document.areaPostremaSyndrome !== 'no');
  }, [formData.document.areaPostremaSyndrome]);

  const [showAcuteBrainstemSyndrome, setAcuteBrainstemSyndrome] = React.useState<boolean>(true);
  React.useEffect(() => {
    setAcuteBrainstemSyndrome(formData.document.acuteBrainstemSyndrome !== 'no');
  }, [formData.document.acuteBrainstemSyndrome]);

  React.useEffect(() => {
    setAllUnknown(formData, isViewing);
  }, [formData, isViewing]);

  return (
    <React.Fragment>
      <FormSectionHistoryAcceptor
        formData={formData}
        documentID={docID}
        documents={documents}
        optionFormatter={generalOptionFormatter}
        header="diagnosis.nmosdDiagnosticCriteria.DiagnosticCriteriaForNMOSDWithoutAQP4.title"
        hideCopyButton={isViewing}
      >
        {{
          atLeastTwoCoreClinicalCharacteristicsOccuring: {
            header: (
              <InputlessFormRow
                title={
                  'diagnosis.nmosdDiagnosticCriteria.DiagnosticCriteriaForNMOSDWithoutAQP4.atLeastTwoCoreClinicalCharacteristicsOccuring'
                }
                element={fm(`general.${atLeastTwoCoreClinicalCharacteristicsOccuring(formData.document)}`)}
                boldValue={true}
                bottomMargin={false}
              />
            ),
          },
          andMeetingAllOfTheFollowingRequirements: {
            header: (
              <Header>
                {fm(
                  'diagnosis.nmosdDiagnosticCriteria.DiagnosticCriteriaForNMOSDWithoutAQP4.andMeetingAllOfTheFollowingRequirements',
                )}
              </Header>
            ),
          },
          atLeastOneCoreClinicalCharacteristic: {
            header: (
              <InputlessFormRow
                title={
                  'diagnosis.nmosdDiagnosticCriteria.DiagnosticCriteriaForNMOSDWithoutAQP4.atLeastOneCoreClinicalCharacteristic'
                }
                element={fm(`general.${atLeastOneCoreClinicalCharacteristicWithoutAQP4(formData.document)}`)}
                boldValue={true}
              />
            ),
          },
          disseminationInSpace: {
            element: (
              <MakeFormRow
                title="diagnosis.nmosdDiagnosticCriteria.DiagnosticCriteriaForNMOSDWithoutAQP4.disseminationInSpace"
                name="disseminationInSpace"
                formData={formData}
                viewing={isViewing}
              />
            ),
          },
          fullfillmentOfAdditionalMRI: {
            header: (
              <div style={{ marginBottom: '1rem' }}>
                <InputlessFormRow
                  title={
                    'diagnosis.nmosdDiagnosticCriteria.DiagnosticCriteriaForNMOSDWithoutAQP4.fullfillmentOfAdditionalMRI'
                  }
                  element={fm(`general.${fullfillmentOfAdditionalMRI(formData.document)}`)}
                  boldValue={true}
                  bottomMargin={false}
                />
              </div>
            ),
          },
          group1: {
            group: {
              groupElement: GroupElement,
              children: {
                acuteOpticNeuritisRequires: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.DiagnosticCriteriaForNMOSDWithoutAQP4.acuteOpticNeuritisRequires"
                      name="acuteOpticNeuritisRequires"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: showAcuteOpticNeuritis,
                },
                acuteMyelitisRequires: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.DiagnosticCriteriaForNMOSDWithoutAQP4.acuteMyelitisRequires"
                      name="acuteMyelitisRequires"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: showAcuteMyelitis,
                },
                areaPostremaSyndromeRequires: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.DiagnosticCriteriaForNMOSDWithoutAQP4.areaPostremaSyndromeRequires"
                      name="areaPostremaSyndromeRequires"
                      formData={formData}
                      viewing={isViewing}
                    />
                  ),
                  condition: showAreaPostremaSyndrome,
                },
                acuteBrainstemSyndromeRequires: {
                  element: (
                    <MakeFormRow
                      title="diagnosis.nmosdDiagnosticCriteria.DiagnosticCriteriaForNMOSDWithoutAQP4.acuteBrainstemSyndromeRequires"
                      name="acuteBrainstemSyndromeRequires"
                      formData={formData}
                      viewing={isViewing}
                      bottomMargin={false}
                    />
                  ),
                  condition: showAcuteBrainstemSyndrome,
                },
              },
              condition:
                showAcuteOpticNeuritis || showAcuteMyelitis || showAreaPostremaSyndrome || showAcuteBrainstemSyndrome,
            },
          },

          negativeTestsForAQP4: {
            header: (
              <div style={{ marginTop: '4.5rem' }}>
                <InputlessFormRow
                  title={'diagnosis.nmosdDiagnosticCriteria.DiagnosticCriteriaForNMOSDWithoutAQP4.negativeTestsForAQP4'}
                  element={fm(`general.${negativeTestsForAQP4(formData.document)}`)}
                  boldValue={true}
                />
              </div>
            ),
          },
          exclusionOfAlternativeDiagnosesWithoutAQP4IgG: {
            element: (
              <MakeFormRow
                title="diagnosis.nmosdDiagnosticCriteria.DiagnosticCriteriaForNMOSDWithoutAQP4.exclusionOfAlternativeDiagnoses"
                name="exclusionOfAlternativeDiagnosesWithoutAQP4IgG"
                formData={formData}
                viewing={isViewing}
              />
            ),
          },
          numberOfRedFlags: {
            element: (
              <React.Fragment>
                <Container style={{ marginTop: '1rem' }}>
                  <Item xs={6} style={{ paddingRight: '1rem' }}>
                    <SubHeader>{fm('diagnosis.nmosdDiagnosticCriteria.RedFlags.titleShort')}</SubHeader>
                  </Item>
                </Container>
                {['yes', 'no', 'unknown'].map(
                  (answer: string): JSX.Element => (
                    <Container key={`${answer}RedFlags`} style={{ marginTop: '1rem' }}>
                      <Item xs={6} style={{ paddingLeft: '3rem' }}>
                        {fm(`general.${answer}`)}
                      </Item>
                      <Item xs={true} style={{ fontWeight: 600 }}>
                        {numberOfGivenAnswers(formData.document, redFlags, answer as 'yes' | 'no' | 'unknown')}
                      </Item>
                    </Container>
                  ),
                )}
              </React.Fragment>
            ),
          },
          diagnosisCriteriaAreFullfilled: {
            header: (
              <div style={{ marginTop: '4.5rem' }}>
                <InputlessFormRow
                  title={
                    'diagnosis.nmosdDiagnosticCriteria.DiagnosticCriteriaForNMOSDWithoutAQP4.diagnosisCriteriaAreFullfilled'
                  }
                  element={fm(`general.${diagnosisCriteriaAreFullfilledWithoutAQP4(formData.document)}`)}
                  boldValue={true}
                  bottomMargin={false}
                  darkerTitle={true}
                />
              </div>
            ),
          },
        }}
      </FormSectionHistoryAcceptor>
    </React.Fragment>
  );
};

interface IOwnProps {
  documents?: Array<INMOSDCriteria>;
  formData: IFormData<INMOSDCriteria>;
  view?: IView;
  editingID?: string;
}

export default NMOSDWithoutAQP4;
