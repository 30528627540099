import { styled } from '@mui/system';
import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import QuestionStepperSimpler from '../../../../../components/QuestionStepperSimpler';
import colors from '../../../../../config/theme/colors';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import { getStepContentGetter, steps, getStepsLocalesGetter } from './bnsqSettingsSleep';
import {
  getStepContentGetter as getStepContentGetterSL,
  steps as stepsSL,
  getStepsLocalesGetter as getStepsLocalesGetterSL,
} from './bnsqSettingsSleepLenght';

const ReferenceText = styled('div')({
  color: colors.tertiaryText,
});

const BNSQForm = ({ formData, view, fm }: IFormContext<IBNSQ>): JSX.Element => {
  const viewing = !!view?.viewing;
  return (
    <React.Fragment>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={!viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormSection header="diagnosticSurvey.bnsq.sleepingTitle">
        <div style={{ marginBottom: '2rem' }}>{fm('diagnosticSurvey.bnsq.sleepingTitleInfo')}</div>
        <QuestionStepperSimpler
          formData={formData}
          steps={steps}
          stepsLocales={getStepsLocalesGetter(fm)}
          getStepContent={getStepContentGetter(fm, viewing)}
        />
      </FormSection>
      <FormSection header="diagnosticSurvey.bnsq.sleepingLengthSnoringTitle">
        <div style={{ marginBottom: '2rem' }}></div>
        <QuestionStepperSimpler
          formData={formData}
          steps={stepsSL}
          stepsLocales={getStepsLocalesGetterSL(fm)}
          getStepContent={getStepContentGetterSL(fm, viewing)}
        />
      </FormSection>
      <ReferenceText>
        Copyright M. Partinen/Väitöskirjan/varusm (1978) ja BNSQ:n (1986) pohjalta. Unikyselylomake 120208 BNSQ.
      </ReferenceText>
    </React.Fragment>
  );
};

export default withFormContext(BNSQForm);
