import * as React from 'react';
import { exists, formatPartialDate } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import EventStepper from '../../../../../../components/EventStepper';
import { defaultPapSettingsUnits } from '../../../config';
import { StepperHeaderValuePair } from '../../../../../../components/EventStepper/components';
import { mappedSettingFields } from '../config';

const ResMedSettings = ({ settings }: IOwnProps): JSX.Element => {
  return (
    <EventStepper
      name="settings"
      formData={{ onChange: () => '', document: {} }}
      stepLabelText={(d: IPapSetting): string => formatPartialDate(d.date)}
      stepContent={(d: IPapSetting): JSX.Element => {
        return (
          <React.Fragment>
            {Object.values(mappedSettingFields).map((field, index) => {
              const value = d[field as keyof IPapSetting];
              return exists(value) ? (
                <StepperHeaderValuePair
                  key={`${field}${index}`}
                  header={fm(`treatment.papTherapy.${field}`)}
                  value={
                    field === 'epapAuto' ? (
                      fm(`treatment.papTherapy.opts.${value}`)
                    ) : (
                      <span>
                        {value}{' '}
                        {defaultPapSettingsUnits[field] === 'inhalationsPerMinute'
                          ? fm('treatment.papTherapy.inhalationsPerMinute')
                          : defaultPapSettingsUnits[field] ?? ''}
                      </span>
                    )
                  }
                />
              ) : null;
            })}
          </React.Fragment>
        );
      }}
      addNewTextHeader="treatment.papTherapy.newSettings"
      addNewTextButton="treatment.papTherapy.newSettings"
      previousEventsTextHeader="treatment.papTherapy.settings"
      noPreviousEventsTextHeader="treatment.papTherapy.noSettings"
      editingElements={() => <></>}
      viewing={true}
      readOnlyEvents={settings}
    />
  );
};

interface IOwnProps {
  settings?: Array<IPapSetting>;
}

export default ResMedSettings;
