import * as React from 'react';
import { styled } from '@mui/system';
import FormRow from '../../../../../../../components/FormRow';
import InputHandler from '../../../../../../../components/InputHandler';
import colors from '../../../../../../../config/theme/colors';
import { IFormContext, withFormContext } from '../../../../../../../containers/FormContextHandler';
import { showNOMSDWithAQP4, showNOMSDWithoutAQP4 } from '../../../../../utils/NMOSD';
import { ReferenceText } from '../../../../../utils/styled';
import AQP4 from './AQP4-IgG';
import NMOSDCoreClinicalCharacteristics from './CoreClinicalChar';
import NeuroImaging from './Neuroimaging';
import NMOSDWithAQP4 from './NMOSDWithAQP4';
import NMOSDWithoutAQP4 from './NMOSDWithoutAQP4';
import RedFlags from './RedFlags';

const Header = styled('div')({
  fontWeight: 600,
  fontSize: '2rem',
  color: colors.primaryText,
  margin: '0rem 0 2rem 0',
});

const NMOSDDiagnosticCriteria = ({
  documents,
  formData,
  view,
  editing,
  fm = (): string => '',
}: IFormContext<INMOSDCriteria>): JSX.Element => {
  const [showNMOSDResultsWithAQP4, setNMOSDResultsWithAQP4] = React.useState<boolean>(false);
  const [showNMOSDResultsWithoutAQP4, setNMOSDResultsWithoutAQP4] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (formData?.document) {
      setNMOSDResultsWithAQP4(showNOMSDWithAQP4(formData?.document));
      setNMOSDResultsWithoutAQP4(showNOMSDWithoutAQP4(formData?.document));
    }
  }, [formData?.document]);

  return (
    <React.Fragment>
      {formData ? (
        <React.Fragment>
          <Header>{fm('diagnosis.nmosdDiagnosticCriteria.header')}</Header>
          <FormRow title="general.date">
            <InputHandler
              type="PartialDate"
              editing={!!editing}
              name="date"
              formData={formData}
              dateDefault="now"
              isNotCancellable={true}
            />
          </FormRow>
          <NMOSDCoreClinicalCharacteristics documents={documents} formData={formData} view={view} editingID={editing} />
          <NeuroImaging documents={documents} formData={formData} view={view} editingID={editing} />
          <RedFlags documents={documents} formData={formData} view={view} editingID={editing} />
          <AQP4 documents={documents} formData={formData} view={view} editingID={editing} />
          {showNMOSDResultsWithAQP4 ? (
            <NMOSDWithAQP4 documents={documents} formData={formData} view={view} editingID={editing} />
          ) : null}
          {showNMOSDResultsWithoutAQP4 ? (
            <NMOSDWithoutAQP4 documents={documents} formData={formData} view={view} editingID={editing} />
          ) : null}
          <ReferenceText>
            Wingerchuk DM, Banwell B, Bennett JL et al. International consensus diagnostic criteria for neuromyelitis
            optica spectrum disorders. Neurology. 2015.
          </ReferenceText>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default withFormContext(NMOSDDiagnosticCriteria);
