import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import { styled } from '@mui/system';

export const opts: string[] = [
  '100',
  '95',
  '90',
  '85',
  '80',
  '75',
  '70',
  '65',
  '60',
  '55',
  '50',
  '45',
  '40',
  '35',
  '30',
  '25',
  '20',
  '15',
  '10',
];

const Instruction = styled('div')({
  fontSize: '1.4rem',
  margin: '0.7rem 0 0 0.7rem',
});

export const optFormatter = (n: string | number): JSX.Element => {
  /* Usually OptionChooser's instructions have their own formatter.
  In UHDRS independence scale form the instructions are required
  in the same "box" with the option. */
  const shouldInstruct = parseInt(`${n}`) % 10 === 0;
  return (
    <>
      <FormattedMessage id={`functionalPerformance.opts.uhdrsIndependenceScale.${n}`} />
      {shouldInstruct && (
        <Instruction>
          <FormattedMessage id={`functionalPerformance.labels.uhdrsIndependenceScale.${n}Instruction`} />
        </Instruction>
      )}
    </>
  );
};
