import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import { styled } from '@mui/system';
import { Container, Item } from 'Components/Grid';

const StyledLabel = styled('div')({
  fontWeight: 600,
});

const instruction = (locale: string): JSX.Element => (
  <React.Fragment>
    <Container style={{ marginBottom: '-2.5rem' }}>
      <Item xs={2}>
        <StyledLabel>Kysymykset</StyledLabel>
      </Item>
      <Item xs={10} style={{ fontStyle: 'italic', paddingLeft: '1rem' }}>
        <FormattedMessage id={'interviewSurveys.labels.madrs.' + locale} />
      </Item>
    </Container>
  </React.Fragment>
);

export const steps = [
  'notedMelancholy',
  'reportedMelancholy',
  'feelingsOfAnxiety',
  'decreasedSleep',
  'decreasedHunger',
  'troubleToConcentrate',
  'lackOfInitiative',
  'lackOfFeelings',
  'pessimisticThoughts',
  'suicidalThoughts',
] as Array<string | { [key: string]: string[] }>;

export const getStepContent = (
  stepName: string,
): {
  opts: (number | string)[];
  optsLocale: string;
  instruction?: JSX.Element;
} => ({
  opts: [0, 1, 2, 3, 4, 5, 6],
  optsLocale: stepName,
  instruction: instruction(`${stepName}Instruction`),
});
