import * as React from 'react';
import { styled } from '@mui/system';
import FormRow from '../../../../../../../../components/FormRow';
import FormSectionHistoryAcceptor from '../../../../../../../../components/FormSectionHistoryAcceptor';
import InputHandler from '../../../../../../../../components/InputHandler';
import { fm } from 'Components/FormatMessage';
import {
  diagnosticCriteriaForRR,
  showDISAndDITQuestions,
  showDISQuestions,
  showDITQuestions,
  showExcludedDifferentialDiagnosis,
} from '../../../../../../utils/MS';
import { InputlessFormRow } from '../../../../../components';

const Header = styled('div')({
  fontWeight: 'bold',
  margin: '2rem 0',
});

const SubHeader = styled('div')({
  margin: '1rem 0',
});

const formFields = [
  'excludedDifferentialDiagnosis',
  'DISNewSymptom',
  'DISPeriventricularArea',
  'DISCorticalArea',
  'DISInfratentorialArea',
  'DISSpinalCordArea',
  'DITNewSymptom',
  'DITNewMKChange',
  'DITLOFracttions',
  'DISAndDITAnatomicNewSymptom',
  'DISAndDITNewMKChange',
  'DITAndDISNewSymptom',
  'DITAndDISTimedMKScatteredColony',
  'DITAndDISLOsegment',
];

const setAllUnknown = (formData: IOwnProps['formData'], viewing: boolean): void => {
  formFields.forEach((f) => {
    if (!viewing && !(formData.document as { [key: string]: any })[f]) {
      formData?.onChange?.({
        [f]: 'unknown',
      });
    }
  });
};

const typicalOptionFormatter = (s?: React.ReactText): JSX.Element =>
  fm(`diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.typicalMSSymptomPeriod.${s || 'empty'}`);
const clinicalOptionFormatter = (s?: React.ReactText): JSX.Element =>
  fm(`diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.clinicalEvidence.${s || 'empty'}`);
const generalOptionFormatter = (s?: React.ReactText | boolean): JSX.Element => fm(`general.${s || 'empty'}`);

const MakeFormRow = ({
  title,
  description,
  name,
  formData,
  viewing,
  leftMargin,
  bottomMargin = true,
}: {
  title: string;
  description?: JSX.Element;
  name: string;
  formData: IOwnProps['formData'];
  viewing: boolean;
  leftMargin?: number;
  bottomMargin?: boolean;
}): JSX.Element => (
  <FormRow
    title={title}
    description={description}
    headerWidth={6}
    bottomMargin={bottomMargin}
    headerStyle={{ marginLeft: `${leftMargin ?? 0}rem` }}
  >
    <InputHandler
      editing={!viewing}
      formData={formData}
      name={name}
      type="RadioButtonRow"
      preset="yesNoUnknown"
      height={3.5}
      width={9}
      optionFormatter={generalOptionFormatter}
    />
  </FormRow>
);
const RRMS = ({ documents, formData, view, editingID }: IOwnProps): JSX.Element => {
  const { document, onChange } = formData;

  const docID = editingID ?? view?.viewing;
  const isViewing = !editingID && !!view?.viewing;

  const [showExcDiffDiagnosis, setExcDiffDiagnosis] = React.useState<boolean>(true);
  const [showDIS, setDIS] = React.useState<boolean>(false);
  const [showDIT, setDIT] = React.useState<boolean>(false);
  const [showDISAndDIT, setDISAndDIT] = React.useState<boolean>(false);

  React.useEffect(() => {
    setExcDiffDiagnosis(showExcludedDifferentialDiagnosis(document));
    setDIS(showDISQuestions(document));
    setDIT(showDITQuestions(document));
    setDISAndDIT(showDISAndDITQuestions(document));
  }, [document]);

  React.useEffect(() => {
    setAllUnknown(formData, isViewing);
  }, [formData, isViewing]);

  React.useEffect(() => {
    if (document.typicalMSSymptomPeriod === '1' && document.clinicalEvidence === '1b') {
      onChange && onChange({ clinicalEvidence: '1a' });
    }
  }, [document.clinicalEvidence, document.typicalMSSymptomPeriod, onChange]);

  return (
    <React.Fragment>
      <FormSectionHistoryAcceptor
        formData={formData}
        documentID={docID}
        documents={documents?.filter((d) => d.revision === '2017')}
        optionFormatter={typicalOptionFormatter}
        header="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.title"
        hideCopyButton={isViewing}
      >
        {{
          typicalMSSymptomPeriod: {
            element: (
              <FormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.typicalMSSymptomPeriod.title"
                headerWidth={6}
                bottomMargin={true}
              >
                <InputHandler
                  type="Select"
                  name="typicalMSSymptomPeriod"
                  editing={!isViewing}
                  formData={formData}
                  options={['1', '2']}
                  optionFormatter={typicalOptionFormatter}
                  placeholder="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.typicalMSSymptomPeriod.placeholder"
                />
              </FormRow>
            ),
          },
          clinicalEvidence: {
            element: (
              <FormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.clinicalEvidence.title"
                headerWidth={6}
                bottomMargin={true}
              >
                <InputHandler
                  type="Select"
                  name="clinicalEvidence"
                  editing={!isViewing}
                  formData={formData}
                  options={['1a', '1b', '2'].filter((o: string) =>
                    formData.document?.typicalMSSymptomPeriod === '1' ? o !== '1b' : o,
                  )}
                  optionFormatter={clinicalOptionFormatter}
                  placeholder="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.clinicalEvidence.placeholder"
                />
              </FormRow>
            ),
          },
          excludedDifferentialDiagnosis: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.excludedDifferentialDiagnosis"
                name="excludedDifferentialDiagnosis"
                formData={formData}
                viewing={isViewing}
              />
            ),
            condition: showExcDiffDiagnosis,
          },

          DISHeader: {
            header: <Header>{fm('diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.DIS')}</Header>,
            condition: showDIS,
          },
          DISNewSymptom: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.DISNewSymptom"
                name="DISNewSymptom"
                formData={formData}
                viewing={isViewing}
              />
            ),
            condition: showDIS,
          },
          DISOneOrMoreMK: {
            header: (
              <SubHeader>
                {fm('diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.DISOneOrMoreMK')}
              </SubHeader>
            ),
            condition: showDIS,
          },
          DISPeriventricularArea: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.DISPeriventricularArea"
                name="DISPeriventricularArea"
                formData={formData}
                viewing={isViewing}
              />
            ),
            condition: showDIS,
          },
          DISCorticalArea: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.DISCorticalArea"
                name="DISCorticalArea"
                formData={formData}
                viewing={isViewing}
              />
            ),
            condition: showDIS,
          },
          DISInfratentorialArea: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.DISInfratentorialArea"
                name="DISInfratentorialArea"
                formData={formData}
                viewing={isViewing}
              />
            ),
            condition: showDIS,
          },
          DISSpinalCordArea: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.DISSpinalCordArea"
                name="DISSpinalCordArea"
                formData={formData}
                viewing={isViewing}
              />
            ),
            condition: showDIS,
          },
          DITHeader: {
            header: <Header>{fm('diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.DIT')}</Header>,
            condition: showDIT,
          },

          DITNewSymptom: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.DITNewSymptom"
                name="DITNewSymptom"
                formData={formData}
                viewing={isViewing}
              />
            ),
            condition: showDIT,
          },
          DITNewMKChange: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.DITNewMKChange"
                name="DITNewMKChange"
                formData={formData}
                viewing={isViewing}
              />
            ),
            condition: showDIT,
          },
          DITLOFracttions: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.DITLOFracttions"
                name="DITLOFracttions"
                formData={formData}
                viewing={isViewing}
              />
            ),
            condition: showDIT,
          },

          DISHeader2: {
            header: <Header>{fm('diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.DIS')}</Header>,
            condition: showDISAndDIT,
          },
          DISAndDITAnatomicNewSymptom: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.DISAndDITNewSymptom"
                name="DISAndDITAnatomicNewSymptom"
                formData={formData}
                viewing={isViewing}
              />
            ),
            condition: showDISAndDIT,
          },
          DISAndDITNewMKChange: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.DISAndDITNewMKChange"
                name="DISAndDITNewMKChange"
                formData={formData}
                viewing={isViewing}
                bottomMargin={false}
              />
            ),
            condition: showDISAndDIT,
          },
          And: {
            header: <Header>{fm('diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.and')}</Header>,
            condition: showDISAndDIT,
          },
          DITHeader2: {
            header: <Header>{fm('diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.DIT')}</Header>,
            condition: showDISAndDIT,
          },
          DITAndDISNewSymptom: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.DITAndDISNewSymptom"
                name="DITAndDISNewSymptom"
                formData={formData}
                viewing={isViewing}
              />
            ),
            condition: showDISAndDIT,
          },
          DITAndDISTimedMKScatteredColony: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.DITAndDISTimedMKScatteredColony"
                name="DITAndDISTimedMKScatteredColony"
                formData={formData}
                viewing={isViewing}
              />
            ),
            condition: showDISAndDIT,
          },
          DITAndDISLOsegment: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.DITAndDISLOsegment"
                name="DITAndDISLOsegment"
                formData={formData}
                viewing={isViewing}
              />
            ),
            condition: showDISAndDIT,
          },

          diagnosticCriteriaForRRMS: {
            header: (
              <InputlessFormRow
                title={'diagnosis.msDiagnosticCriteria.mcDonald2017DiagnosticCriteriaRRMS.result'}
                element={fm(`general.${diagnosticCriteriaForRR(formData.document)}`)}
                boldValue={true}
                bottomMargin={false}
              />
            ),
          },
        }}
      </FormSectionHistoryAcceptor>
    </React.Fragment>
  );
};

interface IOwnProps {
  documents?: Array<IMSCriteria>;
  formData: IFormData<IMSCriteria>;
  view?: IView;
  editingID?: string;
}

export default RRMS;
