/*
 * General visual components used in event steppers.
 * Note: These components are used outside of EventStepper, in other steppers that are more customized for specific events
 */
import { styled } from '@mui/system';
import * as React from 'react';

import colors from '../../../config/theme/colors';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const StyledViewSelector = styled('span', {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active }: { active?: boolean }) => ({
  color: `${colors.primary}`,
  userSelect: 'none',
  textDecoration: active ? 'none' : 'underline',
  fontWeight: active ? 'bold' : 'normal',
  cursor: active ? 'default' : 'pointer',
}));

const StyledControl = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active }: { active?: boolean }) => ({
  fontSize: '1.8rem',
  color: active ? colors.primary : colors.gray,
  cursor: active ? 'pointer' : 'default',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'baseline',
  transform: 'scaleY(1.25)',
}));

export const StyledControls = ({
  eventIndex,
  events,
  changeEventIndex,
}: {
  eventIndex: number;
  events: any[];
  changeEventIndex: (change: number) => () => void;
}): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: '2rem',
        color: colors.tertiaryText,
        userSelect: 'none',
      }}
    >
      <StyledControl
        active={eventIndex > 0}
        onClick={eventIndex > 0 ? changeEventIndex(eventIndex - 1) : (): string => ''}
      >
        <KeyboardArrowLeftIcon />
      </StyledControl>
      &nbsp;&nbsp;&nbsp;
      {`${eventIndex + 1} / ${events.length}`}
      &nbsp;&nbsp;&nbsp;
      <StyledControl
        active={eventIndex < events.length - 1}
        onClick={eventIndex < events.length - 1 ? changeEventIndex(eventIndex + 1) : (): string => ''}
      >
        <KeyboardArrowRightIcon />
      </StyledControl>
    </div>
  );
};
