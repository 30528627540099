import * as React from 'react';
import { styled } from '@mui/system';
import { Container, Item } from '../../../../components/Grid';
import { IFormContext } from '../../../../containers/FormContextHandler';

const SubHeaderArea = styled('div')({
  marginBottom: '1rem',
});

const SubHeader = styled('div')({
  fontWeight: 600,
});

export const SubheaderRow = ({
  mainHeader,
  fm,
  renderPreviousHeaders,
}: {
  mainHeader: string;
  fm: IFormContext['fm'];
  renderPreviousHeaders?: boolean;
}) => (
  <SubHeaderArea>
    <Container>
      <Item xs={5}>
        <SubHeader>{fm(mainHeader)}</SubHeader>
      </Item>
      {renderPreviousHeaders && (
        <Item xs={7}>
          <Container>
            <Item xs={6}>
              <></>
            </Item>
            <Item xs={6}>
              <div style={{ paddingLeft: '2rem' }}>{fm('checklist.previousAnswer')}</div>
            </Item>
          </Container>
        </Item>
      )}
    </Container>
  </SubHeaderArea>
);
