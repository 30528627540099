import { Paper, Popover, styled, Theme } from '@mui/material';
import ActionButtonRounded, { IActionButtonRounded } from 'Components/ActionButtonRounded';
import { Container, Item } from 'Components/Grid';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from '../Icon';
import { useAppSelector } from 'Store/index';

const ListArea = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ width }: { width: number; theme?: Theme }) => ({
  width: width + 'rem',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottomLeftRadius: '0.4rem',
  borderBottomRightRadius: '0.4rem',
}));

const ListItem = styled('button', {
  shouldForwardProp: (prop) => prop !== 'height',
})(({ height, theme }: { height: number; theme?: Theme }) => ({
  width: '100%',
  height: height + 'rem',
  fontFamily: 'inherit',

  backgroundColor: theme?.palette.primary.main,
  border: 'none',
  cursor: 'pointer',
  padding: '0.8rem 0',

  '&:hover': {
    backgroundColor: theme?.palette.primary.main + 'D9',
  },
  '&:last-of-type': {
    borderBottomLeftRadius: '0.4rem',
    borderBottomRightRadius: '0.4rem',
  },
}));

const ListItemText = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fontSize',
})(({ fontSize }: { fontSize: number }) => ({
  color: 'white',
  fontSize: fontSize + 'px',
  fontWeight: 600,
  textTransform: 'uppercase',
  userSelect: 'none',
  textAlign: 'center',
  width: '100%',
}));

const Divider = styled('div')({
  width: '70%',
  height: '0.1rem',
  backgroundColor: 'white',
  margin: '0 auto',
});

const DropDownButton = ({
  text,
  width = 25,
  height = 5,
  fontSize = 24,
  iconSize = 2.5,
  mainButtonOtherProps,
  buttons,
}: IDropDownButton) => {
  const [buttonListOpen, setButtonListOpen] = React.useState<boolean>(false);
  const toggleList = () => setButtonListOpen(!buttonListOpen);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const isCreating = useAppSelector((s: IState) => s.documents.isCreating);

  return (
    <>
      <ActionButtonRounded
        text={
          <Container alignItems={'center'}>
            <Item xs={true} style={{ paddingLeft: iconSize + 'rem' }}>
              <FormattedMessage id={text} />
            </Item>
            <Item>{open ? <Icon icon="close" size={iconSize} /> : <Icon icon="menu" size={iconSize} />}</Item>
          </Container>
        }
        onClick={handleClick}
        loading={!!isCreating}
        {...{ width, height, fontSize }}
        {...mainButtonOtherProps}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slots={{ paper: ListArea }}
        slotProps={{ paper: { width, square: true } as any }}
      >
        <div style={{ width: '100%' }}>
          {buttons.map((but, i) => (
            <React.Fragment key={but.text}>
              <ListItem
                key={but.text}
                height={height}
                onClick={(e) => {
                  but.onClick(e);
                  toggleList();
                }}
              >
                <ListItemText fontSize={fontSize}>
                  <FormattedMessage id={but.text} />
                </ListItemText>
              </ListItem>

              {i < buttons.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </div>
      </Popover>
    </>
  );
};

interface IDropDownButton {
  /** Main button text, locale id */
  text: string;
  width?: number;
  height?: number;
  fontSize?: number;
  iconSize?: number;
  /** Main button other props as speficied in ActionButtonRounded */
  mainButtonOtherProps?: Partial<IActionButtonRounded>;
  /** Button list buttons */
  buttons: Array<{
    text: string;
    onClick: (e: React.MouseEvent) => void;
    disabled?: boolean;
    disabledTooltip?: JSX.Element;
  }>;
}

export default DropDownButton;
