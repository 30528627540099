import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import { isPartialDate, partialDateToValue } from 'neuro-utils';
import ToolTip from '../../../../../components/ToolTip';
import browserLanguage from '../../../../../utility/browserLanguage';
import { getDescription, getICD10Codes } from '../../../../../utility/randomUtil';
import { equals } from 'ramda';
import { useAppSelector } from 'Store/index';

const optionFormatter = (name: number | string): JSX.Element => <FormattedMessage id={`comorbidity.opts.${name}`} />;

const HospitalizationForm = ({ documents, editing, formData, medicationNames, platform }: IOwnProps): JSX.Element => {
  const uiLanguage = useAppSelector((state: IState) => state.settings.userSettings.uiLanguage) || browserLanguage;
  const [ICD10List, setICD10List] = React.useState<Array<ICD10Item>>([]);
  const [ICD10SearchString, setICD10SearchString] = React.useState<string>('');
  const [warningOpen, setWarningOpen] = React.useState<boolean>(false);
  const documentsOnMount = React.useRef<Array<IHospitalization>>(documents);

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const sleep = (milliseconds: number): any => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const updateICD10Codes = React.useCallback((): void => {
    const locale = uiLanguage; // 'fi' or 'se' or 'en'
    if (ICD10SearchString && ICD10SearchString?.length > 2) {
      getICD10Codes(ICD10SearchString, locale)
        .then((res: ICD10Item[]) => setICD10List(res))
        .catch(() => {
          return;
        });
    }
  }, [ICD10SearchString]);

  const onChangeICD = (e: React.SyntheticEvent<Element, Event>, d: string): void => {
    d && d !== 'undefined' ? setICD10SearchString(d) : d === '' ? setICD10SearchString('') : undefined;
  };

  const isDateForbidden = React.useCallback(
    (date?: PartialDate): boolean => {
      const hospitalizationsFiltered = documents.filter((hospitalization) => hospitalization._editing === false);

      return hospitalizationsFiltered && hospitalizationsFiltered.length > 0
        ? hospitalizationsFiltered.some((h): boolean => {
            if (partialDateToValue(h.date) === partialDateToValue(date)) {
              return true;
            }
            if (isPartialDate(h.endDate)) {
              return partialDateToValue(h.endDate) > partialDateToValue(date) &&
                partialDateToValue(h.date) < partialDateToValue(date)
                ? true
                : false;
            }
            return partialDateToValue(h.date) > partialDateToValue(date) ? false : true;
          })
        : false;
    },
    [documents],
  );

  React.useEffect(() => {
    if (platform === 'mgravis') {
      formData?.onChange?.({ hospitalizationType: 'acute', classification: { icd10code: 'G70' } });
    }
  }, []);

  React.useEffect(() => {
    warningOpen
      ? sleep(6000)
          .then(() => {
            setWarningOpen(false);
          })
          .catch(() => {
            setWarningOpen(false);
          })
      : undefined;
  }, [warningOpen]);

  React.useEffect(() => {
    // LOad ICD-10 after typing has been stopped
    const timeout = setTimeout(() => {
      updateICD10Codes();
    }, 750);
    if (equals(documents, documentsOnMount.current)) {
      return;
    }
    setWarningOpen(
      isDateForbidden(formData.document.date) || isDateForbidden(formData.document.endDate) ? true : false,
    );
    return (): any => clearTimeout(timeout);
  }, [
    documents,
    formData.document.date,
    formData.document.endDate,
    updateICD10Codes,
    ICD10SearchString,
    isDateForbidden,
  ]);

  React.useEffect(() => {
    (async (): Promise<void> => {
      const description = formData.document.classification?.icd10code
        ? await getDescription(formData.document.classification.icd10code)
        : undefined;
      if (description != formData.document.classification?.icd10description) {
        const classification = {
          icd10code: formData.document.classification?.icd10code,
          icd10description: description ?? formData?.document?.classification?.icd10description,
        };
        formData.onChange &&
          description &&
          formData.onChange({
            classification: classification,
          });
      }
    })();
  }, [formData]);

  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="comorbidity.startDate">
          <ToolTip
            title={<FormattedMessage id="general.checkDates" />}
            description={<FormattedMessage id={'comorbidity.hospitalizationAlreadyExists'} />}
            content={<div style={{ width: '20rem' }}></div>}
            placement={'top'}
            open={warningOpen}
          />
          <InputHandler
            type="PartialDate"
            name="date"
            editing={editing}
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
            dateHook={{ dateHookCeiling: formData.document.endDate }}
          />
        </FormRow>
        <FormRow title="comorbidity.endDate">
          <InputHandler
            type="PartialDate"
            name="endDate"
            editing={editing}
            formData={formData}
            dateHook={{ dateHookFloor: formData.document.date }}
          />
        </FormRow>
        {platform === 'mgravis' ? (
          <FormRow title="comorbidity.mgravis.period">
            <InputHandler
              type="Radio"
              name="department"
              editing={editing}
              formData={formData}
              options={['hospitalWard', 'intensiveCare']}
              optionFormatter={(o) => fm(`comorbidity.mgravis.${o}`)}
            />
          </FormRow>
        ) : (
          <FormRow title="comorbidity.hospitalizationType">
            <InputHandler
              type="Radio"
              name="hospitalizationType"
              editing={editing}
              formData={formData}
              options={['planned', 'acute']}
              optionFormatter={optionFormatter}
            />
          </FormRow>
        )}
      </FormSection>
      {platform !== 'mgravis' && formData.document.hospitalizationType === 'planned' && (
        <FormSection header="comorbidity.plannedHospitalization">
          <FormRow title="comorbidity.hospitalizationReason">
            <InputHandler
              type="AutoCompleteSelect"
              name="classification"
              editing={editing}
              formData={formData}
              options={ICD10List as any}
              placeholder={'comorbidity.chooseClassification2'}
              labelID={'icd10description'}
              groupID={'icd10code'}
              onInputChange={onChangeICD}
              getOptionLabel={(o: ICD10Item): string =>
                `${o.icd10code}${o.icd10description ? ' - ' + o.icd10description : ''}`
              }
              width={30}
            />
          </FormRow>
          <FormRow title="comorbidity.details">
            <InputHandler
              type="TextArea"
              name="hospitalizationDetails"
              editing={editing}
              formData={formData}
              placeholder="comorbidity.details"
            />
          </FormRow>
        </FormSection>
      )}
      {platform !== 'mgravis' && formData.document.hospitalizationType === 'acute' && (
        <FormSection header="comorbidity.acuteHospitalization">
          <FormRow title="comorbidity.hospitalizationReason">
            <InputHandler
              type="AutoCompleteSelect"
              name="classification"
              editing={editing}
              formData={formData}
              options={ICD10List as any}
              placeholder={'comorbidity.chooseClassification2'}
              labelID={'icd10description'}
              groupID={'icd10code'}
              onInputChange={onChangeICD}
              getOptionLabel={(o: ICD10Item): string =>
                `${o.icd10code}${o.icd10description ? ' - ' + o.icd10description : ''}`
              }
              width={30}
            />
          </FormRow>
          <FormRow title="comorbidity.details">
            <InputHandler
              type="TextArea"
              name="hospitalizationDetails"
              editing={editing}
              formData={formData}
              placeholder="comorbidity.details"
            />
          </FormRow>
          <FormRow title="comorbidity.acuteAdverse">
            <InputHandler
              type="Radio"
              name="acuteAdverseEffect"
              editing={editing}
              formData={formData}
              options={['yes', 'no', 'unknown']}
              optionFormatter={optionFormatter}
            />
          </FormRow>
          <FormRow
            title="comorbidity.acuteAdverseEffectMedication"
            condition={formData.document.acuteAdverseEffect === 'yes'}
          >
            <InputHandler
              type="Select"
              name="acuteAdverseEffectMedication"
              editing={editing}
              formData={formData}
              options={medicationNames}
              placeholder="comorbidity.acuteAdverseEffectPlaceholder"
            />
          </FormRow>
        </FormSection>
      )}
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: Array<IHospitalization>;
  editing?: boolean;
  formData: IFormData<IHospitalization>;
  medicationNames: Array<string>;
  platform?: Platform;
}

export default HospitalizationForm;
