import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import OptionChooser from 'Components/OptionChooser';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import { calculateEQ5DTotalValue } from 'neuro-calculation-commons';
import { eq5d, IEQ5D, isLocaleKey, Task_Progress } from 'neuro-schemas';
import { formatPartialDate, isPartialDate, nowPartialDate, partialDateFromDate, partialDateToValue } from 'neuro-utils';
import * as React from 'react';
import { MyServiceContext } from '../..';
import { omitControlProps } from 'Utility/documentHandling';

export const EQ5D = (): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, locale, viewing, setViewingObj, setEditingObj } = myServContext;
  const eq5dData = (editing?.data || viewing?.data) as IEQ5D & IControlProps;
  const eq5dLocales = eq5d.localizations;
  const useLocale = isLocaleKey(locale) ? locale : 'fi';
  const onChangeSurveyForm = (values: TOnChangeValues): void => {
    const field = Object.keys(values)[0];
    const value = Object.values(values)[0];
    if (field === 'date') {
      setEditingData?.({
        ...eq5dData,
        [field]: isPartialDate(value) ? value : eq5dData.date,
      });
    } else {
      setEditingData?.({
        ...eq5dData,
        sections: {
          ...eq5dData.sections,
          [field]: value,
        },
      });
    }
  };

  const fieldOptions = [1, 2, 3, 4, 5];
  const underlineRegex = /<u|\/u>/gm;
  const underlineLocaleSet = eq5dLocales[useLocale]['yourHealthTodayInfo3'].split(underlineRegex);
  const underlinedLocale = () => {
    return (
      <div>
        {underlineLocaleSet.map((l, i) => {
          const underline = l.includes('>');
          const final = underline ? l.replace('>', '').replace('<', '') : l;
          return (
            <span key={i} style={{ textDecoration: underline ? 'underline' : 'none' }}>
              {final}
            </span>
          );
        })}
      </div>
    );
  };

  const getDescription = (field: keyof NonNullable<IEQ5D['sections']>) => {
    return (
      <div style={{ textTransform: 'uppercase', color: 'black', fontWeight: 600, fontSize: '1.6rem' }}>
        {eq5dLocales[useLocale][field]}
      </div>
    );
  };

  React.useEffect(() => {
    if (
      editing &&
      !viewing &&
      eq5dData &&
      !eq5dData.date &&
      partialDateToValue(partialDateFromDate(new Date(eq5dData?._docCreateDate))) !==
        partialDateToValue(nowPartialDate())
    ) {
      setEditingData?.({
        ...eq5dData,
        date: partialDateFromDate(new Date(eq5dData?._docCreateDate)),
      });
    }
  }, []);

  return (
    <>
      <BlockWrapper
        title={viewing ? formatPartialDate(eq5dData.date) : undefined}
        buttons={
          viewing
            ? [
                {
                  title: 'general.edit',
                  onClick: () => {
                    setViewingObj(null);
                    setEditingObj({ type: 'eq5d', data: eq5dData });
                  },
                },
              ]
            : undefined
        }
      >
        {!viewing && (
          <FormRow title={'general.date'}>
            <InputHandler
              name="date"
              type="PartialDate"
              dateDefault="now"
              formData={{
                document: { date: eq5dData?.date },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
        )}
        <>
          <FormRow
            title={eq5dLocales[useLocale]['surveyFillingInfo']}
            description={getDescription('mobility')}
            formatTitle={false}
          >
            <OptionChooser
              formData={{
                document: { mobility: eq5dData?.sections?.mobility },
                onChange: onChangeSurveyForm,
              }}
              name="mobility"
              opts={fieldOptions}
              optionFormatter={(o) => <>{eq5dLocales[useLocale][`opts.mobility.${o}`]}</>}
              qNumberHidden
              viewing={!!viewing}
            />
          </FormRow>
          <FormRow
            title={eq5dLocales[useLocale]['surveyFillingInfo']}
            description={getDescription('selfcare')}
            formatTitle={false}
          >
            <OptionChooser
              formData={{
                document: { selfcare: eq5dData?.sections?.selfcare },
                onChange: onChangeSurveyForm,
              }}
              name="selfcare"
              opts={fieldOptions}
              optionFormatter={(o) => <>{eq5dLocales[useLocale][`opts.selfcare.${o}`]}</>}
              qNumberHidden
              viewing={!!viewing}
            />
          </FormRow>
          <FormRow
            title={eq5dLocales[useLocale]['surveyFillingInfo']}
            description={getDescription('usualActivities')}
            formatTitle={false}
          >
            <OptionChooser
              formData={{
                document: { usualActivities: eq5dData?.sections?.usualActivities },
                onChange: onChangeSurveyForm,
              }}
              name="usualActivities"
              opts={fieldOptions}
              optionFormatter={(o) => <>{eq5dLocales[useLocale][`opts.usualActivities.${o}`]}</>}
              qNumberHidden
              viewing={!!viewing}
            />
          </FormRow>
          <FormRow
            title={eq5dLocales[useLocale]['surveyFillingInfo']}
            description={getDescription('painDiscomfort')}
            formatTitle={false}
          >
            <OptionChooser
              formData={{
                document: { painDiscomfort: eq5dData?.sections?.painDiscomfort },
                onChange: onChangeSurveyForm,
              }}
              name="painDiscomfort"
              opts={fieldOptions}
              optionFormatter={(o) => <>{eq5dLocales[useLocale][`opts.painDiscomfort.${o}`]}</>}
              qNumberHidden
              viewing={!!viewing}
            />
          </FormRow>
          <FormRow
            title={eq5dLocales[useLocale]['surveyFillingInfo']}
            description={getDescription('anxietyDepression')}
            formatTitle={false}
          >
            <OptionChooser
              formData={{
                document: { anxietyDepression: eq5dData?.sections?.anxietyDepression },
                onChange: onChangeSurveyForm,
              }}
              name="anxietyDepression"
              opts={fieldOptions}
              optionFormatter={(o) => <>{eq5dLocales[useLocale][`opts.anxietyDepression.${o}`]}</>}
              qNumberHidden
              viewing={!!viewing}
            />
          </FormRow>
          <FormRow
            title={''}
            formatTitle={false}
            description={
              <div style={{ fontSize: '1.6rem' }}>
                <ul>
                  <li>{eq5dLocales[useLocale]['yourHealthTodayInfo1']}</li>
                  <li>{eq5dLocales[useLocale]['yourHealthTodayInfo2']}</li>
                  <li>{underlinedLocale()}</li>
                  <li>{eq5dLocales[useLocale]['yourHealthTodayInfo4']}</li>
                </ul>
              </div>
            }
          >
            <InputHandler
              type="VerticalVas"
              name="yourHealthToday"
              editing={!!editing}
              infoValueDisplay={eq5dLocales[useLocale]['yourHealthToday']}
              infoMax={eq5dLocales[useLocale]['yourHealthTodayInfoTop']}
              infoMin={eq5dLocales[useLocale]['yourHealthTodayInfoBottom']}
              formData={{
                document: { yourHealthToday: eq5dData?.sections?.yourHealthToday },
                onChange: onChangeSurveyForm,
              }}
            />
          </FormRow>
          <FormRow title={'myService.sleepApnea.eq5d.score'}>
            <div style={{ fontWeight: 600 }}>
              <span>{eq5dData?.sections ? calculateEQ5DTotalValue(eq5dData.sections) : '-'}</span>
              <span>{` / ${Task_Progress.calculateProgress('eq5d', omitControlProps(eq5dData)).ceiling}`}</span>
            </div>
          </FormRow>
          <div style={{ width: '100%', textAlign: 'center', fontStyle: 'italic' }}>
            {eq5dLocales[useLocale]['reference']}
          </div>
        </>
      </BlockWrapper>
    </>
  );
};
