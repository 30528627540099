import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import { styled } from '@mui/system';
import { Container, Item } from 'Components/Grid';

const StyledLabel = styled('div')({
  fontWeight: 600,
});

const instructionPosition = (locale: string): JSX.Element => (
  <React.Fragment>
    <Container style={{ marginBottom: '-2.5rem' }}>
      <Item xs={2}>
        <StyledLabel>Position</StyledLabel>
      </Item>
      <Item xs={10} style={{ fontStyle: 'italic' }}>
        <FormattedMessage id={'motor.labels.chopINTEND.' + locale} />
      </Item>
    </Container>
  </React.Fragment>
);

const instructionTestProcedure = (locale: string): JSX.Element => (
  <React.Fragment>
    <Container style={{ marginBottom: '1rem' }}>
      <Item xs={2}>
        <StyledLabel>Test Procedure</StyledLabel>
      </Item>
      <Item xs={10} style={{ fontStyle: 'italic' }}>
        <FormattedMessage id={'motor.labels.chopINTEND.' + locale} />
      </Item>
    </Container>
  </React.Fragment>
);

export const steps = [
  {
    spontaneousMovementUpper: [
      'spontaneousMovementUpperRight',
      'spontaneousMovementUpperLeft',
      'spontaneousMovementUpperState',
    ],
  },
  {
    spontaneousMovementLower: [
      'spontaneousMovementLowerRight',
      'spontaneousMovementLowerLeft',
      'spontaneousMovementLowerState',
    ],
  },
  {
    handGrip: ['handGripRight', 'handGripLeft', 'handGripState'],
  },
  {
    headMidlineVisualStimulation: [
      'headMidlineVisualStimulationRight',
      'headMidlineVisualStimulationLeft',
      'headMidlineVisualStimulationState',
    ],
  },
  {
    hipAdductors: ['hipAdductorsRight', 'hipAdductorsLeft', 'hipAdductorsState'],
  },
  {
    rollingElicitedFromLegs: [
      'rollingElicitedFromLegsRight',
      'rollingElicitedFromLegsLeft',
      'rollingElicitedFromLegsState',
    ],
  },
  {
    rollingElicitedFromArms: [
      'rollingElicitedFromArmsRight',
      'rollingElicitedFromArmsLeft',
      'rollingElicitedFromArmsState',
    ],
  },
  {
    shoulderElbowFlexionHorizontalAbduction: [
      'shoulderElbowFlexionHorizontalAbductionRight',
      'shoulderElbowFlexionHorizontalAbductionLeft',
      'shoulderElbowFlexionHorizontalAbductionState',
    ],
  },
  {
    shoulderFlexionElbowFlexion: [
      'shoulderFlexionElbowFlexionRight',
      'shoulderFlexionElbowFlexionLeft',
      'shoulderFlexionElbowFlexionState',
    ],
  },
  {
    kneeExtension: ['kneeExtensionRight', 'kneeExtensionLeft', 'kneeExtensionState'],
  },
  {
    hipFlexionFootDorsiflexion: [
      'hipFlexionFootDorsiflexionRight',
      'hipFlexionFootDorsiflexionLeft',
      'hipFlexionFootDorsiflexionState',
    ],
  },
  {
    headControl: ['headControl', 'headControlState'],
  },
  {
    elbowFlexionScoreWithItem14: [
      'elbowFlexionScoreWithItem14Right',
      'elbowFlexionScoreWithItem14Left',
      'elbowFlexionScoreWithItem14State',
    ],
  },
  {
    neckFlexionScoreWithItem13: ['neckFlexionScoreWithItem13', 'neckFlexionScoreWithItem13State'],
  },
  {
    headNeckExtensionLandau: ['headNeckExtensionLandau', 'headNeckExtensionLandauState'],
  },
  {
    spinalIncurvationGalant: [
      'spinalIncurvationGalantRight',
      'spinalIncurvationGalantLeft',
      'spinalIncurvationGalantState',
    ],
  },
] as Array<string | { [key: string]: string[] }>;

export const getStepContent = (
  stepName: string,
): {
  opts: (number | string)[];
  optsLocale: string;
  info?: string;
  height?: number;
  instruction?: JSX.Element;
  instruction2?: JSX.Element;
} => {
  switch (stepName) {
    case 'spontaneousMovementUpperRight':
      return {
        opts: ['CNT', 0, 1, 2, 3, 4],
        optsLocale: 'spontaneousMovementUpper',
        instruction: instructionPosition('spontaneousMovementUpperInstructionPos'),
        instruction2: instructionTestProcedure('spontaneousMovementUpperInstructionTest'),
      };
    case 'spontaneousMovementUpperLeft':
      return { opts: ['CNT', 0, 1, 2, 3, 4], optsLocale: 'spontaneousMovementUpper' };

    case 'spontaneousMovementLowerRight':
      return {
        opts: ['CNT', 0, 1, 2, 3, 4],
        optsLocale: 'spontaneousMovementLower',
        instruction: instructionPosition('spontaneousMovementLowerInstructionPos'),
        instruction2: instructionTestProcedure('spontaneousMovementLowerInstructionTest'),
      };
    case 'spontaneousMovementLowerLeft':
      return { opts: ['CNT', 0, 1, 2, 3, 4], optsLocale: 'spontaneousMovementLower' };

    case 'handGripRight':
      return {
        opts: ['CNT', 0, 1, 2, 3, 4],
        optsLocale: 'handGrip',
        instruction: instructionPosition('handGripInstructionPos'),
        instruction2: instructionTestProcedure('handGripInstructionTest'),
      };
    case 'handGripLeft':
      return { opts: ['CNT', 0, 1, 2, 3, 4], optsLocale: 'handGrip' };

    case 'headMidlineVisualStimulationRight':
      return {
        opts: ['CNT', 0, 1, 2, 3, 4],
        optsLocale: 'headMidlineVisualStimulation',
        instruction: instructionPosition('headMidlineVisualStimulationInstructionPos'),
        instruction2: instructionTestProcedure('headMidlineVisualStimulationInstructionTest'),
      };
    case 'headMidlineVisualStimulationLeft':
      return { opts: ['CNT', 0, 1, 2, 3, 4], optsLocale: 'headMidlineVisualStimulation' };

    case 'hipAdductorsRight':
      return {
        opts: ['CNT', 0, 2, 4],
        optsLocale: 'hipAdductors',
        instruction: instructionPosition('hipAdductorsInstructionPos'),
        instruction2: instructionTestProcedure('hipAdductorsInstructionTest'),
      };
    case 'hipAdductorsLeft':
      return { opts: ['CNT', 0, 2, 4], optsLocale: 'hipAdductors' };

    case 'rollingElicitedFromLegsRight':
      return {
        opts: ['CNT', 0, 1, 2, 3, 4],
        optsLocale: 'rollingElicitedFromLegs',
        instruction: instructionPosition('rollingElicitedFromLegsInstructionPos'),
        instruction2: instructionTestProcedure('rollingElicitedFromLegsInstructionTest'),
      };
    case 'rollingElicitedFromLegsLeft':
      return { opts: ['CNT', 0, 1, 2, 3, 4], optsLocale: 'rollingElicitedFromLegs' };

    case 'rollingElicitedFromArmsRight':
      return {
        opts: ['CNT', 0, 1, 2, 3, 4],
        optsLocale: 'rollingElicitedFromArms',
        instruction: instructionPosition('rollingElicitedFromArmsInstructionPos'),
        instruction2: instructionTestProcedure('rollingElicitedFromArmsInstructionTest'),
      };
    case 'rollingElicitedFromArmsLeft':
      return {
        opts: ['CNT', 0, 1, 2, 3, 4],
        optsLocale: 'rollingElicitedFromArms',
        instruction: instructionPosition('spontaneousMovementUpperInstructionPos'),
        instruction2: instructionTestProcedure('spontaneousMovementUpperInstructionTest'),
      };
    case 'shoulderElbowFlexionHorizontalAbductionRight':
      return {
        opts: ['CNT', 0, 1, 2, 3, 4],
        optsLocale: 'shoulderElbowFlexionHorizontalAbduction',
        instruction: instructionPosition('shoulderElbowFlexionHorizontalAbductionInstructionPos'),
        instruction2: instructionTestProcedure('shoulderElbowFlexionHorizontalAbductionInstructionTest'),
      };
    case 'shoulderElbowFlexionHorizontalAbductionLeft':
      return { opts: ['CNT', 0, 1, 2, 3, 4], optsLocale: 'shoulderElbowFlexionHorizontalAbduction' };

    case 'shoulderFlexionElbowFlexionRight':
      return {
        opts: ['CNT', 0, 1, 2, 3, 4],
        optsLocale: 'shoulderFlexionElbowFlexion',
        instruction: instructionPosition('shoulderFlexionElbowFlexionInstructionPos'),
        instruction2: instructionTestProcedure('shoulderFlexionElbowFlexionInstructionTest'),
      };
    case 'shoulderFlexionElbowFlexionLeft':
      return { opts: ['CNT', 0, 1, 2, 3, 4], optsLocale: 'shoulderFlexionElbowFlexion' };

    case 'kneeExtensionRight':
      return {
        opts: ['CNT', 0, 1, 2, 4],
        optsLocale: 'kneeExtension',
        instruction: instructionPosition('kneeExtensionInstructionPos'),
        instruction2: instructionTestProcedure('kneeExtensionInstructionTest'),
      };
    case 'kneeExtensionLeft':
      return { opts: ['CNT', 0, 1, 2, 4], optsLocale: 'kneeExtension' };

    case 'hipFlexionFootDorsiflexionRight':
      return {
        opts: ['CNT', 0, 2, 3, 4],
        optsLocale: 'hipFlexionFootDorsiflexion',
        instruction: instructionPosition('hipFlexionFootDorsiflexionInstructionPos'),
        instruction2: instructionTestProcedure('hipFlexionFootDorsiflexionInstructionTest'),
      };
    case 'hipFlexionFootDorsiflexionLeft':
      return { opts: ['CNT', 0, 2, 3, 4], optsLocale: 'hipFlexionFootDorsiflexion' };

    case 'headControl':
      return {
        opts: ['CNT', 0, 1, 2, 3, 4],
        optsLocale: 'headControl',
        instruction: instructionPosition('headControlInstructionPos'),
        instruction2: instructionTestProcedure('headControlInstructionTest'),
      };

    case 'elbowFlexionScoreWithItem14Right':
      return {
        opts: ['CNT', 0, 2, 4],
        optsLocale: 'elbowFlexionScoreWithItem14',
        instruction: instructionPosition('elbowFlexionScoreWithItem14InstructionPos'),
        instruction2: instructionTestProcedure('elbowFlexionScoreWithItem14InstructionTest'),
      };
    case 'elbowFlexionScoreWithItem14Left':
      return { opts: ['CNT', 0, 2, 4], optsLocale: 'elbowFlexionScoreWithItem14' };

    case 'neckFlexionScoreWithItem13':
      return {
        opts: ['CNT', 0, 2, 4],
        optsLocale: 'neckFlexionScoreWithItem13',
        instruction: instructionPosition('neckFlexionScoreWithItem13InstructionPos'),
        instruction2: instructionTestProcedure('neckFlexionScoreWithItem13InstructionTest'),
      };

    case 'headNeckExtensionLandau':
      return {
        opts: ['CNT', 0, 2, 4],
        optsLocale: 'headNeckExtensionLandau',
        instruction: instructionPosition('headNeckExtensionLandauInstructionPos'),
        instruction2: instructionTestProcedure('headNeckExtensionLandauInstructionTest'),
      };

    case 'spinalIncurvationGalantRight':
      return {
        opts: ['CNT', 0, 2, 4],
        optsLocale: 'spinalIncurvationGalant',
        instruction: instructionPosition('spinalIncurvationGalantInstructionPos'),
        instruction2: instructionTestProcedure('spinalIncurvationGalantInstructionTest'),
      };
    case 'spinalIncurvationGalantLeft':
      return { opts: ['CNT', 0, 2, 4], optsLocale: 'spinalIncurvationGalant' };

    case 'spontaneousMovementUpperState':
    case 'spontaneousMovementLowerState':
    case 'handGripState':
    case 'headMidlineVisualStimulationState':
    case 'hipAdductorsState':
    case 'rollingElicitedFromLegsState':
    case 'rollingElicitedFromArmsState':
    case 'shoulderElbowFlexionHorizontalAbductionState':
    case 'shoulderFlexionElbowFlexionState':
    case 'kneeExtensionState':
    case 'hipFlexionFootDorsiflexionState':
    case 'headControlState':
    case 'elbowFlexionScoreWithItem14State':
    case 'neckFlexionScoreWithItem13State':
    case 'headNeckExtensionLandauState':
    case 'spinalIncurvationGalantState':
      return { opts: [1, 2, 3, 4, 5, 6], optsLocale: 'state' };

    default:
      return { opts: [0], optsLocale: '' };
  }
};
