/**
 * Create some control elements for history listing rows (edit, delete, etc)
 */
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { isNil } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';

import ActionButton from '../ActionButton';
import { Container, Item } from '../Grid';
import ToolTip from '../ToolTip';

import { smallActionButtonDimensions } from '../../config/theme/componentTheme';
import MedicationEndReasonDialog from './MedicationEndReasonDialog';
import DocumentCreationButton from '../DocumentCreationButton';
import { useAppSelector as useSelector } from 'Store/index';
import CircularProgressLocal from '../CircularProgress';
import { getJWTData } from '../../utility/jwtAuthTools';
import { canStartEditing, hasWritePermission } from '../../containers/ReadWritePermissions';
import { EditingLockedContentContext } from 'Containers/EditingLockedContentContext';

const DeleteDialog = React.lazy(() => import('./DeleteDialog'));
const ConfirmationDialog = React.lazy(() => import('Components/ConfirmationDialog'));

const StyledSection = styled('div', { shouldForwardProp: (prop) => prop !== 'hideButton' })(
  ({ hideButton }: { hideButton?: boolean }) => ({
    width: '100%',
    minWidth: hideButton ? undefined : '21.7rem',
  }),
);

const StyledMenuIcon = styled(({ onClick }: { onClick?: (e: React.MouseEvent<Element | HTMLElement>) => void }) => (
  <IconButton onClick={onClick}>
    <MoreVertIcon />
  </IconButton>
))`
  color: #6c96ae;
  cursor: pointer;
`;

const attachTooltip = (element: JSX.Element, tip?: JSX.Element): JSX.Element => (
  <ToolTip title={tip} content={element} hover={true} />
);

const LoadingComponent = ({ size = '1.5rem' }: { size?: string }) => (
  <Container alignItems="center" justifyContent="center">
    <Item>
      <CircularProgressLocal size={size} />
    </Item>
  </Container>
);

const HistoryRowControls = ({
  document,
  startEdit,
  view,
  subDocs,
  itemAlign,
  buttonDimensions,
  hideButton,
  showEditInMenu,
  showViewInMenu,
  deleteContent,
  deleteHandler,
  newStyle = false,
  editConfirmationText,
  deleteConfirmationText,
  disableButtons,
  enableButtons,
}: IHistoryRowControls): JSX.Element => {
  // Where the menu will spawn
  const [anchor, setAnchor] = React.useState<null | Element | HTMLElement>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [medicationEndDialogOpen, setMedicationEndDialogOpen] = React.useState<boolean>(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState<false | 'edit' | 'delete'>(false);

  const { allowEditingLockedDocument } = React.useContext(EditingLockedContentContext);

  const toggleMenu = (e: React.MouseEvent<Element | HTMLElement>): void => {
    setAnchor(!anchor ? e.currentTarget : null);
  };

  const toggleDeleteDialog = (): void => {
    setDeleteDialogOpen(!deleteDialogOpen);
  };

  const medicationDialogOpener =
    (open: boolean) =>
    (e: React.MouseEvent<HTMLElement>): void => {
      setMedicationEndDialogOpen(open);
      toggleMenu(e);
    };

  const handleDelete = (e: React.MouseEvent<HTMLElement>): void => {
    toggleDeleteDialog();
    toggleMenu(e);
  };

  const buttonDimensionsAdjusted = { ...smallActionButtonDimensions, ...buttonDimensions };

  const orgId = getJWTData()?.orgid;

  // User doesn't have write permission to this document if the document is from another org and there is no grants that allow writing
  const noWritePermission = document._ownerOrg !== orgId && !hasWritePermission(document);
  // If no write permissions, check also if the document is lockedFor the other organization
  const lockedForOtherOrg = !isNil((document as IControlProps)._lockedFor) && !allowEditingLockedDocument(document);

  const cannotEditThisDoc = !canStartEditing(document);

  const deletionDisabled = !enableButtons && document._ownerOrg !== orgId;
  const editingDisabled = !enableButtons && (disableButtons || noWritePermission || lockedForOtherOrg);

  const medicationEndReasonEnabled =
    editingDisabled &&
    (document as IControlProps)._type === 'medication' &&
    (document as IMedication).hasEnded?.[0] === true;

  const isCreating = useSelector((s: IState) => s.documents.isCreating);

  const loading = document._id ? isCreating?.id === document._id : false;

  const openConfirmation =
    (type: 'delete' | 'edit', _toggleMenu?: true) => (e: React.MouseEvent<Element | HTMLElement>) => {
      _toggleMenu && toggleMenu(e);
      setConfirmationDialogOpen(type);
    };

  return (
    <StyledSection hideButton={hideButton}>
      <Container justifyContent="flex-end" alignItems={itemAlign ?? 'center'}>
        <Item>
          {!hideButton && (
            <React.Fragment>
              {!view ? (
                <DocumentCreationButton
                  name={document._type || ''}
                  document={document}
                  text="general.edit"
                  onClick={
                    !loading ? (editConfirmationText ? openConfirmation('edit') : startEdit(document)) : undefined
                  }
                  width={buttonDimensionsAdjusted.width}
                  height={buttonDimensionsAdjusted.height}
                  fontSize={buttonDimensionsAdjusted.fontSize}
                  disabled={editingDisabled}
                  enabled={enableButtons}
                  newStyle={newStyle}
                  disabledTooltip={
                    lockedForOtherOrg ? (
                      <FormattedMessage id="general.documentLockedIntegration" />
                    ) : (
                      <FormattedMessage id="general.documentLocked" />
                    )
                  }
                  filled={newStyle}
                  uppercase={!newStyle}
                />
              ) : (
                <ActionButton
                  text="general.view"
                  onClick={view.startView(document)}
                  width={buttonDimensionsAdjusted.width}
                  height={buttonDimensionsAdjusted.height}
                  fontSize={buttonDimensionsAdjusted.fontSize}
                  disabled={disableButtons}
                />
              )}
            </React.Fragment>
          )}
        </Item>
        <Item style={{ width: '4.5rem' }}>
          <Container justifyContent="flex-end" alignItems={itemAlign ?? 'center'}>
            <Item>
              {!disableButtons && ((view && !editingDisabled) || !deletionDisabled || medicationEndReasonEnabled) ? (
                <StyledMenuIcon onClick={toggleMenu} />
              ) : (
                attachTooltip(
                  <div>
                    <StyledMenuIcon />
                  </div>,
                  disableButtons ? (
                    <FormattedMessage id="general.locked" />
                  ) : lockedForOtherOrg ? (
                    <FormattedMessage id="general.documentLockedIntegration" />
                  ) : (
                    <FormattedMessage id="general.documentLocked" />
                  ),
                )
              )}
              <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={toggleMenu}>
                {showViewInMenu && view && (
                  <MenuItem onClick={!loading ? view.startView(document) : undefined}>
                    {loading ? <LoadingComponent /> : <FormattedMessage id="general.view" />}
                  </MenuItem>
                )}
                {(view || showEditInMenu) &&
                  !(editingDisabled || cannotEditThisDoc) &&
                  document._type !== 'behaviourLanguage' && (
                    <MenuItem
                      onClick={
                        !loading
                          ? editConfirmationText
                            ? openConfirmation('edit', true)
                            : startEdit(document)
                          : undefined
                      }
                    >
                      {loading ? <LoadingComponent /> : <FormattedMessage id="general.edit" />}
                    </MenuItem>
                  )}
                {medicationEndReasonEnabled && (
                  <MenuItem onClick={medicationDialogOpener(true)}>
                    <FormattedMessage id="medication.endedReason" />
                  </MenuItem>
                )}
                {!deletionDisabled && (
                  <MenuItem onClick={deleteConfirmationText ? openConfirmation('delete', true) : handleDelete}>
                    <FormattedMessage id="general.deletionTitle" />
                  </MenuItem>
                )}
              </Menu>
            </Item>
          </Container>
        </Item>
      </Container>

      <React.Suspense
        fallback={
          <div style={{ position: 'absolute', right: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <CircularProgressLocal size={'3rem'} />
          </div>
        }
      >
        {confirmationDialogOpen && (
          <ConfirmationDialog
            open={!!confirmationDialogOpen}
            text={
              confirmationDialogOpen === 'delete' && deleteConfirmationText
                ? deleteConfirmationText
                : confirmationDialogOpen === 'edit' && editConfirmationText
                  ? editConfirmationText
                  : ''
            }
            cancel={{
              callback: () => {
                setConfirmationDialogOpen(false);
              },
            }}
            confirm={{
              callback: () => {
                if (confirmationDialogOpen === 'delete') {
                  setConfirmationDialogOpen(false);
                  toggleDeleteDialog();
                } else if (confirmationDialogOpen === 'edit') {
                  startEdit(document)({} as React.MouseEvent);
                }
              },
              text: 'general.continue',
              loading: loading,
            }}
          />
        )}
        {deleteDialogOpen && (
          <DeleteDialog
            document={document as IControlProps}
            open={deleteDialogOpen}
            setOpen={toggleDeleteDialog}
            subDocs={subDocs}
            customDeleteContent={deleteContent}
            customDeleteHandler={deleteHandler}
          />
        )}
        {medicationEndDialogOpen && (
          <MedicationEndReasonDialog
            document={document}
            openState={[medicationEndDialogOpen, setMedicationEndDialogOpen]}
          />
        )}
      </React.Suspense>
    </StyledSection>
  );
};

export interface IHistoryRowControls {
  document: any & Partial<IControlProps>;
  startEdit: (document: any) => (e: React.MouseEvent) => void;
  view?: IView;
  subDocs?: Array<{ name: string; id: string } | undefined>;
  itemAlign?: string;
  buttonDimensions?: {
    width?: number;
    height?: number;
    fontSize?: number;
  };
  hideButton?: boolean;
  showEditInMenu?: boolean;
  showViewInMenu?: boolean;
  /** Custom delete dialog content */
  deleteContent?: JSX.Element;
  /** Custom delete handler to substitute default document deletion handler */
  deleteHandler?: () => void;
  newStyle?: boolean;
  editConfirmationText?: JSX.Element | string;
  deleteConfirmationText?: JSX.Element | string;
  disableButtons?: boolean;
  /** Override write permissions etc. that might disable editing/viewing. Does not enable deleting docs from another org. */
  enableButtons?: boolean;
}

export default HistoryRowControls;
