import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import { formatPartialDate } from 'neuro-utils';
import { isMGADLComplete, mgadlScore } from 'Routes/FunctionalPerformanceMgravis/utils';
import InfoPopover from 'Components/InfoPopover';
import HistoryHeader from 'Components/_NewElements/HistoryHeader';

const MGADLHistory = ({ documents, startEdit, view }: IOwnProps): JSX.Element => (
  <>
    <HistoryHeader
      headerText={<FormattedMessage id="functionalPerformanceMgravis.mgadl" />}
      buttons={[{ text: 'functionalPerformanceMgravis.newMGADL', onClick: startEdit({}, 'mgadl') }]}
    />
    <HistoryRowListing
      documents={documents}
      headers={
        <>
          <Item xs={2}>
            <FormattedMessage id="general.date" />
          </Item>
          <Item xs={2}>
            <FormattedMessage id="functionalPerformanceMgravis.labels.mgadl.score" />
          </Item>
          <Item xs={8}>&nbsp;</Item>
        </>
      }
      contentFormat={(d: IMGADL): JSX.Element => (
        <>
          <Item xs={2}>{formatPartialDate(d.date ?? undefined)}</Item>
          <Item xs={2}>
            {isMGADLComplete(d) ? (
              mgadlScore(d)
            ) : d.manualScore || d.manualScore === 0 ? (
              d.manualScore
            ) : (
              <InfoPopover text="general.notFilled" color="primary" />
            )}
          </Item>
          <Item xs={5}>&nbsp;</Item>
          <Item xs={3}>
            <HistoryRowControls document={d} startEdit={startEdit} view={view} />
          </Item>
        </>
      )}
    />
  </>
);

interface IOwnProps {
  documents: Array<IMGADL>;
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default MGADLHistory;
