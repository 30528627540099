import { styled } from '@mui/system';
import * as React from 'react';

import ActionButtonRounded, { IActionButtonRounded } from 'Components/ActionButtonRounded';
import { Container, Item } from 'Components/Grid';

const defaultButtonDimensions = { width: 11, height: 4, fontSize: 16 };

const Header = styled('h2')(({ theme }) => ({
  color: theme.palette.primary.main,
  marginTop: 0,
}));
const DescriptionText = styled('div')(({ theme }) => ({
  color: theme.palette.grey[700],
}));

const HistoryHeader = ({ headerText, descriptionText, buttons }: IHistoryHeader) => {
  return (
    <Container style={{ marginBottom: '4rem' }}>
      <Item xs={8}>
        <Header>{headerText}</Header>
        {descriptionText && <DescriptionText>{descriptionText}</DescriptionText>}
      </Item>
      {buttons && (
        <Item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ justifySelf: 'flex-end' }}>
            {buttons.map((button, i) => (
              <div key={i}>
                <ActionButtonRounded {...defaultButtonDimensions} {...button} />
              </div>
            ))}
          </div>
        </Item>
      )}
    </Container>
  );
};

interface IHistoryHeader {
  headerText: string | JSX.Element;
  descriptionText?: string | JSX.Element;
  buttons?: Array<IActionButtonRounded>;
}

export default HistoryHeader;
