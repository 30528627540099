import { DialogTitle, DialogContent, DialogActions, Dialog, Radio } from '@mui/material';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { formatTime } from 'neuro-utils';
import { equals } from 'ramda';
import { styled } from '@mui/system';

import {
  dialogTitle,
  dialogContent,
  dialogActions,
  dialogCancel,
  dialogDelete,
} from '../../../../config/theme/componentTheme';
import ActionButton from 'Components/ActionButton';

import MeasurementFormSingle from '../FormMeasurement/single';
import { Container, Item } from 'Components/Grid';
import { calculateBMI, loincCodeToFieldName } from 'Routes/Background/utils';
import { actions } from 'Store/documents/actions';
import { actions as myAppActions } from 'Store/myapp/actions';
import ConfirmationDialog from 'Components/ConfirmationDialog';
import ToolTip from 'Components/ToolTip';
import { useAppDispatch } from 'Store/index';
import { IParkinsonMobileBackground } from 'neuro-schemas';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import { omitControlProps } from 'Utility/documentHandling';
import { useSelector } from 'react-redux';

const StyledContainerArea = styled('div')(({ theme }) => ({
  '& > div:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey[500] + 22,
  },
}));
const StyledContainer = styled(Container)({
  cursor: 'pointer',
  padding: '0.6rem',
});

const EditDialog = ({ editingDialogDocuments, cancelEditing, confirmEdit }: IEditDialog) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const fm = (id?: string) => (id ? formatMessage({ id }) : '');
  const myDocs = useSelector((s: IState) => s.myapp.sortedAndMergedDocuments);
  const myDocIds = myDocs.map((md) => md._id);

  const [chosenDoc, setChosenDoc] = React.useState<
    IMeasurement | (IParkinsonMobileBackground & IControlProps) | (ISRMobileBackground & IControlProps) | null
  >(null);
  const [newValues, setNewValues] = React.useState<
    Partial<IMeasurement> | Partial<IParkinsonMobileBackground> | Partial<ISRMobileBackground> | null
  >(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState<boolean>(false);

  const changeValue = (values: TOnChangeValues) => {
    const field = Object.keys(values)[0];
    setNewValues({ ...newValues, [field]: values[field] });
  };

  const saveData = () => {
    if (chosenDoc && newValues && 'value' in newValues && newValues.value) {
      actions.modifyField({ id: chosenDoc._id, name: chosenDoc._type }, chosenDoc, { value: newValues?.value })(
        dispatch,
      );
    }
  };
  const saveNewDocument = () => {
    if (
      editingDialogDocuments &&
      'code' in editingDialogDocuments &&
      newValues &&
      'value' in newValues &&
      newValues.value
    ) {
      actions.putDocument('measurement', { ...editingDialogDocuments, ...newValues })(dispatch);
    }
  };
  const deleteDocument = () => {
    if (chosenDoc) {
      actions.deleteDocument(
        { id: chosenDoc._id, name: chosenDoc._type },
        { reason: 'Manually delete measurement value' },
      )(dispatch);
    }
  };
  const saveMyData = (oldData: IParkinsonMobileBackground | ISRMobileBackground, type: string, id: string) => {
    if (chosenDoc && newValues) {
      myAppActions.putNewCommit(type, id, { ...oldData, ...newValues }, dispatch);
    }
  };

  const documentsIsArray = Array.isArray(editingDialogDocuments);

  const cancel = () => {
    setChosenDoc(null);
    cancelEditing();
    setNewValues(null);
  };

  const deleteFunction = () => {
    setDeleteConfirmationOpen(false);
    deleteDocument();
    setChosenDoc(null);
    cancelEditing();
    setNewValues(null);
  };

  const confirm = () => {
    if (documentsIsArray) {
      confirmEdit(chosenDoc);
    } else if (editingDialogDocuments) {
      if (!editingDialogDocuments._id) {
        // If no id, then create new document
        saveNewDocument();
        setNewValues(null);
        setChosenDoc(null);
        confirmEdit(null);
      } else {
        if (myDocIds.includes(editingDialogDocuments._id) && editingDialogDocuments._type) {
          saveMyData(
            omitControlProps(editingDialogDocuments),
            editingDialogDocuments._type,
            editingDialogDocuments._id,
          );
          setChosenDoc(null);
          confirmEdit(null);
          setNewValues(null);
        } else {
          // Edit old document
          saveData();
          setChosenDoc(null);
          confirmEdit(null);
          setNewValues(null);
        }
      }
    }
  };

  return (
    <>
      <Dialog
        open={!!editingDialogDocuments}
        fullWidth
        maxWidth={!documentsIsArray && editingDialogDocuments ? 'sm' : 'xs'}
      >
        <DialogTitle style={dialogTitle}>
          {documentsIsArray
            ? fm('background.values')
            : editingDialogDocuments?._id
              ? editingDialogDocuments?._type === 'measurement'
                ? fm(`background.editValue`)
                : fm('background.editPatientReported')
              : fm(`background.addValue`)}
        </DialogTitle>
        <DialogContent style={dialogContent}>
          {documentsIsArray ? (
            <div>
              <div>{fm('background.chooseValue')}:</div>
              <br />
              <StyledContainerArea>
                {editingDialogDocuments.map((doc) => {
                  const locked = !!doc._lockedFor;
                  return (
                    <ToolTip
                      key={doc._id}
                      title={fm('background.dataFromIntegration')}
                      disabled={!locked}
                      hover
                      placement={'right-end'}
                      content={
                        doc._type === 'measurement' ? (
                          <StyledContainer alignItems="center" onClick={!locked ? () => setChosenDoc(doc) : undefined}>
                            <Item xs={5}>{formatTime((doc as IMeasurement).time)}</Item>
                            <Item xs={5}>
                              {(doc as IMeasurement).value}{' '}
                              {((doc as IMeasurement).code &&
                                loincCodeToFieldName((doc as IMeasurement).code ?? '')?.unit) ??
                                ''}
                            </Item>
                            <Item xs={2}>
                              <Container justifyContent={'flex-end'}>
                                <Item>
                                  <Radio checked={equals(doc, chosenDoc)} disabled={locked} style={{ padding: 0 }} />
                                </Item>
                              </Container>
                            </Item>
                          </StyledContainer>
                        ) : (
                          <StyledContainer
                            onClick={!locked ? () => setChosenDoc(doc) : undefined}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                          >
                            <Item xs={8}>
                              <div style={{ height: '3rem', fontWeight: 600 }}>{fm('background.patientReported')}</div>
                              {(doc._type === 'parkinsonMobileBackground'
                                ? ['weight', 'height', 'bmi']
                                : ['height']
                              ).map((field, i) => {
                                return (
                                  <div
                                    key={i}
                                    style={{
                                      height: '3rem',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <span>{fm(`background.${field}`)}</span>
                                    <span>
                                      {
                                        (doc as IParkinsonMobileBackground & IControlProps)?.[
                                          field as keyof IParkinsonMobileBackground
                                        ]
                                      }
                                    </span>
                                  </div>
                                );
                              })}
                            </Item>
                            <Item style={{ justifySelf: 'flex-end' }}>
                              <Radio checked={equals(doc, chosenDoc)} disabled={locked} style={{ padding: 0 }} />
                            </Item>
                          </StyledContainer>
                        )
                      }
                    />
                  );
                })}
              </StyledContainerArea>
            </div>
          ) : editingDialogDocuments ? (
            <div style={{ width: '100%', overflowX: 'hidden' }}>
              {myDocIds.includes(editingDialogDocuments?._id ?? '') ? (
                <div>
                  <FormRow
                    title="background.weight"
                    condition={editingDialogDocuments?._type === 'parkinsonMobileBackground'}
                  >
                    <InputHandler
                      name="weight"
                      type="NumberField"
                      editing={true}
                      formData={{ document: { ...editingDialogDocuments, ...newValues }, onChange: changeValue }}
                    />
                  </FormRow>
                  <FormRow title="background.height">
                    <InputHandler
                      name="height"
                      type="NumberField"
                      editing={true}
                      formData={{ document: { ...editingDialogDocuments, ...newValues }, onChange: changeValue }}
                    />
                  </FormRow>
                  <FormRow
                    title="background.bmi"
                    condition={editingDialogDocuments?._type === 'parkinsonMobileBackground'}
                  >
                    <InputHandler
                      name="bmi"
                      type="NumberField"
                      editing={true}
                      formData={{ document: { ...editingDialogDocuments, ...newValues }, onChange: changeValue }}
                      precision={1}
                      placeholder={
                        calculateBMI(
                          (newValues && 'height' in newValues
                            ? newValues.height
                            : (editingDialogDocuments as IParkinsonMobileBackground & IControlProps)?.height) ?? 0,
                          (newValues && 'weight' in newValues
                            ? newValues?.weight
                            : (editingDialogDocuments as IParkinsonMobileBackground & IControlProps)?.weight) ?? 0,
                        ) !== Infinity
                          ? `${calculateBMI(
                              (newValues && 'height' in newValues
                                ? newValues.height
                                : (editingDialogDocuments as IParkinsonMobileBackground & IControlProps)?.height) ?? 0,
                              (newValues && 'weight' in newValues
                                ? newValues?.weight
                                : (editingDialogDocuments as IParkinsonMobileBackground & IControlProps)?.weight) ?? 0,
                            )}`
                          : ''
                      }
                      disablePlaceholderFormatting
                    />
                  </FormRow>
                </div>
              ) : (
                <MeasurementFormSingle
                  formData={{
                    document: { ...editingDialogDocuments, ...newValues },
                    onChange: changeValue,
                  }}
                  editableFields={editingDialogDocuments._id ? ['value'] : ['time', 'value']}
                />
              )}
            </div>
          ) : null}
        </DialogContent>
        <DialogActions style={dialogActions}>
          <Container alignItems={'center'}>
            {!documentsIsArray && editingDialogDocuments?._id && (
              <Item xs={5}>
                <div style={dialogDelete} onClick={() => setDeleteConfirmationOpen(true)}>
                  {fm('general.delete')}
                </div>
              </Item>
            )}
            <Item xs={true}>
              <Container alignItems={'center'} justifyContent={'flex-end'}>
                <Item>
                  <div style={dialogCancel} onClick={cancel}>
                    {fm('general.cancel')}
                  </div>
                </Item>
                <Item>
                  <ActionButton
                    text={documentsIsArray ? 'general.choose' : 'general.save'}
                    onClick={confirm}
                    width={15}
                    height={4}
                    fontSize={16}
                  />
                </Item>
              </Container>
            </Item>
          </Container>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={deleteConfirmationOpen}
        text={fm('general.reallyWantToDelete')}
        cancel={{ callback: () => setDeleteConfirmationOpen(false) }}
        confirm={{ callback: () => deleteFunction() }}
      />
    </>
  );
};

interface IEditDialog {
  editingDialogDocuments:
    | Partial<IMeasurement>
    | Array<IMeasurement | (IParkinsonMobileBackground & IControlProps)>
    | (IParkinsonMobileBackground & IControlProps)
    | (ISRMobileBackground & IControlProps)
    | null;
  cancelEditing: () => void;
  confirmEdit: (d: IMeasurement | IMeasurement[] | null) => void;
}

export default EditDialog;
