import { fm } from 'Components/FormatMessage';
import { Container, Item } from 'Components/Grid';
import HistoryRowBasicItem from 'Components/HistoryRowBasicItem';
import HistoryRowSubHeader from 'Components/HistoryRowSubHeader';
import { withFormContext } from 'Containers/FormContextHandler';
import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import * as React from 'react';
import colors from '../../../../config/theme/colors';

const AdverseEffectHistory = ({ document }: IOwnProps): JSX.Element => {
  return (
    <>
      <Container style={{ marginTop: '2rem' }}>
        <>
          <HistoryRowSubHeader header={fm('thalamotomyHifu.adverseEffects')} />
          <Container style={{ color: colors.tertiaryText }}>
            <Item xs={3}>{fm('general.date')}</Item>
            <Item xs={4}>{fm('thalamotomyHifu.adverseEffects')}</Item>
            <Item xs={5}>{fm('thalamotomyHifu.adverseEffectAdditionalInformation')}</Item>
          </Container>
          {document.adverseEffects
            ?.sort((n1, n2) => sortPartialDate(n2.date, n1.date))
            .map((ae: IThalamotomyOrHifuTreatmentAdverseEffect, i: number) => (
              <Container key={i} style={{ margin: '1rem 0 0 0' }}>
                <Item xs={3} style={{ fontWeight: '600' }}>
                  {formatPartialDate(ae.date)}
                </Item>
                <Item xs={4} style={{ fontWeight: '600' }}>
                  {(ae.adverseEffects || []).length
                    ? ae.adverseEffects?.map((a, i) => (
                        <span key={a + i}>
                          {fm(`thalamotomyHifu.opts.${a}`)}
                          {i < (ae.adverseEffects || []).length - 1 ? ', ' : ''}
                        </span>
                      ))
                    : '-'}
                </Item>
                <Item xs={5} style={{ fontWeight: '600' }}>
                  {ae.additionalInformation ?? '-'}
                </Item>
              </Container>
            ))}
        </>
      </Container>
    </>
  );
};

const ThalamotomyHifuHistoryRowData = ({ document }: IOwnProps): JSX.Element => {
  const thalamotomy: IThalamotomy | undefined = document._type === 'thalamotomy' ? document : undefined;
  const hifu: IHifuTreatment | undefined = document._type === 'hifu' ? document : undefined;

  return (
    <>
      {thalamotomy && (
        <>
          <Container>
            <Item xs={3}>
              <HistoryRowBasicItem
                header={fm('thalamotomyHifu.thalamotomy.treatmentHospital')}
                value={thalamotomy.placeOfOperation}
              />
            </Item>
            <Item xs={3}>
              <HistoryRowBasicItem
                header={fm('thalamotomyHifu.thalamotomy.treatmentType')}
                value={
                  thalamotomy.typeOfOperation
                    ? fm(`thalamotomyHifu.thalamotomy.opts.${thalamotomy.typeOfOperation}`)
                    : ''
                }
              />
            </Item>
          </Container>
          <AdverseEffectHistory document={thalamotomy} />
        </>
      )}
      {hifu && (
        <>
          <Container>
            <Item xs={3}>
              <HistoryRowBasicItem
                header={fm('thalamotomyHifu.hifu.treatmentIndication')}
                value={
                  <>
                    <span>{fm(`thalamotomyHifu.hifu.opts.${hifu.treatmentIndication}`)}</span>
                    {hifu.treatmentIndication === 'other' && hifu.treatmentIndicationOther && (
                      <span>: {hifu.treatmentIndicationOther}</span>
                    )}
                  </>
                }
              />
            </Item>
            <Item xs={3}>
              <HistoryRowBasicItem
                header={fm('thalamotomyHifu.hifu.treatmentTarget')}
                value={hifu.treatmentTarget ? fm(`thalamotomyHifu.hifu.opts.${hifu.treatmentTarget}`) : '-'}
              />
            </Item>
          </Container>
          <AdverseEffectHistory document={hifu} />
        </>
      )}
    </>
  );
};
interface IOwnProps {
  document: IThalamotomy | IHifuTreatment;
}

export default withFormContext(ThalamotomyHifuHistoryRowData);
