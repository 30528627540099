import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';

const optionFormatter =
  (opt: number | string) =>
  (name: number | string): JSX.Element => {
    return <FormattedMessage id={`imaging.${opt}.${name}`} />;
  };

const ScintigraphyForm = ({ formData, disabledFields = [] }: IOwnProps): JSX.Element => {
  return (
    <React.Fragment>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={!disabledFields.includes('date')}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow title="imaging.imagingType">
        <InputHandler
          type="Radio"
          editing={true}
          name="imagingType"
          formData={formData}
          options={['pet', 'datscan', 'other']}
          optionFormatter={optionFormatter('opts.imagingType')}
        />
      </FormRow>
      {formData.document.imagingType === 'pet' ? (
        <React.Fragment>
          <FormRow title="imaging.tracer">
            <InputHandler
              type="Radio"
              editing={true}
              name="tracer"
              formData={formData}
              options={['fdg', 'other']}
              optionFormatter={optionFormatter('opts.tracer')}
            />
          </FormRow>
          {formData.document.tracer === 'other' && (
            <FormRow title="imaging.whatTracer">
              <InputHandler type="TextField" editing={true} name="otherTracer" formData={formData} />
            </FormRow>
          )}
        </React.Fragment>
      ) : formData.document.imagingType === 'other' ? (
        <FormRow title="imaging.whatOther">
          <InputHandler type="TextField" editing={true} name="otherType" formData={formData} />
        </FormRow>
      ) : undefined}
      <FormRow title="imaging.result">
        <InputHandler
          type="Radio"
          editing={true}
          name="result"
          formData={formData}
          options={['normal', 'mildlyAbnormal', 'moderatelyAbnormal', 'stronglyAbnormal']}
          optionFormatter={optionFormatter('opts.result')}
        />
      </FormRow>
      {(formData.document.result === 'mildlyAbnormal' ||
        formData.document.result === 'moderatelyAbnormal' ||
        formData.document.result === 'stronglyAbnormal') && (
        <FormRow title="imaging.symmetryOfFinding">
          <InputHandler
            type="Radio"
            editing={true}
            name="symmetryOfFinding"
            formData={formData}
            options={['symmetric', 'asymmetric']}
            optionFormatter={optionFormatter('opts.symmetricAsymmetric')}
          />
        </FormRow>
      )}
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<IScintigraphy>;
  disabledFields?: string[];
}

export default ScintigraphyForm;
