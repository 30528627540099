import { Paper, Collapse } from '@mui/material';
import { styled } from '@mui/system';
import HelpIcon from '@mui/icons-material/Help';
import * as React from 'react';

import colors from '../../config/theme/colors';
import { Container, Item } from '../Grid';

interface IStyledNumberProps {
  longestValueLength?: number;
  horizontal?: boolean;
  minHeight?: number;
  viewing?: string;
  selected?: boolean;
}

const StyledNumber = styled('div', {
  shouldForwardProp: (prop) => prop !== 'longestValueLength' && prop !== 'horizontal',
})(({ longestValueLength, horizontal }: IStyledNumberProps) => ({
  width: longestValueLength && longestValueLength > 3 ? `${longestValueLength}` : '3rem',
  height: '3rem',
  backgroundColor: 'white',
  textAlign: 'center',
  lineHeight: horizontal ? '2.6rem' : '2.8rem',
  border: 'solid 0.5px #cbcbcb',
  borderRadius: '5rem',
  position: 'relative',
  zIndex: 1,
}));

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) =>
    !['longestValueLength', 'horizontal', 'minHeight', 'viewing', 'selected'].includes(
      typeof prop === 'string' ? prop : '',
    ),
})(({ longestValueLength, horizontal, minHeight, viewing, selected }: IStyledNumberProps) => ({
  position: horizontal ? 'relative' : undefined,
  bottom: horizontal ? '2.5rem' : undefined,
  margin: horizontal ? '0 1rem 0 1rem' : longestValueLength && longestValueLength > 3 ? '0 0 0 -3rem' : '0 0 0 -1.5rem',
  padding: horizontal ? '3rem 1rem 2rem 3rem' : '1rem 1rem 1rem 3rem',
  height: 'fit-content',
  minHeight: horizontal && minHeight ? `${minHeight}rem` : undefined,
  cursor: viewing && viewing === 'true' ? '' : 'pointer',
  backgroundColor: selected ? colors.primary : colors.white + ' !important',
  color: selected ? colors.white : colors.primaryText + ' !important',
  '&:hover': {
    backgroundColor: selected
      ? colors.primary
      : viewing && viewing === 'true'
        ? colors.white
        : colors.lightestGray + ' !important',
  },
}));

const StyledInstructions = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ selected }: IStyledNumberProps) => ({
  padding: '1rem 1rem 1rem 3rem',
  minHeight: '5rem',
  backgroundColor: selected ? colors.primary : colors.white + ' !important',
  color: selected ? colors.white : colors.primaryText + ' !important',
}));

const OptionItem = ({
  qNumber,
  text,
  onClick,
  selected,
  viewing,
  index,
  instruction,
  instructionsOpenIndex,
  handleInstructionsOpenClick,
  secondary,
  allInstructionsOpenAtOnce,
  longestValueLength,
  horizontal,
  minHeight,
  qNumberHidden,
}: IOptionItemProps): JSX.Element => (
  <div style={{ margin: '1rem 0' }}>
    <Container
      justifyContent={horizontal ? 'center' : 'flex-end'}
      alignItems={horizontal ? undefined : 'center'}
      style={!horizontal && secondary ? { marginLeft: horizontal ? undefined : '6rem', width: 'auto' } : {}}
    >
      {!qNumberHidden && (
        <Item>
          <StyledNumber longestValueLength={longestValueLength}>{qNumber}</StyledNumber>
        </Item>
      )}
      <Item xs={horizontal ? undefined : true}>
        <StyledPaper
          elevation={3}
          onClick={onClick}
          selected={selected}
          viewing={viewing ? 'true' : 'false'}
          longestValueLength={longestValueLength}
          horizontal={horizontal}
          minHeight={minHeight}
        >
          <Container
            justifyContent={'space-between'}
            alignItems={'center'}
            style={{ minHeight: secondary && secondary === true ? '2.5rem' : '5rem' }}
          >
            <Item xs={11}>{text}</Item>
            {instruction && handleInstructionsOpenClick && !allInstructionsOpenAtOnce && (
              <Item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
                <HelpIcon
                  style={{ cursor: 'default', color: selected ? 'white' : colors.primary }}
                  onClick={(e): void => {
                    handleInstructionsOpenClick(index);
                    e.stopPropagation();
                  }}
                />
              </Item>
            )}
          </Container>
        </StyledPaper>
      </Item>
    </Container>
    {instruction && (
      <Container justifyContent="flex-end" alignItems="center" style={{ marginLeft: '6rem' }}>
        <Collapse in={instructionsOpenIndex === index || allInstructionsOpenAtOnce}>
          <Item xs={true} style={{ marginTop: '0.5rem' }}>
            <StyledInstructions elevation={2}>
              <Container alignItems="center" style={{ height: '100%' }}>
                <Item>{instruction}</Item>
              </Container>
            </StyledInstructions>
          </Item>
        </Collapse>
      </Container>
    )}
  </div>
);

interface IOptionItemProps {
  qNumber: number;
  text: string | JSX.Element;
  onClick?: () => void;
  selected?: boolean;
  viewing?: boolean;
  index: number;
  instruction?: JSX.Element | string | undefined;
  instructionsOpenIndex?: number | undefined;
  handleInstructionsOpenClick?: (state: number | undefined) => void;
  secondary: boolean;
  allInstructionsOpenAtOnce: boolean;
  longestValueLength: number;
  horizontal?: boolean;
  minHeight?: number;
  qNumberHidden?: boolean;
}

export default OptionItem;
