import * as React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { dialogActions, dialogContent, dialogTitle } from '../../../config/theme/componentTheme';
import ActionButton from '../../../components/ActionButton';
import { fm } from 'Components/FormatMessage';
import { styled } from '@mui/system';
import { Container, Item } from '../../../components/Grid';
import colors from '../../../config/theme/colors';

const StyledTable = styled('table')({});

const StyledTbody = styled('tbody')({});

const StyledTr = styled('tr')({
  verticalAlign: 'top',
});

const StyledTd = styled('td')({
  '&:first-of-type': {
    width: '2rem',
  },
  '&:nth-of-type(2)': {
    paddingRight: '2rem',
  },
});

export const NumberedFormRow = ({
  number,
  title,
  children,
  info,
}: {
  number?: number;
  title: string;
  children: JSX.Element;
  info?: JSX.Element;
}): JSX.Element => (
  <Container style={{ marginBottom: '4.5rem' }}>
    <Item xs={5} style={{ width: '100%', color: colors.tertiaryText }}>
      {number ? (
        <StyledTable>
          <StyledTbody>
            <StyledTr>
              <StyledTd>{number}.</StyledTd>
              <StyledTd>{title}</StyledTd>
            </StyledTr>
          </StyledTbody>
        </StyledTable>
      ) : (
        <Container>
          <Item>{title}</Item>
          {info && (
            <Item xs={1} style={{ height: '4rem' }}>
              {info}
            </Item>
          )}
        </Container>
      )}
    </Item>
    <Item xs={true} style={{ position: 'relative', top: '0.3rem' }}>
      {children}
    </Item>
  </Container>
);

const FormattedInfoContent = ({ content }: { content: string }): JSX.Element => {
  return content === 'diagnosticSurvey.npi.info' ? (
    <React.Fragment>
      {fm(content)}
      <div style={{ fontWeight: 'bold', marginTop: '2.4rem' }}>
        {fm('diagnosticSurvey.npi.neuropsychiatricSymptoms')}:
      </div>
      <div>{fm('diagnosticSurvey.npi.infoNeuropsychiatricSymptoms')}</div>
      <div style={{ fontWeight: 'bold', marginTop: '2.4rem' }}>
        {fm('diagnosticSurvey.npi.neurovegetativeChanges')}:
      </div>
      <div>{fm('diagnosticSurvey.npi.infoNeurovegetativeChanges')}</div>
    </React.Fragment>
  ) : (
    fm(content)
  );
};

const InformationDialog = ({
  info,
  openDialog,
  setOpenDialog,
}: {
  info: Array<{ title: string | JSX.Element; content: string | JSX.Element }>;
  openDialog: boolean;
  setOpenDialog: (open: boolean) => () => void;
}): JSX.Element => {
  return (
    <Dialog
      open={openDialog}
      maxWidth="md"
      fullWidth={true}
      PaperProps={{ square: true }}
      onBackdropClick={setOpenDialog(false)}
    >
      <DialogContent style={dialogContent}>
        {Array.isArray(info)
          ? info.map((info, index) => (
              <React.Fragment key={index}>
                <div style={{ ...dialogTitle, paddingLeft: 0, fontWeight: 400 }}>
                  {typeof info.title === 'string' ? fm(info.title) : info.title}
                </div>
                <div style={{ whiteSpace: 'pre-line' }}>
                  {typeof info.content === 'string' ? <FormattedInfoContent content={info.content} /> : info.content}
                </div>
              </React.Fragment>
            ))
          : null}
      </DialogContent>
      <DialogActions style={dialogActions}>
        <ActionButton text="general.close" width={10} height={3} fontSize={16} onClick={setOpenDialog(false)} />
      </DialogActions>
    </Dialog>
  );
};

export const InformationButton = ({
  info,
  size,
}: {
  info: Array<{ title: string | JSX.Element; content: string | JSX.Element }>;
  size?: string;
}): JSX.Element => {
  const [infoDialogOpen, setInfoDialogOpen] = React.useState<boolean>(false);
  const setInfoShow = (state: boolean) => (): void => {
    setInfoDialogOpen(state);
  };

  return (
    <React.Fragment>
      <InformationDialog openDialog={infoDialogOpen} setOpenDialog={setInfoShow} info={info} />
      <button style={{ background: 'transparent', border: 'none', padding: 0, margin: 0 }} onClick={setInfoShow(true)}>
        <InfoIcon
          style={{ fontSize: size ? size : '2.5rem', display: 'block', marginLeft: '1rem', cursor: 'pointer' }}
          color="primary"
        />
      </button>
    </React.Fragment>
  );
};
