// Supported devices - only these require deviceNumber (3 digits from 0-9 - needs more info?)
// More devices: https://n0-avw-amr.easycareonline.com/resources/help/en-us/en/about-airview/compatible-devices.html
// This array is unused for now
export const devices = [
  'AirSense 10 AutoSet',
  'AirSense 10 AutoSet for Her',
  'AirSense 10 Elite',
  'AirSense 11 AutoSet',
  'AirSense 11 Elite',
  'AirCurve 10 CS PaceWave',
  'Lumis 100',
  'Lumis 150',
];

// S9 devices - only these require deviceType (provided in the Map)
export const deviceTypesToDeviceNamesMap = new Map([
  ['026001', 'S9 AutoSet'],
  ['026002', 'S9 Elite'],
  ['026003', 'S9 Escape'],
  ['026004', 'S9 Escape Auto'],
  ['026009', 'S9 VPAP Auto'],
  ['026011', 'VPAP S (S9)'],
  ['026012', 'VPAP ST (S9)'],
  ['026013', 'VPAP Adapt (S9)'],
  ['026016', 'VPAP Adapt (S9 w/ ASVAuto)'],
  ['026018', 'VPAP ST-A'],
  ['026023', 'VPAP COPD'],
]);
