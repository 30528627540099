import * as React from 'react';
import { styled } from '@mui/system';

import FormRow from '../../../../../../../../components/FormRow';
import FormSectionHistoryAcceptor from '../../../../../../../../components/FormSectionHistoryAcceptor';
import { Container, Item } from '../../../../../../../../components/Grid';
import InputHandler from '../../../../../../../../components/InputHandler';

import { fm } from 'Components/FormatMessage';
import {
  atLeastOneCoreClinicalCharacteristicWithAQP4,
  diagnosisCriteriaAreFullfilledWithAQP4,
  numberOfGivenAnswers,
  positiveTestForAQP4,
  redFlags,
} from '../../../../../../utils/NMOSD';
import { InputlessFormRow } from '../../../../../components';

const SubHeader = styled('div')({
  fontWeight: 'bold',
  margin: '0 0 1rem 0',
});

const generalOptionFormatter = (s?: React.ReactText | boolean): JSX.Element => fm(`general.${s || 'empty'}`);

const formFields = ['exclusionOfAlternativeDiagnosesWithAQP4IgG'];

const setAllUnknown = (formData: IOwnProps['formData'], viewing: boolean): void => {
  formFields.forEach((f) => {
    if (!viewing && !(formData.document as { [key: string]: any })[f]) {
      formData?.onChange?.({
        [f]: 'unknown',
      });
    }
  });
};

const MakeFormRow = ({
  title,
  description,
  name,
  formData,
  viewing,
  leftMargin,
  bottomMargin = true,
}: {
  title: string;
  description?: JSX.Element;
  name: string;
  formData: IOwnProps['formData'];
  viewing: boolean;
  leftMargin?: number;
  bottomMargin?: boolean;
}): JSX.Element => (
  <FormRow
    title={title}
    description={description}
    headerWidth={6}
    bottomMargin={bottomMargin}
    headerStyle={{ marginLeft: `${leftMargin ?? 0}rem` }}
  >
    <InputHandler
      editing={!viewing}
      formData={formData}
      name={name}
      type="RadioButtonRow"
      preset="yesNoUnknown"
      height={3.5}
      width={9}
      optionFormatter={generalOptionFormatter}
    />
  </FormRow>
);

const NMOSDWithAQP4 = ({ documents, formData, view, editingID }: IOwnProps): JSX.Element => {
  const isViewing = !editingID && !!view?.viewing;
  const docID = editingID ?? view?.viewing;

  React.useEffect(() => {
    setAllUnknown(formData, isViewing);
  }, [formData, isViewing]);

  return (
    <React.Fragment>
      <FormSectionHistoryAcceptor
        formData={formData}
        documentID={docID}
        documents={documents}
        optionFormatter={generalOptionFormatter}
        header="diagnosis.nmosdDiagnosticCriteria.DiagnosticCriteriaForNMOSDWithAQP4.title"
        hideCopyButton={isViewing}
      >
        {{
          atLeastOneCoreClinicalCharacteristic: {
            header: (
              <InputlessFormRow
                title={
                  'diagnosis.nmosdDiagnosticCriteria.DiagnosticCriteriaForNMOSDWithAQP4.atLeastOneCoreClinicalCharacteristic'
                }
                element={fm(`general.${atLeastOneCoreClinicalCharacteristicWithAQP4(formData.document)}`)}
                boldValue={true}
              />
            ),
          },
          positiveTestForAQP4: {
            header: (
              <InputlessFormRow
                title={'diagnosis.nmosdDiagnosticCriteria.DiagnosticCriteriaForNMOSDWithAQP4.positiveTestForAQP4'}
                element={fm(`general.${positiveTestForAQP4(formData.document)}`)}
                boldValue={true}
              />
            ),
          },
          exclusionOfAlternativeDiagnosesWithAQP4IgG: {
            element: (
              <MakeFormRow
                title="diagnosis.nmosdDiagnosticCriteria.DiagnosticCriteriaForNMOSDWithAQP4.exclusionOfAlternativeDiagnosesWithAQP4IgG"
                name="exclusionOfAlternativeDiagnosesWithAQP4IgG"
                formData={formData}
                viewing={isViewing}
              />
            ),
          },
          numberOfRedFlags: {
            element: (
              <React.Fragment>
                <Container style={{ marginTop: '1rem' }}>
                  <Item xs={6} style={{ paddingRight: '1rem' }}>
                    <SubHeader>{fm('diagnosis.nmosdDiagnosticCriteria.RedFlags.titleShort')}</SubHeader>
                  </Item>
                </Container>
                {['yes', 'no', 'unknown'].map(
                  (answer: string): JSX.Element => (
                    <Container key={`${answer}RedFlags`} style={{ marginTop: '1rem' }}>
                      <Item xs={6} style={{ paddingLeft: '3rem' }}>
                        {fm(`general.${answer}`)}
                      </Item>
                      <Item xs={true} style={{ fontWeight: 600 }}>
                        {numberOfGivenAnswers(formData.document, redFlags, answer as 'yes' | 'no' | 'unknown')}
                      </Item>
                    </Container>
                  ),
                )}
              </React.Fragment>
            ),
          },
          diagnosisCriteriaAreFullfilled: {
            header: (
              <div style={{ marginTop: '4.5rem' }}>
                <InputlessFormRow
                  title={
                    'diagnosis.nmosdDiagnosticCriteria.DiagnosticCriteriaForNMOSDWithAQP4.diagnosisCriteriaAreFullfilled'
                  }
                  element={fm(`general.${diagnosisCriteriaAreFullfilledWithAQP4(formData.document)}`)}
                  boldValue={true}
                  bottomMargin={false}
                  darkerTitle={true}
                />
              </div>
            ),
          },
        }}
      </FormSectionHistoryAcceptor>
    </React.Fragment>
  );
};

interface IOwnProps {
  documents?: Array<INMOSDCriteria>;
  formData: IFormData<INMOSDCriteria>;
  view?: IView;
  editingID?: string;
}

export default NMOSDWithAQP4;
