import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';
import InfoPopover from '../../../../components/InfoPopover';

import { formatPartialDate } from 'neuro-utils';
import { getPatientAgeInMonthsWithOneDecimal } from '../../../../utility/patientInfo';
import { isHineMMComplete, hineMMScore, showPatientAgeInMonthsOnForm } from '../../utils';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const HINEMM = ({ documents, startEdit, view }: IOwnProps): JSX.Element => (
  <HistorySection
    headerText={<FormattedMessage id={'motor.hineMM'} />}
    newButton={
      <DocumentCreationButton
        name="hine_mm"
        text={'motor.newHineMM'}
        onClick={startEdit({}, 'hine_mm')}
        width={15}
        height={3}
        fontSize={14}
        alternate={true}
      />
    }
  >
    <HistoryRowListing
      documents={documents}
      headers={
        <>
          <Item xs={2}>
            <FormattedMessage id={'general.date'} />
          </Item>
          <Item xs={10}>
            <table
              style={{
                tableLayout: 'fixed',
                width: '100%',
                whiteSpace: 'pre-wrap',
                fontSize: '1.3rem',
              }}
            >
              <tbody>
                <tr style={{ verticalAlign: 'bottom', textAlign: 'center' }}>
                  <td>
                    <FormattedMessage id={'motor.labels.hineMM.headControlHistory'} />
                  </td>
                  <td>
                    <FormattedMessage id={'motor.labels.hineMM.sittingHistory'} />
                  </td>
                  <td>
                    <FormattedMessage id={'motor.labels.hineMM.voluntaryGraspHistory'} />
                  </td>
                  <td>
                    <FormattedMessage id={'motor.labels.hineMM.abilityToKickSupineHistory'} />
                  </td>
                  <td>
                    <FormattedMessage id={'motor.labels.hineMM.rollingHistory'} />
                  </td>
                  <td>
                    <FormattedMessage id={'motor.labels.hineMM.crawlingHistory'} />
                  </td>
                  <td>
                    <FormattedMessage id={'motor.labels.hineMM.standingHistory'} />
                  </td>
                  <td>
                    <FormattedMessage id={'motor.labels.hineMM.walkingHistory'} />
                  </td>
                  <td>
                    <FormattedMessage id={'motor.labels.hineMM.score'} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Item>
        </>
      }
      contentFormat={(d: IHINEMM): JSX.Element => (
        <>
          <Item xs={2} id={'hineMMDate'}>
            {showPatientAgeInMonthsOnForm(d.date) ? (
              <FormattedMessage
                id="motor.dateAndMonth"
                values={{
                  date: formatPartialDate(d.date),
                  months: getPatientAgeInMonthsWithOneDecimal(d.date),
                }}
              />
            ) : (
              formatPartialDate(d.date)
            )}
          </Item>
          <Item xs={10} id={'hineMMValues'}>
            <table style={{ tableLayout: 'fixed', width: '100%' }}>
              <tbody>
                <tr style={{ textAlign: 'center' }}>
                  <td>{d?.headControl}</td>
                  <td>{d?.sitting}</td>
                  <td>{d?.voluntaryGrasp}</td>
                  <td>{d?.abilityToKickSupine}</td>
                  <td>{d?.rolling}</td>
                  <td>{d?.crawling}</td>
                  <td>{d?.standing}</td>
                  <td>{d?.walking}</td>
                  <td>
                    {isHineMMComplete(d) ? hineMMScore(d) : <InfoPopover text="general.notFilled" color="primary" />}
                  </td>
                </tr>
              </tbody>
            </table>
          </Item>
        </>
      )}
      makeControls
      historyRowControls={(d: IControlProps) => <HistoryRowControls document={d} startEdit={startEdit} view={view} />}
    />
  </HistorySection>
);

interface IOwnProps {
  documents: IHINEMM[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default HINEMM;
