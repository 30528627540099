import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import theme from '../../../../../config/theme/muiTheme';

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const SmallLowerCase = styled('li')({
  textTransform: 'lowercase',
  fontSize: theme.typography.body1.fontSize,
});

/** Content for warning popup presenting data under clearance threat. */
const FieldClearanceWarning = (props: IFieldClearanceWarningProps): JSX.Element => {
  return (
    <Column>
      <FormattedMessage id="general.dependentFieldsRemovalWarning" />
      <ul>
        {props.threatenedFieldsLocIds.map((localizationId) => (
          <SmallLowerCase key={localizationId}>
            <FormattedMessage id={localizationId} />
          </SmallLowerCase>
        ))}
      </ul>
    </Column>
  );
};
interface IFieldClearanceWarningProps {
  /** Localization IDs of the threatened fields. */
  threatenedFieldsLocIds: string[];
}

export { FieldClearanceWarning };
