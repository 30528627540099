import DataTable from 'Components/DataTable';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import { dss, IDSS, isLocaleKey, Task_Progress } from 'neuro-schemas';
import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { omitControlProps } from 'Utility/documentHandling';
import { MyServiceContext } from '../..';
import colors from '../../../../../config/theme/colors';

const dssFields: Array<keyof IDSS> = [
  'wakingUpAtNight',
  'morningHeadache',
  'wakingUpRefreshed',
  'tiredDuringDay',
  'shortnessOfBreathAtNight',
];

export const Dss = (): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, locale, viewing, setViewingObj, setEditingObj } = myServContext;
  const dssData = (editing?.data || viewing?.data) as IDSS & IControlProps;
  const dssLocales = dss.localizations;
  const useLocale = isLocaleKey(locale) ? locale : 'fi';
  const onChangeSurveyForm = (values: TOnChangeValues): void => {
    const field = Object.keys(values)[0];
    const value = Object.values(values)[0];
    setEditingData?.({
      ...dssData,
      [field]: value,
    });
  };
  return (
    <>
      <BlockWrapper
        title={viewing ? formatPartialDate(dssData.date) : undefined}
        buttons={
          viewing
            ? [
                {
                  title: 'general.edit',
                  onClick: () => {
                    setViewingObj(null);
                    setEditingObj({ type: 'dss', data: dssData });
                  },
                },
              ]
            : undefined
        }
      >
        {!viewing && (
          <FormRow title={'general.date'} headerWidth={4.2}>
            <InputHandler
              name="date"
              type="PartialDate"
              dateDefault="now"
              formData={{
                document: { date: dssData?.date },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
        )}
        <div style={{ width: '60%', marginBottom: '2rem', marginTop: viewing ? '6rem' : undefined }}>
          <DataTable
            headers={viewing ? ['', '', ''] : ['', '']}
            data={{
              rowData: dssFields.map((f) => {
                const opts = ['never', 'occasionally', 'sometimes', 'always'];
                const rowSet = [
                  dssLocales[useLocale][f],
                  <InputHandler
                    key={f}
                    name={f}
                    type="Radio"
                    editing={!!editing}
                    formData={{
                      document: { [f]: dssData?.[f] },
                      onChange: onChangeSurveyForm,
                    }}
                    options={f === 'wakingUpRefreshed' ? opts.slice().reverse() : opts}
                    optionFormatter={(o) => dssLocales[useLocale][`opts.${o}`]}
                  />,
                ];
                if (viewing && f !== 'date') {
                  rowSet.push(
                    <span style={{ fontWeight: 600 }}>
                      {dssData[f] ? dss.calculators.getNumberValueOfField(f, dssData[f] ?? 'never') : ''}
                    </span>,
                  );
                }
                return rowSet;
              }),
              rowStyle: viewing ? undefined : dssFields.map(() => ({ rowAlign: 'top' })),
            }}
          />
        </div>
        <FormRow
          title="myService.sleepApnea.dss.score"
          headerStyle={{ fontWeight: 600 }}
          headerWidth={viewing ? 6.7 : 4.2}
        >
          <div style={{ color: colors.tertiaryText, fontWeight: 600 }}>
            <span>
              {typeof Task_Progress.calculateProgress('dss', omitControlProps(dssData)).yielded === 'number'
                ? Task_Progress.calculateProgress('dss', omitControlProps(dssData)).yielded
                : '-'}
            </span>
            <span>{` / ${Task_Progress.calculateProgress('dss', omitControlProps(dssData)).ceiling}`}</span>
          </div>
        </FormRow>
      </BlockWrapper>
    </>
  );
};
