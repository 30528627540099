import { styled } from '@mui/material';
import RoundBox from 'Components/_NewElements/RoundBox';
import { Container, Item } from 'Components/Grid';
import Checkboxes from 'Components/InputHandler/components/Checkbox';
import CheckboxSingle from 'Components/InputHandler/components/CheckboxSingle';
import ConnectedPartialDate from 'Components/InputHandler/components/PartialDate';
import TextField from 'Components/InputHandler/components/TextField';
import { INeuroGrant } from 'neuro-data-structures';
import { isPartialDate } from 'neuro-utils';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { IFilterSettings } from 'Routes/Medication/utils';
import { RegimenIcons, SourceIcons, StatusIcons } from './Common';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import Selects from 'Components/InputHandler/components/Select';
import Icon from 'Components/_NewElements/Icon';

const StyledFilterHeader = styled('div')({
  fontSize: '1.6rem',
  lineHeight: '1.8rem',
  fontWeight: '600',
  marginRight: '3rem',
  minWidth: '13rem',
});

const StyledSubHeader = styled('div')(({ theme }) => ({
  fontSize: '1.2rem',
  lineHeight: '1.8rem',
  fontWeight: '400',
  color: theme.palette.grey[700],
}));

const DividerHorizontal = styled('div')(({ theme }) => ({
  paddingBottom: '1rem',
  marginTop: '1rem',
  height: 0,
  borderTop: `0.1rem solid ${theme.palette.grey[300]}`,
  width: '100%',
}));
const DividerVertical = styled('div')(({ theme }) => ({
  paddingRight: '1rem',
  marginLeft: '1rem',
  height: '100%',
  borderLeft: `0.1rem solid ${theme.palette.grey[300]}`,
}));

const StyledOpenerButton = styled('button')(({ theme }) => ({
  backgroundColor: 'white',
  fontSize: '1.5rem',
  color: theme.palette.grey[700],
  fontWeight: 600,
  fontFamily: 'inherit',
  cursor: 'pointer',
  marginTop: '-0.1rem',
  border: `0.1rem solid ${theme.palette.grey[400]}`,
  borderTop: 0,
  borderBottomLeftRadius: '1rem',
  borderBottomRightRadius: '1rem',
  boxSizing: 'border-box',
  padding: '0.3rem 1.1rem 0.3rem 0.5rem',
  zIndex: '15',
}));

const FilterField = ({
  title,
  type,
  children,
}: {
  title: string;
  type: 'row' | 'col';
  children: JSX.Element | string;
}) => {
  return (
    <Container rowGap={'1.5rem'}>
      <Item xs={type === 'row' ? 'auto' : 12}>
        <StyledFilterHeader style={{ textAlign: type === 'row' ? 'end' : 'start' }}>{title}</StyledFilterHeader>
      </Item>
      <Item xs={true}>{children}</Item>
    </Container>
  );
};

const FilterComponent = ({
  filterSettings,
  setFilterSettings,
  otherMedicationOrgs,
  getOrgDisplayname,
  setDefaultFilterSettings,
  showStaticRegimenIcon,
  showOtherRegimenIcon,
}: IFilterComponent) => {
  const [selectedQuick, setSelectedQuick] = React.useState<string>('');

  const onChangeSettings = (values: TOnChangeValues, quickSelection?: boolean) => {
    const setting = Object.keys(values)[0];
    let value = values[setting];

    if (setting === 'medicationName') {
      value = typeof value === 'string' ? value : undefined;
      setFilterSettings({ ...filterSettings, medicationName: value });
    }
    if (setting === 'startDate' || setting === 'endDate') {
      value = isPartialDate(value) ? value : undefined;
      const date = { ...filterSettings['date'], [setting]: value };
      setFilterSettings({ ...filterSettings, date });
      !quickSelection && setSelectedQuick('');
    }
    if (setting === 'platformSpecificDrugsOnly') {
      setFilterSettings({ ...filterSettings, platformSpecificDrugsOnly: value ? true : false });
    }
    if (setting === 'status') {
      setFilterSettings({ ...filterSettings, status: Array.isArray(value) ? value : undefined });
    }
    if (setting === 'regimenType') {
      const newRegimenTypes = value as Array<'default' | 'single-dose' | 'onDemand' | 'custom'>;
      setFilterSettings({
        ...filterSettings,
        regimenType: newRegimenTypes,
      });
    }
    if (setting === 'source') {
      const newSources = value as Array<'stellarq' | 'integration' | string>;
      setFilterSettings({
        ...filterSettings,
        source: newSources,
      });
    }
  };

  const onChangeQuick = (values: TOnChangeValues) => {
    const value = values['quickSelection'];
    if (!value) return;
    const newStartDate = new Date();
    setSelectedQuick(`${value}`);
    if (value === '6m') {
      newStartDate.setMonth(newStartDate.getMonth() - 6);
      const newPartial = [newStartDate.getFullYear(), newStartDate.getMonth() + 1, newStartDate.getDate()];
      onChangeSettings({ startDate: newPartial }, true);
    } else {
      const yearDif = parseInt(`${value}`.substring(0, 1));
      newStartDate.setFullYear(newStartDate.getFullYear() - yearDif);
      const newPartial = [newStartDate.getFullYear(), newStartDate.getMonth() + 1, newStartDate.getDate()];
      onChangeSettings({ startDate: newPartial }, true);
    }
  };

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const [extraFieldsOpen, setExtraFieldsOpen] = React.useState<boolean>(false);

  return (
    <div style={{ marginBottom: '3.5rem' }}>
      <RoundBox style={{ borderBottomRightRadius: '0rem' }}>
        <Container>
          <Item xs={true}>
            <FilterField title={fm('medication.textSearch')} type="row">
              <TextField
                type="TextField"
                editing={true}
                name="medicationName"
                onChange={onChangeSettings}
                width={51}
                value={filterSettings['medicationName'] || ''}
                placeholder={fm('medication.textSearchPlaceholder')}
                disableDelay
              />
            </FilterField>
            <DividerHorizontal />
            <FilterField title={fm('medication.inUse')} type="row">
              <Container>
                <Item style={{ marginRight: '2rem' }}>
                  <StyledSubHeader>{fm('medication.dateStartSubHeader')}</StyledSubHeader>
                  <ConnectedPartialDate
                    type="PartialDate"
                    editing={true}
                    name="startDate"
                    value={filterSettings['date']?.['startDate']}
                    onChange={onChangeSettings}
                  />
                </Item>
                <Item style={{ marginRight: '2rem' }}>
                  <StyledSubHeader>{fm('medication.dateEndSubHeader')}</StyledSubHeader>
                  <ConnectedPartialDate
                    type="PartialDate"
                    editing={true}
                    name="endDate"
                    value={filterSettings['date']?.['endDate']}
                    onChange={onChangeSettings}
                  />
                </Item>
                <Item>
                  <StyledSubHeader>{fm('medication.quickSelection')}</StyledSubHeader>
                  <Selects
                    type="Select"
                    editing={true}
                    name="quickSelection"
                    options={['6m', '1y', '2y', '3y', '5y']}
                    optionFormatter={(opt) => fm(`medication.quickSelectionOpts.${opt}`)}
                    value={selectedQuick}
                    onChange={onChangeQuick}
                    placeholder={fm('medication.select')}
                    width={25}
                  />
                </Item>
              </Container>
            </FilterField>
          </Item>
          <Item style={{ marginLeft: '1rem' }}>
            <ActionButtonRounded
              text="medication.setDefaultFilterSettings"
              onClick={() => {
                setDefaultFilterSettings();
                setSelectedQuick('');
              }}
              width={24}
              height={3}
              fontSize={15}
              uppercase={false}
            />
          </Item>
        </Container>
        {extraFieldsOpen ? (
          <>
            <DividerHorizontal />
            <FilterField title={fm('medication.diseaseHistory')} type="row">
              <Container style={{ lineHeight: '1.8rem' }} alignItems={'center'}>
                <Item style={{ marginBottom: '-2.6rem', marginLeft: '0.7rem' /** Offset checkbox own positionings */ }}>
                  <CheckboxSingle
                    type="CheckboxSingle"
                    editing={true}
                    name="platformSpecificDrugsOnly"
                    option={'true'}
                    onChange={onChangeSettings}
                    value={filterSettings['platformSpecificDrugsOnly'] ? 'true' : 'false'}
                    disableCheckboxPadding
                    showLabel
                    optionFormatter={() => fm('medication.diseaseHistoryPlaceholder')}
                  />
                </Item>
              </Container>
            </FilterField>
            <DividerHorizontal />
            <Container>
              <Item xs={true}>
                <FilterField title={fm('medication.status')} type="col">
                  <Checkboxes
                    type="Checkbox"
                    editing={true}
                    name="status"
                    onChange={onChangeSettings}
                    value={filterSettings['status']}
                    options={['inUse', 'toStart', 'paused', 'ended']}
                    optionFormatter={(opt) => fm(`medication.statusOpts.${opt}`)}
                    checkboxIcons={StatusIcons}
                  />
                </FilterField>
              </Item>
              <Item>
                <DividerVertical />
              </Item>
              <Item xs={true}>
                <FilterField title={fm('medication.regimenType')} type="col">
                  <Checkboxes
                    type="Checkbox"
                    editing={true}
                    name="regimenType"
                    onChange={onChangeSettings}
                    value={filterSettings['regimenType'] || []}
                    options={[
                      'default',
                      'single-dose',
                      'onDemand',
                      'custom',
                      ...(showStaticRegimenIcon ? ['static'] : []),
                      ...(showOtherRegimenIcon ? ['other'] : []),
                    ]}
                    optionFormatter={(opt) => fm(`medication.regimenTypes.${opt}`)}
                    checkboxIcons={RegimenIcons}
                  />
                </FilterField>
              </Item>
              <Item>
                <DividerVertical />
              </Item>
              <Item xs={true}>
                <FilterField title={fm('medication.source')} type="col">
                  <Checkboxes
                    type="Checkbox"
                    editing={true}
                    name="source"
                    onChange={onChangeSettings}
                    value={filterSettings['source'] || []}
                    options={['stellarq', 'integration', ...otherMedicationOrgs.map((org) => org.grantorOrg)]}
                    optionFormatter={(opt: string | number) => {
                      if (opt === 'stellarq' || opt === 'integration') return fm(`medication.sourceOpts.${opt}`);
                      else return getOrgDisplayname(`${opt}`);
                    }}
                    checkboxIcons={SourceIcons(otherMedicationOrgs)}
                  />
                </FilterField>
              </Item>
              {/** Platform specific settings sets or ? */}
              {/* <Item>
            <DividerVertical />
          </Item>
          <Item xs={4}>
            <FilterField title={fm('medication.platformSpecific')} type="col">
              test
            </FilterField>
          </Item> */}
            </Container>
          </>
        ) : (
          <></>
        )}
      </RoundBox>
      <Container justifyContent={'flex-end'}>
        <Item>
          <StyledOpenerButton onClick={() => setExtraFieldsOpen(!extraFieldsOpen)}>
            <Container>
              <Item>
                {extraFieldsOpen ? (
                  <Icon icon="upArrow" color="grayDark" />
                ) : (
                  <Icon icon="downArrow" color="grayDark" />
                )}
              </Item>
              <Item>{fm(extraFieldsOpen ? 'medication.hideExtraFields' : 'medication.showExtraFields')}</Item>
            </Container>
          </StyledOpenerButton>
        </Item>
      </Container>
    </div>
  );
};

interface IFilterComponent {
  filterSettings: IFilterSettings;
  setFilterSettings: (settings: IFilterSettings) => void;
  otherMedicationOrgs: INeuroGrant[];
  getOrgDisplayname: (org: string) => string;
  setDefaultFilterSettings: () => void;
  showStaticRegimenIcon: boolean;
  showOtherRegimenIcon: boolean;
}

export default FilterComponent;
