import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { equals, path } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import ActionButton from '../../../../../../components/ActionButton';
import { dialogTitle, dialogContent, dialogActions, dialogCancel } from '../../../../../../config/theme/componentTheme';
import { actions } from '../../../../../../store/documents/actions';
import ParkinsonInfusion from '../RegimenDialog/Custom/ParkinsonInfusion';
import { isParkinsonInfusionType } from '../RegimenDialog/utils/isParkinsonInfusionType';
import { useAppDispatch } from 'Store/index';

const ModifyLockedRegimenDialog = ({
  open,
  setOpenDialog,
  regimen,
  d,
  field,
}: IModifyLockedRegimenDialog): JSX.Element => {
  const [data, setData] = React.useState<any>(regimen[field]);
  const dispatch = useAppDispatch();

  const closeDialog = (): void => {
    setOpenDialog(false);
  };
  const submitChanges = (): void => {
    if (equals(regimen[field], data)) return;

    actions.modifyField({ name: 'medication', id: d._id }, d, { 'regimen.$.infusionDoses': data }, true, {
      id: regimen.id,
      idFieldName: 'id',
    })(dispatch);
    setOpenDialog(false);
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true} PaperProps={{ square: true }}>
      <DialogTitle style={dialogTitle}>
        <FormattedMessage id="medication.regimen" />
      </DialogTitle>

      <DialogContent style={dialogContent}>
        {regimen.regimenType === 'custom' && !!isParkinsonInfusionType(d) && (
          <ParkinsonInfusion
            type={isParkinsonInfusionType(d)}
            index={0}
            formData={{
              onChange: (values: TOnChangeValues): void => {
                const value = path(['regimen', 0, 'infusionDoses'], values) as TAnyObject;
                setData(value);
              },
              document: {
                regimen: [{ id: regimen.id, infusionDoses: data, strengths: regimen.strengths }],
              } as IMedication,
            }}
          />
        )}
      </DialogContent>
      <DialogActions style={dialogActions}>
        <div style={dialogCancel} onClick={closeDialog}>
          <FormattedMessage id="general.cancel" />
        </div>
        <ActionButton text="general.accept" onClick={submitChanges} width={15} height={4} fontSize={16} />
      </DialogActions>
    </Dialog>
  );
};

type TRegimens = IRegimenBasics &
  (IRegimenCustom | IRegimenDefault | IRegimenOnDemand | IRegimenSingleDose | IRegimenOther);

type TFieldType = keyof TRegimens;

interface IModifyLockedRegimenDialog {
  open: boolean;
  setOpenDialog: (open: boolean) => void;
  regimen: TRegimens;
  d: IMedication;
  field: TFieldType;
}

export default ModifyLockedRegimenDialog;
