import * as React from 'react';
import { styled } from '@mui/system';
import { StepperHeaderValuePair } from '../../../components/EventStepper/components';
import { fm } from 'Components/FormatMessage';
import { Container, Item } from '../../../components/Grid';
import colors from '../../../config/theme/colors';
import { historyValue, historyValuePadding } from '../../../config/theme/componentTheme';
import { formatPartialDate } from 'neuro-utils';
import { keysByType, keyToHeader } from '../utils';

export const StyledBoldText = styled('div')({
  color: colors.primary,
  fontSize: '2rem',
  fontWeight: 600,
});

export const StyledTitleValueRow = ({
  title,
  value,
}: {
  title: string | JSX.Element;
  value?: string | number | JSX.Element | (string | number | JSX.Element)[];
}): JSX.Element => (
  <Container style={{ marginBottom: '2rem' }}>
    <Item xs={5}>
      <div
        style={{
          color: colors.primary,
          fontSize: '1.65rem',
          padding: historyValuePadding,
        }}
      >
        {title}
      </div>
    </Item>
    <Item xs={true} style={historyValue}>
      {value ?? '-'}
    </Item>
  </Container>
);

export const GeneratorDetails = ({ generator }: { generator: IVNSGenerator }): JSX.Element => (
  <React.Fragment>
    {['generator', 'generatorSerialNo', 'generatorNextChangeDate'].map((key, index) => {
      const value = (generator as { [key: string]: any })?.[key];
      return (
        <StepperHeaderValuePair
          key={`${key}${index}`}
          header={fm(`vns.${key}`)}
          value={value ? (key === 'generatorNextChangeDate' ? formatPartialDate(value) : value) : '-'}
        />
      );
    })}
  </React.Fragment>
);

export const ModeActivationDetails = ({ modeActivation }: { modeActivation: IVNSModeActivationEvent }): JSX.Element => {
  return (
    <React.Fragment>
      {keysByType('modeActivation').map((key, index) => {
        const value = (modeActivation as { [key: string]: any })?.[key];
        return <StepperHeaderValuePair key={`${key}${index}`} header={fm(`vns.${key}`)} value={value ?? '-'} />;
      })}
    </React.Fragment>
  );
};

export const PostoperativeComplicationDetails = ({
  postoperativeComplication,
}: {
  postoperativeComplication: IVNSPostoperativeComplicationEvent;
}): JSX.Element => {
  return (
    <React.Fragment>
      {keysByType('postoperativeComplication').map((key, index) => {
        const value = (postoperativeComplication as { [key: string]: any })?.[key];
        return (
          <StepperHeaderValuePair
            key={`${key}${index}`}
            header={fm(`vns.${keyToHeader(key)}`)}
            value={
              value ? (
                key === 'complications' ? (
                  !value || value?.length < 1 ? undefined : (
                    <React.Fragment>
                      {value.map((item: string, i: number) => (
                        <div key={`postoperativeComplication${i}`} style={{ fontWeight: 600 }}>
                          {fm(`vns.opts.postoperativeComplication.${item}`)}
                        </div>
                      ))}
                    </React.Fragment>
                  )
                ) : (
                  value
                )
              ) : (
                '-'
              )
            }
          />
        );
      })}
    </React.Fragment>
  );
};

export const AdverseEffectDetails = ({ adverseEffect }: { adverseEffect: IVNSAdverseEffectEvent }): JSX.Element => {
  return (
    <React.Fragment>
      {keysByType('adverseEffect').map((key, index) => {
        const value = (adverseEffect as { [key: string]: any })?.[key];
        return (
          <StepperHeaderValuePair
            key={`${key}${index}`}
            header={fm(`vns.${keyToHeader(key)}`)}
            value={
              value ? (
                key === 'effects' ? (
                  !value || value?.length < 1 ? undefined : (
                    <React.Fragment>
                      {value.map((item: string, i: number) => (
                        <div key={`adverseEffect${i}`} style={{ fontWeight: 600 }}>
                          {fm(`vns.opts.adverseEffect.${item}`)}
                        </div>
                      ))}
                    </React.Fragment>
                  )
                ) : (
                  value
                )
              ) : (
                '-'
              )
            }
          />
        );
      })}
    </React.Fragment>
  );
};

export const TechnicalIssueDetails = ({ technicalIssue }: { technicalIssue: IVNSTechnicalIssueEvent }): JSX.Element => {
  return (
    <React.Fragment>
      {keysByType('technicalIssue').map((key, index) => {
        const value = (technicalIssue as { [key: string]: any })?.[key];
        return (
          <StepperHeaderValuePair
            key={`${key}${index}`}
            header={fm(`vns.${keyToHeader(key)}`)}
            value={value ? (key === 'issue' ? fm(`vns.opts.technicalIssue.${value}`) : value) : '-'}
          />
        );
      })}
    </React.Fragment>
  );
};

export const DiscontinuationDetails = ({
  discontinuation,
}: {
  discontinuation: IVNSDiscontinuationEvent;
}): JSX.Element => {
  return (
    <React.Fragment>
      {keysByType('discontinuation').map((key, index) => {
        const value = (discontinuation as { [key: string]: any })?.[key];
        return (
          (key !== 'lengthOfRemainingLead' ||
            (key === 'lengthOfRemainingLead' && discontinuation?.type === 'generatorAndPartOfLeadRemoved')) && (
            <StepperHeaderValuePair
              key={`${key}${index}`}
              header={fm(`vns.${keyToHeader(key)}`)}
              value={
                value
                  ? key === 'endDate'
                    ? formatPartialDate(value)
                    : key !== 'additionalInformation'
                      ? fm(`vns.opts.${keyToHeader(key)}.${value}`)
                      : value
                  : '-'
              }
            />
          )
        );
      })}
    </React.Fragment>
  );
};
