import * as React from 'react';
import { formatPartialDate } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import EventStepper from '../../../../../../components/EventStepper';
import { StepperHeaderValuePair } from '../../../../../../components/EventStepper/components';
import { capitalize } from '../../../../../../utility/string';
import { mappedDeviceFields } from '../config';

const ResMedDevices = ({ devices }: IOwnProps): JSX.Element => {
  return (
    <EventStepper
      name="devices"
      formData={{ onChange: () => '', document: {} }}
      stepLabelText={(d: IPapDevice): string => formatPartialDate(d.date)}
      stepContent={(d: IPapDevice): JSX.Element => (
        <React.Fragment>
          {['resMedId', 'deviceModel', 'type', 'mode']
            .filter((field) => Object.values(mappedDeviceFields).includes(field as keyof IPapDevice))
            .map((field, index) => {
              const value = d[field as keyof IPapDevice];
              const k = !['resMedId', 'deviceModel', 'mode'].includes(field) ? `device${capitalize(field)}` : field;
              return (
                <StepperHeaderValuePair
                  key={`${field}${index}`}
                  header={fm(`treatment.papTherapy.${k === 'deviceType' ? 'deviceNumber' : k}`)}
                  value={value}
                />
              );
            })}
        </React.Fragment>
      )}
      addNewTextHeader="treatment.papTherapy.newDevice"
      addNewTextButton="treatment.papTherapy.newDevice"
      previousEventsTextHeader="treatment.papTherapy.devices"
      noPreviousEventsTextHeader="treatment.papTherapy.noDevices"
      editingElements={() => <></>}
      viewing={true}
      deleteMessage="treatment.papTherapy.deleteDevice"
      readOnlyEvents={devices}
    />
  );
};

interface IOwnProps {
  devices?: Array<IPapDevice>;
}

export default ResMedDevices;
