import * as React from 'react';
import { Container, Item } from '../Grid';
import colors from '../../config/theme/colors';
import { MessageDescriptor, useIntl } from 'react-intl';
import { AddCircle } from '@mui/icons-material';
import { styled } from '@mui/system';

const TabContainer = styled('div')({});
const TabsArea = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  margin: '0rem 0rem 0rem 0rem',
  borderBottom: `1px solid ${colors.gray}`,
  padding: '0rem 0rem 0rem 0rem',
  background: 'linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.5) 100%)',
});

const Tabs = styled(Container)({
  // Position tabs borders over TabsArea border
  marginBottom: '-1px',
});

const Tab = styled(Item, {
  shouldForwardProp: (prop) =>
    !['selected', 'empty', 'ended', 'disabled'].includes(typeof prop === 'string' ? prop : ''),
})(({ selected, empty, ended, disabled }: ITabProps) => ({
  padding: '0.7rem 2rem',
  zIndex: 0,
  fontSize: '1.6rem',
  fontWeight: 600,

  // If selected
  background: selected ? colors.white : 'inherit',
  border: selected ? `1px solid ${colors.gray}` : '0',
  borderBottom: selected ? `1px solid ${colors.white}` : '0',

  // If ended or empty
  color: empty ? colors.secondaryText : ended ? colors.quaternary : disabled ? colors.secondaryText : colors.primary,

  // If disabled
  cursor: disabled ? 'default' : 'pointer',

  userSelect: 'none',
}));

interface ITabProps {
  selected?: number;
  empty?: boolean;
  ended?: number;
  disabled?: number;
}

const Content = styled('div')({
  margin: '0rem 0rem 0rem 0rem',
  padding: '2rem 2rem 2rem 2rem',
  marginRight: '0rem',
  borderBottom: `1px solid ${colors.gray}`,
  borderLeft: `1px solid ${colors.gray}`,
  borderRight: `1px solid ${colors.gray}`,
  backgroundColor: colors.white,
});

const AddButtonStyle = styled('div')({
  padding: '0.7rem 2rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  fontWeight: 600,
  color: colors.primary,
  '&:hover': {
    cursor: 'pointer',
    color: colors.primary,
  },
});

const AddButton = ({ fm, text = true, style }: IAddButton): JSX.Element => {
  return (
    <AddButtonStyle style={style}>
      <AddCircle />
      {text && <span style={{ marginLeft: '0.7rem' }}>{fm({ id: `general.add` })}</span>}
    </AddButtonStyle>
  );
};

interface IAddButton {
  fm: (descriptor: MessageDescriptor) => string;
  text?: boolean;
  style?: { [key: string]: string | number | undefined };
}

interface ITabContentContext {
  selected: number;
  changeSelected?: (i: number) => () => void;
}
export const TabContentContext = React.createContext<ITabContentContext>({ selected: 0 });

const TabContent = ({
  children,
  addButton,
  tabEditor,
  tabButtonRow,
  addTabEditor,
  addTabButtonRow,
  startIndex,
  indexSelectionTools,
  customAddTabEditorHandler,
  disableScaling,
}: ITabContent): JSX.Element => {
  const { formatMessage } = useIntl();

  const [selected, setSelected] = React.useState<number>(startIndex ?? 0);

  const selectTab: TSelectTab = (i: number) => (): void => indexSelectionTools?.change(i) || setSelected(i);

  const selectedIndex = indexSelectionTools?.index ?? selected;

  return Array.isArray(children) ? (
    <TabContainer>
      <TabsArea>
        <Tabs>
          {children.concat(addTabEditor ? [addTabEditor] : []).map((c, i, arr) => (
            <React.Fragment key={c.id + i}>
              {!addTabEditor || i !== arr.length - 1 ? (
                <Tab
                  disabled={!addTabEditor || selectedIndex !== arr.length - 1 ? undefined : 1}
                  ended={c.ended ? 1 : undefined}
                  onClick={!addTabEditor || selectedIndex !== arr.length - 1 ? selectTab(i) : () => ''}
                  selected={i === selectedIndex ? 1 : undefined}
                  xs={disableScaling ? undefined : 3}
                >
                  <Container
                    style={{ height: '100%', textAlign: 'center' }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Item style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                      {c.title}
                    </Item>
                  </Container>
                </Tab>
              ) : addTabEditor ? (
                <React.Fragment>
                  <Item xs={true} />
                  <Tab
                    xs={1}
                    onClick={customAddTabEditorHandler ? customAddTabEditorHandler(selectTab) : selectTab(i)}
                    selected={i === selectedIndex ? 1 : undefined}
                    // Keep symbol centered
                    style={{ paddingTop: i === selectedIndex ? '0.6rem' : undefined }}
                  >
                    {addButton && typeof addButton !== 'function' ? (
                      addButton
                    ) : (
                      <AddButton fm={formatMessage} text={false} style={{ padding: 0 }} />
                    )}
                  </Tab>
                </React.Fragment>
              ) : (
                <></>
              )}
            </React.Fragment>
          ))}
          {!addTabEditor && (
            <React.Fragment>
              <Item xs={true} />
              <div
                style={{ display: 'flex' }}
                onClick={typeof addButton === 'function' ? addButton(selectTab) : () => ''}
              >
                {typeof addButton === 'function' ? <AddButton fm={formatMessage} /> : addButton}
              </div>
            </React.Fragment>
          )}
        </Tabs>
      </TabsArea>
      <Content>
        <TabContentContext.Provider value={{ selected: selectedIndex, changeSelected: selectTab }}>
          <React.Fragment key={selected}>
            {tabEditor && (!addTabEditor || selectedIndex !== children.length) ? <>{tabEditor}</> : undefined}
            {!addTabEditor || selectedIndex !== children.length
              ? children[selectedIndex] && children[selectedIndex].content
              : addTabEditor && addTabEditor.content}
            {(!addTabEditor || selectedIndex !== children.length) && tabButtonRow ? <>{tabButtonRow}</> : undefined}
            {addTabEditor && selectedIndex === children.length && addTabButtonRow ? <>{addTabButtonRow}</> : undefined}
          </React.Fragment>
        </TabContentContext.Provider>
      </Content>
    </TabContainer>
  ) : (
    <></>
  );
};

type TChild = {
  id: string;
  title: JSX.Element | string;
  content: JSX.Element;
  count?: number;
  ended?: boolean;
};

type TIndexSelectionTools = { index: number; change: (i: number) => void };
type TSelectTab = (i: number) => () => void;

interface ITabContent {
  /** The tabs */
  children: Array<TChild>;
  /** Button to add more tabs */
  addButton?: JSX.Element | ((selectTab: TSelectTab) => (e?: any) => void);
  /** An element shown in every tab */
  tabEditor?: JSX.Element;
  /** An element shown when the add button is selected, overrides addButton by default */
  addTabEditor?: TChild;
  /** Button row shown below tab content */
  tabButtonRow?: JSX.Element;
  /** Button row shown below tab content when the add button is selected and addTabEditor is defined */
  addTabButtonRow?: JSX.Element;
  /** Default tab index selected */
  startIndex?: number;
  /** Use to override index selection */
  indexSelectionTools?: TIndexSelectionTools;
  /** Use to override addTabEditor actions */
  customAddTabEditorHandler?: (selectTab?: TSelectTab) => (e?: any) => void;
  /** Use to disable set width for tabs */
  disableScaling?: boolean;
}

export default TabContent;
