import * as React from 'react';
import { styled } from '@mui/system';

import InputHandler from '../../../../../components/InputHandler';
import { Container, Item } from '../../../../../components/Grid';

import colors from '../../../../../config/theme/colors';

import { options, tooltips } from '../optionsAndTooltips';
import { fm } from 'Components/FormatMessage';
import { optionFormatter, resolvePath, rootOf } from '../../utils';
import { DisplaySelectedOption } from './index';

const StyledButtonRowContainer = styled('div')({});

const StyledScoreItem = styled('div')({
  fontWeight: 600,
});

export const SubScoreSelectRow = ({
  formData,
  viewing,
  subject,
  altColor,
  rightLeft,
  isNumberField,
}: ISubScoreSelectRow): JSX.Element => {
  const formatVisualAcuity = (value: number | undefined): string | number | undefined => {
    return value && value.toString().length < 2 ? value.toFixed(1) : value;
  };

  return (
    <Container
      alignItems="center"
      style={{
        backgroundColor: altColor ? colors.lightestGray : undefined,
        padding: '2rem',
        margin: '0 -2rem 0 -2rem',
        width: 'auto',
      }}
    >
      <Item style={{ color: colors.tertiaryText, width: '28rem' }}>
        {fm(`neurologicalStatusAndEDSS.${subject}.title`)}
      </Item>
      <Item xs={true}>
        <Container alignItems="center" style={{ minHeight: '3.2rem' }}>
          {rightLeft === false ? (
            <Item xs={5}>
              <StyledButtonRowContainer>
                <DisplaySelectedOption
                  optionInput={
                    <InputHandler
                      type="RadioButtonRow"
                      editing={!viewing}
                      name={subject}
                      formData={formData}
                      options={resolvePath(subject, options)}
                      tooltips={tooltips(subject)}
                      optionFormatter={optionFormatter}
                    />
                  }
                  viewing={viewing}
                  option={resolvePath(subject, options).indexOf(resolvePath(subject, formData.document))}
                  tooltips={tooltips(subject)}
                  options={resolvePath(subject, options)}
                />
              </StyledButtonRowContainer>
            </Item>
          ) : isNumberField ? (
            <React.Fragment>
              {['Right', 'Left'].map((side) => (
                <Item xs={6} key={subject + side}>
                  <StyledScoreItem>
                    {!viewing ? (
                      <InputHandler
                        type="NumberField"
                        editing={!viewing}
                        name={subject + side}
                        formData={{
                          onChange: formData.onChange,
                          document: {
                            [subject + side]:
                              resolvePath(subject + side, formData.document) === 1 && rootOf(subject) === 'visualAcuity'
                                ? resolvePath(subject + side, formData.document).toFixed(1)
                                : resolvePath(subject + side, formData.document),
                          },
                        }}
                        width={7}
                        precision={rootOf(subject) === 'visualAcuity' ? 2 : undefined}
                      />
                    ) : rootOf(subject) === 'visualAcuity' ? (
                      formatVisualAcuity(resolvePath(subject + side, formData.document))
                    ) : (
                      resolvePath(subject + side, formData.document)
                    )}
                  </StyledScoreItem>
                </Item>
              ))}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {['Right', 'Left'].map((side) => (
                <React.Fragment key={subject + side}>
                  <Item xs={5} style={{ position: 'relative', top: '0.4rem' }}>
                    <StyledButtonRowContainer>
                      <DisplaySelectedOption
                        optionInput={
                          <InputHandler
                            type="RadioButtonRow"
                            editing={!viewing}
                            name={subject + side}
                            formData={formData}
                            options={resolvePath(subject, options)}
                            tooltips={tooltips(subject)}
                            optionFormatter={optionFormatter}
                          />
                        }
                        viewing={viewing}
                        option={resolvePath(subject, options).indexOf(resolvePath(subject + side, formData.document))}
                        tooltips={tooltips(subject)}
                        options={resolvePath(subject, options)}
                      />
                    </StyledButtonRowContainer>
                  </Item>
                  <Item xs={1} />
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
        </Container>
      </Item>
    </Container>
  );
};

interface ISubScoreSelectRow {
  formData: IFormData<INeurologicalStatusAndEDSS>;
  viewing: boolean;
  subject: string;
  altColor?: boolean;
  rightLeft?: boolean;
  isNumberField?: boolean;
}
