import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import HistoryRowVerticalItem from '../../../../components/HistoryRowVerticalItem';
import { formatPartialDate, partialDateToValue } from 'neuro-utils';
import colors from '../../../../config/theme/colors';

import { IFieldItem, mergeDocuments, parseValue } from '../../utils/index';
import { surveyCreator, surveyModifyer } from 'Routes/MyService/Document/HistoryRowData/SurveysHistory';
import { useSelector } from 'react-redux';

// Function for combining the value and the date into an element
const ValueDateItem = ({ value, date }: IValueDateItemProps): JSX.Element => (
  <>
    <div>{value ? value : '-'}</div>
    <div style={{ fontSize: '1.2rem', color: colors.darkGray }}>{date ? formatPartialDate(date) : ''}</div>
  </>
);

interface IValueDateItemProps {
  value: JSX.Element | number | string | undefined;
  date?: PartialDate | undefined;
}

// Maps through all fields and turns them into valuedateitems
const Content = ({ array }: IContentProps): JSX.Element => (
  <>
    {array.map(
      (item: IFieldItem) =>
        !item.hide && (
          <React.Fragment key={item.name + item.id}>
            <HistoryRowVerticalItem
              header={<FormattedMessage id={`lifestyle.${item.name}.title`} />}
              value={<ValueDateItem value={parseValue(item)} date={item && item.date ? item.date : undefined} />}
            />
          </React.Fragment>
        ),
    )}
  </>
);

interface IContentProps {
  array: Array<IFieldItem>;
}

const LifestyleHistoryRowData = ({ d, docs }: IOwnProps): JSX.Element => {
  const documents = docs.filter((n) => (partialDateToValue(n.date) || 0) <= (partialDateToValue(d.date) || 0));
  const document = mergeDocuments('history', documents as { [key: string]: any }[]);

  const mysqUserId = useSelector((s: IState) => s.myapp.mysqUserId) || '';
  const users = useSelector((s: IState) => s.session.orgUsers) ?? [];
  const delegates = useSelector((s: IState) => s.patient.delegates) ?? [];

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  return (
    <>
      <HistoryRowVerticalItem
        header={fm('myService.reporter')}
        value={<span style={{ fontWeight: 600 }}>{surveyCreator(d, mysqUserId, users, delegates, fm)}</span>}
      />
      {surveyModifyer(d, mysqUserId, users, delegates, fm) && (
        <HistoryRowVerticalItem
          header={fm('myService.edited')}
          value={<span style={{ fontWeight: 600 }}>{surveyModifyer(d, mysqUserId, users, delegates, fm)}</span>}
        />
      )}
      {document && <Content array={document} />}
    </>
  );
};

interface IOwnProps {
  d: ILifestyle;
  docs: ILifestyle[];
  startEdit: (document: TAnyObject) => (e: React.MouseEvent) => void;
}

export default LifestyleHistoryRowData;
