import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';

import DocumentCreationButton from '../../../../components/DocumentCreationButton';
import { EditingLockedContentContext } from 'Containers/EditingLockedContentContext';

const Echocardiography = ({ documents, startEdit }: IOwnProps): JSX.Element => {
  const { openEditing } = React.useContext(EditingLockedContentContext);

  return (
    <HistorySection
      headerText={<FormattedMessage id={'imaging.echocardiography'} />}
      newButton={
        <DocumentCreationButton
          name="echocardiography"
          text={'imaging.newEchocardiography'}
          onClick={startEdit({} as IEchocardiography, 'echocardiography')}
          width={15}
          height={3}
          fontSize={12}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <>
            <Item xs={4}>
              <FormattedMessage id={'imaging.lvef'} />
            </Item>
            <Item xs={4}>
              <FormattedMessage id={'imaging.fs'} />
            </Item>
          </>
        }
        contentFormat={(d: IEchocardiography): JSX.Element => (
          <>
            <Item xs={4} id={'lvefPercent'}>
              {d.lvef ?? '-'}
            </Item>
            <Item xs={4} id={'fsPercent'}>
              {d.fs ?? '-'}
            </Item>
          </>
        )}
        makeDate
        makeControls
        historyRowControls={(d: IControlProps) => (
          <HistoryRowControls
            document={d}
            startEdit={
              d._lockedFor && d._source !== null
                ? (doc) => () => openEditing(doc, 'imaging.echocardiography')
                : startEdit
            }
          />
        )}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IEchocardiography[];
  startEdit: (document: IEchocardiography, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export default Echocardiography;
