import { Dialog, DialogActions, DialogContent, DialogTitle, Paper, PaperProps, Step, Stepper } from '@mui/material';
import { remove, uniq, filter, path, clone } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import ActionButton from '../../../../../../components/ActionButton';
import InputHandler from '../../../../../../components/InputHandler';
import FormRow from '../../../../../../components/FormRow';
import DeleteDialog from '../../../../../../components/ConfirmationDialog';
import RouteOfAdministration from './components/RouteOfAdministration';
import { Container, Item } from '../../../../../../components/Grid';
import { sortByDateAndSave } from '../../../../../../components/EventStepper/utils';
import {
  Connector,
  StyledStepLabel,
  IconChooser,
  StyledLabelText,
  StyledStepContent,
  StandAloneHeader,
  HeaderControls,
} from '../../../../../../components/EventStepper/components';

import { exists, formatTime, formatPartialDate, nowPartialDate } from 'neuro-utils';
import { fetchWithOptions } from '../../../../../../utility/fetch';
import { parseJWTFromCookie } from '../../../../../../utility/jwtAuthTools';
import { makeLog } from '../../../../../../utility/logger';
import { createID } from '../../../../../../utility/appendIDs';

import colors from '../../../../../../config/theme/colors';
import { dialogActions, dialogCancel, dialogContent, dialogTitle } from '../../../../../../config/theme/componentTheme';

import Default from './Default';
import OnDemand from './OnDemand';
import Custom from './Custom';
import SingleDose from './SingleDose';
import OtherRegimen from './OtherRegimen';
import { getRegimenType, getRegimenOptions } from '../../../../utils';
import {
  RegimenCustom,
  RegimenDefault,
  RegimenOnDemand,
  RegimenOther,
  RegimenSingleDose,
} from './components/RegimenItems';
import { staticRegimenElements } from '../../../config';

const DialogPaper = (props: PaperProps): JSX.Element => <Paper square={true} {...props} />;

const optionFormatter = (n: string | number): JSX.Element => <FormattedMessage id={`medication.opts.${n}`} />;

const DoseType = ({ regimenType, ...other }: IDoseTypeProps & IRegimenContext): JSX.Element => {
  switch (regimenType) {
    case 'default':
      return <Default {...other} />;
    case 'onDemand':
      return <OnDemand {...other} />;
    case 'single-dose':
      return <SingleDose {...other} />;
    case 'custom':
      return <Custom {...other} />;
    case 'other':
      return <OtherRegimen {...other} />;
    default:
      return <React.Fragment />;
  }
};

interface IDoseTypeProps {
  regimenType: IRegimenBasics['regimenType'];
}

class RegimenDialogUnit extends React.Component<IRegimenDialogProps & IStateFromProps, IOwnState> {
  constructor(props: IRegimenDialogProps) {
    super(props);
    this.state = {
      packages: undefined,
      regimen: structuredClone(this.props.formData.document.regimen),
    };
    this.fetchPackages();
  }

  public componentDidUpdate(prevProps: Readonly<IRegimenDialogProps & IStateFromProps>): void {
    if (prevProps.dialogOpen !== this.props.dialogOpen) {
      this.setState({ regimen: structuredClone(this.props.formData.document.regimen) });
    }
  }

  public onChangeUnit = (values: TOnChangeValues): void => {
    const { editIndex } = this.props;
    const name = Object.keys(values)[0];
    const value = values[name];

    // Update current infusion item and then update all infusion
    if (editIndex || editIndex === 0) {
      const allRegimens = structuredClone(this.state.regimen ?? []);
      const thisRegimen = allRegimens[editIndex];
      allRegimens[editIndex] = { ...thisRegimen, [name]: value };
      this.setState({ regimen: allRegimens });
    }
  };

  // Fetch available packages for this medication
  public fetchPackages = (): void => {
    const { medicationName, medicationSubstances, dosageForm, isClinicalStudy } = this.props.formData.document;
    if (medicationName && medicationSubstances && path([0], isClinicalStudy) !== true) {
      const query = JSON.stringify({
        name: medicationName,
        substanceString: medicationSubstances,
      });
      fetchWithOptions(
        `/api/medication/medication?medication=${query}`,
        { neurojwt: parseJWTFromCookie() },
        { method: 'GET' },
      )
        .then(
          (res: Response) => {
            if (res.status === 200) {
              return res.json();
            } else {
              throw { status: res.status, fullResponse: res };
            }
          },
          (error: Error) => {
            throw error;
          },
        )
        .then((res: IMedicationPackage[]) => {
          // Save ATC-code to medication document
          this.props.formData.onChange?.({ atc: res[0].atc });
          // Taking unique packages and filtering those where the dosageForm is matching to the one selected
          this.setState({ packages: filter((med) => med.dosageForm === dosageForm, uniq(res)) });
        })
        .catch((err: Error) => makeLog('Error', err));
    }
  };

  public render(): JSX.Element {
    const { formData, editIndex, platform, dialogOpen, save, cancel } = this.props;
    const { medicationName, medicationSubstances, dosageForm } = this.props.formData.document;
    const { packages = [], regimen = [] } = this.state;
    return (
      <Dialog open={dialogOpen} fullWidth={true} maxWidth="lg" PaperComponent={DialogPaper}>
        <DialogTitle style={dialogTitle}>
          <div style={{ fontWeight: 600, marginBottom: '1rem' }}>
            <FormattedMessage id="medication.regimen" />
          </div>
          <div style={{ color: colors.tertiaryText, fontSize: '1.6rem' }}>
            <FormattedMessage id="medication.regimenDialogInfoText" />
          </div>
          <div style={{ fontWeight: 600, fontSize: '2rem', marginTop: '3rem', color: colors.primaryText }}>
            {(medicationName ? medicationName.toUpperCase() : '') +
              (dosageForm && !formData.document.isClinicalStudy ? ` - ${dosageForm} ` : '') +
              (medicationSubstances ? ` (${medicationSubstances})` : '')}
          </div>
        </DialogTitle>
        <DialogContent style={dialogContent}>
          <FormRow title="general.date" headerWidth={3}>
            <InputHandler
              type="PartialDate"
              editing={true}
              name="date"
              formData={{
                onChange: this.onChangeUnit,
                document: { date: regimen?.[editIndex]?.date },
              }}
              isNotCancellable={true}
            />
          </FormRow>
          <FormRow title="general.time" headerWidth={3}>
            <InputHandler
              type="TimePicker"
              editing={true}
              name="time"
              formData={{
                onChange: this.onChangeUnit,
                document: { time: regimen?.[editIndex]?.time },
              }}
            />
          </FormRow>
          <RouteOfAdministration
            routeOfAdministration={regimen?.[editIndex]?.routeOfAdministration}
            onChange={this.onChangeUnit}
          />
          <FormRow title="medication.regimenTypeSelect" headerWidth={3}>
            <InputHandler
              type="Radio"
              editing={true}
              name="regimenType"
              formData={{
                onChange: this.onChangeUnit,
                document: { regimenType: regimen?.[editIndex]?.regimenType },
              }}
              options={getRegimenOptions(
                formData?.document?.medicationName,
                platform,
                formData?.document?.isClinicalStudy,
              )}
              optionFormatter={optionFormatter}
            />
          </FormRow>

          <DoseType
            regimenType={regimen?.[editIndex]?.regimenType}
            formData={{
              onChange: (v: TOnChangeValues) => {
                const name = Object.keys(v)[0];
                const value = v[name];
                if (Array.isArray(value)) this.setState({ regimen: Array.isArray(value) ? value : undefined });
              },
              document: { ...structuredClone(formData.document), regimen: regimen },
            }}
            editIndex={editIndex}
            packages={packages}
          />

          <FormRow title="medication.regimenDetailsOther" headerWidth={3}>
            <InputHandler
              type="TextArea"
              editing={true}
              name="regimenDetailsOther"
              formData={{
                onChange: this.onChangeUnit,
                document: {
                  regimenDetailsOther: regimen?.[editIndex]?.regimenDetailsOther,
                },
              }}
              width={40}
              placeholder={'medication.regimenDetailsOther'}
            />
          </FormRow>
        </DialogContent>
        <DialogActions style={dialogActions}>
          {cancel && (
            <div
              style={dialogCancel}
              onClick={() => {
                this.setState({ regimen: structuredClone(this.props.formData.document.regimen) });
                cancel();
              }}
            >
              <FormattedMessage id="general.cancel" />
            </div>
          )}
          <ActionButton
            text={'general.accept'}
            onClick={() => {
              this.setState({ regimen: structuredClone(this.props.formData.document.regimen) });
              save(regimen);
            }}
            width={12}
            height={3}
            fontSize={16}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

export interface IRegimenContext {
  formData: IOwnProps['formData'];
  editIndex: number;
  packages: IMedicationPackage[];
  regimen?: IOwnProps['formData']['document']['regimen'];
}

interface IOwnState {
  packages?: IMedicationPackage[];
  regimen?: IOwnProps['formData']['document']['regimen'];
}

interface IStateFromProps {
  platform?: string;
}

interface IState {
  session: {
    platforms?: {
      selected?: string;
    };
  };
}

const mapStateToProps = (state: IState): IStateFromProps => ({
  platform: state.session.platforms?.selected || undefined,
});

interface IRegimenDialogProps {
  formData: IOwnProps['formData'];
  editIndex: number; // Editing an old regimen
  deleteRegimen: (id: number, formData: IOwnProps['formData']) => () => void;
  dialogOpen: boolean;
  save: (r?: IOwnProps['formData']['document']['regimen']) => void;
  cancel?: () => void;
}

const RegimenDialog = connect(mapStateToProps)(RegimenDialogUnit);

const RegimenInformation = ({ document, i }: IRegimenInformationProps): JSX.Element => {
  const regimen = document?.regimen?.[i] as any;
  if (regimen.regimenType === 'onDemand') {
    const regimenOnDemand: IRegimenBasics & IRegimenOnDemand = clone(regimen as IRegimenBasics & IRegimenOnDemand);
    return <RegimenOnDemand r={regimenOnDemand} />;
  } else if (regimen.regimenType === 'custom') {
    const regimenCustom: IRegimenBasics & IRegimenCustom = clone(regimen);
    return <RegimenCustom r={regimenCustom} d={document} />;
  } else if (regimen.regimenType === 'single-dose') {
    const regimenSingleDose: IRegimenBasics & IRegimenSingleDose = clone(regimen);
    return <RegimenSingleDose r={regimenSingleDose} />;
  } else if (regimen.regimenType === 'other') {
    const regimenOther: IRegimenBasics & IRegimenOther = clone(regimen);
    return <RegimenOther r={regimenOther} />;
  } else {
    const regimenDefault = regimen as IRegimenDefault & IRegimenBasics;
    return <RegimenDefault r={regimenDefault} />;
  }
};

interface IRegimenInformationProps {
  document: IMedication;
  i: number;
}

// Push new regimen object to regimen array
const createRegimenItem = (formData: IRegimenDialogProps['formData']) => (): void => {
  const { document, onChange } = formData;
  const allRegimen = document.regimen ? [...document.regimen] : [];
  if (document.startDate && allRegimen.length === 0) {
    allRegimen.unshift({
      id: createID(),
      date: [...document.startDate],
      regimenType:
        getRegimenType(document.medicationName, document.medicationSubstances, document.dosageForm) || undefined,
    } as any);
    onChange && onChange({ regimen: allRegimen });
  } else {
    // Fetch previous regimen type as default for a new regimen
    const previousRegimenType = path([0, 'regimenType'], allRegimen) || undefined;
    allRegimen.unshift({
      id: createID(),
      date: [...nowPartialDate()],
      regimenType: previousRegimenType
        ? previousRegimenType
        : getRegimenType(document.medicationName, document.medicationSubstances, document.dosageForm) || undefined,
    } as any);
    onChange && onChange({ regimen: allRegimen });
  }
};

const deleteRegimen = (id: number, formData: IOwnProps['formData']) => (): void => {
  const allRegimen = formData.document.regimen ? [...formData.document.regimen] : [];
  const newRegimen = remove(id, 1, allRegimen);
  formData.onChange && formData.onChange({ regimen: newRegimen });
};

const RegimenStepper = ({ formData }: IOwnProps): JSX.Element => {
  // Use this variable to know whether new event is being added and render events based on it (true means new event, otherwise number is event index)
  const [editingEvent, setEditingEvent] = React.useState<boolean | number>(false);

  const document = formData.document;

  // Regimen stepper control functions --->
  // Medication regimen stepper index
  const [activeStep, setActiveStep] = React.useState<number>(-1);
  const activateStep = (id: number) => (): void => {
    if (id !== activeStep) setActiveStep(id);
    //else setActiveStep(-1);
  };
  // Close all previous regimen if new ones are added/deleted
  React.useEffect(() => {
    setActiveStep(-1);
  }, [document.regimen?.length]);
  // <---

  const setEditing =
    (i: number) =>
    (e: React.MouseEvent): void => {
      e.stopPropagation();
      setEditingEvent(i);
      activateStep(i - 1)();
    };

  // Where the menu will spawn
  const [anchor, setAnchor] = React.useState<null | { anchor: any; index: number }>(null);
  const toggleMenu =
    (index: number) =>
    (e: React.MouseEvent<any>): void => {
      //e.stopPropagation();
      setAnchor(!anchor ? { anchor: e.currentTarget, index } : null);
    };

  // Set index for deletion
  const [deleteIndex, setDeleteIndex] = React.useState<number | null>(null);
  const openDeleteDialog =
    (index: number) =>
    (e: React.MouseEvent): void => {
      e.stopPropagation();
      setDeleteIndex(index);
      setAnchor(null);
    };

  const deleteCancelCallback = (): void => {
    setDeleteIndex(null);
  };
  const deleteConfirmCallback = (): void => {
    if (deleteIndex || deleteIndex === 0) {
      deleteRegimen(deleteIndex, formData)();
      setDeleteIndex(null);
    }
  };

  const addingNewEvent = editingEvent === true;

  const sortByDateAndSaveRegimen = (r?: typeof document.regimen): void => {
    sortByDateAndSave('regimen', { onChange: formData.onChange, document: { ...document, regimen: r } });
  };

  const save = (r?: any): void => {
    sortByDateAndSaveRegimen(r);
    setEditingEvent(false);
  };
  const cancelAddNew =
    (id = 0) =>
    (): void => {
      editingEvent === true && deleteRegimen(id, formData)();
      setEditingEvent(false);
    };

  const thisRegimen = formData.document.regimen || [];

  // Track events length inside EventStepper so that the forms do not react immediately to the events changes
  const [eventsLength, setEventsLength] = React.useState<number>(thisRegimen?.length);

  React.useEffect(() => {
    if (thisRegimen?.length > (eventsLength || 0)) {
      // Set editing new event if the events length has increased, meaning a new event has been added
      setEditingEvent(true);
    }
    //setEvents(thisEvents);
    setEventsLength(thisRegimen?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thisRegimen?.length]);

  React.useEffect(() => {
    // Add new regimen after medication has been selected
    if (thisRegimen.length === 0 && formData.document.medicationName && !formData.document.isClinicalStudy) {
      createRegimenItem(formData)();
      setEditingEvent(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.document.medicationName]);

  React.useEffect(() => {
    // Update regimen if medication substances are changed
    if (thisRegimen.length > 0 && formData.document.isClinicalStudy) {
      const newRegimen = JSON.parse(JSON.stringify(thisRegimen)).map((r: Regimen) => {
        if (Array.isArray(r.strengths) && r.strengths.length > 0) {
          const newStrengths = r.strengths.map((s) => {
            return { [formData.document.medicationSubstances ?? 's']: s[Object.keys(s)[0]] };
          });
          r.strengths = newStrengths;
        }
        return r;
      });
      formData.onChange && formData.onChange({ regimen: newRegimen });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.document.medicationSubstances]);

  return (
    <>
      {getRegimenType(document?.medicationName) !== 'static' ? ( // No new regimen button if static regimen
        <FormRow title="medication.newRegimen">
          <div style={{ marginBottom: '2rem' }}>
            <ActionButton
              text={'medication.newRegimen'}
              onClick={createRegimenItem(formData)}
              width={20}
              height={5}
              fontSize={18}
            />
          </div>
        </FormRow>
      ) : undefined}
      {getRegimenType(document?.medicationName) !== 'static' && document.regimen && document.regimen.length > 0 ? ( // First and current regimen
        <FormRow title="medication.currentRegimen">
          <React.Fragment>
            <StandAloneHeader>
              <Container alignItems="center" style={{ height: '100%' }}>
                <Item xs={6}>
                  {document?.regimen?.[0].date && formatPartialDate(document?.regimen?.[0].date)}
                  {document?.regimen?.[0].time && ` - ${formatTime(document?.regimen?.[0].time)}`}
                </Item>
                <Item xs={6}>
                  <HeaderControls
                    index={0}
                    setEditing={setEditing}
                    toggleMenu={toggleMenu(0)}
                    anchor={anchor}
                    openDeleteDialog={openDeleteDialog}
                  />
                </Item>
              </Container>
            </StandAloneHeader>
            <div style={{ margin: '2rem 0rem 0rem 5rem' }}>
              <RegimenInformation document={document} i={0} />
            </div>
            <RegimenDialog
              formData={formData}
              editIndex={0}
              deleteRegimen={deleteRegimen}
              dialogOpen={editingEvent === 0 || addingNewEvent}
              save={save}
              cancel={cancelAddNew()}
            />
          </React.Fragment>
        </FormRow>
      ) : getRegimenType(document?.medicationName) === 'static' ? ( // Use specific element for static regimen types, not regimen dialog
        <FormRow>
          {document.medicationName
            ? staticRegimenElements[document.medicationName.toUpperCase()] || undefined
            : undefined}
        </FormRow>
      ) : undefined}
      {document?.regimen && document.regimen.length > 1 ? ( // Previous regimen
        <FormRow title="medication.previousRegimen">
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            style={{ margin: '0', padding: '0' }}
            connector={<Connector />}
          >
            {document.regimen.map((d: IRegimenBasics, i: number) => {
              if (i > 0) {
                return (
                  <Step key={'regimen' + i}>
                    <StyledStepLabel StepIconComponent={IconChooser} onClick={activateStep(i - 1)}>
                      <StyledLabelText>
                        <Container alignItems="center" style={{ height: '100%' }}>
                          <Item xs={6}>
                            {formatPartialDate(d.date)}
                            {d.time && ` - ${formatTime(d.time)}`}
                          </Item>
                          <Item xs={6}>
                            <HeaderControls
                              index={i}
                              setEditing={setEditing}
                              toggleMenu={toggleMenu(i)}
                              anchor={anchor}
                              openDeleteDialog={openDeleteDialog}
                            />
                          </Item>
                        </Container>
                      </StyledLabelText>
                    </StyledStepLabel>
                    <StyledStepContent editing={false}>
                      <RegimenInformation document={document} i={i} />
                      <RegimenDialog
                        formData={formData}
                        editIndex={i}
                        deleteRegimen={deleteRegimen}
                        dialogOpen={editingEvent === i}
                        save={save}
                        cancel={cancelAddNew()}
                      />
                    </StyledStepContent>
                  </Step>
                );
              } else return undefined;
            })}
          </Stepper>
        </FormRow>
      ) : undefined}
      <DeleteDialog
        open={exists(deleteIndex)}
        text={<FormattedMessage id={'general.reallyWantToDelete'} />}
        confirm={{ callback: deleteConfirmCallback }}
        cancel={{ callback: deleteCancelCallback }}
      />
    </>
  );
};

interface IOwnProps {
  formData: IFormData<IMedication>;
}

export default RegimenStepper;
