import * as React from 'react';

import HistoryRowVerticalItem from '../../../../../../components/HistoryRowVerticalItem';

import { formatPartialDate } from 'neuro-utils';
import { newButton } from '../../../../utils/functions';
import { isNil } from 'ramda';
import { IHistoryContext, withHistoryContext } from '../../../../../../containers/FormContextHandler';
import HistoryRow from '../../../../../../components/HistoryRow';

const HuntingtonSymptoms = ({ documents = [], startEdit, fm = (): string => '' }: IHistoryContext): JSX.Element => {
  const huntingtonSymptoms: IHuntingtonSymptoms = documents.filter((d) => d._type === 'huntingtonSymptoms')[0];

  return (
    <HistoryRow
      headerText={fm('diagnosis.huntingtonSymptoms')}
      rowButton={
        startEdit
          ? newButton(
              'huntingtonSymptoms',
              startEdit(huntingtonSymptoms ?? {}, 'huntingtonSymptoms'),
              'general.edit',
              undefined,
              undefined,
              !isNil(((huntingtonSymptoms ?? {}) as IControlProps)._lockedFor),
              undefined,
              undefined,
              huntingtonSymptoms,
            )
          : undefined
      }
    >
      <React.Fragment>
        <HistoryRowVerticalItem
          header={fm('diagnosis.symptomOnsetDate')}
          value={
            huntingtonSymptoms?.symptomOnsetDate ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {formatPartialDate(huntingtonSymptoms?.symptomOnsetDate)}
              </div>
            ) : (
              ' - '
            )
          }
        />
        <HistoryRowVerticalItem
          header={fm('diagnosis.huntingtonSymptom.title')}
          value={
            huntingtonSymptoms?.symptoms ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {fm(`diagnosis.huntingtonSymptom.opts.${huntingtonSymptoms?.symptoms}`)}
              </div>
            ) : (
              ' - '
            )
          }
        />
        <HistoryRowVerticalItem
          header={fm('diagnosis.huntingtonSymptom.additionalInformation')}
          value={
            huntingtonSymptoms?.additionalInformation ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {huntingtonSymptoms?.additionalInformation}
              </div>
            ) : (
              ' - '
            )
          }
        />
      </React.Fragment>
    </HistoryRow>
  );
};

export default withHistoryContext(HuntingtonSymptoms);
