import * as React from 'react';
import CollapseElem from 'Components/Collapse';
import { Container, Item } from 'Components/Grid';
import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import { styled } from '@mui/system';
import colors from '../../../../../config/theme/colors';
import { capitalize } from '../../../../../utility/string';
import TreatmentsByProtocol from './TreatmentsByProtocol';
import TreatmentsBySession from './TreatmentsBySession';
import { TTDCSEvent } from 'Utility/ninmtUtil';
import TitleValueItem from 'Components/TitleValueItem';

const StyledEventContainer = styled(Container)({
  width: 'auto !important',
  margin: '0 -2.5rem 3rem -2.5rem',
  padding: '0 2.5rem',
});

const StyledBarHeaderContainer = styled(StyledEventContainer)({
  backgroundColor: `${colors.defaultBackground}`,
  height: '5rem',
});

const StyledBarHeader = styled('div')({
  fontWeight: 600,
  marginBottom: '-0.3rem',
});

export const StyledViewSelector = styled('span', {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active }: { active: boolean }) => ({
  color: colors.primary,
  userSelect: 'none' as const,
  textDecoration: active ? 'none' : 'underline',
  fontSize: '1.6rem',
  fontWeight: active ? 'bold' : 'normal',
  cursor: active ? 'default' : 'pointer',
  marginLeft: '2rem',
}));

const HeaderBar = ({
  date,
  secondary,
  eventType,
}: {
  date?: PartialDate;
  secondary?: boolean;
  eventType?: string;
}): JSX.Element => (
  <StyledBarHeaderContainer
    alignItems="center"
    style={{ backgroundColor: secondary ? colors.highlight.light : undefined }}
  >
    <Item>
      <StyledBarHeader>
        <span style={{ marginRight: '1rem' }}>{formatPartialDate(date)}</span>
        <span>{eventType ? fm(`tdcs.opts.eventTypes.${eventType}`) : ''}</span>
      </StyledBarHeader>
    </Item>
  </StyledBarHeaderContainer>
);

const Event = ({
  event,
  type,
  eventsBeyondThisDoc,
  secondary,
}: {
  event: TTDCSEvent;
  type: string;
  eventsBeyondThisDoc: Array<TTDCSEvent>;
  secondary?: boolean;
}): JSX.Element => {
  const unusedSes = !('type' in event) && secondary;
  return (
    <React.Fragment>
      <HeaderBar
        date={event.date}
        secondary={unusedSes}
        eventType={'type' in event ? event.type : unusedSes ? 'unusedSession' : 'session'}
      />
      {unusedSes ? (
        <StyledEventContainer>
          {type === 'sessions' &&
            ['reason', 'reasonDetails'].map((key, ind) => {
              const e = event as { [key: string]: any };
              let value = e?.[key];
              value = value ? (
                <React.Fragment>
                  {fm(`rtms.opts.unusedSession${capitalize(key)}.${value}`)}
                  {key === 'reasonDetails' &&
                    value === 'other' &&
                    e?.reasonDetailsOther &&
                    e?.reasonDetailsOther.length > 0 &&
                    `: ${e.reasonDetailsOther}`}
                </React.Fragment>
              ) : (
                '-'
              );
              return (
                <Item xs={3} key={ind}>
                  <TitleValueItem title={`rtms.unusedSession${capitalize(key)}`} value={value ?? '-'} />
                </Item>
              );
            })}
        </StyledEventContainer>
      ) : secondary ? (
        <StyledEventContainer>
          {type === 'sessions' && (
            <Item xs={9}>
              <TitleValueItem title={'tdcs.details'} value={'details' in event ? event.details : '-'} />
            </Item>
          )}
        </StyledEventContainer>
      ) : (
        type !== 'sessions' && (
          <StyledEventContainer>
            {(['complications', 'adverseEffects'].includes(type)
              ? ['complications', 'adverseEffects', 'additionalInformation'].filter((k) =>
                  Object.keys(event).includes(k),
                )
              : Object.keys(event)
            )
              .filter((key) => !['id', 'date', 'subjectOfTreatment', 'backupSubjectOfTreatment'].includes(key))
              .map((key, ind) => {
                let value = (event as { [key: string]: any })?.[key];
                if (Array.isArray(value)) {
                  value =
                    value?.length < 1 ? undefined : (
                      <React.Fragment>
                        {value.map((item: string, i: number) => (
                          <div key={`${key}${i}`} style={{ fontWeight: 600 }}>
                            {fm(`tdcs.opts.${key}.${item}`)}
                          </div>
                        ))}
                      </React.Fragment>
                    );
                }
                if (typeof value === 'string' && !['device', 'additionalInformation'].includes(key)) {
                  value = fm(`tdcs.opts.${key}.${value}`);
                }
                return (
                  <Item xs={3} key={ind}>
                    <TitleValueItem title={`tdcs.${key}`} value={value ?? '-'} />
                  </Item>
                );
              })}
          </StyledEventContainer>
        )
      )}
      {!secondary && type === 'sessions' && (
        <React.Fragment>
          {(event as ITDCSSession).subjectOfTreatment && (
            <StyledEventContainer style={{ marginTop: '-4.5rem' }}>
              <Item xs={12}>
                <TreatmentsBySession event={event} eventsBeyondThisDoc={eventsBeyondThisDoc} />
              </Item>
            </StyledEventContainer>
          )}
          <StyledEventContainer style={{ marginTop: '-4.5rem' }}>
            <Item xs={3}>
              <TitleValueItem
                title="tdcs.additionalInformation"
                value={'additionalInformation' in event ? event.additionalInformation : '-'}
              />
            </Item>
          </StyledEventContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export const EventListing = ({
  type,
  events,
  secondaryEvents,
  eventsBeyondThisDoc,
}: {
  type: string;
  events: Array<TTDCSEvent>;
  secondaryEvents?: Array<TTDCSEvent>;
  eventsBeyondThisDoc?: Array<TTDCSEvent>;
}): JSX.Element => {
  let allEventsSorted: Array<TTDCSEvent> = [];
  if (type === 'sessions') {
    allEventsSorted = (
      [...events, ...(secondaryEvents ?? [])] as Array<ITDCSSession | ITDCSUnusedSession | ITDCSOtherEvent>
    )
      .sort((s1, s2) => (s2?.createDate ?? 0) - (s1?.createDate ?? 0))
      .sort((s1, s2) => sortPartialDate(s2.date, s1.date));
  }
  const allEvents = allEventsSorted.length > 0 ? allEventsSorted : events;
  return (
    <React.Fragment>
      {Array.isArray(allEvents) &&
        allEvents.length > 0 &&
        (type !== 'sessions' ||
          (events.length === 0 && Array.isArray(secondaryEvents) && secondaryEvents.length > 0) ||
          (Array.isArray(eventsBeyondThisDoc) && eventsBeyondThisDoc.length > 0)) && (
          <React.Fragment>
            <Event
              event={allEvents[0]}
              type={type}
              eventsBeyondThisDoc={eventsBeyondThisDoc ?? []}
              secondary={secondaryEvents?.includes(allEvents[0])}
            />
            {allEvents.length > 1 && (
              <CollapseElem
                localeIDs={{ showMessage: `tdcs.show${capitalize(type)}`, hideMessage: `tdcs.hide${capitalize(type)}` }}
                amount={allEvents.length - 1}
              >
                {allEvents.slice(1).map((event, index) => (
                  <Event
                    key={index}
                    event={event}
                    type={type}
                    eventsBeyondThisDoc={eventsBeyondThisDoc ?? []}
                    secondary={secondaryEvents?.includes(event)}
                  />
                ))}
              </CollapseElem>
            )}
          </React.Fragment>
        )}
    </React.Fragment>
  );
};

export const EventTable = ({
  type,
  events,
  secondaryEvents,
  eventsBeyondThisDoc,
}: {
  type: string;
  events: Array<TTDCSEvent>;
  secondaryEvents: Array<TTDCSEvent>;
  eventsBeyondThisDoc: Array<TTDCSEvent>;
}): JSX.Element => {
  switch (type) {
    case 'sessions': {
      return (
        <TreatmentsByProtocol
          events={events}
          secondaryEvents={secondaryEvents}
          eventsBeyondThisDoc={eventsBeyondThisDoc}
        />
      );
    }
    // Implement more tables if needed
    default: {
      return <React.Fragment />;
    }
  }
};
