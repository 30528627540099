import * as React from 'react';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import { deviceTherapyControlVisitInquiry, IDeviceTherapyControlVisitInquiry, isLocaleKey } from 'neuro-schemas';
import { formatPartialDate } from 'neuro-utils';
import { MyServiceContext } from '../..';
import colors from '../../../../../config/theme/colors';
import TableContent from './components/TableContent';

const deviceTherapyControlVisitInquiryFields: Array<keyof IDeviceTherapyControlVisitInquiry> = [
  'tirednessOrNearMissWhileDriving',
  'morningHeadaches',
  'daytimeSleepiness',
  'involuntarySleepAttacks',
  'moodDisorders',
  'heartburn',
  'urgeToMoveLegsInEvening',
  'dryMouthDuringNight',
  'snoring',
  'awakeningDueToSuffocationSensation',
  'witnessedNocturnalApneas',
  'nocturnalSweating',
  'nocturia',
  'increasedAlertnessDuringVentilatorTreatment',
  'improvedSleepDuringVentilatorTreatment',
  'adverseEventsDuringVentilatorTherapy',
  'nasalDryness',
  'nasalStuffiness',
  'runningNose',
  'nasalBleeding',
  'airLeaksTowardsEyes',
  'mouthLeakage',
  'nasalBridgeIrritation',
  'lowPressureSensation',
  'highPressureSensation',
  'difficultyBreathingOutward',
  'disturbedSleep',
  'otherAdverseEvents',
  'noVentilatorUseReason',
];

const deviceTherapyControlVisitInquiryConditionalFields: Array<keyof IDeviceTherapyControlVisitInquiry> = [
  'nasalDryness',
  'nasalStuffiness',
  'runningNose',
  'nasalBleeding',
  'airLeaksTowardsEyes',
  'mouthLeakage',
  'nasalBridgeIrritation',
  'lowPressureSensation',
  'highPressureSensation',
  'difficultyBreathingOutward',
  'disturbedSleep',
  'otherAdverseEvents',
];

export const DeviceTherapyControlVisitInquiry = (): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, locale, viewing, setViewingObj, setEditingObj } = myServContext;

  const deviceTherapyControlVisitInquiryData = (editing?.data || viewing?.data) as IDeviceTherapyControlVisitInquiry &
    IControlProps;
  const deviceTherapyControlVisitInquiryLocales = deviceTherapyControlVisitInquiry.localizations;
  const useLocale = isLocaleKey(locale) ? locale : 'fi';

  const onChangeSurveyForm = (values: TOnChangeValues): void => {
    const field = Object.keys(values)[0];
    const value = Object.values(values)[0];
    const nullConditionals: { [key: string]: TFieldValue } = {};
    if (field === 'adverseEventsDuringVentilatorTherapy' && value === 'no') {
      deviceTherapyControlVisitInquiryConditionalFields.forEach((f) => {
        nullConditionals[f] = undefined;
      });
    }
    setEditingData?.({
      ...deviceTherapyControlVisitInquiryData,
      [field]: value,
      ...nullConditionals,
    });
  };
  return (
    <>
      {!viewing ? (
        <div style={{ width: '80%', color: colors.tertiaryText, marginBottom: '2rem' }}>
          <p>{deviceTherapyControlVisitInquiryLocales[useLocale]['surveyInfo']}</p>
          <p>{deviceTherapyControlVisitInquiryLocales[useLocale]['surveyFillingInfo']}</p>
        </div>
      ) : null}
      <BlockWrapper
        title={viewing ? formatPartialDate(deviceTherapyControlVisitInquiryData.date) : undefined}
        buttons={
          viewing
            ? [
                {
                  title: 'general.edit',
                  onClick: () => {
                    setViewingObj(null);
                    setEditingObj({
                      type: 'deviceTherapyControlVisitInquiry',
                      data: deviceTherapyControlVisitInquiryData,
                    });
                  },
                },
              ]
            : undefined
        }
      >
        {!viewing && (
          <FormRow title={'general.date'} headerWidth={3.4}>
            <InputHandler
              name="date"
              type="PartialDate"
              dateDefault="now"
              formData={{
                document: { date: deviceTherapyControlVisitInquiryData?.date },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
        )}
        <TableContent width={70}>
          {
            deviceTherapyControlVisitInquiryFields
              .map((field) => {
                if (
                  deviceTherapyControlVisitInquiryConditionalFields.includes(field) &&
                  deviceTherapyControlVisitInquiryData?.adverseEventsDuringVentilatorTherapy !== 'yes'
                ) {
                  return undefined;
                }
                const textAreaFields = ['otherAdverseEvents', 'noVentilatorUseReason'];
                return {
                  style: { paddingBottom: viewing ? '0.5rem' : 0 },
                  title: {
                    content: deviceTherapyControlVisitInquiryLocales[useLocale][field],
                    style: { paddingLeft: '0.5rem', paddingRight: '1rem' },
                  },
                  content: {
                    content: (
                      <InputHandler
                        type={textAreaFields.includes(field) ? 'TextArea' : 'Radio'}
                        name={field}
                        editing={!!editing}
                        formData={{
                          onChange: onChangeSurveyForm,
                          document: { [field]: deviceTherapyControlVisitInquiryData?.[field] },
                        }}
                        options={textAreaFields.includes(field) ? undefined : ['yes', 'no']}
                        optionFormatter={
                          textAreaFields.includes(field)
                            ? undefined
                            : (id: string | number) => deviceTherapyControlVisitInquiryLocales[useLocale][`opts.${id}`]
                        }
                        horizontal={textAreaFields.includes(field) ? undefined : true}
                      />
                    ),
                    xs: 4.5,
                    style: {
                      paddingTop: '0.3rem',
                      paddingLeft: '0.5rem',
                      paddingBottom: textAreaFields.includes(field) ? undefined : '1.5rem',
                      overflow: 'visible',
                    },
                  },
                };
              })
              .filter((f) => f) as []
          }
        </TableContent>
      </BlockWrapper>
    </>
  );
};
