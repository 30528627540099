import * as React from 'react';
import FormRow from '../../../../../../../components/FormRow';
import InputHandler from '../../../../../../../components/InputHandler';
import { FormContext } from '../../../../../../../containers/FormContextHandler';

const Doses = ({ formData, editIndex }: IDosesProps): JSX.Element => {
  const formContext = React.useContext(FormContext);
  const fm = formContext?.fm;

  const { document, onChange } = formData as IFormData<IMedication>;

  const changeDoses = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    const regimens = document.regimen ? [...document.regimen] : [];
    if (regimens?.[editIndex]) {
      regimens[editIndex] = { ...regimens[editIndex], doses: value } as IRegimenBasics & IRegimenDefault;
      onChange?.({ regimen: regimens });
    }
  };

  const changeInterval = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    const regimens = document.regimen ? [...document.regimen] : [];
    if (regimens?.[editIndex]) {
      regimens[editIndex] = { ...regimens[editIndex], interval: value } as IRegimenBasics & IRegimenDefault;
      onChange?.({ regimen: regimens });
    }
  };

  const changeRepeatNumber = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    const regimens = document.regimen ? [...document.regimen] : [];
    if (regimens?.[editIndex]) {
      regimens[editIndex] = { ...regimens[editIndex], repeatNumber: value } as IRegimenBasics & IRegimenDefault;
      onChange?.({ regimen: regimens });
    }
  };

  return (
    <React.Fragment>
      <FormRow title="medication.doses.title" headerWidth={3}>
        <InputHandler
          type="NumberField"
          name="doses"
          formData={{
            document: { doses: ((document as IMedication).regimen?.[editIndex] as IRegimenDefault).doses },
            onChange: changeDoses,
          }}
          editing={true}
          placeholder="medication.doses.title"
          precision={2}
        />
      </FormRow>
      <FormRow title="medication.doses.repeatNumber" headerWidth={3}>
        <InputHandler
          type="NumberField"
          name="repeatNumber"
          editing={true}
          width={19}
          formData={{
            document: {
              repeatNumber: ((document as IMedication).regimen?.[editIndex] as IRegimenDefault).repeatNumber,
            },
            onChange: changeRepeatNumber,
          }}
          placeholder={'medication.doses.enterNumber'}
        />
      </FormRow>
      <FormRow title="medication.interval" headerWidth={3}>
        <InputHandler
          type="Select"
          name="interval"
          formData={{
            document: { interval: ((document as IMedication).regimen?.[editIndex] as IRegimenDefault).interval },
            onChange: changeInterval,
          }}
          options={['hourly', 'daily', 'weekly', 'monthly', 'yearly']}
          optionFormatter={(name: string | number): string => (fm ? fm(`medication.opts.${name}`) : '')}
          editing={true}
          placeholder="medication.chooseTimePeriod"
        />
      </FormRow>
    </React.Fragment>
  );
};

interface IDosesProps {
  editIndex: number;
  formData: IFormData<IMedication>;
}

export default Doses;
