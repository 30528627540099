import * as React from 'react';
import { sortPartialDate, formatPartialDate, isPartialDate } from 'neuro-utils';

import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { fm } from 'Components/FormatMessage';
import { headerText } from 'Utility/ninmtUtil';
import { DescriptionText } from 'Components/DashboardTile/TileContentMaker/components/common/commonComponents';
import { FormattedMessage } from 'react-intl';

const RTMSData = ({ document }: { document: IRTMS }): JSX.Element => {
  const { sessions, unusedSessions } = document;

  const allEvents = [...(sessions ?? []), ...(unusedSessions ?? [])];

  const getEventType = (e: (typeof allEvents)[0]): 'session' | 'unusedSession' | undefined => {
    if (sessions?.some((s) => s.id === e.id)) return 'session';
    if (unusedSessions?.some((s) => s.id === e.id)) return 'unusedSession';
    return undefined;
  };

  const sessionCount = Array.isArray(document?.sessions) ? document?.sessions.length : '-';

  return (
    <React.Fragment>
      <TileContentMaker
        type="bigHeaderInfo"
        bigHeaderText={
          document?.type
            ? fm(`rtms.opts.${document?.type}`)
            : !document.hasEnded
              ? fm('rtms.rtmsOngoing')
              : fm('rtms.rtmsHasEnded')
        }
        bigHeaderValue={headerText(document)}
        data={
          document.sessions && Array.isArray(sessions) && document?.hasEnded
            ? [
                {
                  title: fm('rtms.sessionsAndProtocols'),
                  value: <div style={{ whiteSpace: 'pre-line' }}>{sessionCount}</div>,
                  key: 'sessions',
                },
              ]
            : []
        }
      />
      {!document?.hasEnded && (
        <TileContentMaker
          type="specificList"
          itemsCanFitAmount={5}
          data={allEvents.map((e) => ({
            date: isPartialDate(e.date) ? formatPartialDate(e.date) : '',
            title: getEventType(e) ? fm(`rtms.${getEventType(e)}`) : fm('rtms.session'),
            value: null,
            key: e.id,
          }))}
        />
      )}
    </React.Fragment>
  );
};

const RTMSDash = ({ documents }: IOwnProps): JSX.Element | undefined => {
  const sortedArr = documents
    ?.filter((d) => d._type === 'rtms')
    .sort((n1, n2) => n1._cdate - n2._cdate)
    .sort((n1, n2) => sortPartialDate(n2.date, n1.date));

  const sortedNinmtTreatmentPeriod = documents
    ?.filter((d) => d._type === 'ninmtTreatmentPeriod')
    .sort((n1, n2) => n1._cdate - n2._cdate)
    .sort((n1, n2) => sortPartialDate(n2.date, n1.date));

  const latestRTMS = sortedArr.find((d) => !d.hasEnded) ?? sortedArr?.[0];

  return (
    <React.Fragment>
      {sortedArr?.length > 0 && sortPartialDate(sortedNinmtTreatmentPeriod?.[0]?.date, latestRTMS.date) === 1 ? (
        <DescriptionText>
          <FormattedMessage id="rtms.noActiveTreatmentPeriod" />
        </DescriptionText>
      ) : (
        <RTMSData document={latestRTMS} />
      )}
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: Array<IRTMS>;
}

export default RTMSDash;
