import { path } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import HistoryRow from '../../../../components/HistoryRow';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import colors from '../../../../config/theme/colors';
import GeneratorSetting from '../Form/GeneratorSetting';

import LeadClicker from '../Form/LeadClicker';
import { defaultUnits, leadConfiguration } from '../config';
import CollapseElem from '../../../../components/Collapse';
import { getLatestDiscontinuation, hasLfpRegistration } from '../../utils';
import { StyledTabArea, StyledTab } from '../../components';
import { exists, formatPartialDate } from 'neuro-utils';
import PlatformConditional from '../../../../components/PlatformConditional';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';
import { Container, Item } from 'Components/Grid';

const StyledRowContainer = styled((props) => <Container justifyContent="space-around" {...props} />)<
  React.ComponentProps<typeof Container>
>({});

const StyledRowItem = styled((props) => <Item {...props} />)<React.ComponentProps<typeof Item>>({});

const StyledHistory = styled('div')({});

const StyledHeader = styled('div')({
  color: colors.primary,
  fontSize: '1.65rem',
  marginBottom: '1rem',
});

const StyledSubHeader = styled('div')({
  color: colors.primary,
  fontSize: '1.65rem',
  marginBottom: '0.5rem',
});

const StyledTitle = styled('div')({
  color: colors.primary,
  fontSize: '2.2rem',
  marginBottom: '1rem',
  fontWeight: 600,
});

const StyledTextValue = styled('div')({
  fontWeight: 600,
  fontSize: '1.45rem',
  marginTop: '-0.5rem',
  marginBottom: '2rem',
});

const StyledValue = styled('div')({
  fontWeight: 600,
  color: colors.primaryText,
  fontSize: '1.55rem',
  marginBottom: '0.5rem',
  textAlign: 'center',
});

const StyledSide = styled('div')({
  color: colors.primary,
  fontSize: '1.65rem',
  fontWeight: 600,
  marginBottom: '1rem',
  textAlign: 'center',
});

const StyledRowContainerBar = styled(Container)({
  backgroundColor: `${colors.defaultBackground}`,
  width: 'auto !important',
  height: '5rem',
  margin: '0 -2.5rem',
  padding: '0 2.5rem',
});

const StyledBarHeader = styled('div')({
  fontWeight: 600,
  marginBottom: '-0.3rem',
});

const StyledRowContainerEvent = styled(Container)({
  width: 'auto !important',
  margin: '0 -2.5rem',
  padding: '2rem 2.5rem',
});

const StyledColorBox = styled('div')({
  minWidth: '4rem',
});

// Set anchor for scroll and start editing
const startEditAndSetAnchor = (startEdit: (e: any) => void, setAnchor: () => void) => (): void => {
  setAnchor();
  startEdit(undefined);
};

// Remove anchor and call startEdit
const startEditAndRemoveAnchor =
  (startEdit: IOwnProps['startEdit'], setAnchor: (n?: string) => () => void) =>
  (d: IDBS) =>
  (e: React.MouseEvent): void => {
    setAnchor(undefined)();
    startEdit(d)(e);
  };

const EditButton = ({
  startEdit,
  setAnchor,
  d,
  anchorName,
}: {
  startEdit: IOwnProps['startEdit'];
  setAnchor: IOwnProps['setAnchor'];
  d: IOwnProps['d'];
  anchorName: string;
}): JSX.Element => (
  <StyledRowContainer justifyContent="flex-end">
    <DocumentCreationButton
      name="dbs"
      document={d}
      text="general.edit"
      onClick={startEditAndSetAnchor(startEdit(d), setAnchor(anchorName))}
      width={13}
      height={3}
      fontSize={16}
    />

    <StyledRowItem xs={2}></StyledRowItem>
  </StyledRowContainer>
);

const generatorType = (generator: string, platform: string): JSX.Element | null => {
  if (leadConfiguration[platform][generator]?.rechargeable === true && generator !== 'Other rechargeable') {
    return <FormattedMessage id="dbs.rechargeable" />;
  } else if (leadConfiguration[platform][generator]?.rechargeable === false && generator !== 'Other battery')
    return <FormattedMessage id="dbs.primaryCell" />;
  else return null;
};

const leadFormatter = (name: string): string | JSX.Element =>
  name === 'Medtronic SenSight (0.5 mm Spaced Lead Assembly)' ? (
    <FormattedMessage id="dbs.medtronicSenSightLead0.5mm" />
  ) : name === 'Medtronic SenSight (1.5 mm Spaced Lead Assembly)' ? (
    <FormattedMessage id="dbs.medtronicSenSightLead1.5mm" />
  ) : (
    name
  );

const settingsDescription = (lfpRegistration?: boolean): JSX.Element => (
  <React.Fragment>
    <StyledRowContainer justifyContent="flex-start" style={{ marginTop: '2rem' }}>
      <StyledRowItem xs={2}>
        <StyledColorBox style={{ backgroundColor: '#D3D3D3' }}>&nbsp;</StyledColorBox>
      </StyledRowItem>
      <StyledRowItem style={{ marginLeft: '2rem' }}>
        <FormattedMessage id="dbs.notInUse" />
      </StyledRowItem>
    </StyledRowContainer>
    <StyledRowContainer justifyContent="flex-start" style={{ marginTop: '2rem' }}>
      <StyledRowItem xs={2}>
        <StyledColorBox style={{ backgroundColor: '#f71939' }}>&nbsp;</StyledColorBox>
      </StyledRowItem>
      <StyledRowItem style={{ marginLeft: '2rem' }}>
        <FormattedMessage id="dbs.positive" />
      </StyledRowItem>
    </StyledRowContainer>
    <StyledRowContainer justifyContent="flex-start" style={{ marginTop: '2rem' }}>
      <StyledRowItem xs={2}>
        <StyledColorBox style={{ backgroundColor: colors.primaryText }}>&nbsp;</StyledColorBox>
      </StyledRowItem>
      <StyledRowItem style={{ marginLeft: '2rem' }}>
        <FormattedMessage id="dbs.negative" />
      </StyledRowItem>
    </StyledRowContainer>
    {lfpRegistration === true && (
      <StyledRowContainer justifyContent="flex-start" style={{ marginTop: '2rem' }}>
        <StyledRowItem xs={2}>
          <StyledColorBox>
            <div style={{ display: 'flex', justifyContent: 'center', fontWeight: 600 }}>R</div>
          </StyledColorBox>
        </StyledRowItem>
        <StyledRowItem style={{ marginLeft: '2rem' }}>
          <FormattedMessage id="dbs.registering" />
        </StyledRowItem>
      </StyledRowContainer>
    )}
  </React.Fragment>
);

const optionsEvents = { showMessage: 'dbs.showPastEvents', hideMessage: 'dbs.hidePastEvents' };
const optionsIssues = { showMessage: 'dbs.showPastIssues', hideMessage: 'dbs.hidePastIssues' };
const optionsDiscontinuations = {
  showMessage: 'dbs.showPastDiscontinuations',
  hideMessage: 'dbs.hidePastDiscontinuations',
};

const DbsEventInfoRow = ({ title, content }: { title: string; content?: JSX.Element }): JSX.Element => (
  <StyledRowContainer style={{ marginBottom: '2rem' }}>
    <StyledRowItem xs={3}>
      <FormattedMessage id={title} />
    </StyledRowItem>
    <StyledRowItem xs={9} style={{ fontWeight: 600 }}>
      {content || '-'}
    </StyledRowItem>
  </StyledRowContainer>
);

const DBSPostoperativeComplicationEvent = ({
  e,
  i,
}: {
  e: IDBSPostoperativeComplicationEvent;
  i: number;
}): JSX.Element => (
  <StyledRowContainerEvent key={i + 'dbsPostoperativeComplication'}>
    <StyledRowItem xs={2}>
      <div style={{ fontWeight: 600 }}>{formatPartialDate(e.date)}</div>
    </StyledRowItem>
    <StyledRowItem xs={10}>
      <DbsEventInfoRow
        title="dbs.complications"
        content={
          e.complications && e.complications.length > 0 ? (
            <React.Fragment>
              {e.complications.map((item: string, index: number) => (
                <div key={index + 'complicationsHistoryRowData'}>
                  <FormattedMessage id={`dbs.opts.${item}`} />
                </div>
              ))}
            </React.Fragment>
          ) : undefined
        }
      />
      <DbsEventInfoRow
        title="dbs.postoperativeComplicationDetails"
        content={e.additionalInformation ? <span>{e.additionalInformation}</span> : undefined}
      />
    </StyledRowItem>
  </StyledRowContainerEvent>
);

const DBSPostoperativeComplications = ({ d, startEdit, setAnchor }: IOwnProps): JSX.Element => {
  const events = d.postoperativeComplications || [];
  const firstEvents = events.slice(0, 2);
  const restEvents = events.slice(2);

  return (
    <React.Fragment>
      <StyledRowContainer style={{ margin: '3rem 0 0 0' }}>
        <StyledRowItem xs={9}>
          <StyledTitle>
            <FormattedMessage id="dbs.postoperativeComplications" />
          </StyledTitle>
        </StyledRowItem>
        <StyledRowItem xs={3} id="postoperativeComplication">
          <EditButton d={d} startEdit={startEdit} setAnchor={setAnchor} anchorName="postoperativeComplications" />
        </StyledRowItem>
      </StyledRowContainer>
      {events &&
        firstEvents.map((e: IDBSPostoperativeComplicationEvent, i) => (
          <DBSPostoperativeComplicationEvent e={e} i={i} key={e.id} />
        ))}
      {events.length > 2 && (
        <CollapseElem localeIDs={optionsEvents} amount={restEvents.length}>
          {restEvents.map((e: IDBSPostoperativeComplicationEvent, i) => (
            <DBSPostoperativeComplicationEvent e={e} i={i} key={e.id} />
          ))}
        </CollapseElem>
      )}
    </React.Fragment>
  );
};

const DBSEvent = ({ e, i }: { e: IDBSEvent; i: number }): JSX.Element => (
  <StyledRowContainerEvent key={i + 'dbsEvent'}>
    <StyledRowItem xs={2}>
      <div style={{ fontWeight: 600 }}>{formatPartialDate(e.date)}</div>
    </StyledRowItem>
    <StyledRowItem xs={10}>
      <DbsEventInfoRow
        title={'dbs.eventTypeShort'}
        content={
          e.eventTypes && e.eventTypes.length > 0 ? (
            <>
              {e.eventTypes.map((item: string, index: number) => (
                <div key={index + 'eventTypesHistoryRowData'}>
                  <FormattedMessage id={`dbs.opts.${item}`} />
                  {item === 'other' && e.otherDetails ? ': ' + e.otherDetails : ''}
                </div>
              ))}
            </>
          ) : undefined
        }
      />
      <DbsEventInfoRow title="dbs.eventDetails" content={e.eventDetails ? <span>{e.eventDetails}</span> : undefined} />
    </StyledRowItem>
  </StyledRowContainerEvent>
);

const DBSEvents = ({ d, startEdit, setAnchor }: IOwnProps): JSX.Element => {
  const events = d.dbsEvents || [];
  const firstEvents = events.slice(0, 2);
  const restEvents = events.slice(2);

  return (
    <React.Fragment>
      <StyledRowContainer style={{ margin: '3rem 0 0 0' }}>
        <StyledRowItem xs={9}>
          <StyledTitle>
            <FormattedMessage id="dbs.events" />
          </StyledTitle>
        </StyledRowItem>
        <StyledRowItem xs={3} id={'dbsEvent'}>
          <EditButton d={d} startEdit={startEdit} setAnchor={setAnchor} anchorName="dbsEvents" />
        </StyledRowItem>
      </StyledRowContainer>
      {events && firstEvents.map((e: IDBSEvent, i) => <DBSEvent e={e} i={i} key={e.id} />)}
      {events.length > 2 && (
        <CollapseElem localeIDs={optionsEvents} amount={restEvents.length}>
          {restEvents.map((e: IDBSEvent, i) => (
            <DBSEvent e={e} i={i} key={e.id} />
          ))}
        </CollapseElem>
      )}
    </React.Fragment>
  );
};

const TechnicalIssuesEvent = ({ e, i }: { e: IDBSTechnicalIssue; i: number }): JSX.Element => (
  <StyledRowContainerEvent key={(e.issueType || 'issueType') + i}>
    <StyledRowItem xs={2}>
      <div style={{ fontWeight: 600 }}>{formatPartialDate(e.date)}</div>
    </StyledRowItem>
    <StyledRowItem xs={10}>
      <DbsEventInfoRow
        title="dbs.issueTypeShort"
        content={e.issueType ? <FormattedMessage id={'dbs.opts.' + e.issueType} /> : undefined}
      />
      <DbsEventInfoRow title="dbs.issueDetails" content={e.issueDetails ? <>{e.issueDetails}</> : undefined} />
    </StyledRowItem>
  </StyledRowContainerEvent>
);

const TechnicalIssues = ({ d, startEdit, setAnchor }: IOwnProps): JSX.Element => {
  const events = d.technicalIssues || [];
  const firstEvents = events.slice(0, 2);
  const restEvents = events.slice(2);

  return (
    <React.Fragment>
      <StyledRowContainer style={{ margin: '3rem 0 0 0' }}>
        <StyledRowItem xs={9}>
          <StyledTitle>
            <FormattedMessage id="dbs.technicalIssue" />
          </StyledTitle>
        </StyledRowItem>
        <StyledRowItem xs={3}>
          <EditButton d={d} startEdit={startEdit} setAnchor={setAnchor} anchorName="technicalIssues" />
        </StyledRowItem>
      </StyledRowContainer>
      {events && firstEvents.map((e: IDBSTechnicalIssue, i) => <TechnicalIssuesEvent e={e} i={i} key={e.id} />)}
      {events.length > 2 && (
        <CollapseElem localeIDs={optionsIssues} amount={restEvents.length}>
          {restEvents.map((e: IDBSTechnicalIssue, i) => (
            <TechnicalIssuesEvent e={e} i={i} key={e.id} />
          ))}
        </CollapseElem>
      )}
    </React.Fragment>
  );
};

const DiscontinuationEvent = ({ e }: { e: IDBSDiscontinuationEvent }): JSX.Element => (
  <StyledRowContainerEvent key={e.id}>
    <StyledRowItem xs={2}>
      <div style={{ fontWeight: 600 }}>{formatPartialDate(e.date)}</div>
    </StyledRowItem>
    <StyledRowItem xs={10}>
      <DbsEventInfoRow
        title="dbs.discontinuationReasonShort"
        content={e.discontinuationReason ? <FormattedMessage id={'dbs.opts.' + e.discontinuationReason} /> : undefined}
      />

      <DbsEventInfoRow
        title="dbs.discontinuationDetails"
        content={e.discontinuationDetails ? <>{e.discontinuationDetails}</> : undefined}
      />

      <DbsEventInfoRow
        title="dbs.discontinuationType"
        content={e.discontinuationType ? <FormattedMessage id={'dbs.opts.' + e.discontinuationType} /> : undefined}
      />

      <DbsEventInfoRow
        title="dbs.treatmentContinuationDate"
        content={e.endDate ? <>{formatPartialDate(e.endDate)}</> : undefined}
      />
    </StyledRowItem>
  </StyledRowContainerEvent>
);

const Discontinuations = ({ d, startEdit, setAnchor }: IOwnProps): JSX.Element => {
  const events = d.discontinuationEvents || [];
  const firstEvents = events.slice(0, 2);
  const restEvents = events.slice(2);

  return (
    <React.Fragment>
      <StyledRowContainer style={{ margin: '3rem 0 0 0' }}>
        <StyledRowItem xs={9}>
          <StyledTitle>
            <FormattedMessage id="dbs.discontinuations" />
          </StyledTitle>
        </StyledRowItem>
        <StyledRowItem xs={3}>
          <EditButton d={d} startEdit={startEdit} setAnchor={setAnchor} anchorName="discontinuations" />
        </StyledRowItem>
      </StyledRowContainer>
      {events && firstEvents.map((e: IDBSDiscontinuationEvent) => <DiscontinuationEvent e={e} key={e.id} />)}
      {events.length > 2 && (
        <CollapseElem localeIDs={optionsDiscontinuations} amount={restEvents.length}>
          {restEvents.map((e: IDBSDiscontinuationEvent) => (
            <DiscontinuationEvent e={e} key={e.id} />
          ))}
        </CollapseElem>
      )}
    </React.Fragment>
  );
};

const InfoRow = ({
  d,
  arr,
  firstOrSecond,
}: {
  d: IDBS;
  arr: { title: any; value: any }[];
  firstOrSecond: string;
}): JSX.Element => (
  <StyledRowContainer justifyContent="flex-start" style={{ marginTop: '3rem' }}>
    {((firstOrSecond === 'secondRow' && d.removed) || firstOrSecond === 'firstRow') && (
      <StyledRowItem xs={2} key={firstOrSecond + 0}>
        <StyledHeader>{arr[0].title}</StyledHeader>
        <StyledTextValue>{arr[0].value}</StyledTextValue>
      </StyledRowItem>
    )}
    <StyledRowItem xs={10}>
      <StyledRowContainer>
        {((d.removed && firstOrSecond === 'secondRow' && d.removed === 'yes') || firstOrSecond === 'firstRow') &&
          arr.slice(1, 5).map((item: any, i: number) => (
            <StyledRowItem xs={3} key={firstOrSecond + i}>
              <StyledHeader>{item.title}</StyledHeader>
              <StyledTextValue>{item.value}</StyledTextValue>
            </StyledRowItem>
          ))}
      </StyledRowContainer>
    </StyledRowItem>
  </StyledRowContainer>
);

const DBSEnding = ({ d, startEdit, setAnchor }: IOwnProps): JSX.Element => {
  const firstRow = [
    {
      title: <FormattedMessage id="dbs.endedShort" />,
      value: <FormattedMessage id="general.yes" />,
    },
    {
      title: <FormattedMessage id="dbs.endedDate" />,
      value: d.endDate?.[0] ? formatPartialDate(d.endDate) : '-',
    },
    {
      title: <FormattedMessage id="dbs.endedReason" />,
      value: d.endReason ? <FormattedMessage id={`dbs.opts.${d.endReason}`} /> : '-',
    },
    {
      title: <FormattedMessage id="dbs.endedDetails" />,
      value: d.endDetails || '-',
    },
    {
      title: <>&nbsp;</>,
      value: <>&nbsp;</>,
    },
  ];
  const secondRow = [
    {
      title: <FormattedMessage id="dbs.removedShort" />,
      value: <FormattedMessage id={`dbs.opts.yesNoUnknown.${d.removed}`} />,
    },
    {
      title: <FormattedMessage id="dbs.removalDate" />,
      value: d.removalDate?.[0] ? formatPartialDate(d.removalDate) : '-',
    },
    {
      title: <FormattedMessage id="dbs.removalReason" />,
      value: d.removalReason ? <FormattedMessage id={`dbs.opts.${d.removalReason}`} /> : '-',
    },
    {
      title: <FormattedMessage id="dbs.removalAdditionalInformation" />,
      value: d.removalAdditionalInformation || '-',
    },
    {
      title: <FormattedMessage id="dbs.removalType" />,
      value:
        d.removalType && Array.isArray(d.removalType) && d.removalType.length > 0
          ? d.removalType.map((rt, i) => (
              <div key={i}>
                <FormattedMessage id={`dbs.opts.${rt}`} />
              </div>
            ))
          : '-',
    },
  ];

  return (
    <React.Fragment>
      <StyledRowContainer style={{ margin: '3rem 0 0 0' }}>
        <StyledRowItem xs={9}>
          <StyledTitle>
            <FormattedMessage id="dbs.dbsEnding" />
          </StyledTitle>
        </StyledRowItem>
        <StyledRowItem xs={3} id={'dbsEnd'}>
          <EditButton d={d} startEdit={startEdit} setAnchor={setAnchor} anchorName="dbsEnding" />
        </StyledRowItem>
      </StyledRowContainer>
      {d.hasEnded?.[0] === true && (
        <>
          <InfoRow d={d} arr={firstRow} firstOrSecond={'firstRow'} />
          <InfoRow d={d} arr={secondRow} firstOrSecond={'secondRow'} />
        </>
      )}
    </React.Fragment>
  );
};

const DBSSetting = ({ d, doc }: ISetting): JSX.Element => {
  const [selectedProgram, setSelectedProgram] = React.useState<number>(0);

  return (
    <React.Fragment>
      <StyledRowContainerBar alignItems="center">
        <StyledRowItem xs={12}>
          <StyledBarHeader>{doc.generatorAdjustedDate && formatPartialDate(doc.generatorAdjustedDate)}</StyledBarHeader>
        </StyledRowItem>
      </StyledRowContainerBar>
      <StyledRowContainer style={{ marginTop: '2rem' }}>
        <StyledRowItem xs={12}>
          <span style={{ fontWeight: 600 }}>
            {d.generators
              ?.map((generator: IDBSGenerator) => {
                if (generator.id === doc?.generator) {
                  return `${generator?.generator} - ${formatPartialDate(generator?.generatorDate)}`;
                }
                return undefined;
              })
              .filter((g) => g)
              .join('')}
          </span>
        </StyledRowItem>
      </StyledRowContainer>
      <StyledRowContainer>
        <StyledRowItem xs={12}>
          <StyledTabArea style={{ margin: '2rem 5.5rem 4rem 0' }}>
            <StyledRowContainer alignItems="stretch">
              {doc &&
                Array.isArray(doc?.programs) &&
                doc.programs.map(
                  (program: any, index: number): JSX.Element => (
                    <React.Fragment key={program.programName + index}>
                      <StyledTab
                        xs={true}
                        style={{
                          backgroundColor: selectedProgram === index ? 'rgba(4, 90, 139, 0.28)' : undefined,
                        }}
                        onClick={(): void => setSelectedProgram(index)}
                      >
                        {program.programName}
                      </StyledTab>
                    </React.Fragment>
                  ),
                )}
            </StyledRowContainer>
          </StyledTabArea>
        </StyledRowItem>
      </StyledRowContainer>
      <StyledRowContainer style={{ marginTop: '0.5rem' }}>
        <StyledRowItem xs={9}>
          <StyledRowContainer justifyContent="center">
            <StyledRowItem xs={4} />
            <StyledRowItem xs={4}>
              <StyledRowContainer justifyContent="center">
                <StyledRowItem>
                  <StyledSide style={{ marginBottom: '2rem' }}>
                    <FormattedMessage id="general.right" />
                  </StyledSide>
                </StyledRowItem>
              </StyledRowContainer>
            </StyledRowItem>
            <StyledRowItem xs={4}>
              <StyledRowContainer justifyContent="center">
                <StyledRowItem>
                  <StyledSide style={{ marginBottom: '2rem' }}>
                    <FormattedMessage id="general.left" />
                  </StyledSide>
                </StyledRowItem>
              </StyledRowContainer>
            </StyledRowItem>
          </StyledRowContainer>
        </StyledRowItem>
        <StyledRowItem xs={3} />
      </StyledRowContainer>
      <StyledRowContainer style={{ marginTop: '1.5rem' }}>
        <StyledRowItem xs={9}>
          <StyledRowContainer justifyContent="center">
            <StyledRowItem xs={4}>
              <StyledHeader style={{ marginBottom: '2rem' }}>
                <FormattedMessage id="dbs.generator" />
              </StyledHeader>
            </StyledRowItem>
            <StyledRowItem xs={4}>
              <StyledRowContainer justifyContent="center">
                <StyledRowItem>
                  <GeneratorSetting
                    editable={false}
                    name="rightGeneratorSetting"
                    formData={
                      {
                        document: { rightGeneratorSetting: doc?.programs?.[selectedProgram].rightGeneratorSetting },
                      } as any
                    }
                  />
                </StyledRowItem>
              </StyledRowContainer>
            </StyledRowItem>
            <StyledRowItem xs={4}>
              <StyledRowContainer justifyContent="center">
                <StyledRowItem>
                  <GeneratorSetting
                    editable={false}
                    name="leftGeneratorSetting"
                    formData={
                      {
                        document: { leftGeneratorSetting: doc?.programs?.[selectedProgram].leftGeneratorSetting },
                      } as any
                    }
                  />
                </StyledRowItem>
              </StyledRowContainer>
            </StyledRowItem>
          </StyledRowContainer>
          <StyledRowContainer style={{ marginTop: '2rem' }}>
            <StyledRowItem xs={4}>
              <StyledRowContainer>
                <StyledRowItem xs={12}>
                  <StyledHeader>
                    <FormattedMessage id="dbs.leads" />
                  </StyledHeader>
                  {settingsDescription(
                    hasLfpRegistration(doc ?? ({} as IDBSSetting)) &&
                      selectedProgram === (doc?.programs ?? []).length - 1,
                  )}
                </StyledRowItem>
              </StyledRowContainer>
            </StyledRowItem>
            <StyledRowItem xs={8}>
              <StyledRowContainer justifyContent="space-around">
                {!!path(['leadRight'], d) && (
                  <StyledRowItem xs={6}>
                    <StyledRowContainer justifyContent="center">
                      <StyledRowItem
                        style={{ minWidth: '300px', minHeight: '370px', maxWidth: '100%', maxHeight: '100%' }}
                      >
                        <LeadClicker
                          editable={'false'}
                          name="rightSettings"
                          formData={{
                            document: {
                              ...d,
                              rightSettings: doc?.programs?.[selectedProgram].rightSettings || {},
                            } as any,
                          }}
                        />
                      </StyledRowItem>
                    </StyledRowContainer>
                    <StyledTextValue>
                      <div style={{ textAlign: 'center', marginTop: '3rem' }}>
                        {leadFormatter(d.leadRight as string)}
                      </div>
                    </StyledTextValue>
                  </StyledRowItem>
                )}
                {!!path(['leadLeft'], d) && (
                  <StyledRowItem xs={6}>
                    <StyledRowContainer justifyContent="center">
                      <StyledRowItem
                        style={{ minWidth: '300px', minHeight: '370px', maxWidth: '100%', maxHeight: '100%' }}
                      >
                        <LeadClicker
                          editable={'false'}
                          name="leftSettings"
                          formData={{
                            document: {
                              ...d,
                              leftSettings: doc?.programs?.[selectedProgram].leftSettings || {},
                            } as any,
                          }}
                        />
                      </StyledRowItem>
                    </StyledRowContainer>
                    <StyledTextValue>
                      <div style={{ textAlign: 'center', marginTop: '3rem' }}>
                        {leadFormatter(d.leadLeft as string)}
                      </div>
                    </StyledTextValue>
                  </StyledRowItem>
                )}
              </StyledRowContainer>
            </StyledRowItem>
          </StyledRowContainer>
        </StyledRowItem>
        <StyledRowItem xs={3}></StyledRowItem>
      </StyledRowContainer>

      {!(hasLfpRegistration(doc ?? ({} as IDBSSetting)) && selectedProgram === (doc?.programs ?? []).length - 1) && (
        <StyledRowContainer style={{ marginTop: '3rem' }} justifyContent="flex-start">
          <StyledRowItem xs={3}>
            <StyledSubHeader>&nbsp;</StyledSubHeader>
            <StyledSubHeader>
              <FormattedMessage id="dbs.amplitude" />
            </StyledSubHeader>
            <StyledSubHeader>
              <FormattedMessage id="dbs.pulseWidth" />
            </StyledSubHeader>
            <StyledSubHeader>
              <FormattedMessage id="dbs.rate" />
            </StyledSubHeader>
          </StyledRowItem>
          <StyledRowItem xs={6}>
            <StyledRowContainer justifyContent="space-around">
              {!!path(['leadRight'], d) && (
                <StyledRowItem>
                  <StyledRowContainer justifyContent="center">
                    <StyledRowItem>
                      <StyledSide>
                        <FormattedMessage id="general.right" />
                      </StyledSide>
                      <StyledValue>
                        {exists(doc?.programs?.[selectedProgram].rightAmplitude)
                          ? `${doc?.programs?.[selectedProgram].rightAmplitude} ${
                              doc?.programs?.[selectedProgram]?.amplitudeUnit === 'V'
                                ? 'V'
                                : doc?.programs?.[selectedProgram]?.amplitudeUnit === 'mA'
                                  ? 'mA'
                                  : ''
                            }`
                          : '-'}
                      </StyledValue>
                      <StyledValue>
                        {exists(doc?.programs?.[selectedProgram].rightPulseWidth)
                          ? `${doc?.programs?.[selectedProgram].rightPulseWidth} ${defaultUnits.pulseWidth}`
                          : '-'}
                      </StyledValue>
                      <StyledValue>
                        {exists(doc?.programs?.[selectedProgram].rightRate)
                          ? `${doc?.programs?.[selectedProgram].rightRate} ${defaultUnits.rate}`
                          : '-'}
                      </StyledValue>
                    </StyledRowItem>
                  </StyledRowContainer>
                </StyledRowItem>
              )}
              {!!path(['leadLeft'], d) && (
                <StyledRowItem>
                  <StyledRowContainer justifyContent="center">
                    <StyledRowItem>
                      <StyledSide>
                        <FormattedMessage id="general.left" />
                      </StyledSide>
                      <StyledValue>
                        {exists(doc?.programs?.[selectedProgram].leftAmplitude)
                          ? `${doc?.programs?.[selectedProgram].leftAmplitude} ${
                              doc?.programs?.[selectedProgram]?.amplitudeUnit === 'V'
                                ? 'V'
                                : doc?.programs?.[selectedProgram]?.amplitudeUnit === 'mA'
                                  ? 'mA'
                                  : ''
                            }`
                          : '-'}
                      </StyledValue>
                      <StyledValue>
                        {exists(doc?.programs?.[selectedProgram].leftPulseWidth)
                          ? `${doc?.programs?.[selectedProgram].leftPulseWidth} ${defaultUnits.pulseWidth}`
                          : '-'}
                      </StyledValue>
                      <StyledValue>
                        {exists(doc?.programs?.[selectedProgram].leftRate)
                          ? `${doc?.programs?.[selectedProgram].leftRate} ${defaultUnits.rate}`
                          : '-'}
                      </StyledValue>
                    </StyledRowItem>
                  </StyledRowContainer>
                </StyledRowItem>
              )}
            </StyledRowContainer>
          </StyledRowItem>
        </StyledRowContainer>
      )}
      <PlatformConditional platform="parkinson">
        <StyledRowContainer style={{ marginTop: '5rem' }} justifyContent="flex-start">
          <StyledRowItem xs={3}>
            <StyledHeader>
              <FormattedMessage id="dbs.interlacing" />
            </StyledHeader>
          </StyledRowItem>
          <StyledRowItem xs={6}>
            <StyledRowContainer justifyContent="center" style={{ marginBottom: '3rem' }}>
              <StyledRowItem xs={6}>
                <StyledValue>
                  {doc?.programs?.[selectedProgram].interlacing === true ||
                  doc?.programs?.[selectedProgram].interlacing === false ? (
                    <FormattedMessage id={'general.' + doc?.programs?.[selectedProgram].interlacing} />
                  ) : (
                    '-'
                  )}
                </StyledValue>
              </StyledRowItem>
              <StyledRowItem xs={6}>{undefined}</StyledRowItem>
            </StyledRowContainer>
          </StyledRowItem>
        </StyledRowContainer>
      </PlatformConditional>
      <PlatformConditional platform="epilepsy">
        <React.Fragment>
          {hasLfpRegistration(doc ?? ({} as IDBSSetting)) && selectedProgram === (doc?.programs ?? []).length - 1 ? (
            <React.Fragment>
              <StyledRowContainer style={{ marginTop: '5rem' }} justifyContent="flex-start">
                <StyledRowItem xs={3}>
                  <StyledSubHeader style={{ fontSize: '2rem', fontWeight: 600 }}>
                    <FormattedMessage id="dbs.automaticRegistration" />
                  </StyledSubHeader>
                </StyledRowItem>
                <StyledRowItem xs={6} />
              </StyledRowContainer>
              <StyledRowContainer justifyContent="flex-start">
                <StyledRowItem xs={3}>
                  <StyledSubHeader>
                    <FormattedMessage id="dbs.rate" />
                  </StyledSubHeader>
                </StyledRowItem>
                <StyledRowItem xs={6}>
                  <StyledRowContainer justifyContent="center">
                    <StyledRowItem xs={6}>
                      <StyledValue>
                        {exists(doc?.programs?.[selectedProgram].automaticRegistrationRate)
                          ? `${doc?.programs?.[selectedProgram].automaticRegistrationRate} ${defaultUnits.rate}`
                          : '-'}
                      </StyledValue>
                    </StyledRowItem>
                    <StyledRowItem xs={6} />
                  </StyledRowContainer>
                </StyledRowItem>
              </StyledRowContainer>
              <StyledRowContainer style={{ marginTop: '5rem' }} justifyContent="flex-start">
                <StyledRowItem xs={3}>
                  <StyledSubHeader style={{ fontSize: '2rem', fontWeight: 600 }}>
                    <FormattedMessage id="dbs.eventRegistration" />
                  </StyledSubHeader>
                </StyledRowItem>
                <StyledRowItem xs={6} />
              </StyledRowContainer>
              <StyledRowContainer justifyContent="flex-start">
                <StyledRowItem xs={3}>
                  <StyledSubHeader>
                    <FormattedMessage id="dbs.eventRegistrationEvents" />
                  </StyledSubHeader>
                </StyledRowItem>
                <StyledRowItem xs={6}>
                  <StyledRowContainer justifyContent="center">
                    <StyledRowItem xs={6}>
                      <StyledValue>
                        {doc?.programs?.[selectedProgram]?.eventRegistration ? (
                          <FormattedMessage
                            id={`dbs.opts.inUseNotInUse.${doc?.programs?.[selectedProgram]?.eventRegistration}`}
                          />
                        ) : (
                          '-'
                        )}
                      </StyledValue>
                    </StyledRowItem>
                    <StyledRowItem xs={6} />
                  </StyledRowContainer>
                </StyledRowItem>
              </StyledRowContainer>
              {doc?.programs?.[selectedProgram]?.eventRegistration === 'inUse' && (
                <StyledRowContainer justifyContent="flex-start">
                  <StyledRowItem xs={3}>
                    <StyledSubHeader>
                      <FormattedMessage id="dbs.eventRegistrationEvent" />
                    </StyledSubHeader>
                  </StyledRowItem>
                  <StyledRowItem xs={6}>
                    <StyledRowContainer justifyContent="center">
                      <StyledRowItem xs={6}>
                        <StyledValue>
                          {doc?.programs?.[selectedProgram]?.events ? (
                            <React.Fragment>
                              {(doc?.programs?.[selectedProgram]?.events ?? []).map((event, index) => (
                                <div key={index}>{event}</div>
                              ))}
                            </React.Fragment>
                          ) : (
                            '-'
                          )}
                        </StyledValue>
                      </StyledRowItem>
                      <StyledRowItem xs={6} />
                    </StyledRowContainer>
                  </StyledRowItem>
                </StyledRowContainer>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <StyledRowContainer style={{ marginTop: '5rem' }} justifyContent="flex-start">
                <StyledRowItem xs={3}>
                  <StyledSubHeader>
                    <FormattedMessage id="dbs.mode" />
                  </StyledSubHeader>
                </StyledRowItem>
                <StyledRowItem xs={6}>
                  <StyledRowContainer justifyContent="center">
                    <StyledRowItem xs={6}>
                      <StyledValue>
                        {doc?.programs?.[selectedProgram]?.mode ? (
                          <FormattedMessage id={`dbs.opts.${doc?.programs?.[selectedProgram]?.mode}`} />
                        ) : (
                          '-'
                        )}
                      </StyledValue>
                    </StyledRowItem>
                    <StyledRowItem xs={6} />
                  </StyledRowContainer>
                </StyledRowItem>
              </StyledRowContainer>
              {doc?.programs?.[selectedProgram]?.mode === 'cyclicStimulation' && (
                <React.Fragment>
                  <StyledRowContainer style={{ marginTop: '5rem' }} justifyContent="flex-start">
                    <StyledRowItem xs={3}>
                      <StyledSubHeader>
                        <FormattedMessage id="dbs.cyclicONTime" />
                      </StyledSubHeader>
                    </StyledRowItem>
                    <StyledRowItem xs={6}>
                      <StyledRowContainer justifyContent="center">
                        <StyledRowItem xs={6}>
                          <StyledValue>
                            {exists(doc?.programs?.[selectedProgram].cyclicONTime)
                              ? `${doc?.programs?.[selectedProgram].cyclicONTime} ${defaultUnits.cyclicONTime}`
                              : '-'}
                          </StyledValue>
                        </StyledRowItem>
                        <StyledRowItem xs={6} />
                      </StyledRowContainer>
                    </StyledRowItem>
                  </StyledRowContainer>
                  <StyledRowContainer justifyContent="flex-start">
                    <StyledRowItem xs={3}>
                      <StyledSubHeader>
                        <FormattedMessage id="dbs.cyclicOFFTime" />
                      </StyledSubHeader>
                    </StyledRowItem>
                    <StyledRowItem xs={6}>
                      <StyledRowContainer justifyContent="center">
                        <StyledRowItem xs={6}>
                          <StyledValue>
                            {exists(doc?.programs?.[selectedProgram].cyclicOFFTime)
                              ? `${doc?.programs?.[selectedProgram].cyclicOFFTime} ${defaultUnits.cyclicOFFTime}`
                              : '-'}
                          </StyledValue>
                        </StyledRowItem>
                        <StyledRowItem xs={6} />
                      </StyledRowContainer>
                    </StyledRowItem>
                  </StyledRowContainer>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      </PlatformConditional>
    </React.Fragment>
  );
};

const optionsSettings = {
  showMessage: 'dbs.showPastSettings',
  hideMessage: 'dbs.hidePastSettings',
};

const DBSSettings = ({ d, startEdit, setAnchor }: IOwnProps): any => (
  <React.Fragment>
    <StyledRowContainer style={{ margin: '3rem 0 0 0' }}>
      <StyledRowItem xs={9}>
        <StyledTitle>
          <FormattedMessage id="dbs.settings" />
        </StyledTitle>
      </StyledRowItem>
      <StyledRowItem xs={3}>
        <EditButton d={d} startEdit={startEdit} setAnchor={setAnchor} anchorName="settings" />
      </StyledRowItem>
    </StyledRowContainer>
    {d && d.settings && d.settings.length > 0 && (
      <>
        <DBSSetting d={d} doc={d.settings[0]} startEdit={startEdit} />
        {d.settings.length > 1 ? (
          <CollapseElem localeIDs={optionsSettings} amount={d.settings.length - 1}>
            {d.settings.map((doc: IDBSSetting, i: number) => (
              <div key={'dbsSetting' + i}>
                {i > 0 ? <DBSSetting d={d} doc={doc} startEdit={startEdit} /> : undefined}
              </div>
            ))}
          </CollapseElem>
        ) : undefined}
      </>
    )}
  </React.Fragment>
);

const DBSHistoryRowData = ({ d, startEdit, setAnchor }: IOwnProps): JSX.Element => (
  <StyledHistory>
    <HistoryRow
      headerText={
        d.hasEnded?.[0] !== true ? (
          d.date && d.status != 'suspended' ? (
            <FormattedMessage id="dbs.startingDate" values={{ date: formatPartialDate(d.date) }} />
          ) : d.date ? (
            <div>
              <FormattedMessage id="dbs.startingDate" values={{ date: formatPartialDate(d.date) }} />
              {' - '}
              <span style={{ fontWeight: 600, textTransform: 'uppercase' }}>
                <FormattedMessage id="dbs.opts.suspended" />
              </span>
            </div>
          ) : (
            ''
          )
        ) : (
          d.date && `${formatPartialDate(d.date)} - ${d.endDate ? formatPartialDate(d.endDate) : ''}`
        )
      }
      rowButton={<HistoryRowControls document={d} startEdit={startEditAndRemoveAnchor(startEdit, setAnchor)} />}
      controlsMargin={false}
    >
      <StyledRowContainer>
        <StyledRowItem xs={3}>
          {d.implantationSite && (
            <React.Fragment>
              <StyledHeader>
                <FormattedMessage id="dbs.implantationSite" />
              </StyledHeader>
              <StyledTextValue>
                <div>{d.implantationSite}</div>
              </StyledTextValue>
            </React.Fragment>
          )}
          {d.generators?.[0] && (
            <React.Fragment>
              <StyledHeader>
                <FormattedMessage id="dbs.generator" />
              </StyledHeader>
              <StyledTextValue>
                <div>
                  {d.status === 'suspended' &&
                  getLatestDiscontinuation(d.discontinuationEvents ? d.discontinuationEvents : [])
                    ?.discontinuationType != 'generatorSwitchedOff' ? (
                    '-'
                  ) : d?.generators?.[0]?.generator && d.generators[0].generator === 'Other rechargeable' ? (
                    <FormattedMessage id="dbs.otherRechargeable" />
                  ) : d.generators[0].generator === 'Other battery' ? (
                    <FormattedMessage id="dbs.otherPrimaryCell" />
                  ) : (
                    d.generators[0].generator
                  )}
                </div>
                <div>
                  {d.status === 'suspended' &&
                  getLatestDiscontinuation(d.discontinuationEvents ? d.discontinuationEvents : [])
                    ?.discontinuationType != 'generatorSwitchedOff' ? undefined : d?.generators?.[0]?.generator ? (
                    <React.Fragment>
                      <PlatformConditional platform="parkinson">
                        <React.Fragment>{generatorType(d.generators[0].generator, 'parkinson')}</React.Fragment>
                      </PlatformConditional>
                      <PlatformConditional platform="epilepsy">
                        <React.Fragment>{generatorType(d.generators[0].generator, 'epilepsy')}</React.Fragment>
                      </PlatformConditional>
                    </React.Fragment>
                  ) : undefined}
                </div>
                {d?.generators?.[0]?.generatorSerialNo && (
                  <div style={{ fontWeight: 400 }}>
                    <FormattedMessage id="dbs.serialNo" />:{' '}
                    <span style={{ fontWeight: 600 }}>{d?.generators?.[0]?.generatorSerialNo}</span>
                  </div>
                )}
              </StyledTextValue>
            </React.Fragment>
          )}
          {d.generators?.[0]?.generatorDate && (
            <React.Fragment>
              <StyledHeader>
                <FormattedMessage id="dbs.generatorDate" />
              </StyledHeader>
              <StyledTextValue>
                <div>
                  {d.status === 'suspended' &&
                  getLatestDiscontinuation(d.discontinuationEvents ? d.discontinuationEvents : [])
                    ?.discontinuationType != 'generatorSwitchedOff'
                    ? '-'
                    : formatPartialDate(d.generators[0].generatorDate)}
                </div>
              </StyledTextValue>
            </React.Fragment>
          )}
          {d.generators?.[0]?.generatorNextChangeDate && (
            <React.Fragment>
              <StyledHeader>
                <FormattedMessage id="dbs.nextChange" />
              </StyledHeader>
              <StyledTextValue>
                <div>
                  {d.status === 'suspended' &&
                  getLatestDiscontinuation(d.discontinuationEvents ? d.discontinuationEvents : [])
                    ?.discontinuationType != 'generatorSwitchedOff'
                    ? '-'
                    : formatPartialDate(d.generators[0].generatorNextChangeDate)}
                </div>
              </StyledTextValue>
            </React.Fragment>
          )}
        </StyledRowItem>
        <StyledRowItem xs={6}>
          <StyledRowContainer justifyContent="center" style={{ marginTop: '3rem', marginBottom: '0.5rem' }}>
            <StyledRowItem>
              {(d.leadRight || d.leadLeft) && (
                <StyledHeader>
                  <FormattedMessage id="dbs.leads" />
                </StyledHeader>
              )}
            </StyledRowItem>
          </StyledRowContainer>
          <StyledRowContainer>
            {!!path(['leadRight'], d) && (
              <StyledRowItem xs={6} style={{ textAlign: 'center' }}>
                <StyledSide>
                  <FormattedMessage id="general.right" />
                </StyledSide>
                <StyledTextValue>
                  <div>{leadFormatter(d.leadRight as string)}</div>
                  {!!path(['leadRightSerialNo'], d) && (
                    <div style={{ fontWeight: 400 }}>
                      <FormattedMessage id="dbs.serialNo" />:{' '}
                      <span style={{ fontWeight: 600 }}>{d.leadRightSerialNo}</span>
                    </div>
                  )}
                </StyledTextValue>
              </StyledRowItem>
            )}
            {!!path(['leadLeft'], d) && (
              <StyledRowItem xs={6} style={{ textAlign: 'center' }}>
                <StyledSide>
                  <FormattedMessage id="general.left" />
                </StyledSide>
                <StyledTextValue>
                  <div>{leadFormatter(d.leadLeft as string)}</div>
                  {!!path(['leadLeftSerialNo'], d) && (
                    <div style={{ fontWeight: 400 }}>
                      <FormattedMessage id="dbs.serialNo" />:{' '}
                      <span style={{ fontWeight: 600 }}>{d.leadLeftSerialNo}</span>
                    </div>
                  )}
                </StyledTextValue>
              </StyledRowItem>
            )}
          </StyledRowContainer>
          <StyledRowContainer style={{ marginTop: '3rem', flexDirection: 'column', alignItems: 'center' }}>
            {(d.targetNucleus || d.targetNucleusLeft) && (
              <React.Fragment>
                <StyledHeader>
                  <FormattedMessage id="dbs.targetNucleus" />
                </StyledHeader>
                {d.targetNucleus && !d.targetNucleusLeft ? (
                  <StyledTextValue>
                    <div>{d.targetNucleus}</div>
                  </StyledTextValue>
                ) : (
                  <StyledRowContainer>
                    <StyledRowItem xs={6} style={{ textAlign: 'center' }}>
                      <StyledSide>
                        <FormattedMessage id="general.right" />
                      </StyledSide>
                      <StyledTextValue>
                        <div>{d.targetNucleus ?? '-'}</div>
                      </StyledTextValue>
                    </StyledRowItem>
                    <StyledRowItem xs={6} style={{ textAlign: 'center' }}>
                      <StyledSide>
                        <FormattedMessage id="general.left" />
                      </StyledSide>
                      <StyledTextValue>
                        <div>{d.targetNucleusLeft ?? '-'}</div>
                      </StyledTextValue>
                    </StyledRowItem>
                  </StyledRowContainer>
                )}
              </React.Fragment>
            )}
          </StyledRowContainer>
        </StyledRowItem>
        <StyledRowItem xs={3}></StyledRowItem>
      </StyledRowContainer>
    </HistoryRow>

    <DBSSettings d={d} startEdit={startEdit} setAnchor={setAnchor} />
    <PlatformConditional platform="epilepsy">
      <DBSPostoperativeComplications d={d} startEdit={startEdit} setAnchor={setAnchor} />
    </PlatformConditional>
    <DBSEvents d={d} startEdit={startEdit} setAnchor={setAnchor} />
    <TechnicalIssues d={d} startEdit={startEdit} setAnchor={setAnchor} />
    <Discontinuations d={d} startEdit={startEdit} setAnchor={setAnchor} />
    <DBSEnding d={d} startEdit={startEdit} setAnchor={setAnchor} />
  </StyledHistory>
);

interface ISetting {
  d: IDBS;
  doc: IDBSSetting;
  startEdit: (document: IDBS) => (e: React.MouseEvent) => void;
}

interface IOwnProps {
  d: IDBS;
  startEdit: (document: IDBS) => (e: React.MouseEvent) => void;
  setAnchor: (anchor?: string) => () => void;
}

export default DBSHistoryRowData;
