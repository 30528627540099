import * as React from 'react';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import { IHistoryContext, withFormContext } from '../../../../containers/FormContextHandler';
import { filterNotSeizureDocs, formatSeizureClassification, isISeizure } from '../../utils';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import HistoryRowSubHeader from 'Components/HistoryRowSubHeader';
import DataTable from 'Components/DataTable';
import { formatPartialDate, formatTime, isPartialDate, sortPartialDate } from 'neuro-utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import colors from '../../../../config/theme/colors';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ToolTip from 'Components/ToolTip';

// Same as in HistoryRowControls
const StyledMenuIcon = styled(({ onClick }: { onClick?: (e: React.MouseEvent<Element | HTMLElement>) => void }) => (
  <IconButton onClick={onClick}>
    <MoreVertIcon />
  </IconButton>
))({
  color: '#6c96ae',
  cursor: 'pointer',
});

const ConfirmedSeizures = ({
  documents,
  patientReportedDocs,
  fm,
  startEdit,
  setPatRepSeizFormOpen,
}: IOwnProps): JSX.Element => {
  if (!fm || !startEdit) throw 'fm or startEdit is undefined';
  const allSeizureDocs = documents ? (documents.filter((d) => isISeizure(d)) as ISeizure[]) : [];
  /** docs that are classified with a proper classification, i.e. not "notSeizure" */
  const classifiedDocs = filterNotSeizureDocs(allSeizureDocs);
  const acceptedPatientReportedDocs = patientReportedDocs?.filter((d) => d?.status === 'accepted') ?? [];
  const listedDocs: Array<ISeizure | (IPatientReportedSeizure & IControlProps)> = [
    ...classifiedDocs,
    ...acceptedPatientReportedDocs,
  ].sort((d1, d2) =>
    sortPartialDate(
      'date' in d2 ? d2.date : 'startDate' in d2 ? d2.startDate : undefined,
      'date' in d1 ? d1.date : 'startDate' in d1 ? d1.startDate : undefined,
    ),
  );

  const [anchor, setAnchor] = React.useState<null | { anchor: any; index: number }>(null);
  const toggleMenu =
    (index: number) =>
    (e: React.MouseEvent<any>): void => {
      //e.stopPropagation();
      setAnchor(!anchor ? { anchor: e.currentTarget, index } : null);
    };

  const startEditViewPatientReported = (document: IPatientReportedSeizure & IControlProps, type: 'view' | 'edit') => {
    setAnchor(null);
    setPatRepSeizFormOpen({ doc: document, type: type });
  };

  const listedDocsHistoryData: (
    | string
    | number
    | JSX.Element
    | (string | number | JSX.Element | number[] | string[])[]
  )[] = listedDocs.map((d, i) => [
    <div key={d._id} style={{ paddingRight: '2rem', width: '100%' }}>
      <div style={{ fontWeight: 600, display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatPartialDate('date' in d ? d.date : 'startDate' in d ? d.startDate : undefined)}
        {d.endDate && (!('startTime' in d) || !d.startTime || !('time' in d) || !d.time)
          ? '\u00A0-\u00A0'
          : ('startTime' in d && d.startTime) || ('time' in d && d.time)
            ? '\u00A0'
            : ''}
      </div>
      <div
        style={{
          fontWeight: d?.endDate && isPartialDate(d.endDate) ? 600 : 400,
          display: 'inline-block',
          whiteSpace: 'nowrap',
        }}
      >
        {d?.endDate
          ? `${formatPartialDate(d.endDate)}`
          : 'startTime' in d
            ? ` ${formatTime(d?.startTime)}`
            : 'time' in d
              ? `${formatTime(d?.time)}`
              : ''}
      </div>
    </div>,
    d?.count ?? '',
    formatSeizureClassification(
      'classification' in d ? d.classification : 'seizureType' in d ? d.seizureType : undefined,
      'long',
    ),
    d?.seizureMedicationGiven ? (
      <div style={{ display: 'flex' }}>
        <CheckCircleIcon style={{ marginRight: '0.5rem', color: colors.success }} />
      </div>
    ) : (
      ''
    ),
    d?.medicCompliance === 'yes' ? (
      <div style={{ display: 'flex' }}>
        <CheckCircleIcon style={{ marginRight: '0.5rem', color: colors.success }} />
        {fm('general.yes')}
      </div>
    ) : d.medicCompliance === 'no' ? (
      <div style={{ display: 'flex' }}>
        <WarningIcon style={{ marginRight: '0.5rem', color: colors.warning.dark }} />
        {fm('general.no')}
      </div>
    ) : (
      '-'
    ),
    <div key={`${d._id}-desc`} style={{ display: 'flex' }}>
      {'freeDescription' in d && (
        <ToolTip
          description={d.freeDescription}
          content={<ManageSearchIcon style={{ color: colors.primary }} />}
          hover={true}
        />
      )}
    </div>,
    <div key={`${d._id}-controls`} style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {d._type === 'seizure' ? (
        <HistoryRowControls document={d} startEdit={startEdit} hideButton showEditInMenu />
      ) : (
        <div key={d._id} style={{ width: '4.5rem', display: 'flex', justifyContent: 'flex-end' }}>
          <StyledMenuIcon onClick={toggleMenu(i)} />
          <Menu
            anchorEl={anchor?.index === i ? anchor?.anchor : undefined}
            open={anchor?.index === i ? Boolean(anchor?.anchor) : false}
            onClose={() => setAnchor(null)}
          >
            <MenuItem
              onClick={() => {
                startEditViewPatientReported(d as IPatientReportedSeizure & IControlProps, 'edit');
              }}
            >
              {fm('general.edit')}
            </MenuItem>
          </Menu>
        </div>
      )}
    </div>,
  ]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          marginBottom: '2rem',
        }}
      >
        <HistoryRowSubHeader header={fm('seizure.confirmedSeizures')} />
        <ActionButtonRounded
          text={'seizure.addNew'}
          onClick={startEdit && startEdit({}, 'seizure')}
          filled
          width={10}
          height={4}
          uppercase={false}
          fontSize={16}
        />
      </div>
      <DataTable
        headers={[
          fm('patientReportedSeizure.timeframe'),
          fm('seizure.count'),
          fm('seizure.classification'),
          fm('seizure.seizureMedicationGiven'),
          fm('patientReportedSeizure.medicCompliance'),
          fm('patientReportedSeizure.freeDescription'),
          '',
        ]}
        data={{ rowData: listedDocsHistoryData }}
      />
    </>
  );
};

interface IOwnProps extends IHistoryContext {
  documents?: Array<ISeizureDoc>;
  patientReportedDocs?: Array<IPatientReportedSeizure & IControlProps>;
  setPatRepSeizFormOpen: React.Dispatch<
    React.SetStateAction<
      | {
          doc: IPatientReportedSeizure & IControlProps;
          type: 'edit' | 'view';
        }
      | undefined
    >
  >;
}

export default withFormContext(ConfirmedSeizures);
