import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import { Container, Item } from '../../../../components/Grid';

export const steps: Array<string | { [key: string]: string[] }> = [
  { depressedMood: ['depressedMoodSeverity', 'depressedMoodFrequency', 'depressedMoodWorst'] },
  { suicidalIdeation: ['suicidalIdeationSeverity', 'suicidalIdeationFrequency', 'suicidalIdeationWorst'] },
  { anxiety: ['anxietySeverity', 'anxietyFrequency', 'anxietyWorst'] },
  { irritability: ['irritabilitySeverity', 'irritabilityFrequency', 'irritabilityWorst'] },
  {
    angryOrAggressiveBehaviour: [
      'angryOrAggressiveBehaviourSeverity',
      'angryOrAggressiveBehaviourFrequency',
      'angryOrAggressiveBehaviourWorst',
    ],
  },
  { apathy: ['apathySeverity', 'apathyFrequency', 'apathyWorst'] },
  { perseveration: ['perseverationSeverity', 'perseverationFrequency', 'perseverationWorst'] },
  {
    obsessiveCompulsiveBehaviours: [
      'obsessiveCompulsiveBehavioursSeverity',
      'obsessiveCompulsiveBehavioursFrequency',
      'obsessiveCompulsiveBehavioursWorst',
    ],
  },
  {
    delusionsOrParanoidThinking: [
      'delusionsOrParanoidThinkingSeverity',
      'delusionsOrParanoidThinkingFrequency',
      'delusionsOrParanoidThinkingWorst',
    ],
  },
  {
    hallucinations: [
      'hallucinationsModality',
      'hallucinationsSeverity',
      'hallucinationsFrequency',
      'hallucinationsWorst',
    ],
  },
  {
    disorientedBehaviour: [
      'disorientedBehaviourSeverity',
      'disorientedBehaviourFrequency',
      'disorientedBehaviourWorst',
    ],
  },
];

const StyledLabel = styled('div')({
  fontWeight: 600,
});

const instruction = (locale: string): JSX.Element => (
  <React.Fragment>
    <Container style={{ marginBottom: '-2.5rem' }}>
      <Item xs={3}>
        <StyledLabel>Instructions related to the question</StyledLabel>
      </Item>
      <Item xs={9} style={{ fontStyle: 'italic', paddingLeft: '1rem' }}>
        <FormattedMessage
          id={`behaviour.instructions.pbaS.${locale}`}
          values={{
            ul: (chunks) => <ul>{chunks}</ul>,
            li: (chunks) => <li>{chunks}</li>,
            br: <br />,
          }}
        />
      </Item>
    </Container>
  </React.Fragment>
);

const fieldsWithNoInstructions = ['obsessiveCompulsiveBehaviours'];

const getDisabledOptions = (
  type: 'severity' | 'frequency' | 'worst',
  step: string,
  opts: (number | string)[],
  doc: { [key: string]: string | number | null | undefined },
): { disabledOpts?: (number | string)[]; warning?: string } => {
  switch (type) {
    case 'severity': {
      const baseStep = step.split('Severity')[0];
      const freqVal = doc[baseStep + 'Frequency'];
      if (typeof freqVal !== 'number') return { disabledOpts: undefined };
      if (freqVal === 0)
        return { disabledOpts: opts.filter((o) => o !== 0), warning: 'behaviour.warnings.severityZero' };
      return { disabledOpts: opts.filter((o) => o === 0), warning: 'behaviour.warnings.severityValue' };
    }
    case 'frequency': {
      const baseStep = step.split('Frequency')[0];
      const seveVal = doc[baseStep + 'Severity'];
      if (typeof seveVal !== 'number') return { disabledOpts: undefined };
      if (seveVal === 0)
        return { disabledOpts: opts.filter((o) => o !== 0), warning: 'behaviour.warnings.frequencyZero' };
      return { disabledOpts: opts.filter((o) => o === 0), warning: 'behaviour.warnings.frequencyValue' };
    }
    case 'worst': {
      const baseStep = step.split('Worst')[0];
      const seveVal = doc[baseStep + 'Severity'];
      if (typeof seveVal !== 'number') return { disabledOpts: undefined };
      return {
        disabledOpts: opts.filter((o) => (typeof o === 'number' ? o < (seveVal as number) : false)),
        warning: 'behaviour.warnings.worst',
      };
    }
    default:
      return { disabledOpts: undefined };
  }
};

export const getStepContent =
  (doc: IPBAS) =>
  (
    stepName: string,
  ): {
    opts: (number | string)[];
    optsLocale: string;
    info?: string;
    assets?: number[];
    height?: number;
    instruction?: JSX.Element;
    disabledOptions?: (number | string)[];
    disabledMessage?: JSX.Element | string;
  } => {
    const severityOpts = [0, 1, 2, 3, 4, 8, 'U', 'N'];
    const frequencyOpts = [0, 1, 2, 3, 4, 'U', 'N'];
    const worstOpts = [0, 1, 2, 3, 4, 'U', 'N'];
    switch (stepName) {
      // Severity questions with answers from 0 to 4, 8, U and N
      case 'depressedMoodSeverity':
      case 'suicidalIdeationSeverity':
      case 'anxietySeverity':
      case 'delusionsOrParanoidThinkingSeverity':
      case 'hallucinationsSeverity':
      case 'perseverationSeverity':
      case 'apathySeverity':
      case 'irritabilitySeverity':
      case 'obsessiveCompulsiveBehavioursSeverity':
      case 'angryOrAggressiveBehaviourSeverity':
      case 'disorientedBehaviourSeverity': {
        const disabledOptions = getDisabledOptions('severity', stepName, severityOpts, doc as any);
        return {
          opts:
            stepName === 'apathySeverity' ||
            stepName === 'delusionsOrParanoidThinkingSeverity' ||
            stepName === 'perseverationSeverity'
              ? [0, 1, 2, 3, 4, 8, 9, 'U', 'N']
              : severityOpts,
          optsLocale: stepName,
          instruction: !fieldsWithNoInstructions.includes(
            stepName.replace('Severity', '').replace('Frequency', '').replace('Worst', ''),
          )
            ? instruction(stepName.replace('Severity', '').replace('Frequency', '').replace('Worst', ''))
            : undefined,
          height:
            stepName === 'perseverationSeverity' || stepName === 'apathySeverity'
              ? 29
              : stepName === 'irritabilitySeverity' || stepName === 'obsessiveCompulsiveBehavioursSeverity'
                ? 24
                : stepName === 'angryOrAggressiveBehaviourSeverity'
                  ? 34
                  : 20,
          disabledOptions: disabledOptions.disabledOpts,
          disabledMessage: disabledOptions.warning && <FormattedMessage id={disabledOptions.warning} />,
        };
      }

      // Frequency questions
      case 'depressedMoodFrequency':
      case 'suicidalIdeationFrequency':
      case 'anxietyFrequency':
      case 'irritabilityFrequency':
      case 'angryOrAggressiveBehaviourFrequency':
      case 'apathyFrequency':
      case 'perseverationFrequency':
      case 'obsessiveCompulsiveBehavioursFrequency':
      case 'delusionsOrParanoidThinkingFrequency':
      case 'disorientedBehaviourFrequency':
      case 'hallucinationsFrequency': {
        const disabledOptions = getDisabledOptions('frequency', stepName, frequencyOpts, doc as any);
        return {
          opts: frequencyOpts,
          optsLocale: 'frequency',
          disabledOptions: disabledOptions.disabledOpts,
          disabledMessage: disabledOptions.warning && <FormattedMessage id={disabledOptions.warning} />,
        };
      }
      // Worst questions
      case 'depressedMoodWorst':
      case 'suicidalIdeationWorst':
      case 'anxietyWorst':
      case 'irritabilityWorst':
      case 'angryOrAggressiveBehaviourWorst':
      case 'apathyWorst':
      case 'perseverationWorst':
      case 'obsessiveCompulsiveBehavioursWorst':
      case 'delusionsOrParanoidThinkingWorst':
      case 'disorientedBehaviourWorst':
      case 'hallucinationsWorst': {
        const disabledOptions = getDisabledOptions('worst', stepName, worstOpts, doc as any);
        return {
          opts: worstOpts,
          optsLocale: 'worst',
          disabledOptions: disabledOptions.disabledOpts,
          disabledMessage: disabledOptions.warning && <FormattedMessage id={disabledOptions.warning} />,
        };
      }
      default: {
        return {
          opts: [],
          optsLocale: '',
        };
      }
    }
  };
