import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import CollapseElem from '../../../../components/Collapse';
import HistoryRow from '../../../../components/HistoryRow';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowSubHeader from '../../../../components/HistoryRowSubHeader';
import HistoryRowVerticalItem from '../../../../components/HistoryRowVerticalItem';
import HistorySection from '../../../../components/HistorySection';

import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';
import { EditingLockedContentContext } from 'Containers/EditingLockedContentContext';

const StyledSubHeader = ({ title }: IStyledRow): JSX.Element => <HistoryRowSubHeader header={title} />;

const StyledRow = ({ title, value, condition, id }: IStyledRow): JSX.Element => (
  <React.Fragment>
    <span id={id} />
    {condition && condition === true ? (
      <HistoryRowVerticalItem header={title} value={value ? value : '-'} />
    ) : undefined}
  </React.Fragment>
);

const fm = (id: string, values?: { [key: string]: string | number | null }): JSX.Element => (
  <FormattedMessage id={id} values={values || undefined} />
);

const TTDataSection = ({ document }: ITTDataSection): JSX.Element => {
  return (
    <React.Fragment>
      {(document.atrophy || document.otherClinicallyRelevantFindingFound) && (
        <React.Fragment>
          {document.atrophy && (
            <React.Fragment>
              <StyledSubHeader title={fm('imaging.atrophy')} />
              <StyledRow
                id={'atrophyTTHistory'}
                title={fm('imaging.atrophy')}
                value={document.atrophy && fm(`imaging.opts.atrophy.${document.atrophy}`)}
                condition={true}
              />
              <StyledRow
                title={fm('imaging.atrophyLocalization')}
                value={document.atrophyLocalization}
                condition={document.atrophy === 'focal'}
              />
            </React.Fragment>
          )}
          {document.otherClinicallyRelevantFindingFound && (
            <React.Fragment>
              <StyledSubHeader title={fm('imaging.other')} />
              <StyledRow
                id={'otherRelevantTTHistory'}
                title={fm('imaging.otherClinicallyRelevantFindingFound')}
                value={
                  document.otherClinicallyRelevantFindingFound &&
                  fm(`imaging.opts.yesNo.${document.otherClinicallyRelevantFindingFound}`)
                }
                condition={true}
              />
              <StyledRow
                title={fm('imaging.whatFinding')}
                value={document.otherClinicallyRelevantFinding}
                condition={document.otherClinicallyRelevantFindingFound === 'yes'}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const TT = ({ documents, startEdit }: IOwnProps): JSX.Element => {
  const showProcedureCode = (d: ITT) => d.procedureCode && d.procedureCode.length > 0;
  const showStatement = (d: ITT) => d.statement && d.statement.length > 0;
  const { openEditing } = React.useContext(EditingLockedContentContext);

  return (
    <HistorySection
      headerText={<FormattedMessage id={'imaging.tt'} />}
      newButton={
        <DocumentCreationButton
          name="tt"
          text={'imaging.newtt'}
          onClick={startEdit({} as ITT, 'tt')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
      hasHistoryRows={true}
    >
      {documents &&
        documents
          .sort((n1, n2) => n1._cdate - n2._cdate)
          .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
          .reverse()
          .map((d: ITT, index) => (
            <React.Fragment key={d._id}>
              <HistoryRow
                headerText={formatPartialDate(d.date)}
                rowButton={
                  <HistoryRowControls
                    document={d}
                    startEdit={
                      d._lockedFor && d._source !== null
                        ? (doc) => () => openEditing(doc, 'imaging.scoliosis')
                        : startEdit
                    }
                  />
                }
                controlsMargin={false}
              >
                <>
                  {showProcedureCode(d) && (
                    <StyledRow
                      id={'procedureCode'}
                      title={fm('imaging.procedureCode')}
                      value={d.procedureCode}
                      condition={true}
                    />
                  )}
                  {showStatement(d) && (
                    <StyledRow
                      id={'statement'}
                      title={fm('imaging.statementText')}
                      value={d.statement}
                      condition={true}
                    />
                  )}
                  {index === 0 ? (
                    <TTDataSection document={d} />
                  ) : (
                    (d.atrophy || d.otherClinicallyRelevantFindingFound) && (
                      <CollapseElem
                        localeIDs={{ showMessage: 'imaging.show', hideMessage: 'imaging.hide' }}
                        amount={documents.length}
                      >
                        <div style={{ marginBottom: '1rem' }}>
                          <TTDataSection document={d} />
                        </div>
                      </CollapseElem>
                    )
                  )}
                </>
              </HistoryRow>
            </React.Fragment>
          ))}
    </HistorySection>
  );
};

interface ITTDataSection {
  document: ITT;
}

interface IStyledRow {
  title: string | JSX.Element;
  value?: any;
  condition?: boolean;
  id?: string;
}

interface IOwnProps {
  documents: ITT[];
  startEdit: (document: ITT, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export default TT;
