/**
 * Create a row for history listing, single item/event (lighter header background-color).
 */
import * as React from 'react';

import colors from '../../config/theme/colors';
import { styleDocument } from '../../config/theme/componentTheme';
import { Container, Item } from '../Grid';
import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import { styled } from '@mui/system';

const StyledHeader = styled('div', { shouldForwardProp: (prop) => prop !== 'active' && prop !== 'open' })(
  ({ active, open }: { active: boolean; open?: boolean }) => ({
    margin: `0 -${styleDocument.padding}`,
    padding: `0 ${styleDocument.padding}`,
    height: '5rem',
    backgroundColor: active ? colors.quinary : colors.lightGray,
    color: colors.primary,
    fontSize: '2rem',
    cursor: open !== undefined ? 'pointer' : 'default',
    '&:hover': {
      cursor: open !== undefined ? 'pointer' : 'default',
    },
  }),
);

const RowContent = styled('div')({
  padding: '3rem 0 4rem 0',
});

// 4.5rem margin for HistoryRowControls three dots menu button (can be false if controls are used as historyrow buttons)
const ButtonArea = styled('div', { shouldForwardProp: (prop) => prop !== 'controlsMargin' })(
  ({ controlsMargin }: { controlsMargin: boolean }) => ({
    marginRight: controlsMargin ? '4.5rem' : '0',
  }),
);

const IconArea = styled('div')({
  width: '4rem',
  marginLeft: '-4rem',
});

const OpenClosedIcon = ({ open }: { open: boolean }): JSX.Element | null => (
  <IconArea>
    <Container justifyContent="center">
      <Item>
        {open ? (
          <ArrowDropDown color="inherit" fontSize="large" style={{ display: 'block' }} />
        ) : (
          <ArrowRight color="inherit" fontSize="large" style={{ display: 'block' }} />
        )}
      </Item>
    </Container>
  </IconArea>
);

const HistoryRow = ({
  headerText,
  children,
  active,
  rowButton,
  controlsMargin = true,
  onClick,
  open,
}: IOwnProps): JSX.Element => (
  <React.Fragment>
    <StyledHeader active={active || false} open={open}>
      <Container alignItems="center" style={{ height: '100%' }}>
        {open !== undefined && (
          <Item onClick={onClick}>
            <OpenClosedIcon open={open} />
          </Item>
        )}
        <Item xs={true} onClick={onClick}>
          {headerText || null}
        </Item>
        {rowButton && (
          <Item>
            <Container justifyContent="flex-end">
              <Item>
                <ButtonArea controlsMargin={controlsMargin}>{rowButton}</ButtonArea>
              </Item>
            </Container>
          </Item>
        )}
      </Container>
    </StyledHeader>
    <RowContent>{children}</RowContent>
  </React.Fragment>
);

interface IOwnProps {
  headerText?: string | number | JSX.Element;
  children: JSX.Element;
  active?: boolean;
  rowButton?: JSX.Element;
  controlsMargin?: boolean;
  onClick?: () => void;
  open?: boolean;
}

export default HistoryRow;
