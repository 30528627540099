/**
 * Create a section for history listing. Used for main categories (darker header background-color).
 */
import * as React from 'react';

import colors from '../../config/theme/colors';
import { styleDocument } from '../../config/theme/componentTheme';
import { Container, Item } from '../Grid';
import ToolTip from '../ToolTip';
import { createID } from '../../utility/appendIDs';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';

interface IStyleProps {
  inactive?: string;
  mode?: string;
}

const StyledSection = styled('div')({});

const StyledHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'inactive' && prop !== 'mode',
})(({ inactive, mode }: IStyleProps) => ({
  margin: `0 -${styleDocument.padding}`,
  padding: `0 ${styleDocument.padding}`,
  height: mode === 'noBar' ? '2rem' : '5rem',
  backgroundColor: mode === 'noBar' ? 'transparent' : !inactive ? colors.primary : colors.quaternary,
  color: mode === 'noBar' ? colors.primary : colors.white,
  fontSize: '2rem',
  fontWeight: 600,
}));

const SectionContent = styled('div', { shouldForwardProp: (prop) => prop !== 'hasHistoryRows' })(
  ({ hasHistoryRows }: { hasHistoryRows?: boolean }) => ({
    padding: hasHistoryRows ? '1rem 0 0 0' : '3rem 0 4rem 0',
    '&:empty': {
      padding: '3rem 0 4rem 0',
    },
  }),
);

// 4.5rem margin for HistoryRowControls three dots menu button
const ButtonArea = styled('div')({
  marginRight: '4.5rem',
});

const InfoText = styled('div')({
  fontWeight: 'normal',
  fontSize: '1.4rem',
  color: styleDocument.documentHeader.infoTextColor,
});

const HistorySection = ({
  headerText,
  children,
  inactive = false,
  mode = 'default',
  newButton,
  hasHistoryRows = false,
  sectionInfoKey,
}: IOwnProps): JSX.Element => {
  const headerId = React.useRef(createID());
  const headerElement = document.getElementById(headerId.current);
  return (
    <StyledSection>
      <StyledHeader mode={mode} inactive={inactive ? 'true' : undefined}>
        <Container alignItems="center" style={{ height: '100%' }}>
          <Item
            id={headerId.current}
            xs={true}
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {headerText &&
              (headerElement && headerElement?.scrollWidth > headerElement?.clientWidth ? (
                <ToolTip
                  title={''}
                  description={typeof headerText === 'number' ? headerText.toString() : headerText}
                  content={<span>{typeof headerText === 'number' ? headerText.toString() : headerText}</span>}
                  hover={true}
                />
              ) : (
                headerText
              ))}
          </Item>
          {newButton && (
            <Item>
              <Container justifyContent="flex-end">
                <Item>
                  <ButtonArea>{newButton}</ButtonArea>
                </Item>
              </Container>
            </Item>
          )}
        </Container>
      </StyledHeader>
      {sectionInfoKey && (
        <Container style={{ marginBlock: '2rem' }}>
          <Item xs={7}>
            <InfoText>
              <FormattedMessage id={sectionInfoKey} />
            </InfoText>
          </Item>
        </Container>
      )}
      <SectionContent hasHistoryRows={hasHistoryRows}>{children}</SectionContent>
    </StyledSection>
  );
};

interface IOwnProps {
  headerText?: string | number | JSX.Element;
  children: JSX.Element | Array<JSX.Element | undefined> | undefined;
  inactive?: boolean;
  mode?: 'default' | 'noBar';
  newButton?: JSX.Element;
  hasHistoryRows?: boolean; // If the HistorySection has HistoryRows, then have a smaller margin after section header
  sectionInfoKey?: string;
}

export default HistorySection;
