/**
 * Wrapper that displays a title (date/type/etc) for the history item.
 * Renders child elements under the title.
 * Also renders possible buttons next to the child elements.
 */

const ColorMap: { [key: string]: { dividers: string; text: string } } = {
  light: { dividers: colors.appBlue.default + 40, text: colors.appBlue.default },
  warning: { dividers: colors.warning.dark + 40, text: colors.warning.dark },
  gray: { dividers: colors.darkGray + 40, text: colors.darkGray },
};

import { IconButton, Menu, MenuItem, Theme } from '@mui/material';
import { styled } from '@mui/system';
import { ArrowDropDown, ArrowRight, MoreVert } from '@mui/icons-material';
import * as React from 'react';

import LeftBarWrapper from '../LeftBarWrapper';
import ActionButtonRounded, { IActionButtonRounded } from 'Components/ActionButtonRounded';
import { Container, Item } from 'Components/Grid';
import colors from '../../../config/theme/colors';

const StyledMenuIcon = styled(({ onClick }: { onClick?: (e: React.MouseEvent<Element | HTMLElement>) => void }) => (
  <IconButton onClick={onClick}>
    <MoreVert />
  </IconButton>
))({
  color: '#6c96ae',
  cursor: 'pointer',
});

// Horizontal bar shown between blocks
const StyledDivider = styled('div')(({ color, theme }) => ({
  backgroundColor: color || theme.palette.primary.main,
  minWidth: '100%',
  minHeight: '0.2rem',
  margin: '0.7rem 0',
}));

const StyledHistoryTitle = styled('h2', {
  shouldForwardProp: (prop) => prop !== 'collapseOpen' && prop !== 'color' && prop !== 'contentMargin',
})(
  ({
    theme,
    collapseOpen,
    color,
    contentMargin,
  }: {
    theme?: Theme;
    collapseOpen: boolean | null;
    color?: string;
    contentMargin: number;
  }) => ({
    color: color || theme?.palette.primary.main,
    margin: 0,
    marginBottom: collapseOpen || collapseOpen === null ? `${contentMargin}rem` : 0,
    cursor: collapseOpen !== null ? 'pointer' : 'default',
  }),
);

// Horizontal bar shown under the title
const TitleDivider = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  minWidth: '100%',
  minHeight: '0.2rem',
  margin: '0.7rem 0 0.7rem -2.5rem',
}));

const CollapseArrowArea = ({
  collapseOpen,
  onClick,
  color,
}: {
  collapseOpen: boolean;
  onClick: (() => void) | undefined;
  color?: string;
}) => {
  const iconProps = {
    fontSize: 'large' as const,
    style: {
      marginTop: '-0.7rem',
      color: color || colors.brandBlue.default,
    },
  };
  return (
    <Item style={{ width: '3.5rem', cursor: 'pointer' }} onClick={onClick}>
      <Container justifyContent="center">
        {collapseOpen ? <ArrowDropDown {...iconProps} /> : <ArrowRight {...iconProps} />}
      </Container>
    </Item>
  );
};

const BlockWrapper = ({
  title,
  children,
  divider = true,
  titleDivider = false,
  contentMargin = 3.5,
  collapse,
  buttons,
  menuItems,
  color,
}: IBlockWrapper) => {
  const [internalCollapseOpen, setInternalCollapseOpen] = React.useState<boolean | null>(
    collapse ? collapse.open || false : null,
  );
  const collapseOpen = collapse?.collapseHandler ? collapse.open || false : internalCollapseOpen;
  const setCollapseOpen = collapse?.collapseHandler ? collapse?.collapseHandler : setInternalCollapseOpen;

  const [anchor, setAnchor] = React.useState<null | Element | HTMLElement>(null);
  const toggleMenu = (e: React.MouseEvent<Element | HTMLElement>): void => {
    setAnchor(!anchor ? e.currentTarget : null);
  };

  const themeValues = color && color in ColorMap ? ColorMap[color] : null;
  return (
    <div>
      {divider && <StyledDivider color={themeValues?.dividers} />}
      <LeftBarWrapper leftPadding={!(collapseOpen !== null)} color={themeValues?.dividers}>
        <Container>
          {collapseOpen !== null && (
            <CollapseArrowArea
              collapseOpen={collapseOpen}
              onClick={collapseOpen === null ? undefined : () => setCollapseOpen(!collapseOpen)}
              color={themeValues?.text}
            />
          )}
          <Item xs={true}>
            {title && (
              <StyledHistoryTitle collapseOpen={collapseOpen} color={themeValues?.text} contentMargin={contentMargin}>
                <Container alignItems="center">
                  <Item xs={true} onClick={collapseOpen === null ? undefined : () => setCollapseOpen(!collapseOpen)}>
                    {title}
                  </Item>
                  {buttons && (
                    <Item xs={'auto'}>
                      <Container>
                        {buttons.map((b, i) => (
                          <Item key={i} style={{ marginLeft: '2rem' }}>
                            <ActionButtonRounded
                              text={b.title}
                              onClick={b.onClick}
                              width={10}
                              height={3.5}
                              fontSize={16}
                              {...b.props}
                            />
                          </Item>
                        ))}
                      </Container>
                    </Item>
                  )}
                  {menuItems && menuItems.length > 0 && (
                    <Item xs="auto">
                      <StyledMenuIcon onClick={toggleMenu} />
                      <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={toggleMenu}>
                        {menuItems.map((mi, i) => (
                          <MenuItem
                            key={'mi' + i}
                            onClick={() => {
                              mi.onClick();
                              setAnchor(null);
                            }}
                          >
                            {mi.title}
                          </MenuItem>
                        ))}
                      </Menu>
                    </Item>
                  )}
                </Container>
                {titleDivider && collapseOpen && <TitleDivider />}
              </StyledHistoryTitle>
            )}
            {collapseOpen === null ? children : collapseOpen ? children : null}
          </Item>
        </Container>
      </LeftBarWrapper>
    </div>
  );
};

interface IBlockWrapper {
  title?: React.ReactNode;
  divider?: boolean;
  titleDivider?: boolean;
  /** How big is margin between title and content (rem) */
  contentMargin?: number;
  collapse?: {
    /** If handler is set then this is the state of the collapse. Otherwise if set it is the initial collapse state */
    open?: boolean;
    /** External handler for the collapse */
    collapseHandler?: () => void;
  };
  buttons?: {
    title: IActionButtonRounded['text'];
    onClick: IActionButtonRounded['onClick'];
    props?: Omit<IActionButtonRounded, 'text' | 'onClick'>;
  }[];
  menuItems?: {
    title: JSX.Element;
    onClick: () => void;
  }[];
  children?: React.ReactNode | React.ReactNode[] | null;
  color?: 'warning' | 'light' | 'gray';
}

export default BlockWrapper;
