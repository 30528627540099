/**
 * Creates a single header-value pair for history listing.
 */
import * as React from 'react';
import { styled } from '@mui/system';

import { historyHeader, historyValue, historyValuePadding } from '../../config/theme/componentTheme';

const StyledHeader = styled('div')({
  marginBottom: '0.5rem',
});

const StyledValue = styled('div')({
  wordWrap: 'break-word',
});

const HistoryRowBasicItem = ({ header, value }: IOwnProps): JSX.Element => (
  <div style={{ padding: historyValuePadding }}>
    <StyledHeader style={historyHeader}>{header}</StyledHeader>
    <StyledValue style={historyValue}>{value ? value : '-'}</StyledValue>
  </div>
);

interface IOwnProps {
  header: string | number | JSX.Element;
  value?: string | string[] | number | JSX.Element;
}

export default HistoryRowBasicItem;
