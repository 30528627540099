import ActionButtonRounded from 'Components/ActionButtonRounded';
import { Container, Item } from 'Components/Grid';
import HistoryRowSubHeader from 'Components/HistoryRowSubHeader';
import { sortPartialDate } from 'neuro-utils';
import * as React from 'react';
import { MyServiceContext } from '..';
import { ICompactVaultUser } from 'neuro-data-structures';
import { isLocaleKey, supplyShortagesInquiry } from 'neuro-schemas';
import SuppliesHistoryWindow from './components/SuppliesHistoryWindow';
import { ICapabilityContextProps } from 'Containers/CapabilityHandler';
import SuppliesHistoryTable from './components/SuppliesHistoryTable';

const SuppliesHistory = ({ supplyShortagesInquiryDocs, users }: IOwnProps): JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { fm, locale, setEditingObj } = myServContext;

  const supplyShortagesInquiryLocales = supplyShortagesInquiry.localizations;
  const useLocale = isLocaleKey(locale) ? locale : 'fi';

  const fmSupplyShortagesInquiry = (id: string) => supplyShortagesInquiryLocales[useLocale][id];

  const historyData: (ISupplyShortagesInquiry & IControlProps)[] = supplyShortagesInquiryDocs
    .slice()
    .sort((a, b) => sortPartialDate(b.date, a.date));

  const unresolvedDocs = historyData.filter((d) => !d.status || d.status === 'pending');
  const resolvedDocs = historyData.filter((d) => d.status === 'completed' || d.status === 'rejected');

  return (
    <React.Fragment>
      <Container style={{ justifyContent: 'space-between' }}>
        <Item>
          <HistoryRowSubHeader header={fm('myService.unresolvedSuppliesRequests')} />
        </Item>
      </Container>
      {unresolvedDocs.length > 0 ? (
        unresolvedDocs.map((d, i) => (
          <SuppliesHistoryWindow
            key={i}
            supplyShortagesInquiryDoc={d}
            fmSupplyShortagesInquiry={fmSupplyShortagesInquiry}
          />
        ))
      ) : (
        <div style={{ marginBottom: '4.5rem' }}>
          <ActionButtonRounded
            text="myService.addNewSuppliesRequest"
            width={18}
            height={4}
            fontSize={16}
            onClick={() => setEditingObj({ type: 'supplyShortagesInquiry', data: null })}
            uppercase={false}
            filled
          />
        </div>
      )}
      <Container style={{ justifyContent: 'space-between' }}>
        <Item>
          <HistoryRowSubHeader header={fm('myService.history')} />
        </Item>
      </Container>
      <SuppliesHistoryTable supplyShortagesInquiryDocs={resolvedDocs ?? []} users={users} />
    </React.Fragment>
  );
};

interface IOwnProps extends ICapabilityContextProps {
  supplyShortagesInquiryDocs: (ISupplyShortagesInquiry & IControlProps)[];
  users: ICompactVaultUser[];
}

export default SuppliesHistory;
