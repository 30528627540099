import * as React from 'react';

import FormSection from 'Components/FormSection';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import FormSectionBar from 'Components/FormSectionBar';
import AdverseEffects from './AdverseEffects';
import Complications from './Complications';
import Sessions from './Sessions';
import { TDCSContext } from '..';

const Form = (): JSX.Element => {
  const editing = true;

  const tdcsContext = React.useContext(TDCSContext);
  const { formData, fm, anchor, doctorsOrdersReview } = tdcsContext;

  // Ref for scrolling to specific section of the form
  const ref = React.createRef() as React.RefObject<HTMLDivElement>;

  // When ending review of doctor's orders, scroll back to sessions and protocols section
  React.useEffect(() => {
    if (ref.current && !doctorsOrdersReview.active) {
      scroll({ top: ref.current.getBoundingClientRect().top + window.scrollY - 150 });
    }
  }, [ref]);

  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="general.date">
          <InputHandler editing={editing} name="date" type="PartialDate" formData={formData} dateDefault="now" />
        </FormRow>
      </FormSection>
      <FormSectionBar header="tdcs.type" />
      <FormSection>
        <FormRow title="tdcs.type">
          <InputHandler
            editing={editing}
            name="type"
            type="Radio"
            formData={formData}
            options={['intense', 'upkeep']}
            optionFormatter={(id: string | number): string => fm(`tdcs.opts.${id}`)}
          />
        </FormRow>
      </FormSection>
      <FormSectionBar header="tdcs.sessionsAndProtocols" />
      <FormSection headerRef={anchor === 'sessionsAndProtocols' ? ref : undefined}>
        <Sessions />
      </FormSection>
      <FormSectionBar header="tdcs.events" />
      <FormSection>
        <Complications formData={formData} />
        <AdverseEffects formData={formData} />
      </FormSection>
      <FormSection>
        <FormRow title="tdcs.end">
          <InputHandler
            editing={editing}
            name="hasEnded"
            type="CheckboxSingle"
            formData={{
              onChange: (e) => {
                formData.onChange?.({ ...e, endDate: undefined });
              },
              document: formData.document,
            }}
            option={'true'}
            optionFormatter={(): string => fm('tdcs.end')}
          />
        </FormRow>
        {formData.document.hasEnded && (
          <FormRow title="tdcs.endDate">
            <InputHandler
              editing={editing}
              name="endDate"
              type="PartialDate"
              formData={formData}
              dateDefault="now"
              dateHook={{ dateHookFloor: formData.document.date }}
            />
          </FormRow>
        )}
      </FormSection>
    </React.Fragment>
  );
};

export default Form;
