import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import { Container, Item } from '../../../../../components/Grid';
import InfoPopover from '../../../../../components/InfoPopover';
import InputHandler from '../../../../../components/InputHandler';
import QuestionStepper from '../../../../../components/QuestionStepper';
import colors from '../../../../../config/theme/colors';
import { uhdrsMotorRatingScaleAutomaticScore } from '../../../utils';
import { steps as uhdrsMotorSteps, getStepContent as getUHDRSMotorSteps } from '../uhdrsMotorSettings';
import {
  disabledSteps as uhdrsMotorDisabledSteps,
  getDisabledStepContent as getUHDRSMotorDisabledSteps,
} from '../uhdrsMotorDisabledSettings';
import { omit, path, values } from 'ramda';
import ConfirmationDialog from '../../../../../components/ConfirmationDialog';
import { controlProps } from '../../../../../utility/documentHandling';
import { useAppDispatch as useDispatch } from 'Store/index';
import { actions } from '../../../../../store/form';
import { isIE } from '../../../../../utility/isIE';

// Used for resetting fields when selecting to use manual score
const temp = {
  shortTest: undefined,
  ocularPursuitHorizontal: undefined,
  ocularPursuitVertical: undefined,
  saccadeInitiationHorizontal: undefined,
  saccadeInitiationVertical: undefined,
  saccadeVelocityHorizontal: undefined,
  saccadeVelocityVertical: undefined,
  dysarthria: undefined,
  tongueProtrusion: undefined,
  fingerTapsRight: undefined,
  fingerTapsLeft: undefined,
  pronateSupinateHandsRight: undefined,
  pronateSupinateHandsLeft: undefined,
  luria: undefined,
  rigidityArmsRight: undefined,
  rigidityArmsLeft: undefined,
  bradykinesiaBody: undefined,
  maximalDystoniaTrunk: undefined,
  maximalDystoniaRue: undefined,
  maximalDystoniaLue: undefined,
  maximalDystoniaRle: undefined,
  maximalDystoniaLle: undefined,
  maximalChoreaFace: undefined,
  maximalChoreaBol: undefined,
  maximalChoreaTrunk: undefined,
  maximalChoreaRue: undefined,
  maximalChoreaLue: undefined,
  maximalChoreaRle: undefined,
  maximalChoreaLle: undefined,
  gait: undefined,
  tandemWalking: undefined,
  retropulsionPullTest: undefined,
};

// Used for resetting fields when changing to short test
const temp2 = {
  ocularPursuitHorizontal: undefined,
  ocularPursuitVertical: undefined,
  saccadeInitiationHorizontal: undefined,
  saccadeInitiationVertical: undefined,
  saccadeVelocityHorizontal: undefined,
  saccadeVelocityVertical: undefined,
  tongueProtrusion: undefined,
  fingerTapsRight: undefined,
  fingerTapsLeft: undefined,
  pronateSupinateHandsRight: undefined,
  pronateSupinateHandsLeft: undefined,
  luria: undefined,
  rigidityArmsRight: undefined,
  rigidityArmsLeft: undefined,
  bradykinesiaBody: undefined,
  maximalDystoniaTrunk: undefined,
  maximalDystoniaRue: undefined,
  maximalDystoniaLue: undefined,
  maximalDystoniaRle: undefined,
  maximalDystoniaLle: undefined,
  maximalChoreaFace: undefined,
  maximalChoreaBol: undefined,
  maximalChoreaTrunk: undefined,
  maximalChoreaRue: undefined,
  maximalChoreaLue: undefined,
  maximalChoreaRle: undefined,
  maximalChoreaLle: undefined,
};

const UHDRSForm = ({ formData, viewing, editing }: IOwnProps): JSX.Element => {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [jumpStep, setJumpStep] = React.useState<number>(0);

  const toggleDialog = (): void => setDialogOpen(!dialogOpen);

  const dispatch = useDispatch();
  const resetTest = (): void => {
    formData.onChange &&
      formData.onChange({
        totalScoreOnly: [true],
      });
    editing && dispatch(actions.updateFormValuesBatch(temp, editing));
    setDialogOpen(false);
  };

  const onChecked = (onChangeValues: TOnChangeValues): void => {
    const omitted = [...controlProps, 'date', 'shortTest', 'manualScore', 'totalScoreOnly'];
    const scoreFields = values(omit(omitted, formData.document)).filter((v) => v || v === 0);

    if (scoreFields.length > 0) {
      setDialogOpen(true);
    } else {
      const name = Object.keys(onChangeValues)[0];
      const value = onChangeValues[name];
      formData.onChange &&
        formData.onChange({
          [name]: value,
        });
    }
  };

  const jumpToQuestion = (q: string): void => {
    if (isIE()) return; // auto scrolling is known to cause trouble in IE11

    const steps = uhdrsMotorSteps.map((s) => (typeof s === 'string' ? s : Object.keys(s)[0]));

    // set stepper to open at the right question...
    const idx = steps.indexOf(q);

    setJumpStep(idx);
    // ...and scroll to it
    setTimeout((): void => {
      const scrollOffset = 90 * idx + 371;
      (parent ? parent : window).scrollTo({ behavior: 'smooth', top: scrollOffset });
    }, 600);
  };

  const onShortTest = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    formData.onChange &&
      formData.onChange({
        [name]: value,
      });
    // If checkbox is checked
    if (Array.isArray(value) && value.length > 0 && value[0]) {
      // Clear other questions
      editing && dispatch(actions.updateFormValuesBatch(temp2, editing));
      // Jump to "Dysarthria" question
      jumpToQuestion('dysarthria');
    } else {
      // Jump to start
      jumpToQuestion('ocularPursuit');
    }
  };

  return (
    <React.Fragment>
      <div style={{ fontStyle: 'italic', color: colors.darkGray, margin: '0rem 0 3rem 0' }}>
        The Unified Huntington Disease Rating Scale and revised UHDR® &apos;99 and accompanying manual (collectively,
        the “UHDRS™”) are the copyrighted intellectual property of the Huntington Study Group, Ltd. (“HSG”).
        Organizations seeking to use these tools and/or materials must obtain prior written permission from the HSG. The
        request form can be found in https://huntingtonstudygroup.org/.
      </div>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={!viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow title="motorFunctionHuntington.uhdrsTotalScoreOnly">
        <InputHandler
          type="Checkbox"
          editing={!viewing}
          name="totalScoreOnly"
          formData={{ onChange: onChecked, document: formData.document }}
          preset="yes"
        />
      </FormRow>
      <ConfirmationDialog
        open={dialogOpen}
        text={<FormattedMessage id="motorFunctionHuntington.uhdrsWarningPopup" />}
        cancel={{ callback: toggleDialog }}
        confirm={{ callback: resetTest }}
      />
      {path(['totalScoreOnly', 0], formData.document) !== true ? (
        <>
          <FormSection>
            <FormRow title="motorFunctionHuntington.uhdrsMotorPreQuestion">
              <InputHandler
                type="Checkbox"
                editing={true}
                name="shortTest"
                formData={{
                  document: formData.document,
                  onChange: onShortTest,
                }}
                preset="yesForceEn"
              />
            </FormRow>
            <QuestionStepper
              viewing={viewing}
              formData={formData}
              pageType={'motorFunctionHuntington'}
              docType={'uhdrsMotorRatingScale'}
              steps={path(['shortTest', 0], formData.document) ? uhdrsMotorDisabledSteps : uhdrsMotorSteps}
              getStepContent={
                path(['shortTest', 0], formData.document) ? getUHDRSMotorDisabledSteps : getUHDRSMotorSteps
              }
              jumpStep={jumpStep}
            />
          </FormSection>
          <FormSection>
            <FormRow title="motorFunctionHuntington.uhdrsScore">
              <Container style={{ fontSize: '1.8rem', fontWeight: 600, color: colors.primary }}>
                {uhdrsMotorRatingScaleAutomaticScore(formData.document) === 'notCounted' ? (
                  <InfoPopover
                    text={
                      (formData.document.dysarthria || formData.document.dysarthria === 0) &&
                      (formData.document.retropulsionPullTest || formData.document.retropulsionPullTest === 0) &&
                      (formData.document.gait || formData.document.gait === 0) &&
                      (formData.document.tandemWalking || formData.document.tandemWalking === 0) &&
                      formData.document.shortTest?.[0] === true
                        ? 'motorFunctionHuntington.uhdrsShortTestSelected'
                        : 'general.notFilled'
                    }
                    color="primary"
                  />
                ) : (
                  uhdrsMotorRatingScaleAutomaticScore(formData.document)
                )}
              </Container>
            </FormRow>
          </FormSection>
          <Item style={{ fontStyle: 'italic' }}>
            <FormattedMessage id="motorFunctionHuntington.uhdrsMotorInfoPartOne" />
          </Item>
          <Item style={{ fontStyle: 'italic' }}>
            <FormattedMessage id="motorFunctionHuntington.uhdrsMotorInfoPartTwo" />
          </Item>
        </>
      ) : (
        <FormSection>
          <FormRow
            title={!viewing ? 'motorFunctionHuntington.uhdrsMotorManualScore' : 'motorFunctionHuntington.uhdrsScore'}
          >
            <Container style={{ fontSize: '1.8rem', fontWeight: 600, color: colors.primary }}>
              <InputHandler
                type="NumberField"
                editing={!viewing}
                name="manualScore"
                formData={formData}
                height={3}
                width={12}
                placeholder="motorFunctionHuntington.uhdrsMotorManualScorePlaceholder"
                maxLength={3}
                min={0}
                max={124}
              />
            </Container>
          </FormRow>
          <Item style={{ fontStyle: 'italic' }}>
            <FormattedMessage id="motorFunctionHuntington.uhdrsMotorInfoPartOne" />
          </Item>
          <Item style={{ fontStyle: 'italic' }}>
            <FormattedMessage id="motorFunctionHuntington.uhdrsMotorInfoPartTwo" />
          </Item>
        </FormSection>
      )}
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<IUHDRSMotorRatingScale>;
  viewing: boolean;
  editing: any;
}

export default UHDRSForm;
