import { equals, filter, includes, intersperse, mergeRight, path, uniq, values } from 'ramda';
import { sortStrengths } from '../../../../../../../utility/randomUtil';
import { a2String } from '../../../../../../../utility/string';

export const formatStrenghtString = (s: IStrengths): string => a2String(intersperse(' / ', values(s)));

export const strengthsArray = (packages: IMedicationPackage[]): { [key: string]: string }[] => {
  const strengths = packages.map((p: IMedicationPackage) => p.strengths);
  return uniq(strengths).sort((a1, a2) => sortStrengths(a1, a2));
};

/**
 * Filter duplicates from medication packages based on pkg size, type and strengths.
 * @param packages Medication packages to filter
 * @returns Filtered medication packages
 */
export const removePackageDuplicates = (packages: Array<IMedicationPackage>): Array<IMedicationPackage> => {
  return packages.filter(
    (_package: IMedicationPackage, _index: number, _self: Array<IMedicationPackage>) =>
      _index ===
      _self.findIndex(
        (p: IMedicationPackage) =>
          p.packageSize === _package.packageSize &&
          p.packageType === _package.packageType &&
          equals(p.strengths, _package.strengths),
      ),
  );
};

export const valueFilter = (
  availableStrengths: Array<IStrengths>,
  strengths: Array<IStrengths>,
  comparison: (n1: number, n2: number) => boolean,
): number | string => {
  const numberOfStrengths = path(
    [0],
    filter(
      (j) => j !== null,
      availableStrengths.map((s, i) => (includes(s, strengths) ? i : null)),
    ),
  ) as number;
  return comparison(numberOfStrengths, 0) ? numberOfStrengths : '';
};

export const strengthsOnChange =
  (
    onChange: IFormData['onChange'],
    strArr: Array<IStrengths>,
    index: number,
    thisRegimen?: Regimen,
    allRegimen?: Array<Regimen>,
  ) =>
  (values: TOnChangeValues): void => {
    if (thisRegimen && allRegimen) {
      const name = Object.keys(values)[0];
      const value = [strArr[values[name] as number]];

      const thisRegimens = { ...thisRegimen };
      const allRegimens = [...allRegimen];

      allRegimens[index] = mergeRight(thisRegimens, { [name]: value as any, vnr: '' });
      onChange?.({ regimen: allRegimens });
    }
  };

export const otherOnChange =
  (onChange: IFormData['onChange'], index: number, thisRegimen?: Regimen, allRegimen?: Array<Regimen>) =>
  (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    if (thisRegimen && allRegimen) {
      const thisRegimens = { ...thisRegimen };
      const allRegimens = [...allRegimen];

      allRegimens[index] = mergeRight(thisRegimens, { [name]: value } as any);
      // Update all regimen
      onChange?.({ regimen: allRegimens });
    }
  };
