import { formatPartialDate, formatTime, sortPartialDate } from 'neuro-utils';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Container, Item } from '../../../../../components/Grid';
import {
  DeleteButton,
  EditButton,
  HistoryEventListItem,
  HistoryListHeader,
  RegimenIconByType,
  StatusIconByStatus,
} from '../Medication/components/Common';
import { styled } from '@mui/material';

const StyledButtonArea = styled(Item)(({ bigMargin }: { bigMargin?: boolean }) => ({
  '* > &:not(:last-of-type)': { marginBottom: bigMargin ? '2.5rem' : '1.5rem' },
}));

const Regimen = ({ regimen }: { regimen: IVitaminRegimen }) => {
  return (
    <>
      <HistoryEventListItem title="medication.otherTreatment.dosage" value={regimen.dosage} />
      <HistoryEventListItem
        title="medication.otherTreatment.frequency"
        value={regimen.frequency && <FormattedMessage id={'medication.otherTreatment.opts.' + regimen.frequency} />}
      />
      {regimen.frequency === 'other' && (
        <HistoryEventListItem title="medication.otherTreatment.otherFrequency" value={regimen.otherFrequency} />
      )}
    </>
  );
};

const Ending = ({ doc }: { doc: IMedication }) => {
  return (
    <>
      <HistoryEventListItem
        title="general.date"
        value={
          <>
            {formatPartialDate(doc.endDate)}
            {`${doc.endTime ? ` (${formatTime(doc.endTime)})` : ''}`}
          </>
        }
      />
      <HistoryEventListItem
        title="medication.endedReason"
        value={
          <>
            {doc.endReason?.map((reason, i) => (
              <React.Fragment key={reason}>
                <FormattedMessage id={`medication.opts.${reason}`} />
                {`${i < (doc.endReason?.length || 0) - 1 ? ', ' : ''}` /* Print commas between reasons */}
              </React.Fragment>
            ))}
          </>
        }
      />
      <HistoryEventListItem title="medication.endedReasonOther" value={doc.endReasonOther} />
    </>
  );
};

const OtherTreatmentsHistoryRowData = ({ d, startEdit }: IOwnProps): JSX.Element => {
  const treatmentEnding =
    'type' in d && d.type === 'vitaminD' && d.hasEnded?.[0] === true ? { type: 'ending', data: d } : null;

  const events =
    'type' in d && d.type === 'vitaminD'
      ? (
          [
            ...(treatmentEnding ? [treatmentEnding] : []),
            ...(d.regimen || []).map((reg) => ({ type: 'regimen', data: reg })),
          ] as Array<{ type: 'regimen' | 'ending'; data: any }>
        ).sort((e1, e2) => {
          const e1date = e1.data.date || e1.data.startDate;
          const e2date = e2.data.date || e2.data.startDate;
          if (e2.type === 'ending') return 1;
          return sortPartialDate(e2date, e1date);
        })
      : null;

  const [historyItemOpenId, setHistoryItemOpeId] = React.useState<string | null>(null);
  return (
    <>
      <Container style={{ padding: '1rem 1rem 1rem 0' }}>
        <Item xs={8.5}>
          {events &&
            events.map((ev, i) => {
              const date: PartialDate | undefined =
                'date' in ev.data ? ev.data.date : 'startDate' in ev.data ? ev.data.startDate : undefined;
              const id: string = 'id' in ev.data ? ev.data.id : '_id' in ev.data ? ev.data._id : ev.type;
              const historyItemOpen = historyItemOpenId === null ? i === 0 : id === historyItemOpenId;
              return (
                <React.Fragment key={id}>
                  <HistoryListHeader
                    open={historyItemOpen}
                    date={date}
                    type={ev.type}
                    typeIcon={ev.type === 'ending' ? StatusIconByStatus('ended') : RegimenIconByType('default')}
                    onClick={() => setHistoryItemOpeId(id === historyItemOpenId ? null : id)}
                  />
                  {historyItemOpen && (
                    <>
                      <Container style={{ marginBottom: '2rem' }}>
                        <Item xs={0.6}></Item>
                        <Item xs={1.5}></Item>
                        <Item xs={0.7}></Item>
                        <Item xs={true}>
                          {ev.type === 'regimen' && <Regimen regimen={ev.data as Regimen} />}

                          {ev.type === 'ending' && <Ending doc={d} />}
                        </Item>
                      </Container>
                    </>
                  )}
                </React.Fragment>
              );
            })}
        </Item>
        <Item xs={0.5}></Item>
        <Item xs={true}>
          <Container direction={'column'} style={{ marginBottom: '2rem' }}>
            <StyledButtonArea style={{ marginTop: '0.5rem' }}>
              <EditButton
                text="general.edit"
                document={d}
                documentType={d._type}
                startEdit={startEdit}
                buttonProps={{
                  width: 22,
                  height: 3,
                  // @ts-expect-error Field type does not exist with plasmaferesis anymore, but disable editing it until migration is done
                  disabled: d._type === 'otherTreatment' && d.type === 'plasmaferesis' ? true : undefined,
                }}
              />
            </StyledButtonArea>

            <StyledButtonArea style={{ marginTop: '0.5rem' }}>
              <DeleteButton
                text="general.delete"
                document={d}
                buttonProps={{
                  width: 22,
                  height: 3,
                }}
              />
            </StyledButtonArea>
          </Container>
        </Item>
      </Container>
    </>
  );
};

interface IOwnProps {
  d: IMedicationOtherTreatment | IProcedure;
  startEdit: (document: { _id?: string }, name?: string) => (e: React.MouseEvent) => void;
}

export default OtherTreatmentsHistoryRowData;
