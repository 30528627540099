import InfoPopover from 'Components/InfoPopover';
import * as React from 'react';
import { calculateFBIMODScore, fbiModFields } from 'Routes/DiagnosticSurvey/utils';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';

const questions = [
  'apathy',
  'lackOfInitiative',
  'indifference',
  'inflexibility',
  'disregardForSelf',
  'disorganization',
  'inattentiveness',
  'unawareOfSurroundings',
  'decreaseInSpeech',
  'perseverance',
  'irritability',
  'overlyJoking',
  'badJudgement',
  'hoarding',
  'unsuitableBehaviour',
  'impulsiveness',
  'restlessness',
  'aggressiveness',
  'hyperorality',
  'hypersexuality',
  'compulsiveBehaviour',
  'incontinency',
];

const FBIMODForm = ({ formData, view, fm }: IFormContext<IFBIMOD>): JSX.Element => {
  const viewing = !!view?.viewing;

  const totalScore = React.useMemo(() => calculateFBIMODScore(formData.document), [formData.document]);
  const notFilledFields = React.useMemo(
    () => fbiModFields.filter((f) => !Object.keys(formData.document).includes(f)),
    [formData.document],
  );

  return (
    <React.Fragment>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={!viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow title="diagnosticSurvey.fbimod.respondent">
        <InputHandler
          type="Radio"
          editing={!viewing}
          name="respondent"
          formData={formData}
          options={['spouse', 'child', 'other']}
          optionFormatter={(o: string | number) => fm('diagnosticSurvey.fbimod.opts.' + o)}
        />
      </FormRow>
      <FormRow title="diagnosticSurvey.fbimod.respondentOther" condition={formData.document.respondent === 'other'}>
        <InputHandler
          type="TextField"
          editing={!viewing}
          name="respondentOther"
          formData={formData}
          placeholder={'diagnosticSurvey.fbimod.respondentOtherPlaceholder'}
        />
      </FormRow>
      <FormSection>
        {questions.map((q) => (
          <FormRow key={q} title={'diagnosticSurvey.fbimod.' + q}>
            <InputHandler
              type={'Radio'}
              name={q}
              formData={formData}
              options={['0', '1', '2', '3']}
              optionFormatter={(o: string | number) =>
                fm('diagnosticSurvey.fbimod.opts.' + o + `${(o == 3 || o == 0) && q === 'disregardForSelf' ? 'b' : ''}`)
              }
              editing={!viewing}
            />
            <div style={{ marginTop: '2rem' }}>
              <InputHandler
                type={'TextArea'}
                name={q + 'Detail'}
                formData={formData}
                editing={!viewing}
                placeholder={'diagnosticSurvey.fbimod.details'}
                width={40}
                rows={3}
              />
            </div>
          </FormRow>
        ))}
      </FormSection>
      <FormRow title="diagnosticSurvey.fbimod.score">
        <div style={{ fontWeight: 600 }}>
          {totalScore || totalScore === 0 ? (
            fm('diagnosticSurvey.fbimod.scoreTotal', { value: totalScore })
          ) : (
            <InfoPopover
              text={
                <div style={{ whiteSpace: 'pre-line' }}>
                  <div style={{ fontWeight: 600 }}>{`${fm('diagnosticSurvey.fbimod.notFilled')} ${fm(
                    'diagnosticSurvey.fbimod.remaining',
                  )}`}</div>
                  {notFilledFields.map((field) => `${fm(`diagnosticSurvey.fbimod.${field}`)}\n`).join('')}
                </div>
              }
              color="primary"
            />
          )}
        </div>
      </FormRow>
    </React.Fragment>
  );
};

export default withFormContext(FBIMODForm);
